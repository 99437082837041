<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-blOceanVesselCode'] == null || model['error-blOceanVesselCode'] == '' || model['error-blOceanVesselCode'] == undefined ">
          {{model['error-blOceanVesselCode']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselVoyage'] == null || model['error-blOceanVesselVoyage'] == '' || model['error-blOceanVesselVoyage'] == undefined ">
          {{model['error-blOceanVesselVoyage']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselBound'] == null || model['error-blOceanVesselBound'] == '' || model['error-blOceanVesselBound'] == undefined ">
          {{model['error-blOceanVesselBound']}}
        </li>
      </ul>
    </div>

    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <div class="field">
        <div class="field" style="margin-top:10px;">
          <div class="ui radio checkbox" >
            <input type="radio"  name="rdDiscrepanciesContainersBy" [checked]="radioDiscrepanciesContainersByVessel == 'Y'" (change)="changeEventDiscrepanciesContainersByVessel($event)" value="vessel">
            <label>Vessel Wise</label>
          </div>
        </div>
      </div>


      <div class="field">
        <div class="three fields">
          <div class="field">
            <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%">
              <div class="four wide fields">
                <div class="required two field" style="width:100%" [class.error]="model['error-blOceanVesselCode'] != null && model['error-blOceanVesselCode'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" [disableCombo]="radioDiscrepanciesContainersByVessel == 'N'" style="width:100%;"></combo-plugin>
                </div>

                <div class="required one field" style="width:70%" [class.error]="model['error-blOceanVesselVoyage'] != null && model['error-blOceanVesselVoyage'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" [disableCombo]="radioDiscrepanciesContainersByVessel == 'N'" style="width:100%;"></combo-plugin>
                </div>

                <div class="required one field" style="width:45%" [class.error]="model['error-blOceanVesselBound'] != null && model['error-blOceanVesselBound'] != ''">
                  <label>Bound</label>
                  <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['blOceanVesselBound']" [disabled]="radioDiscrepanciesContainersByVessel == 'N'" >
                </div
                >
              </div>

            </fieldset>

          </div>

          <div class="field" style="width:20%;">
            <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%; padding-top:35px;" [disabled]="radioDiscrepanciesContainersByVessel == 'N'? true:false">
              <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
              <div class="three fields">
                <div class="field" style="margin-top:10px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdContainerOwnership" [checked]="radioContainerOwnership == 'ALL'" (change)="changeEventContainerOwnership($event)" value="ALL" >
                    <label>ALL</label>
                  </div>
                </div>
                <div class="field" style="margin-top:10px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdContainerOwnership" [checked]="radioContainerOwnership == 'SOC'" (change)="changeEventContainerOwnership($event)" value="SOC">
                    <label>SOC</label>
                  </div>
                </div>
                <div class="field" style="margin-top:10px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdContainerOwnership" [checked]="radioContainerOwnership == 'COC'" (change)="changeEventContainerOwnership($event)" value="COC">
                    <label>COC</label>
                  </div>
                </div>
                <div class="field" style="margin-top:10px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdContainerOwnership" [checked]="radioContainerOwnership == 'SLOT'" (change)="changeEventContainerOwnership($event)" value="SLOT">
                    <label>SLOT</label>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>


        </div>
      </div>

    </form>
  </div>  <!-- end div container -->
</form>



<div id="pdfDiscrepanciesContainersReport" style="display:none;">
  <div class="ui form">   
    <div class="field">
      <div id='pdfDiscrepanciesContainersReport-viewer' class="field" style="width:100%;height:570px;">
      </div>
    </div>
  </div>
</div>

<footer-plugin [info]="info"></footer-plugin>


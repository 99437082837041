import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { interval } from "rxjs/observable/interval";
import FormValidation from "../../utils/formValidation";
import { L } from "@angular/core/src/render3";
import { TransactionBlService } from "./transaction-bl.service";

declare var $: any;

export class BLFlagStatusParam {
  blOfficeCode = "";
  blVesselId = "";
  blVoyage = "";
  blBound = "O";
  blPOLCode = "";
  blPOLName = "";
  blPODCode = "";
  blPODName = "";
  blPORCode = "";
  blPORName = "";
  blPOT1Code = "";
  blPOT1Name = "";
  blFDESTCode = "";
  blFDESTName = "";
  blIsCOC = true;
  blIsSOC = true;
  blIsSLOT = true;
  blIsNVOCC = false;
  blLocalTransshipment = "ALL";
  blFullEmpty = "ALL";
  blShipperDraftBLNumber = "";
  blIsFinished = false;
  blIsNotFinished = false;
  blIsDeleted = false;
  blIsWithoutContainer = false;
  blIsWithoutCharge = false;
  blTotalSOC = 0;
  blTotalCOC = 0;
  blTotalSLOT = 0;
  blTotalAll = 0;
  blTotalNVOCC = 0;
  blTotalDeleted = 0;
  selected = "";
  constructor() {}
}

@Component({
  selector: "app-transaction-bl-flag-status-latest",
  templateUrl: "./transaction-bl-flag-status-latest-page.component.html",
  styleUrls: ["./transaction-bl-flag-status-latest-page.component.css"],
})
export class TransactionBlFlagStatusLatestPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbPOL") cbPOL: ComboPluginComponent;
  @ViewChild("cbPOD") cbPOD: ComboPluginComponent;
  @ViewChild("cbPOR") cbPOR: ComboPluginComponent;
  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;
  @ViewChild("cbFDEST") cbFDEST: ComboPluginComponent;
  @ViewChild("cbLocalTransshipment") cbLocalTransshipment: ComboPluginComponent;
  @ViewChild("cbFullEmpty") cbFullEmpty: ComboPluginComponent;
  @ViewChild("grid") grid: GridPluginComponent;

  /* Parameter for information into Footer */
  info = "";
  defaultColumnDefs;
  gridApi;
  rowData = [];
  rowDataTotal: any;
  progressBarValue = 0;
  progressBarMessage = "";
  getRowStyle;
  currentMenu;
  viewOnlyStatus = false;

  /* Parameter for dialog */
  dialog: any;

  loading = false;
  lock = true;
  isLoad = false;
  isError = false;

  formValidation = new FormValidation();

  validatorRules = {
    blVesselId: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Vessel.",
        },
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regVesVoy,
          prompt: "Only allowed special characters .-/",
        },
      ],
    },
    blVoyage: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Voyage.",
        },
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regVesVoy,
          prompt: "Only allowed special characters .-/",
        },
      ],
    },
    blBound: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Bound.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[iImMoO]*$/,
          prompt: "Only allowed characters I,M,O",
        },
      ],
    },
  };

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;

  settingPOL;
  settingPOD;
  settingPOR;
  settingPOT1;
  settingFDEST;
  settingLocalTransshipment;
  settingFullEmpty;

  settingGrid;

  frmDialogMessage = "";

  // This form model
  model = new BLFlagStatusParam();
  columnApi: any;
  filterModel: any;
  containerPreSave: any;

  constructor(
    private router: Router,
    private genericUtil: GenericUtil,
    private genericService: GenericService,
    private configService: ConfigService,
    private cookieService: CookieService,
    private transactionBlService: TransactionBlService
  ) {
    super();

    this.defaultColumnDefs = [
      { headerName: "BL Reference", field: "blNo", width: 150 },
      { headerName: "BLS", field: "blStatus", width: 75 },
      { headerName: "F", field: "blIsFinished", width: 75 },
      { headerName: "POR", field: "blPlaceOfReceiptName" },
      { headerName: "POL", field: "blLoadPortName" },
      { headerName: "POD", field: "blDischargePortName" },
      { headerName: "PODL", field: "blPlaceOfDeliveryName" },
      { headerName: "FD", field: "blFinalDestinationName" },
      { headerName: "POT(1)", field: "blPortOfTransshipment1Name" },
      {
        headerName: "Freight Party",
        field: "blFreightPartyName",
        tooltipField: "blFreightPartyName",
      },
      {
        headerName: "Shipper",
        field: "blShipperName",
        tooltipField: "blShipperName",
      },
      {
        headerName: "Consignee",
        field: "blConsigneeName",
        tooltipField: "blConsigneeName",
      },
      {
        headerName: "Notify Party",
        field: "blNotifyPartyName",
        tootipField: "blNotifyPartyName",
      },
      { headerName: "Created By", field: "blUserCreated" },
      { headerName: "Created Date", field: "blDateCreated" },
      { headerName: "Modified By", field: "blUserModified" },
      { headerName: "Modified Date", field: "blDateModified" },
      { headerName: "Deleted By", field: "blUserDeleted" },
      { headerName: "Deleted Date", field: "blDateDeleted" },
      { headerName: "Printed By", field: "blUserPrinted" },
      { headerName: "Printed Date", field: "blDatePrinted" },
    ];

    this.getRowStyle = function (params) {
      if (params.data.blStatus === "D") {
        return { "background-color": "lightcoral !important;" };
      }
    };

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "remove circle" },
        { name: "Export", event: "export", icon: "print" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BL Flag Status",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "-- Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 175 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "-- Voyage --",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingPOL = {
      id: "cbPOL",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingPOD = {
      id: "cbPOD",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingPOR = {
      id: "cbPOR",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingFDEST = {
      id: "cbFDDEST",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingLocalTransshipment = {
      id: "cbLocalTranshipment",
      type: "select", // search | select | select input
      url: '[{ "localTransshipmentCode" : "ALL", "localTransshipmentName" : "All/Local/Transshipment" },{ "localTransshipmentCode" : "LOCAL", "localTransshipmentName" : "Local" },{ "localTransshipmentCode" : "TRANSSHIPMENT", "localTransshipmentName" : "Transshipment" }]',
      urlType: "inject",
      placeholder: "All/Local/Transshipment",
      code: "localTransshipmentCode",
      title: "localTransshipmentName",
    };

    this.settingFullEmpty = {
      id: "cbFullEmpty",
      type: "select", // search | select | select input
      url: '[{ "fullEmptyCode" : "ALL", "fullEmptyName" : "All/Full/Empty" },{ "fullEmptyCode" : "FULL", "fullEmptyName" : "Full" },{ "fullEmptyCode" : "EMPTY", "fullEmptyName" : "Empty" }]',
      urlType: "inject",
      placeholder: "All/Full/Empty",
      code: "fullEmptyCode",
      title: "fullEmptyName",
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() + "/BL/findBlFlagStatus",
      page: 10,
      columns: [
        { header: "BL Reference", field: "blNo", width: 150 },
        { header: "BLS", field: "blStatus", width: 75 },
        { header: "F", field: "blIsFinished", width: 75 },
        // {header: 'C/S/P', field: 'blContainerOwnership', width: 75},
        {
          header: "POR",
          field: "blPlaceOfReceiptName",
          width: 100,
          type: "string",
        },
        { header: "POL", field: "blLoadPortName", width: 100, type: "string" },
        {
          header: "POD",
          field: "blDischargePortName",
          width: 100,
          type: "string",
        },
        {
          header: "PODL",
          field: "blPlaceOfDeliveryName",
          width: 100,
          type: "string",
        },
        {
          header: "FD",
          field: "blFinalDestinationName",
          width: 100,
          type: "string",
        },
        {
          header: "POT(1)",
          field: "blPortOfTransshipment1Name",
          width: 100,
          type: "string",
        },
        {
          header: "Freight Party",
          field: "blFreightPartyName",
          width: 100,
          type: "string",
        },
        {
          header: "Shipper",
          field: "blShipperName",
          width: 100,
          type: "string",
        },
        {
          header: "Consignee",
          field: "blConsigneeName",
          width: 100,
          type: "string",
        },
        {
          header: "Notify Party",
          field: "blNotifyPartyName",
          width: 100,
          type: "string",
        },
        { header: "Created By", field: "blUserCreated", width: 110 },
        { header: "Created Date", field: "blDateCreated", width: 110 },
        { header: "Modified By", field: "blUserModified", width: 110 },
        { header: "Modified Date", field: "blDateModified", width: 110 },
        { header: "Deleted By", field: "blUserDeleted", width: 110 },
        { header: "Deleted Date", field: "blDateDeleted", width: 110 },
        { header: "Printed By", field: "blUserPrinted", width: 110 },
        { header: "Printed Date", field: "blDatePrinted", width: 110 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      enableHideColumns: false,
      sortingColumns: "blDateModified",
      sortingDirection: "DESC",
    };
  }

  ngOnInit() {
    this.transactionBlService.containerState$.subscribe(res => {
      if (res) {
        this.containerPreSave = res;
      }
    })
  }

  ngAfterViewInit() {
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();

    if (localStorage.getItem("close-bl-to-flag") != null) {
      this.model = JSON.parse(localStorage.getItem("close-bl-to-flag"));
      console.log(this.model);

      var self = this;
      this.cbVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/vesselId={query}"
      );
      this.cbVessel.setValue(
        String(this.model.blVesselId).trim(),
        function (callbackVessel) {
          self.model.blVesselId = self.model.blVesselId;
          self.cbVessel.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterVessels/findByComboBoxControl/vesselName={query}"
          );

          self.cbVoyage.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
              self.cookieService.getDefaultLocationCode() +
              "/" +
              self.model.blVesselId +
              "/" +
              self.model.blBound +
              "/voyage_like={query}"
          );
          //self.cbVoyage.setValue(self.model.blVoyage);
          self.cbVoyage.setForceValue(self.model.blVoyage)

          var hitUrlPOL: string =
            self.configService.config.BASE_API.toString() +
            "/BLFlagStatus/findPOL" +
            "/" +
            self.cookieService.getDefaultLocationCode() +
            "/" +
            self.model.blVesselId +
            "/" +
            self.model.blVoyage +
            "/" +
            self.model.blBound +
            "/{query}";

          var hitUrlPOD: string =
            self.configService.config.BASE_API.toString() +
            "/BLFlagStatus/findPOD" +
            "/" +
            self.cookieService.getDefaultLocationCode() +
            "/" +
            self.model.blVesselId +
            "/" +
            self.model.blVoyage +
            "/" +
            self.model.blBound +
            "/{query}";

          var hitUrlPOR: string =
            self.configService.config.BASE_API.toString() +
            "/BLFlagStatus/findPOR" +
            "/" +
            self.cookieService.getDefaultLocationCode() +
            "/" +
            self.model.blVesselId +
            "/" +
            self.model.blVoyage +
            "/" +
            self.model.blBound +
            "/{query}";

          var hitUrlPOT1: string =
            self.configService.config.BASE_API.toString() +
            "/BLFlagStatus/findPOT1" +
            "/" +
            self.cookieService.getDefaultLocationCode() +
            "/" +
            self.model.blVesselId +
            "/" +
            self.model.blVoyage +
            "/" +
            self.model.blBound +
            "/{query}";

          var hitUrlFDEST: string =
            self.configService.config.BASE_API.toString() +
            "/BLFlagStatus/findFDEST" +
            "/" +
            self.cookieService.getDefaultLocationCode() +
            "/" +
            self.model.blVesselId +
            "/" +
            self.model.blVoyage +
            "/" +
            self.model.blBound +
            "/{query}";

          self.cbPOL.setUrl(hitUrlPOL);
          self.cbPOD.setUrl(hitUrlPOD);
          self.cbPOR.setUrl(hitUrlPOR);
          self.cbPOT1.setUrl(hitUrlPOT1);
          self.cbFDEST.setUrl(hitUrlFDEST);

          self.cbPOL.setValue(self.model.blPOLName);
          self.cbPOD.setValue(self.model.blPODName);
          self.cbPOR.setValue(self.model.blPORName);
          self.cbPOT1.setValue(self.model.blPOT1Name);
          self.cbFDEST.setValue(self.model.blFDESTName);

          if (
            self.model.blFullEmpty === "ALL" ||
            self.model.blFullEmpty === ""
          ) {
            self.cbFullEmpty.setValue("All/Full/Empty");
          } else if (self.model.blFullEmpty === "FULL") {
            self.cbFullEmpty.setValue("Full");
          } else if (self.model.blFullEmpty === "EMPTY") {
            self.cbFullEmpty.setValue("Empty");
          }

          if (
            self.model.blLocalTransshipment === "ALL" ||
            self.model.blLocalTransshipment === ""
          ) {
            self.cbLocalTransshipment.setValue("All/Local/Transshipment");
          } else if (self.model.blLocalTransshipment === "LOCAL") {
            self.cbLocalTransshipment.setValue("Local");
          } else if (self.model.blLocalTransshipment === "TRANSSHIPMENT") {
            self.cbLocalTransshipment.setValue("Transshipment");
          }

          if (self.model.blVesselId != "" && self.model.blVoyage != "") {
            self.isLoad = true;
            //self.grid.setCurrentPage(Number.parseInt(localStorage.getItem("bl-flag-status-current-page")));
            //self.grid.setBody(self.model);
            //self.grid.loadData();

            self.rowData = JSON.parse(
              localStorage.getItem("bl-flag-status-row-data")
            );
            self.rowDataTotal = JSON.parse(
              localStorage.getItem("bl-flag-status-row-data-total")
            );
            self.calculateFooter(self.rowDataTotal);

            localStorage.removeItem("bl-flag-status-row-data");
            localStorage.removeItem("bl-flag-status-row-data-total");
          }
        }
      );
      setTimeout(() => {
        self.restoreState();
      }, 10);
      self.model.blIsCOC = self.containerPreSave.isCOC;
      self.model.blIsSOC = self.containerPreSave.isSOC;
      self.model.blIsSLOT = self.containerPreSave.isSLOT;

      localStorage.removeItem("close-bl-to-flag");
    } else {
      this.cbFullEmpty.setValue("All/Full/Empty");
      this.cbLocalTransshipment.setValue("All/Local/Transshipment");
    }

    // reset window to 0.0
    window.scrollTo(0, 0);
  }

  init() {
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }
  }

  gridEvent(event) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              const dt = this.grid.getSelectedValues()[0];
              if (dt.blStatus === "D") {
                this.model.selected = dt.blNo;
                this.frmDialogMessage =
                  "Current B/L is Deleted by " +
                  dt.blUserDeleted +
                  ", View Only?";
                this.genericUtil.showDialog(
                  "blDeleteDialogYesNo",
                  "Confirm",
                  350,
                  150
                );
              } else {
                if (dt.bIsUnlocked === "N") {
                  // this.fullBookingNo =  dt.bPrefix + '-' + dt.bNo;
                  this.message(
                    "information",
                    "Confirmation",
                    "Current booking is locked! View only?",
                    "yesno",
                    {
                      yes: "this.continueToMaintenance(this.fullBookingNo);",
                      no: "",
                    }
                  );
                } else {
                  this.continueToBLMaintenance(dt.blNo);
                }
              }
              break;

            case "afterLoad":
              if (this.grid.listStore.store.length === 0) {
                if (this.isLoad) {
                  this.message(
                    "information",
                    "Information",
                    "No record(s) found.",
                    "okonly",
                    { ok: "this.loading=false;this.isLoad=false;" }
                  );
                  this.model.blTotalCOC = 0;
                  this.model.blTotalSOC = 0;
                  this.model.blTotalSLOT = 0;
                  this.model.blTotalAll = 0;
                  this.model.blTotalDeleted = 0;
                }
              } else {
                this.model.blTotalCOC = Number.parseInt(
                  this.grid.getRawJson()["total"]["totalCoc"]
                );
                this.model.blTotalSOC = Number.parseInt(
                  this.grid.getRawJson()["total"]["totalSoc"]
                );
                this.model.blTotalSLOT = Number.parseInt(
                  this.grid.getRawJson()["total"]["totalSlot"]
                );
                this.model.blTotalNVOCC = Number.parseInt(
                  this.grid.getRawJson()["total"]["totalNvocc"]
                );
                this.model.blTotalDeleted = Number.parseInt(
                  this.grid.getRawJson()["total"]["totalDeleted"]
                );
                this.model.blTotalAll =
                  this.model.blTotalCOC +
                  this.model.blTotalSOC +
                  this.model.blTotalSLOT;
              }
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  continueToBLMaintenance(blNo: string) {
    this.genericUtil.showLoaderPage();
    const self = this;
    // console.log('Above set time out ');
    setTimeout(function () {
      self.model.selected = blNo;

      localStorage.setItem("retrieve-bl-from-flag", JSON.stringify(self.model));
      localStorage.setItem(
        "bl-flag-status-current-page",
        self.grid.getCurrentPage().toString()
      );

      // grid logic to retain previous retrieval value
      localStorage.setItem(
        "bl-flag-status-row-data",
        JSON.stringify(self.rowData)
      );
      localStorage.setItem(
        "bl-flag-status-row-data-total",
        JSON.stringify(self.rowDataTotal)
      );

      self.saveState();
      self.saveFilterModel();

      self.transactionBlService.temporarySaveContainer({
        isCOC: self.model.blIsCOC,
        isSOC: self.model.blIsSOC,
        isSLOT: self.model.blIsSLOT
      });

      if (self.viewOnlyStatus) {
        localStorage.setItem("view-only-mode", "Y");
      }

      self.router.navigate(["/main/transaction-bl-maintenance-latest"]);
    }, 100);
  }

  toolbarEvent(event: any) {
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        this.router.navigateByUrl("/main/home");
        break;
      case 'export':
        if (this.rowData.length > 0) {
          this.gridApi.exportDataAsExcel();
        } else {
          this.message(
            "information",
            "Information",
            "No record to export.",
            "okonly",
            { ok: "this.loading=false;this.isLoad=false;this.clearGrid();" }
          )
        }
        break;
      default:
        break;
    }
  }

  /** Combo Change Event Block **/
  changeEventVessel(event) {
    // console.log('Masuk event vessel');
    // console.log(event);
    if (
      event.vesselId != null &&
      event.vesselId !== "" &&
      event.vesselId !== undefined
    ) {
      this.model.blVesselId = event.vesselId;
      this.model[`error-blVesselId`] = "";
      // console.log('masuk sini');
      // reset and set voyage url
      this.model.blVoyage = "";
      this.cbVoyage.setValue("");
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.blBound +
          "/voyage_like={query}"
      );
      console.log("#2");
    } else {
      this.model.blVesselId = "";
      this.model.blVoyage = "";
      this.cbVoyage.setValue("");
      this.cbVoyage.setUrl("");
      console.log("#3");
    }

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }
  changeEventVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage !== "" &&
      event.voyage !== undefined
    ) {
      console.log("#4");
      this.model.blVoyage = event.voyage;
      this.model[`error-blVoyage`] = "";
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.blVesselId +
          "/" +
          this.model.blBound +
          "/voyage_like={query}"
      );

      // Set POL, POD, PODT1, FDEST
      const hitUrlPOL: string =
        this.configService.config.BASE_API.toString() +
        "/BLFlagStatus/findPOL" +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blVesselId +
        "/" +
        this.model.blVoyage +
        "/" +
        this.model.blBound +
        "/{query}";
      const hitUrlPOD: string =
        this.configService.config.BASE_API.toString() +
        "/BLFlagStatus/findPOD" +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blVesselId +
        "/" +
        this.model.blVoyage +
        "/" +
        this.model.blBound +
        "/{query}";
      const hitUrlPOR: string =
        this.configService.config.BASE_API.toString() +
        "/BLFlagStatus/findPOR" +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blVesselId +
        "/" +
        this.model.blVoyage +
        "/" +
        this.model.blBound +
        "/{query}";
      const hitUrlPOT1: string =
        this.configService.config.BASE_API.toString() +
        "/BLFlagStatus/findPOT1" +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blVesselId +
        "/" +
        this.model.blVoyage +
        "/" +
        this.model.blBound +
        "/{query}";
      const hitUrlFDEST: string =
        this.configService.config.BASE_API.toString() +
        "/BLFlagStatus/findFDEST" +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blVesselId +
        "/" +
        this.model.blVoyage +
        "/" +
        this.model.blBound +
        "/{query}";

      this.cbPOL.setUrl(hitUrlPOL);
      this.cbPOD.setUrl(hitUrlPOD);
      this.cbPOR.setUrl(hitUrlPOR);
      this.cbPOT1.setUrl(hitUrlPOT1);
      this.cbFDEST.setUrl(hitUrlFDEST);
      this.isError = false;
    } else {
      console.log("#5");
      this.model.blVoyage = "";
      this.cbPOL.setUrl("");
      this.cbPOD.setUrl("");
      this.cbPOR.setUrl("");
      this.cbPOT1.setUrl("");
      this.cbFDEST.setUrl("");
    }
    this.checkErrorblVesselIdblVoyage();
  }

  changeEventBound(event) {
    // console.log(event);
    this.model.blBound = this.model.blBound.toUpperCase();

    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blVesselId +
        "/" +
        this.model.blBound +
        "/voyage_like={query}"
    );

    // Set POL, POD, PODT1, FDEST
    const hitUrlPOL: string =
      this.configService.config.BASE_API.toString() +
      "/BLFlagStatus/findPOL" +
      "/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      this.model.blVesselId +
      "/" +
      this.model.blVoyage +
      "/" +
      this.model.blBound +
      "/{query}";
    const hitUrlPOD: string =
      this.configService.config.BASE_API.toString() +
      "/BLFlagStatus/findPOD" +
      "/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      this.model.blVesselId +
      "/" +
      this.model.blVoyage +
      "/" +
      this.model.blBound +
      "/{query}";
    const hitUrlPOR: string =
      this.configService.config.BASE_API.toString() +
      "/BLFlagStatus/findPOR" +
      "/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      this.model.blVesselId +
      "/" +
      this.model.blVoyage +
      "/" +
      this.model.blBound +
      "/{query}";
    const hitUrlPOT1: string =
      this.configService.config.BASE_API.toString() +
      "/BLFlagStatus/findPOT1" +
      "/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      this.model.blVesselId +
      "/" +
      this.model.blVoyage +
      "/" +
      this.model.blBound +
      "/{query}";
    const hitUrlFDEST: string =
      this.configService.config.BASE_API.toString() +
      "/BLFlagStatus/findFDEST" +
      "/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      this.model.blVesselId +
      "/" +
      this.model.blVoyage +
      "/" +
      this.model.blBound +
      "/{query}";

    this.cbPOL.setUrl(hitUrlPOL);
    this.cbPOD.setUrl(hitUrlPOD);
    this.cbPOR.setUrl(hitUrlPOR);
    this.cbPOT1.setUrl(hitUrlPOT1);
    this.cbFDEST.setUrl(hitUrlFDEST);
  }

  changeEventPOL(event) {
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPOLCode = event.locationCode;
      this.model.blPOLName = event.locationName;
    } else {
      this.model.blPOLCode = "";
      this.model.blPOLName = "";
    }
  }
  changeEventPOD(event) {
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPODCode = event.locationCode;
      this.model.blPODName = event.locationName;
    } else {
      this.model.blPODCode = "";
      this.model.blPODName = "";
    }
  }
  changeEventPOR(event) {
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPORCode = event.locationCode;
      this.model.blPORName = event.locationName;
    } else {
      this.model.blPORCode = "";
      this.model.blPORName = "";
    }
  }
  changeEventPOT1(event) {
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPOT1Code = event.locationCode;
      this.model.blPOT1Name = event.locationName;
    } else {
      this.model.blPOT1Code = "";
      this.model.blPOT1Name = "";
    }
  }
  changeEventFDEST(event) {
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blFDESTCode = event.locationCode;
      this.model.blFDESTName = event.locationName;
    } else {
      this.model.blFDESTCode = "";
      this.model.blFDESTName = "";
    }
  }
  changeEventLocalTransshipment(event) {
    if (
      event.localTransshipmentCode != null &&
      event.localTransshipmentCode !== "" &&
      event.localTransshipmentCode !== undefined
    ) {
      this.model.blLocalTransshipment = event.localTransshipmentCode;
    } else {
      this.model.blLocalTransshipment = "";
    }
  }
  changeEventFullEmpty(event) {
    if (
      event.fullEmptyCode != null &&
      event.fullEmptyCode !== "" &&
      event.fullEmptyCode !== undefined
    ) {
      this.model.blFullEmpty = event.fullEmptyCode;
    } else {
      this.model.blFullEmpty = "";
    }
  }
  /** End of Combo Change Event Block **/

  /** Check Box change event block **/
  changeEventCheckBoxIsCOC(event) {
    if (event.target.checked) {
      this.model.blIsCOC = true;
    } else {
      this.model.blIsCOC = false;
    }
  }
  changeEventCheckBoxIsSOC(event) {
    if (event.target.checked) {
      this.model.blIsSOC = true;
    } else {
      this.model.blIsSOC = false;
    }
  }
  changeEventCheckBoxIsSLOT(event) {
    if (event.target.checked) {
      this.model.blIsSLOT = true;
    } else {
      this.model.blIsSLOT = false;
    }
  }
  changeEventCheckBoxIsNVOCC(event) {
    if (event.target.checked) {
      this.model.blIsNVOCC = true;
    } else {
      this.model.blIsNVOCC = false;
    }
  }
  changeEventCheckBoxIsFinished(event) {
    if (event.target.checked) {
      this.model.blIsFinished = true;
    } else {
      this.model.blIsFinished = false;
    }
  }
  changeEventCheckBoxIsNotFinished(event) {
    if (event.target.checked) {
      this.model.blIsNotFinished = true;
    } else {
      this.model.blIsNotFinished = false;
    }
  }
  changeEventCheckBoxIsDeleted(event) {
    if (event.target.checked) {
      this.model.blIsDeleted = true;
    } else {
      this.model.blIsDeleted = false;
    }
  }
  changeEventCheckBoxIsWithoutContainer(event) {
    if (event.target.checked) {
      this.model.blIsWithoutContainer = true;
    } else {
      this.model.blIsWithoutContainer = false;
    }
  }
  changeEventCheckBoxIsWithoutCharge(event) {
    if (event.target.checked) {
      this.model.blIsWithoutCharge = true;
    } else {
      this.model.blIsWithoutCharge = false;
    }
  }

  /** End of Check box change event **/

  /** Misc Event Block **/
  handleCancel() {
    this.isError = false;

    this.model = new BLFlagStatusParam();
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");

    this.cbVessel.close();

    this.cbVoyage.setUrl("");
    this.cbPOL.setUrl("");
    this.cbPOL.setValue("");
    this.cbPOD.setUrl("");
    this.cbPOD.setValue("");
    this.cbPOR.setUrl("");
    this.cbPOR.setValue("");
    this.cbPOT1.setUrl("");
    this.cbPOT1.setValue("");
    this.cbFDEST.setUrl("");
    this.cbFDEST.setValue("");

    const self = this;
    this.cbLocalTransshipment.clearSelect();
    this.cbFullEmpty.clearSelect();
    setTimeout(() => {
      self.cbFullEmpty.setValue("All/Full/Empty");
      self.model.blFullEmpty = "ALL";
      self.cbLocalTransshipment.setValue("All/Local/Transshipment");
      self.model.blLocalTransshipment = "ALL";
    }, 10);

    this.grid.onClear();
    this.grid.curPage = 1;
    this.grid.currentPage = 1;
    this.grid.url = "";
    this.isLoad = false;
    this.grid.loadData();
    this.grid.url =
      this.configService.config.BASE_API.toString() + "/BL/findBlFlagStatus";

    // reset ag-grid
    this.rowData = [];

    // reset progress bar
    this.progressBarValue = 0;
    this.progressBarMessage = "";
    this.onProgress();

    // reset scroll position
    window.scrollTo(0, 0);
  }

  handleRetrieve() {
    this.saveFilterModel();
    var goErr = 0;
    if(this.model.blIsCOC ==  false && this.model.blIsSOC == false && this.model.blIsSLOT == false && this.model.blIsNVOCC == false){

      goErr = 1;

    }else{
      goErr = 0;
    }

    if(goErr == 0){
      this.progressBarValue = 0;
      this.progressBarMessage = "";
      this.model["error-blVoyage"] = "";
      this.model["error-blVesselId"] = "";

      if (
        this.model.blShipperDraftBLNumber == null ||
        this.model.blShipperDraftBLNumber === "" ||
        this.model.blShipperDraftBLNumber === undefined
      ) {
        this.isError = this.onValidate(this.model);
      } else {
        this.model["error-blVesselId"] = "";
        this.model["error-blVoyage"] = "";
        this.model["error-blBound"] = "";

        this.isError = false;
      }

      if (!this.isError) {
        this.grid.curPage = 1;
        this.grid.currentPage = 1;

        this.isLoad = true;
        this.grid.setBody(this.model);
        // this.grid.loadData();

        // ag-grid load
        this.gridApi.showLoadingOverlay();

        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/BL/findBlFlagStatus/1/10/DESC/blDateModified/" +
              this.cookieService.getName(),
            this.model
          )
          .subscribe((resp) => {
            console.log("dbeug");
            console.log(resp.json()["content"]);
            this.rowData = resp.json()["content"];
            this.rowDataTotal = resp.json()["total"];

            this.calculateFooter(this.rowDataTotal);
            setTimeout(() => {
              this.restoreFilterModel();
              this.gridApi.hideOverlay();
            }, 50);
          });

        // progress bar worker
        /**
        const self = this;
        const source = interval(500);
        const subscribe = source.subscribe(val => {
          this.genericService.GET(this.configService.config.BASE_API.toString() + '/BL/updateProgressBar/' + this.cookieService.getName() + '/BL_FLAG_STATUS').subscribe((resp) => {
            self.progressBarValue = resp.json()['progress'];
            self.progressBarMessage = '(Processing record = ' + resp.json()['message'] + ')';

            console.log('progress value = > ' + this.progressBarValue);
            if (self.progressBarValue >= 100){
              self.progressBarMessage = '';
              subscribe.unsubscribe();
            }
            self.onProgress();
          });
        });
        */
      }
    }else{
      this.message(
        "information",
        "Information",
        "Please select at least one [COC/SOC/SLOT/NVOCC].",
        "okonly",
        { ok: "this.loading=false;this.isLoad=false;" }
      )
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {}

  blockEnter(event) {
    const regexPattern = /^[iImMoO]*$/;

    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }
  /** End of Misc Event Block **/
  onDialogBlYesDelete() {
    this.continueToBLMaintenance(this.model.selected);
    this.genericUtil.closeDialog("blDeleteDialogYesNo");
  }

  onDialogBlNoDelete() {
    this.genericUtil.closeDialog("blDeleteDialogYesNo");
    localStorage.removeItem("navigateFromTransactionBlFlagStatus");
  }

  rowDoubleClick(params) {
    const dt = params.data;
    if (dt.blStatus === "D") {
      this.model.selected = dt.blNo;
      this.frmDialogMessage =
        "Current B/L is Deleted by " + dt.blUserDeleted + ", View Only?";
      localStorage.setItem("navigateFromTransactionBlFlagStatus", dt.blUserDeleted);
      this.genericUtil.showDialog("blDeleteDialogYesNo", "Confirm", 350, 150);
    } else {
      if (dt.bIsUnlocked === "N") {
        // this.fullBookingNo =  dt.bPrefix + '-' + dt.bNo;
        this.message(
          "information",
          "Confirmation",
          "Current booking is locked! View only?",
          "yesno",
          { yes: "this.continueToMaintenance(this.fullBookingNo);", no: "" }
        );
      } else {
        this.saveState();
        this.saveFilterModel();
        this.continueToBLMaintenance(dt.blNo);
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }

  onProgress() {
    $("#blflag-progress-bar").progress({
      percent: this.progressBarValue,
    });
  }

  calculateFooter(data: any) {
    this.model.blTotalCOC = Number.parseInt(data["totalCoc"]);
    this.model.blTotalSOC = Number.parseInt(data["totalSoc"]);
    this.model.blTotalSLOT = Number.parseInt(data["totalSlot"]);
    this.model.blTotalNVOCC = Number.parseInt(data["totalNvocc"]);
    this.model.blTotalDeleted = Number.parseInt(data["totalDeleted"]);
    this.model.blTotalAll =
      this.model.blTotalCOC + this.model.blTotalSOC + this.model.blTotalSLOT;
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar?: string
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
    this.checkErrorblVesselIdblVoyage();
  }

  checkErrorblVesselIdblVoyage(){
    if(!this.model[`error-blVesselId`] && !this.model[`error-blVoyage`]){
      this.isError = false;
    }else{
      this.isError = true;
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      return (this.model[`error-${field}`] = "");
    }

    return false;
  }

  backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string, fieldName?: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar, fieldName);
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar, fieldName) {
    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  saveState() {
    localStorage.setItem(
      "bl-flag-status-data-column-state",
      JSON.stringify(this.columnApi.getColumnState())
    );
  }

  restoreState() {
    const state = JSON.parse(localStorage.getItem("bl-flag-status-data-column-state"));
    if (!state) {
      console.log('no columns state to restore by, you must save state first');
      return;
    }
    this.columnApi.setColumnState(state);
    setTimeout(() => {
      this.restoreFilterModel();
    }, 100);
  }

  saveFilterModel() {
    this.filterModel = this.gridApi.getFilterModel();
    this.transactionBlService.saveFilterModel(this.filterModel);
  }

  restoreFilterModel() {
    const model = this.transactionBlService.getFilterModel();
    this.gridApi.setFilterModel(model);
  }
}

import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import {
  splitBL,
  gridAfterSplit,
  gridheaderSplit,
} from "../shared/models/splitBL";
import { isString } from "util";
import { Z_ASCII } from "zlib";
import { specialCharUtil } from "../shared/index";

import FormValidation from "../../utils/formValidation";
import { timingSafeEqual } from "crypto";
import { finalize } from "rxjs/operators";

declare var $: any;

export class BLSplit {
  bNo: String = "";
  blNo: String = "";

  ctype: string = "";

  d20: Number = 0;
  d40: Number = 0;
  d45: Number = 0;

  fr20: Number = 0;
  fr40: Number = 0;
  fr45: Number = 0;

  ot20: Number = 0;
  ot40: Number = 0;
  ot45: Number = 0;

  rd20: Number = 0;
  rd40: Number = 0;
  rd45: Number = 0;

  r20: Number = 0;
  r40: Number = 0;
  r45: Number = 0;

  tk20: Number = 0;
  tk40: Number = 0;
  tk45: Number = 0;

  pl20: Number = 0;
  pl40: Number = 0;
  pl45: Number = 0;

  // --------------------
  hc20: Number = 0;
  hc40: Number = 0;
  hc45: Number = 0;

  frh20: Number = 0;
  frh40: Number = 0;
  frh45: Number = 0;

  oth20: Number = 0;
  oth40: Number = 0;
  oth45: Number = 0;

  rdh20: Number = 0;
  rdh40: Number = 0;
  rdh45: Number = 0;

  rh20: Number = 0;
  rh40: Number = 0;
  rh45: Number = 0;

  tkh20: Number = 0;
  tkh40: Number = 0;
  tkh45: Number = 0;

  plh20: Number = 0;
  plh40: Number = 0;
  plh45: Number = 0;

  isNew: String = "";
}

export class ParamGenerate {
  officeCode: string = "";
  blNo: string = "";
  jml: Number = 0;
  lastNo: Number = 0;
}

export class ParamCheckBL {
  officeCode: string = "";
  defaultBLNumber: string = "";
  bls: any[] = [];
}

export class Param {
  blOfficeCode: string = "";
  blNo: string = "";
  bPrefix: string = "";
  bNo: string = "";
  qty: number = 0;
  aftersplitQty: number = 0;
  generateType: String = "SUFFIX";
  methodType: String = "FREIGHT";
  pol: string = "";
  pod: string = "";
  containerOwnership: string = "";
  user: string = "";
  ctype: string = "";
  blPrepaidAt1Code: string = "";
  blPrepaidAt2Code: string = "";
  blPrepaidAt3Code: string = "";
  blPayableAt1Code: string = "";
  blPayableAt2Code: string = "";
  blPayableAt3Code: string = "";
  blPayer1Code: string = "";
  blPayer2Code: string = "";
  blPayer3Code: string = "";
  blIsSplit: string = "";
  bSplitSeq: number = 0;
}

@Component({
  selector: "app-transaction-bl-split-latest-page",
  templateUrl: "./transaction-bl-split-latest-page.component.html",
  styleUrls: ["./transaction-bl-split-latest-page.component.css"],
})
export class TransactionBLSplitLatestPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  validatorRules: any;

  dialog: any;

  @ViewChild("gridQty") gridQty: GridPluginComponent;
  @ViewChild("gridBooking") gridBooking: GridPluginComponent;
  @ViewChild("gridMain") gridMain: GridPluginComponent;
  @ViewChild("gridSplit") gridSplit: GridPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";
  //typeSize = ["NORMAL","REEFER","PLATFORM","REEFER DRY","OPEN TOP","TANK"];

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGridBooking;
  settingGridMain;
  settingGridSplit;
  settingGridSplit2;
  splitStore = [];
  blNumberdefault = '';
  regexSuffixBlNumber = /[a-zA-Z]{2}[0-9]{4}/;
  splitDetails = null;
  sCharUtil = new specialCharUtil();
  formValidation = new FormValidation();

  isLoad: boolean = false;
  lock: boolean = true;
  lockQty: boolean = true;

  model = new Param();
  //generateType: String = "suffix";
  //methodType: String = "freight";
  lastVal: number = 0;
  lastNo: string = "";
  edited: boolean = false;
  totalB: number = 0;
  totalM: number = 0;
  totalS: number = 0;
  editM: number = 0;
  editS: number = 0;
  totalDiff: number = 0;
  typeDiff:string="";
  //lastQty:number = 0;
  msg: string = "";
  msgTypeQty: string ="";

  perCodeList: any[] = [];

  compareMoreMap = new Map<string, number>();
  compareMinMap = new Map<string, number>();
  showGrid = true;
  codeCN: boolean;
  isErrorSaveSplit: any;
  isInValidBl: boolean;
  resultNumberBl: any;

  get typeSuffix() {
    return this.model['generateType'] == 'SUFFIX' ? 1 : 2;
  }

  constructor(
    private auth: Authorize,
    private cdr: ChangeDetectorRef,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        //{ name: "Retrieve2", event: "retrieve2", icon: "search" },
        { name: "Split", event: "split", icon: "random" },
        { name: "Save", event: "save", icon: "save" },
        //{ name: "Save2", event: "save2", icon: "save" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BL Split",
    };

    this.settingGridBooking = {
      id: "gridBooking",
      url: "",
      columns: [
        { header: "Booking Ref", field: "bNo", width: 250 },
        { header: "D20", field: "d20", width: 70 },
        { header: "R20", field: "r20", width: 70 },
        { header: "HC20", field: "hc20", width: 70 },
        { header: "RH20", field: "rh20", width: 70 },
        { header: "D40", field: "d40", width: 70 },
        { header: "R40", field: "r40", width: 70 },
        { header: "HC40", field: "hc40", width: 70 },
        { header: "RH40", field: "rh40", width: 70 },
        { header: "D45", field: "d45", width: 70 },
        { header: "R45", field: "r45", width: 70 },
        { header: "HC45", field: "hc45", width: 70 },
        { header: "RH45", field: "rh45", width: 70 },
        { header: "TK20", field: "tk20", width: 70 },
        { header: "TK40", field: "tk40", width: 70 },
        { header: "TK45", field: "tk45", width: 70 },
      ],
      buttons: [],
      height: 40,
      minHeight: 80,
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
    };

    this.settingGridMain = {
      id: "gridMain",
      url: "",
      page: 10,
      columns: [
        { header: "Main B/L No", field: "blNo", width: 250 },
        { header: "D20", field: "d20", width: 70 },
        { header: "R20", field: "r20", width: 70 },
        { header: "HC20", field: "hc20", width: 70 },
        { header: "RH20", field: "rh20", width: 70 },
        { header: "D40", field: "d40", width: 70 },
        { header: "R40", field: "r40", width: 70 },
        { header: "HC40", field: "hc40", width: 70 },
        { header: "RH40", field: "rh40", width: 70 },
        { header: "D45", field: "d45", width: 70 },
        { header: "R45", field: "r45", width: 70 },
        { header: "HC45", field: "hc45", width: 70 },
        { header: "RH45", field: "rh45", width: 70 },
        { header: "TK20", field: "tk20", width: 70 },
        { header: "TK40", field: "tk40", width: 70 },
        { header: "TK45", field: "tk45", width: 70 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      height: 40,
      minHeight: 80,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
      editable: true,
    };

    this.settingGridSplit = {
      id: "gridSplit",
      url: "",
      page: 10,
      columns: [
        { header: "Split B/L No", field: "blNo", width: 250, editType: 'text' },
        {
          header: "D20",
          field: "d20",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "R20",
          field: "r20",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "HC20",
          field: "hc20",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "RH20",
          field: "rh20",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "D40",
          field: "d40",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "R40",
          field: "r40",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "HC40",
          field: "hc40",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "RH40",
          field: "rh40",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "D45",
          field: "d45",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "R45",
          field: "r45",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "HC45",
          field: "hc45",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "RH45",
          field: "rh45",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "TK20",
          field: "tk20",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "TK40",
          field: "tk40",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
        {
          header: "TK45",
          field: "tk45",
          width: 70,
          editType: "number",
          enableMinus: false,
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      height: 40,
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
      editable: true,
    };

    this.settingGridSplit2 = {
      id: "tblSplit",
      url: "",
      page: 10,
      columns: [
        { header: "Split B/L No", field: "blNo", width: 5},
        {
          header: "D20",
          field: "d20",
          width: 1,
          editType: "number",
        },
        {
          header: "R20",
          field: "r20",
          width: 1,
          editType: "number",
        },
        {
          header: "HC20",
          field: "hc20",
          width: 1,
          editType: "number",
        },
        {
          header: "RH20",
          field: "rh20",
          width: 1,
          editType: "number",
        },
        {
          header: "D40",
          field: "d40",
          width: 1,
          editType: "number",
        },
        {
          header: "R40",
          field: "r40",
          width: 1,
          editType: "number",
        },
        {
          header: "HC40",
          field: "hc40",
          width: 1,
          editType: "number",
        },
        {
          header: "RH40",
          field: "rh40",
          width: 1,
          editType: "number",
        },
        {
          header: "D45",
          field: "d45",
          width: 1,
          editType: "number",
        },
        {
          header: "R45",
          field: "r45",
          width: 1,
          editType: "number",
        },
        {
          header: "HC45",
          field: "hc45",
          width: 1,
          editType: "number",
        },
        {
          header: "RH45",
          field: "rh45",
          width: 1,
          editType: "number",
        },
        {
          header: "TK20",
          field: "tk20",
          width: 1,
          editType: "number",
        },
        {
          header: "TK40",
          field: "tk40",
          width: 1,
          editType: "number",
        },
        {
          header: "TK45",
          field: "tk45",
          width: 1,
          editType: "number",
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      height: 40,
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
    };
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.disableToolbarButtons = "save,save2,split,retrieve2";
    this.gridSplit.lock = true;
    this.cdr.detectChanges();
  }

  retrieve(event) {
    //  console.log(event);
    if (event.keyCode === 13) {
      this.onRetrieve();
    }
  }

  toolbarEvent(event) {
    switch (event) {
      case "retrieve":
        if (this.model.blNo == "") {
          this.msg = "Please input B/L number.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
        } else {
          this.onRetrieve();
        }
        break;
      case "retrieve2":
        if (this.model.blNo == "") {
          this.msg = "Please input B/L number.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
        } else {
          this.onRetrieve2();
        }
        break;
      case "save":
        //this.onSave();
        /*
        if(this.compareMinMap.size > 0){
          this.msg = "Container in BL does not tally with booking. Please check your container.";
          this.genericUtil.showDialog('msgDialog', "Information", 420, 160);
        }else{
          */
        //gso 65

        this.editS = 0;
        this.editM = 0;
        
        /*
        if (this.codeCN) {
          const bl = this.gridSplit.listStore.store.find((x) => x.blNo.length < 10);
          if (bl) {
            this.msg = 'Please input BL Number.';
            this.genericUtil.showDialog("msgDialog", "Warning", 420, 160);
            return;
          }
        }
        this.gridSplit.listStore.store.forEach((element, index) => {

          //nyari total yang di split
          if (this.codeCN && this.model['generateType'] == 'NEW') {
            this.validateRuleBl(element.blNo, index + 1);
          }
          console.log('nilai element split ');
          this.editS+=Number.parseInt(element['d20']);
          this.editS+=Number.parseInt(element['d40']);
          this.editS+=Number.parseInt(element['d45']);
          this.editS+=Number.parseInt(element['fr20']);
          this.editS+=Number.parseInt(element['fr40']);
          this.editS+=Number.parseInt(element['fr45']);
          this.editS+=Number.parseInt(element['frh20']);
          this.editS+=Number.parseInt(element['frh40']);
          this.editS+=Number.parseInt(element['frh45']);
          this.editS+=Number.parseInt(element['hc20']);
          this.editS+=Number.parseInt(element['hc40']);
          this.editS+=Number.parseInt(element['hc45']);
          this.editS+=Number.parseInt(element['ot20']);
          this.editS+=Number.parseInt(element['ot40']);
          this.editS+=Number.parseInt(element['ot45']);
          this.editS+=Number.parseInt(element['oth20']);
          this.editS+=Number.parseInt(element['oth40']);
          this.editS+=Number.parseInt(element['oth45']);
          this.editS+=Number.parseInt(element['pl20']);
          this.editS+=Number.parseInt(element['pl40']);
          this.editS+=Number.parseInt(element['pl45']);
          this.editS+=Number.parseInt(element['plh20']);
          this.editS+=Number.parseInt(element['plh40']);
          this.editS+=Number.parseInt(element['plh45']);
          this.editS+=Number.parseInt(element['r20']);
          this.editS+=Number.parseInt(element['r40']);
          this.editS+=Number.parseInt(element['r45']);
          this.editS+=Number.parseInt(element['rd20']);
          this.editS+=Number.parseInt(element['rd40']);
          this.editS+=Number.parseInt(element['rd45']);
          this.editS+=Number.parseInt(element['rdh20']);
          this.editS+=Number.parseInt(element['rdh40']);
          this.editS+=Number.parseInt(element['rdh45']);
          this.editS+=Number.parseInt(element['rh20']);
          this.editS+=Number.parseInt(element['rh40']);
          this.editS+=Number.parseInt(element['rh45']);
          this.editS+=Number.parseInt(element['tk20']);
          this.editS+=Number.parseInt(element['tk40']);
          this.editS+=Number.parseInt(element['tk45']);
          this.editS+=Number.parseInt(element['tkh20']);
          this.editS+=Number.parseInt(element['tkh40']);
          this.editS+=Number.parseInt(element['tkh45']);
        });

        this.gridMain.listStore.store.forEach((element)=>{
           //cek jumlah main

           this.editM+=Number.parseInt(element['d20']);
           this.editM+=Number.parseInt(element['d40']);
           this.editM+=Number.parseInt(element['d45']);
           this.editM+=Number.parseInt(element['fr20']);
           this.editM+=Number.parseInt(element['fr40']);
           this.editM+=Number.parseInt(element['fr45']);
           this.editM+=Number.parseInt(element['frh20']);
           this.editM+=Number.parseInt(element['frh40']);
           this.editM+=Number.parseInt(element['frh45']);
           this.editM+=Number.parseInt(element['hc20']);
           this.editM+=Number.parseInt(element['hc40']);
           this.editM+=Number.parseInt(element['hc45']);
           this.editM+=Number.parseInt(element['ot20']);
           this.editM+=Number.parseInt(element['ot40']);
           this.editM+=Number.parseInt(element['ot45']);
           this.editM+=Number.parseInt(element['oth20']);
           this.editM+=Number.parseInt(element['oth40']);
           this.editM+=Number.parseInt(element['oth45']);
           this.editM+=Number.parseInt(element['pl20']);
           this.editM+=Number.parseInt(element['pl40']);
           this.editM+=Number.parseInt(element['pl45']);
           this.editM+=Number.parseInt(element['plh20']);
           this.editM+=Number.parseInt(element['plh40']);
           this.editM+=Number.parseInt(element['plh45']);
           this.editM+=Number.parseInt(element['r20']);
           this.editM+=Number.parseInt(element['r40']);
           this.editM+=Number.parseInt(element['r45']);
           this.editM+=Number.parseInt(element['rd20']);
           this.editM+=Number.parseInt(element['rd40']);
           this.editM+=Number.parseInt(element['rd45']);
           this.editM+=Number.parseInt(element['rdh20']);
           this.editM+=Number.parseInt(element['rdh40']);
           this.editM+=Number.parseInt(element['rdh45']);
           this.editM+=Number.parseInt(element['rh20']);
           this.editM+=Number.parseInt(element['rh40']);
           this.editM+=Number.parseInt(element['rh45']);
           this.editM+=Number.parseInt(element['tk20']);
           this.editM+=Number.parseInt(element['tk40']);
           this.editM+=Number.parseInt(element['tk45']);
           this.editM+=Number.parseInt(element['tkh20']);
           this.editM+=Number.parseInt(element['tkh40']);
           this.editM+=Number.parseInt(element['tkh45']);

           //end cek jumlah main
        })

        if (this.isInValidBl) {
          return;
        }

        console.log('this jumlah main '+this.editM)
        console.log('this. jumlah slit '+this.editS)


        console.log("EDITED GA? : " + this.edited + " "+this.totalB+" "+this.editM+" "+this.editS);
        //if (!this.edited && this.totalB != this.totalM + this.totalS) {
          if ( this.totalB != this.editM + this.editS) { //gos 65 try fix
          this.msg = "Quantity in BL(s) does not equate to BKG Qty.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
        } else {
          if (this.model.methodType == "PRORATE" || (this.model['generateType'] == 'NEW' && this.codeCN) ) {
            let checkMap = new Map<String, String>();

            this.gridSplit.listStore.store.forEach((element) => {
              console.log(element.blNo);
              checkMap.set(element.blNo, element.blNo);
              this.perCodeList.forEach((el) => {
                if (element[el] != undefined && element[el] > 0) {
                  checkMap.delete(element.blNo);
                }
              });
            });

            console.log(checkMap);
            if (checkMap.size > 0) {
              var xx: any[] = [];
              checkMap.forEach((el) => {
                xx.push(el.toString());
              });

              console.log("(" + xx.toString() + ") Data Not Valid!.");
              //this.msg = "(" + xx.toString() + ") Data Not Valid!.";
              this.msg =
                "No quantity detected for below B/L(s): <br/>" +
                xx.toString() +
                "<br/><br/>&lt;Yes&gt; to proceed, these B/L(s) will be removed. <br/> &lt;No&gt; to edit.";
              this.genericUtil.showDialog(
                "saveDialog",
                "Information",
                650,
                200
              );
            } else {
              this.msg = "Are you sure to save following B/L ?";
              this.genericUtil.showDialog(
                "saveDialog",
                "Information",
                420,
                160
              );
            }
          } else {
            this.msg = "Are you sure to save following B/L ?";
            this.genericUtil.showDialog("saveDialog", "Information", 420, 160);
          }
        }
        */
        let bls:any[] = [];
        let errorNumber = 0;
        if(this.model['generateType'] == 'NEW'){
          
          
          if(this.splitStore.length > 1){
            let valueArr = this.splitStore.map(function(item){ return item.blNo.toLowerCase() });
            let isDuplicate = valueArr.some(function(item, idx){
              return valueArr.indexOf(item) != idx
            });
            if(isDuplicate){
              errorNumber = 3;
            }
          }
          
          this.splitStore.forEach(item=>{
            console.log(item)
            if(!item.blNo){
              errorNumber = 1;
            }
            if(item.blNo && this.regexSuffixBlNumber.test(item.blNo.toUpperCase()) === false && this.codeCN === true){
              errorNumber = 2;
            }

            if(this.codeCN && item.isNew != ''){
              bls.push(this.blNumberdefault.toUpperCase()+item.blNo.toUpperCase());
            }

          })

          if(errorNumber === 1){
            this.message(
              "warning",
              "Warning",
              "Please input BL Number.",
              "okonly",
              { ok: "this.loading=false;" }
            );
            return
          }
    
          if(errorNumber === 2){
            this.message(
              "warning",
              "Warning",
              "Should follow by 2 chars (ranging from AA to ZZ) and follow by 4 digits",
              "okonly",
              { ok: "this.loading=false;" }
            );
            return
          }
    
          if(errorNumber === 3){
            this.message(
              "warning",
              "Warning",
              "BL Reference is duplicated, please try again!",
              "okonly",
              { ok: "this.loading=false;" }
            );
            return
          }

          if(this.codeCN){
            if(bls.length > 0){
              let paramCheckBL = new ParamCheckBL();
              paramCheckBL.bls = bls;
              paramCheckBL.defaultBLNumber = this.blNumberdefault;
              paramCheckBL.officeCode = this.model.blOfficeCode;
              this.genericService
              .POST(
                this.configService.config.BASE_API.toString() + "/BLSplit/checkBLsExist",paramCheckBL
              )
              .subscribe((resp) => {
                if(resp.json()['message'] !== 'SUCCESS'){
                    this.message(
                      "warning",
                      "Warning",
                      resp.json()['message'],
                      "okonly",
                      { ok: "this.loading=false;" }
                    );
                    return
                }else{
                  this.checkBeforeSave(errorNumber);
                }
              });
            }else{
              this.checkBeforeSave(errorNumber);
            }

          }else{
            this.checkBeforeSave(errorNumber);
          }

        }else{
          this.checkBeforeSave(errorNumber);
        }

        //}
        break;
      case "save2":
        if (this.model.methodType == "PRORATE") {
          let checkMap = new Map<String, String>();

          this.gridSplit.listStore.store.forEach((element) => {
            console.log(element.blNo);
            checkMap.set(element.blNo, element.blNo);
            this.perCodeList.forEach((el) => {
              if (element[el] != undefined && element[el] > 0) {
                checkMap.delete(element.blNo);
              }
            });
          });

          console.log(checkMap);
          if (checkMap.size > 0) {
            var xx: any[] = [];
            checkMap.forEach((el) => {
              xx.push(el.toString());
            });

            console.log("(" + xx.toString() + ") Data Not Valid!.");
            //this.msg = "(" + xx.toString() + ") Data Not Valid!.";
            this.msg =
              "No quantity detected for below B/L(s): <br/>" +
              xx.toString() +
              "<br/><br/>&lt;Yes&gt; to proceed, these B/L(s) will be removed. <br/> &lt;No&gt; to edit.";
            this.genericUtil.showDialog("saveDialog2", "Information", 650, 200);
          } else {
            this.msg = "Are you sure to save following B/L ?";
            this.genericUtil.showDialog("saveDialog2", "Information", 420, 160);
          }
        } else {
          this.msg = "Are you sure to save following B/L ?";
          this.genericUtil.showDialog("saveDialog2", "Information", 420, 160);
        }

        //}
        break;
      case "split":
        //this.onSplit();
        if (this.model.qty > 0) {
          this.msg =
            "You are about to generate new B/L(s),<br/> YES to continue or NO to abort.";
          this.genericUtil.showDialog("splitDialog", "Information", 420, 160);
        } else {
          this.msg = "Number of BL cannot be empty.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
        }
        break;
      case "cancel":
        this.onCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  checkBeforeSave(errorNumber:number = 0){
    let checkMap = new Map<String, String>();
    //check Quantity
    this.splitStore.forEach(item=>{
      console.log(item);
      checkMap.set(item.blNo, item.blNo);
      this.perCodeList.forEach((el) => {
        
        if (item[el] != undefined && item[el] > 0) {
          checkMap.delete(item.blNo);
        }
      });
    })

    console.log("checkMap.size : " + checkMap.size);
    if (checkMap.size > 0) {
      errorNumber = 4;
    }

    this.splitStore.forEach((element, index) => {

      //nyari total yang di split
      console.log('nilai element split ');
      this.editS+=Number.parseInt(element['d20']);
      this.editS+=Number.parseInt(element['d40']);
      this.editS+=Number.parseInt(element['d45']);
      this.editS+=Number.parseInt(element['fr20']);
      this.editS+=Number.parseInt(element['fr40']);
      this.editS+=Number.parseInt(element['fr45']);
      this.editS+=Number.parseInt(element['frh20']);
      this.editS+=Number.parseInt(element['frh40']);
      this.editS+=Number.parseInt(element['frh45']);
      this.editS+=Number.parseInt(element['hc20']);
      this.editS+=Number.parseInt(element['hc40']);
      this.editS+=Number.parseInt(element['hc45']);
      this.editS+=Number.parseInt(element['ot20']);
      this.editS+=Number.parseInt(element['ot40']);
      this.editS+=Number.parseInt(element['ot45']);
      this.editS+=Number.parseInt(element['oth20']);
      this.editS+=Number.parseInt(element['oth40']);
      this.editS+=Number.parseInt(element['oth45']);
      this.editS+=Number.parseInt(element['pl20']);
      this.editS+=Number.parseInt(element['pl40']);
      this.editS+=Number.parseInt(element['pl45']);
      this.editS+=Number.parseInt(element['plh20']);
      this.editS+=Number.parseInt(element['plh40']);
      this.editS+=Number.parseInt(element['plh45']);
      this.editS+=Number.parseInt(element['r20']);
      this.editS+=Number.parseInt(element['r40']);
      this.editS+=Number.parseInt(element['r45']);
      this.editS+=Number.parseInt(element['rd20']);
      this.editS+=Number.parseInt(element['rd40']);
      this.editS+=Number.parseInt(element['rd45']);
      this.editS+=Number.parseInt(element['rdh20']);
      this.editS+=Number.parseInt(element['rdh40']);
      this.editS+=Number.parseInt(element['rdh45']);
      this.editS+=Number.parseInt(element['rh20']);
      this.editS+=Number.parseInt(element['rh40']);
      this.editS+=Number.parseInt(element['rh45']);
      this.editS+=Number.parseInt(element['tk20']);
      this.editS+=Number.parseInt(element['tk40']);
      this.editS+=Number.parseInt(element['tk45']);
      this.editS+=Number.parseInt(element['tkh20']);
      this.editS+=Number.parseInt(element['tkh40']);
      this.editS+=Number.parseInt(element['tkh45']);
    });

    this.gridMain.listStore.store.forEach((element)=>{
      //cek jumlah main

      this.editM+=Number.parseInt(element['d20']);
      this.editM+=Number.parseInt(element['d40']);
      this.editM+=Number.parseInt(element['d45']);
      this.editM+=Number.parseInt(element['fr20']);
      this.editM+=Number.parseInt(element['fr40']);
      this.editM+=Number.parseInt(element['fr45']);
      this.editM+=Number.parseInt(element['frh20']);
      this.editM+=Number.parseInt(element['frh40']);
      this.editM+=Number.parseInt(element['frh45']);
      this.editM+=Number.parseInt(element['hc20']);
      this.editM+=Number.parseInt(element['hc40']);
      this.editM+=Number.parseInt(element['hc45']);
      this.editM+=Number.parseInt(element['ot20']);
      this.editM+=Number.parseInt(element['ot40']);
      this.editM+=Number.parseInt(element['ot45']);
      this.editM+=Number.parseInt(element['oth20']);
      this.editM+=Number.parseInt(element['oth40']);
      this.editM+=Number.parseInt(element['oth45']);
      this.editM+=Number.parseInt(element['pl20']);
      this.editM+=Number.parseInt(element['pl40']);
      this.editM+=Number.parseInt(element['pl45']);
      this.editM+=Number.parseInt(element['plh20']);
      this.editM+=Number.parseInt(element['plh40']);
      this.editM+=Number.parseInt(element['plh45']);
      this.editM+=Number.parseInt(element['r20']);
      this.editM+=Number.parseInt(element['r40']);
      this.editM+=Number.parseInt(element['r45']);
      this.editM+=Number.parseInt(element['rd20']);
      this.editM+=Number.parseInt(element['rd40']);
      this.editM+=Number.parseInt(element['rd45']);
      this.editM+=Number.parseInt(element['rdh20']);
      this.editM+=Number.parseInt(element['rdh40']);
      this.editM+=Number.parseInt(element['rdh45']);
      this.editM+=Number.parseInt(element['rh20']);
      this.editM+=Number.parseInt(element['rh40']);
      this.editM+=Number.parseInt(element['rh45']);
      this.editM+=Number.parseInt(element['tk20']);
      this.editM+=Number.parseInt(element['tk40']);
      this.editM+=Number.parseInt(element['tk45']);
      this.editM+=Number.parseInt(element['tkh20']);
      this.editM+=Number.parseInt(element['tkh40']);
      this.editM+=Number.parseInt(element['tkh45']);

      //end cek jumlah main
    })

    console.log('this jumlah main '+this.editM)
    console.log('this. jumlah slit '+this.editS)

    console.log("EDITED GA? : " + this.edited + " "+this.totalB+" "+this.editM+" "+this.editS);
    //if (!this.edited && this.totalB != this.totalM + this.totalS) {
    if ( this.totalB != this.editM + this.editS) { //gos 65 try fix
      this.msg = "Quantity in BL(s) does not equate to BKG Qty.";
      this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
    } else {

      if (this.model.methodType == "PRORATE") {
        if (errorNumber === 4) {
          var xx: any[] = [];
          checkMap.forEach((el) => {
            xx.push(this.codeCN ? this.blNumberdefault + el.toString() : el.toString());
          });

          this.msg = "No quantity detected for below B/L(s): <br/>" +
          xx.toString() +
          "<br/><br/>&lt;Yes&gt; to proceed, these B/L(s) will be removed. <br/> &lt;No&gt; to edit.";
        
          this.genericUtil.showDialog(
            "saveDialog",
            "Information",
            650,
            200
          );
          return
        }
      }

      this.msg = "Are you sure to save following B/L ?";
      this.genericUtil.showDialog("saveDialog", "Information", 420, 160);

    }

  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventGenerateType(event) {
    console.log(event);
    if (event.target.value == "SUFFIX") {
      this.model.generateType = "SUFFIX";
    } else {
      this.model.generateType = "NEW";
    }
  }

  changeEventMethodType(event) {
    console.log(event);
    if (event.target.value == "FREIGHT") {
      this.model.methodType = "FREIGHT";
    } else {
      this.model.methodType = "PRORATE";
    }
  }

  gridBookingEvent(event) {}

  gridMainEvent(event) {}

  gridSplitEvent(event) {
    console.log(event);

    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      case "AfterEdit":
        this.editM = 0;
        this.editS = 0;
        this.edited = true;
        console.log("** AfterEdit Event **");
        /*
        var no = Number.parseInt(event.split(".")[1]);
        var field: string = event.split(";")[1];
        if (field === 'blNo') {
          const bl = this.gridSplit.listStore.store.find((x, index) => x.blNo.length !== 15 && index === no - 1);
          if (bl) {
            this.validateRuleBl(bl.blNo);
          } else {
            document.getElementById(`gridSplit.span.${this.resultNumberBl}.blNo`).style.color = 'black';
            document.getElementById(`gridSplit.td.${this.resultNumberBl}.blNo`).style.border = '1px solid rgba(34, 36, 38, .1)';
          }
          return;
        }

        var value: number = Number.parseInt(event.split(";")[2]);
        console.log("value => " + value);

        var isError: boolean = false;
        var xfield: number = 0;
        this.gridBooking.listStore.store.forEach((element) => {
          xfield = element[field];
        });
        console.log('gridsplit' )
        console.log(this.gridSplit.listStore.store)
        var xfieldx: number = 0;
        this.gridSplit.listStore.store.forEach((element) => {

          //nyari total yang di split

          console.log('nilai element ');
          //end nyari total yang di split


          if (element["no"] != no) {
            var xfieldxx: number = Number.parseInt(element[field]);
            xfieldx += xfieldxx;
          }
        });
        console.log('jumlah '+this.editS);
        console.log(this.gridMain.listStore.store)
        this.gridMain.listStore.store.forEach((element) => {
          xfield = xfield - (xfieldx + value);

          if (xfield < 0) {
            isError = true;
          } else if (xfield == 0) {
            this.gridMain.listStore.store.forEach((element) => {
              let hit: number = 0;
              this.perCodeList.forEach((el) => {
                if (el != field) {
                  if (element[el] != undefined) {
                    console.log(element[el]);
                    hit += Number.parseInt(element[el]);
                  }
                }
              });
              console.log("** hit **");
              console.log(hit);
              if (hit > 0) {
                element[field] = xfield;
              } else {
                isError = true;
              }
            });
          } else {
            element[field] = xfield;
          }
        });

        this.gridMain.loadData();


        if (isError) {
          this.message(
            "information",
            "Information",
            "Total Container Can't Exceed From Main BL.",
            "okonly",
            { ok: "" }
          );
          this.gridSplit.listStore.store.forEach((element) => {
            if (element["no"] == no) {
              console.log("** lastval 2 **");
              console.log(this.lastVal);
              element[field] = this.lastVal;
            }
          });
          this.gridSplit.loadData();
          this.gridSplit.setRenderValue(no, field, "number", this.lastVal);
        }
        */
        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split(";");
          switch (arr[0]) {
            case "edit":
              this.lastVal = Number.parseInt(arr[3]);
              console.log("** lastval **");
              console.log(this.lastVal);

              break;
            case "dblClick":
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  checkValidSchedule(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ): String {
    let validSch: String = "";
    console.log("CEK VALID 1 : " + validSch);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        let dtx: any[];
        let valid: String;
        dtx = resp.json()["content"];
        validSch = dtx[0]["isValid"];
        if (validSch == "N") {
          this.message(
            "information",
            "Information",
            "Schedule is invalid, unable to split B/L.",
            "okonly",
            { ok: "/*this.onCancel();*/" }
          );
          this.onCancel();
        }
      });
    console.log("CEK VALID 3 : " + validSch);

    return validSch;
  }

  onRetrieve() {
    this.genericUtil.showLoader();
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.user = this.cookieService.getName();
    this.model.blNo = this.model.blNo.trim().toUpperCase();
    this.edited = false;
    this.codeCN = this.model.blOfficeCode.substring(0, 2) === 'CN' ? true : false;
    this.blNumberdefault = "";

    // GOS-362
    this.msgTypeQty = "";
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() + "/BLSplit/getPerCode"
      )
      .subscribe((respx) => {
        this.perCodeList = respx.json();
        console.log(this.perCodeList);

        var self = this;
        setTimeout(() => {
          self.genericService
            .POST(
              self.configService.config.BASE_API.toString() +
                "/BLSplit/searchSplit",
              self.model
            )
            .pipe(finalize(() => {
              this.genericUtil.hideLoader();
            }))
            .subscribe((resp) => {
              console.log(resp.json());
              if (resp.json()["status"] == "OK") {
                //check valid schedule
                this.checkValidSchedule(
                  resp.json()["blOceanVesselId"],
                  resp.json()["blOceanVesselVoyage"],
                  resp.json()["blOceanVesselBound"],
                  this.model.blOfficeCode
                );

                self.model.generateType = "SUFFIX";
                if (
                  resp.json()["blSplitGenerateType"] != undefined &&
                  resp.json()["blSplitGenerateType"] != ""
                ) {
                  let blSplitGenerateType = resp.json()["blSplitGenerateType"];
                  self.model.generateType = blSplitGenerateType;
                }

                self.model.methodType = "FREIGHT";
                if (
                  resp.json()["blSplitMethodType"] != undefined &&
                  resp.json()["blSplitMethodType"] != ""
                ) {
                  let blSplitMethodType = resp.json()["blSplitMethodType"];
                  self.model.methodType = blSplitMethodType;
                }

                self.model.blPrepaidAt1Code = resp.json()["blPrepaidAt1Code"];
                self.model.blPrepaidAt2Code = resp.json()["blPrepaidAt2Code"];
                self.model.blPrepaidAt3Code = resp.json()["blPrepaidAt3Code"];
                self.model.blPayableAt1Code = resp.json()["blPayableAt1Code"];
                self.model.blPayableAt2Code = resp.json()["blPayableAt2Code"];
                self.model.blPayableAt3Code = resp.json()["blPayableAt3Code"];
                self.model.blPayer1Code = resp.json()["blPayer1Code"];
                self.model.blPayer2Code = resp.json()["blPayer2Code"];
                self.model.blPayer3Code = resp.json()["blPayer3Code"];

                if (resp.json()["blSplitQty"] != undefined) {
                  let blSplitQty: number = resp.json()["blSplitQty"];
                  //this.model.qty = blSplitQty;
                  //this.lastQty = this.model.qty;
                }

                if (resp.json()["booking"] != undefined) {
                  let booking: BLSplit[] = resp.json()["booking"];

                  if (booking.length > 0) {
                    self.gridBooking.listStore.store = [];
                    booking.forEach((element) => {
                      self.model.pol = element["polBooking"];
                      self.model.pod = element["pod"];
                      self.model.containerOwnership =
                        element["containerOwnership"];
                      self.model.bPrefix = element["bPrefix"];
                      self.model.bNo = String(element["bNo"]);

                      let s = new BLSplit();
                      s.bNo = element["bPrefix"] + element["bNo"];
                      s.ctype = element["ctype"];
                      self.model.ctype = s.ctype;

                      self.perCodeList.forEach((el) => {
                        if (element[el] != undefined) {
                          s[el] = element[el];
                        }
                      });
                      self.gridBooking.listStore.addData(s);
                    });
                    self.gridBooking.loadData();
                  } else {
                    self.gridBooking.listStore.store = [];
                    self.gridBooking.loadData();
                  }
                }

                if (resp.json()["split"] != undefined) {
                  let split: any[] = resp.json()["split"];
                  if (split.length > 0) {
                    self.gridSplit.listStore.store = [];
                    self.splitStore = [];
                    let num:number = 0;
                    split.forEach((element) => {
                      self.splitDetails = new BLSplit();
                      self.splitDetails.seqnoNew = ++num;
                      self.splitDetails.blNo = element["blNo"];
                      self.splitDetails.ctype = element["ctype"];

                      self.perCodeList.forEach((el) => {
                        if (element[el] != undefined) {
                          self.splitDetails[el] = element[el];
                        }
                      });
                      self.gridSplit.listStore.addData(self.splitDetails);
                      self.splitStore.push(self.splitDetails);
                    });
                    self.gridSplit.loadData();
                  } else {
                    self.gridSplit.listStore.store = [];
                    self.gridSplit.loadData();
                    self.splitStore = [];
                  }
                }

                this.totalB = 0;
                this.totalM = 0;
                this.totalS = 0;

                let bookingQty = {};
                let mainBLQty = {};
                let splitBLQty = {};
                let diffQty = {};

                if (resp.json()["main"] != undefined) {
                  let booking: any[] = resp.json()["booking"];
                  let split: any[] = resp.json()["split"];
                  let main: BLSplit[] = resp.json()["main"];

                  if (main.length > 0) {
                    self.gridMain.listStore.store = [];
                    main.forEach((element) => {
                      let s = new BLSplit();
                      s.blNo = element["blNo"];
                      s.ctype = element["ctype"];

                      self.perCodeList.forEach((el) => {
                        if (element[el] != undefined) {
                          s[el] = element[el];
                          console.log("ELEMENT : " + s[el]);
                        }
                      });
                      self.gridMain.listStore.addData(s);
                    });

                    self.gridMain.loadData();
                  } else {
                    self.gridMain.listStore.store = [];
                    self.gridMain.loadData();
                  }

                  //compare data
                  self.compareMinMap.clear();
                  self.compareMoreMap.clear();
                  self.perCodeList.forEach((el) => {
                    //data booking
                    let b = Number.parseInt(booking[0][el]);
                    if (isNaN(b)) {
                      b = 0;
                    } else {
                      bookingQty[el] = booking[0][el] *1;
                    }

                    let m = Number.parseInt(main[0][el]);
                    if (isNaN(m)) {
                      m = 0;
                    } else {
                      mainBLQty[el] = main[0][el] *1;
                    }

                    //data split
                    let s: number = 0;
                    split.forEach((sel) => {
                      let dataSplit = Number.parseInt(sel[el]);
                      if (!isNaN(dataSplit)) {
                        s += dataSplit;
                        splitBLQty[el] = sel[el] *1;
                      }
                    });

                    console.log("per : " + el);
                    console.log("booking : " + b);
                    console.log("main : " + m);
                    console.log("split : " + s);
                    console.log(" ");

                    if (b > m + s) {
                      let dif: number = b - (m + s);
                      this.compareMoreMap.set(el, dif);
                    }

                    if (b < m + s) {
                      let dif: number = b - (m + s);
                      this.compareMinMap.set(el, dif);
                    }

                    //patch


                    this.totalB += b;
                    this.totalM += m;
                    this.totalS += s;

                    if(b > 0 && b == m+s){
                      this.totalDiff = this.totalM - this.totalB
                      this.typeDiff = el.toUpperCase();
                    }

                    if (!isNaN(bookingQty[el])) {
                      diffQty[el] = bookingQty[el];
                      if (!isNaN(mainBLQty[el])) {
                        diffQty[el] = diffQty[el] - mainBLQty[el];
                      }

                      if (!isNaN(splitBLQty[el])) {
                        diffQty[el] = diffQty[el] - splitBLQty[el];
                      }

                      // GOS-363
                      if (diffQty[el]) {
                        self.msgTypeQty = "Program will UPDATE the Quantity in the Main BL.";
                      }

                      // if (diffQty[el] > 0) {
                      //   self.msgTypeQty = self.msgTypeQty + "Total of " + el.toUpperCase() +" in Booking is more than BL! ";
                      //   // "Program will add " +
                      //   // diffQty[el] +" " +el.toUpperCase()+" container in Main BL. <br/> Total "+el.toUpperCase()+" in Booking : "+bookingQty[el]+" <br/>";
                      // } else if (diffQty[el] < 0) {
                      //   self.msgTypeQty = self.msgTypeQty + "Total of " + el.toUpperCase() +" in Booking is less than BL! ";
                      //   // "Program will decrease " +
                      //   // diffQty[el] * -1 +" " +el.toUpperCase()+" container in Main BL. <br/> Total "+el.toUpperCase()+" in Booking : "+bookingQty[el]+" <br/>";
                      // }
                    }else if (!isNaN(mainBLQty[el])) {
                      diffQty[el] = mainBLQty[el] * -1;

                      if (!isNaN(splitBLQty[el])) {
                        diffQty[el] = diffQty[el] - splitBLQty[el];
                      }

                      if (!isNaN(bookingQty[el])) {
                        diffQty[el] = bookingQty[el] + diffQty[el];
                      }

                      if (diffQty[el] != 0) {
                        self.msgTypeQty = "Program will UPDATE the Quantity in the Main BL.";
                      }
                    } else if (!isNaN(splitBLQty[el])) {
                      diffQty[el] = splitBLQty[el] * -1;

                      if (!isNaN(mainBLQty[el])) {
                        diffQty[el] = diffQty[el] - mainBLQty[el];
                      }

                      if (!isNaN(bookingQty[el])) {
                        diffQty[el] = bookingQty[el] + diffQty[el];
                      }

                      if (diffQty[el] != 0) {
                        self.msgTypeQty = "Program will UPDATE the Quantity in the Main BL.";
                      }
                    }

                  });

                  console.log("bookingQty: ");
                  console.log(bookingQty);
                  console.log("mainBlQty :");
                  console.log(mainBLQty);
                  console.log("splitBLQty :");
                  console.log(splitBLQty);
                  console.log("diffQty :");
                  console.log(diffQty);
                }

                //jira gos 65
                console.log("totalB : " + this.totalB);
                console.log("totalM : " + this.totalM);
                console.log("totalS : " + this.totalS);

                self.gridSplit.lock = false;
                self.lockQty = false;
                self.lock = false;
                //if (self.gridSplit.listStore.store.length > 0) {
                if (self.splitStore.length > 0) {
                  self.lock = true;
                }
                //if (self.gridSplit.listStore.store.length > 0) {
                if (self.splitStore.length > 0) {
                  self.disableToolbarButtons = "retrieve,retrieve2";
                } else {
                  self.disableToolbarButtons = "retrieve,retrieve2,save,save2";
                }
                //self.disableToolbarButtons = "retrieve,save";
                self.model.qty = 0;
                //self.model.aftersplitQty = self.gridSplit.listStore.store.length + 1;
                self.model.aftersplitQty = self.splitStore.length + 1;
                self.genericUtil.hideLoader();

                self.model.bSplitSeq = resp.json()["bookingSplitSeq"];
                self.lastNo = resp.json()["lastNo"];

                console.log(
                  "self.compareMinMap.size = " + self.compareMinMap.size
                );
                console.log(
                  "self.compareMoreMap.size = " + self.compareMoreMap.size
                );

                if (
                  self.compareMinMap.size > 0 &&
                  self.compareMoreMap.size > 0
                ) {
                  self.msg =
                    "Container in BL does not tally with booking. Please check your container.";
                  self.genericUtil.showDialog(
                    "compareDialog",
                    "Information",
                    775,
                    200
                  );
                }
                console.log(self.compareMinMap);
                console.log(self.compareMoreMap);
                if (
                  self.compareMinMap.size > 0 &&
                  self.compareMoreMap.size == 0
                ) {
                  self.msg =
                    "Container in BL does not tally with booking. Please check your container.";
                  self.genericUtil.showDialog(
                    "compareDialog",
                    "Information",
                    775,
                    200
                  );
                }

                // https://samudera.atlassian.net/browse/GOS-832
                if (
                  self.compareMinMap.size == 0 &&
                  self.compareMoreMap.size > 0
                ) {
                  self.msg =
                    "Container in BL does not tally with booking. Please check your container.";
                  self.genericUtil.showDialog(
                    "compareDialog",
                    "Information",
                    775,
                    200
                  );
                }
                /*
                if(this.totalDiff > 0){
                  self.msg = "";
                  //self.compareMoreMap.forEach(function (item, key, mapObj) {
                    self.msg =
                      self.msg +
                      "Total of " +
                      self.typeDiff +
                      " in Booking is more than BL! Program will add " +
                      self.totalDiff +
                      " container in Main BL. <br/>";
                 // });
                  self.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    775,
                    200
                  );
                }
                */
                // if (
                //   self.compareMinMap.size == 0 &&
                //   self.compareMoreMap.size > 0
                // ) {
                //   self.msg = "";
                //   self.compareMoreMap.forEach(function (item, key, mapObj) {
                //     self.msg =
                //       self.msg +
                //       "Total of " +
                //       key.toUpperCase() +
                //       " in Booking is more than BL! Program will add " +
                //       item +
                //       " container in Main BL. <br/>";
                //   });
                //   self.genericUtil.showDialog(
                //     "compareDialog",
                //     "Information",
                //     775,
                //     200
                //   );
                // }

                // // jira gos 65
                // let itemNew: number = 0;
                // let itemNow: number = this.totalM + this.totalS;
                // if (self.compareMoreMap.size > 0) {
                //   self.msg = "";
                //   self.compareMoreMap.forEach(function (item, key, mapObj) {
                //     itemNew = item - itemNow;
                //     self.msg =
                //       self.msg +
                //       "Total of " +
                //       key.toUpperCase() +
                //       " in Booking is more than BL! Program will add " +
                //       itemNew +
                //       " container in Main BL. <br/>";
                //   });
                //   self.genericUtil.showDialog(
                //     "compareDialog",
                //     "Information",
                //     775,
                //     200
                //   );
                // }

                let ss: string = resp.json()["message"];
                if (ss != "") {
                  self.disableToolbarButtons =
                    "retrieve,retrieve2,split,save,save2";
                  self.msg = ss;
                  self.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    420,
                    160
                  );
                }
              } else {
                let ss: string = resp.json()["message"];
                console.log(ss);
                self.msg = ss;
                self.genericUtil.showDialog(
                  "msgDialog",
                  "Information",
                  420,
                  160
                );
              }
            });
        }, 10);
      });
  }

  onRetrieve2() {
    this.genericUtil.showLoader();
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.user = this.cookieService.getName();
    this.model.blNo = this.model.blNo.trim();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() + "/BLSplit/getPerCode"
      )
      .pipe(finalize(() => {
        this.genericUtil.hideLoader();
      }))
      .subscribe((respx) => {
        this.perCodeList = respx.json();
        console.log(this.perCodeList);

        var self = this;
        setTimeout(() => {
          self.genericService
            .POST(
              self.configService.config.BASE_API.toString() +
                "/BLSplit/searchSplit2",
              self.model
            )
            .subscribe((resp) => {
              console.log(resp.json());
              if (resp.json()["status"] == "OK") {
                self.model.generateType = "SUFFIX";
                if (
                  resp.json()["blSplitGenerateType"] != undefined &&
                  resp.json()["blSplitGenerateType"] != ""
                ) {
                  let blSplitGenerateType = resp.json()["blSplitGenerateType"];
                  self.model.generateType = blSplitGenerateType;
                }

                self.model.methodType = "FREIGHT";
                if (
                  resp.json()["blSplitMethodType"] != undefined &&
                  resp.json()["blSplitMethodType"] != ""
                ) {
                  let blSplitMethodType = resp.json()["blSplitMethodType"];
                  self.model.methodType = blSplitMethodType;
                }

                self.model.blPrepaidAt1Code = resp.json()["blPrepaidAt1Code"];
                self.model.blPrepaidAt2Code = resp.json()["blPrepaidAt2Code"];
                self.model.blPrepaidAt3Code = resp.json()["blPrepaidAt3Code"];
                self.model.blPayableAt1Code = resp.json()["blPayableAt1Code"];
                self.model.blPayableAt2Code = resp.json()["blPayableAt2Code"];
                self.model.blPayableAt3Code = resp.json()["blPayableAt3Code"];
                self.model.blPayer1Code = resp.json()["blPayer1Code"];
                self.model.blPayer2Code = resp.json()["blPayer2Code"];
                self.model.blPayer3Code = resp.json()["blPayer3Code"];

                if (resp.json()["blSplitQty"] != undefined) {
                  let blSplitQty: number = resp.json()["blSplitQty"];
                  //this.model.qty = blSplitQty;
                  //this.lastQty = this.model.qty;
                }

                if (resp.json()["booking"] != undefined) {
                  let booking: BLSplit[] = resp.json()["booking"];

                  if (booking.length > 0) {
                    self.gridBooking.listStore.store = [];
                    booking.forEach((element) => {
                      self.model.pol = element["polBooking"];
                      self.model.pod = element["pod"];
                      self.model.containerOwnership =
                        element["containerOwnership"];
                      self.model.bPrefix = element["bPrefix"];
                      self.model.bNo = String(element["bNo"]);

                      let s = new BLSplit();
                      s.bNo = element["bPrefix"] + element["bNo"];
                      s.ctype = element["ctype"];
                      self.model.ctype = s.ctype;

                      self.perCodeList.forEach((el) => {
                        if (element[el] != undefined) {
                          s[el] = element[el];
                        }
                      });
                      self.gridBooking.listStore.addData(s);
                    });
                    self.gridBooking.loadData();
                  } else {
                    self.gridBooking.listStore.store = [];
                    self.gridBooking.loadData();
                  }
                }

                if (resp.json()["split"] != undefined) {
                  let split: any[] = resp.json()["split"];
                  if (split.length > 0) {
                    self.gridSplit.listStore.store = [];
                    split.forEach((element) => {
                      let s = new BLSplit();
                      s.blNo = element["blNo"];
                      s.ctype = element["ctype"];

                      self.perCodeList.forEach((el) => {
                        if (element[el] != undefined) {
                          s[el] = element[el];
                        }
                      });
                      self.gridSplit.listStore.addData(s);
                    });
                    self.gridSplit.loadData();
                  } else {
                    self.gridSplit.listStore.store = [];
                    self.gridSplit.loadData();
                  }
                }

                if (resp.json()["main"] != undefined) {
                  let booking: any[] = resp.json()["booking"];
                  let split: any[] = resp.json()["split"];
                  let main: BLSplit[] = resp.json()["main"];

                  if (main.length > 0) {
                    self.gridMain.listStore.store = [];
                    main.forEach((element) => {
                      let s = new BLSplit();
                      s.blNo = element["blNo"];
                      s.ctype = element["ctype"];

                      /*
                    self.perCodeList.forEach(el => {
                      //data booking
                      let hit1:number = 0;
                      let b = Number.parseInt(booking[0][el]);
                      if(!isNaN(b)){
                        hit1+=b;
                      }
                      if(hit1 > 0){
                        console.log(el + " booking : " + hit1);
                      }

                      //data split
                      let hit2:number = 0;
                      split.forEach(sel => {
                        let s = Number.parseInt(sel[el]);
                        if(!isNaN(s)){
                          hit2+=s;
                        }
                      });
                      if(hit2 > 0){
                        console.log(el + " split : " + hit2);
                      }

                      if(element[el] != undefined){
                        //s[el] = element[el];
                        s[el] = hit1 - hit2;
                      }

                    });
                    */

                      self.perCodeList.forEach((el) => {
                        if (element[el] != undefined) {
                          s[el] = element[el];
                        }
                      });

                      self.gridMain.listStore.addData(s);
                    });

                    self.gridMain.loadData();
                  } else {
                    self.gridMain.listStore.store = [];
                    self.gridMain.loadData();
                  }

                  //compare data
                  self.compareMinMap.clear();
                  self.compareMoreMap.clear();
                  self.perCodeList.forEach((el) => {
                    //data booking
                    let b = Number.parseInt(booking[0][el]);
                    if (isNaN(b)) {
                      b = 0;
                    }
                    let m = Number.parseInt(main[0][el]);
                    if (isNaN(m)) {
                      m = 0;
                    }
                    //data split
                    let s: number = 0;
                    split.forEach((sel) => {
                      let dataSplit = Number.parseInt(sel[el]);
                      if (!isNaN(dataSplit)) {
                        s += dataSplit;
                      }
                    });

                    console.log("per : " + el);
                    console.log("booking : " + b);
                    console.log("main : " + m);
                    console.log("split : " + s);
                    console.log(" ");

                    if (b > m + s) {
                      let dif: number = b - (m + s);
                      this.compareMoreMap.set(el, dif);
                    }

                    if (b < m + s) {
                      let dif: number = b - (m + s);
                      this.compareMinMap.set(el, dif);
                    }
                  });
                }

                self.gridSplit.lock = false;
                self.lockQty = false;
                self.lock = false;
                if (self.gridSplit.listStore.store.length > 0) {
                  self.lock = true;
                }
                if (self.gridSplit.listStore.store.length > 0) {
                  self.disableToolbarButtons = "retrieve,retrieve2";
                } else {
                  self.disableToolbarButtons = "retrieve,retrieve2,save,save2";
                }
                //self.disableToolbarButtons = "retrieve,save";
                self.model.qty = 0;
                self.model.aftersplitQty =
                  self.gridSplit.listStore.store.length + 1;
                self.genericUtil.hideLoader();

                self.model.bSplitSeq = resp.json()["bookingSplitSeq"];
                self.lastNo = resp.json()["lastNo"];

                console.log(
                  "self.compareMinMap.size = " + self.compareMinMap.size
                );
                console.log(
                  "self.compareMoreMap.size = " + self.compareMoreMap.size
                );

                if (
                  self.compareMinMap.size > 0 &&
                  self.compareMoreMap.size > 0
                ) {
                  self.msg =
                    "Container in BL does not tally with booking. Please check your container.";
                  self.genericUtil.showDialog(
                    "compareDialog",
                    "Information",
                    775,
                    200
                  );
                }

                if (
                  self.compareMinMap.size > 0 &&
                  self.compareMoreMap.size == 0
                ) {
                  self.msg =
                    "Container in BL does not tally with booking. Please check your container.";
                  self.genericUtil.showDialog(
                    "compareDialog",
                    "Information",
                    775,
                    200
                  );
                }

                if (
                  self.compareMinMap.size == 0 &&
                  self.compareMoreMap.size > 0
                ) {
                  // self.msg = "";
                  // self.compareMoreMap.forEach(function (item, key, mapObj) {
                  //   self.msg =
                  //     self.msg +
                  //     "Total of " +
                  //     key.toUpperCase() +
                  //     " in Booking is more than BL! Program will add " +
                  //     item +
                  //     " container in Main BL. <br/>";
                  // });
                  self.msg =
                    "Container in BL does not tally with booking. Please check your container.";
                  self.genericUtil.showDialog(
                    "compareDialog",
                    "Information",
                    775,
                    200
                  );
                }

                let ss: string = resp.json()["message"];
                if (ss != "") {
                  self.disableToolbarButtons =
                    "retrieve,retrieve2,split,save,save2";
                  self.msg = ss;
                  self.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    420,
                    160
                  );
                }
              } else {
                let ss: string = resp.json()["message"];
                console.log(ss);
                self.msg = ss;
                self.genericUtil.showDialog(
                  "msgDialog",
                  "Information",
                  420,
                  160
                );
              }
            });
        }, 10);
      });
  }

  onRetrieveAfterSave() {
    this.genericUtil.showLoader();
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.user = this.cookieService.getName();
    this.model.blNo = this.model.blNo.trim();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() + "/BLSplit/getPerCode"
      )
      .subscribe((resp) => {
        this.perCodeList = resp.json();
        console.log(this.perCodeList);

        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/BLSplit/searchSplit",
            this.model
          )
          .pipe(finalize(() => {
            this.genericUtil.hideLoader();
          }))
          .subscribe((resp) => {
            if (resp.json()["status"] == "OK") {
              this.model.generateType = "SUFFIX";
              if (
                resp.json()["blSplitGenerateType"] != undefined &&
                resp.json()["blSplitGenerateType"] != ""
              ) {
                let blSplitGenerateType = resp.json()["blSplitGenerateType"];
                this.model.generateType = blSplitGenerateType;
              }

              this.model.methodType = "FREIGHT";
              if (
                resp.json()["blSplitMethodType"] != undefined &&
                resp.json()["blSplitMethodType"] != ""
              ) {
                let blSplitMethodType = resp.json()["blSplitMethodType"];
                this.model.methodType = blSplitMethodType;
              }

              if (resp.json()["blSplitQty"] != undefined) {
                let blSplitQty: number = resp.json()["blSplitQty"];
                //this.model.qty = blSplitQty;
                //this.lastQty = this.model.qty;
              }

              if (resp.json()["booking"] != undefined) {
                let booking: BLSplit[] = resp.json()["booking"];

                if (booking.length > 0) {
                  this.gridBooking.listStore.store = [];
                  booking.forEach((element) => {
                    this.model.pol = element["polBooking"];
                    this.model.pod = element["pod"];
                    this.model.containerOwnership =
                      element["containerOwnership"];

                    let s = new BLSplit();
                    s.bNo = element["bPrefix"] + element["bNo"];
                    this.perCodeList.forEach((el) => {
                      if (element[el] != undefined) {
                        s[el] = element[el];
                      }
                    });
                    this.gridBooking.listStore.addData(s);
                  });
                  this.gridBooking.loadData();
                } else {
                  this.gridBooking.listStore.store = [];
                  this.gridBooking.loadData();
                }
              }

              if (resp.json()["split"] != undefined) {
                let split: any[] = resp.json()["split"];
                if (split.length > 0) {
                  /*
                  this.gridSplit.listStore.store = [];
                  split.forEach((element) => {
                    let s = new BLSplit();
                    s.blNo = element["blNo"];
                    this.perCodeList.forEach((el) => {
                      if (element[el] != undefined) {
                        s[el] = element[el];
                      }
                    });
                    this.gridSplit.listStore.addData(s);
                  });
                  this.gridSplit.loadData();
                  */

                  this.splitStore = [];
                  let num:number = 0;
                  split.forEach((element) => {
                    let s :any = new BLSplit();
                    s.seqnoNew = ++num;
                    s.blNo = this.model.generateType == "NEW" && this.codeCN ? element["blNo"].replace(this.blNumberdefault,""):element["blNo"];
                    this.perCodeList.forEach((el) => {
                      if (element[el] != undefined) {
                        s[el] = element[el];
                      }
                    });
                    this.splitStore.push(s);
                  });
                } else {
                  this.splitStore = [];
                }
              }

              if (resp.json()["main"] != undefined) {
                let booking: any[] = resp.json()["booking"];
                let split: any[] = resp.json()["split"];
                let main: BLSplit[] = resp.json()["main"];

                if (main.length > 0) {
                  this.gridMain.listStore.store = [];
                  main.forEach((element) => {
                    let s = new BLSplit();
                    s.blNo = element["blNo"];
                    /*
                  this.perCodeList.forEach(el => {
                    //data booking
                    let hit1:number = 0;
                    let b = Number.parseInt(booking[0][el]);
                    if(!isNaN(b)){
                      hit1+=b;
                    }
                    if(hit1 > 0){
                      console.log(el + " booking : " + hit1);
                    }

                    //data split
                    let hit2:number = 0;
                    split.forEach(sel => {
                      let s = Number.parseInt(sel[el]);
                      if(!isNaN(s)){
                        hit2+=s;
                      }
                    });
                    if(hit2 > 0){
                      console.log(el + " split : " + hit2);
                    }

                    if(element[el] != undefined){
                      //s[el] = element[el];
                      s[el] = hit1 - hit2;
                    }

                  });
                  */
                    this.perCodeList.forEach((el) => {
                      if (element[el] != undefined) {
                        s[el] = element[el];
                      }
                    });

                    this.gridMain.listStore.addData(s);
                  });

                  this.gridMain.loadData();
                } else {
                  this.gridMain.listStore.store = [];
                  this.gridMain.loadData();
                }
              }

              /*
           let tmp:string = this.model.blNo;
           this.onCancel()
           this.model.blNo = tmp;
           */

              this.lock = true;
              this.lockQty = true;
              this.model.qty = 0;
              this.disableToolbarButtons = "split,save,save2,retrieve2";
              this.gridSplit.lock = true;

              //this.model.aftersplitQty = this.gridSplit.listStore.store.length + 1;
              this.model.aftersplitQty = this.splitStore.length + 1;
            } else {
              let ss: string = resp.json()["message"];
              console.log(ss);
              this.msg = ss;
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            }
          });
      });
  }

  onRetrieveAfterSave2() {
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.user = this.cookieService.getName();
    this.model.blNo = this.model.blNo.trim();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() + "/BLSplit/getPerCode"
      )
      .subscribe((resp) => {
        this.perCodeList = resp.json();
        console.log(this.perCodeList);

        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/BLSplit/searchSplit2",
            this.model
          )
          .subscribe((resp) => {
            if (resp.json()["status"] == "OK") {
              this.model.generateType = "SUFFIX";
              if (
                resp.json()["blSplitGenerateType"] != undefined &&
                resp.json()["blSplitGenerateType"] != ""
              ) {
                let blSplitGenerateType = resp.json()["blSplitGenerateType"];
                this.model.generateType = blSplitGenerateType;
              }

              this.model.methodType = "FREIGHT";
              if (
                resp.json()["blSplitMethodType"] != undefined &&
                resp.json()["blSplitMethodType"] != ""
              ) {
                let blSplitMethodType = resp.json()["blSplitMethodType"];
                this.model.methodType = blSplitMethodType;
              }

              if (resp.json()["blSplitQty"] != undefined) {
                let blSplitQty: number = resp.json()["blSplitQty"];
                //this.model.qty = blSplitQty;
                //this.lastQty = this.model.qty;
              }

              if (resp.json()["booking"] != undefined) {
                let booking: BLSplit[] = resp.json()["booking"];

                if (booking.length > 0) {
                  this.gridBooking.listStore.store = [];
                  booking.forEach((element) => {
                    this.model.pol = element["polBooking"];
                    this.model.pod = element["pod"];
                    this.model.containerOwnership =
                      element["containerOwnership"];

                    let s = new BLSplit();
                    s.bNo = element["bNo"];
                    this.perCodeList.forEach((el) => {
                      if (element[el] != undefined) {
                        s[el] = element[el];
                      }
                    });
                    this.gridBooking.listStore.addData(s);
                  });
                  this.gridBooking.loadData();
                } else {
                  this.gridBooking.listStore.store = [];
                  this.gridBooking.loadData();
                }
              }

              if (resp.json()["split"] != undefined) {
                let split: any[] = resp.json()["split"];
                if (split.length > 0) {
                  this.gridSplit.listStore.store = [];
                  split.forEach((element) => {
                    let s = new BLSplit();
                    s.blNo = element["blNo"];
                    this.perCodeList.forEach((el) => {
                      if (element[el] != undefined) {
                        s[el] = element[el];
                      }
                    });
                    this.gridSplit.listStore.addData(s);
                  });
                  this.gridSplit.loadData();
                } else {
                  this.gridSplit.listStore.store = [];
                  this.gridSplit.loadData();
                }
              }

              if (resp.json()["main"] != undefined) {
                let booking: any[] = resp.json()["booking"];
                let split: any[] = resp.json()["split"];
                let main: BLSplit[] = resp.json()["main"];

                if (main.length > 0) {
                  this.gridMain.listStore.store = [];
                  main.forEach((element) => {
                    let s = new BLSplit();
                    s.blNo = element["blNo"];
                    /*
                  this.perCodeList.forEach(el => {
                    //data booking
                    let hit1:number = 0;
                    let b = Number.parseInt(booking[0][el]);
                    if(!isNaN(b)){
                      hit1+=b;
                    }
                    if(hit1 > 0){
                      console.log(el + " booking : " + hit1);
                    }

                    //data split
                    let hit2:number = 0;
                    split.forEach(sel => {
                      let s = Number.parseInt(sel[el]);
                      if(!isNaN(s)){
                        hit2+=s;
                      }
                    });
                    if(hit2 > 0){
                      console.log(el + " split : " + hit2);
                    }

                    if(element[el] != undefined){
                      //s[el] = element[el];
                      s[el] = hit1 - hit2;
                    }

                  });
                  */
                    this.perCodeList.forEach((el) => {
                      if (element[el] != undefined) {
                        s[el] = element[el];
                      }
                    });

                    this.gridMain.listStore.addData(s);
                  });

                  this.gridMain.loadData();
                } else {
                  this.gridMain.listStore.store = [];
                  this.gridMain.loadData();
                }
              }

              /*
           let tmp:string = this.model.blNo;
           this.onCancel()
           this.model.blNo = tmp;
           */

              this.lock = true;
              this.lockQty = true;
              this.model.qty = 0;
              this.disableToolbarButtons = "split,save,save2,retrieve2";
              this.gridSplit.lock = true;

              this.model.aftersplitQty =
                this.gridSplit.listStore.store.length + 1;
            } else {
              let ss: string = resp.json()["message"];
              console.log(ss);
              this.msg = ss;
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            }
          });
      });
  }

  convertToNumberingScheme(number) {
    var baseChar = "A".charCodeAt(0),
      letters = "";

    do {
      number -= 1;
      letters = String.fromCharCode(baseChar + (number % 26)) + letters;
      number = (number / 26) >> 0;
    } while (number > 0);

    return letters.toUpperCase();
  }

  convertLetterToNumber(str) {
    var out = 0,
      len = str.length;
    for (let pos = 0; pos < len; pos++) {
      out += (str.charCodeAt(pos) - 96) * Math.pow(26, len - pos - 1);
    }
    return out;

    //return str.charCodeAt(0) - 96;
  }

  onSplit() {
    console.log("** onSplit **");
    console.log(this.model.generateType)

    if (this.model.generateType == "SUFFIX") {
      /*
      let store: any[] = this.gridSplit.listStore.store;
      let tmp: any[] = [];

      this.gridSplit.editable = false;

      console.log("lastNo : " + this.lastNo);
      if (this.lastNo != undefined) {
        let cars = this.lastNo.replace(this.lastNo.substring(0, 15), "");
        console.log(cars);
        let num = this.convertLetterToNumber(cars.toLowerCase());
        console.log(num);

        //let cars2 = this.convertToNumberingScheme(num)
        //console.log(cars2);

        if (store.length > 0) {
          store.forEach((element) => {
            tmp.push(element);
          });
        }

        for (let i = num; i < this.model.qty + num; i++) {
          let data = new BLSplit();
          data.blNo = this.model.blNo + this.convertToNumberingScheme(i + 1);
          data.isNew = "Y";
          data.ctype = this.model.ctype;
          tmp.push(data);
        }
        this.gridSplit.listStore.store = [];
        this.gridSplit.listStore.store = tmp;
        this.gridSplit.loadData();

      } else {
        
        let ParGenerate = new ParamGenerate();
        ParGenerate.officeCode = this.model.blOfficeCode;
        ParGenerate.blNo = this.model.blNo;
        ParGenerate.jml = this.model.qty;

        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/BLSplit/generateBLNoSplit",
            ParGenerate
          )
          .subscribe((resp) => {
            //console.log(resp)
            let datas: any[] = resp.json()["data"];
            datas.forEach((el) => {
              //console.log(el)
              let data = new BLSplit();
              data.blNo = el;
              data.isNew = "Y";
              data.ctype = this.model.ctype;
              tmp.push(data);
            });

            this.gridSplit.listStore.store = [];
            this.gridSplit.listStore.store = tmp;
            this.gridSplit.loadData();
          });
      }
      */

       // -- new
       let tmp2: any[] = [];
       if (this.lastNo != undefined) {
          let cars = this.lastNo.replace(this.lastNo.substring(0, 15), ""); //check again for china if chars less than 15
          console.log(cars);
          let num = this.convertLetterToNumber(cars.toLowerCase());
          console.log(num);

          if (this.splitStore.length > 0) {
            this.splitStore.forEach((element) => {
              tmp2.push(element);
            });
          }

          for (let i = num; i < this.model.qty + num; i++) {
            let data:any = new BLSplit();
            data.seqnoNew = i;
            data.blNo = this.model.blNo + this.convertToNumberingScheme(i + 1);
            data.isNew = "Y";
            data.ctype = this.model.ctype;
            tmp2.push(data);
          }

          this.splitStore = [];
          this.splitStore = tmp2;

       }else{
          let ParGenerate = new ParamGenerate();
          ParGenerate.officeCode = this.model.blOfficeCode;
          ParGenerate.blNo = this.model.blNo;
          ParGenerate.jml = this.model.qty;

          this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/BLSplit/generateBLNoSplit",
            ParGenerate
          )
          .subscribe((resp) => {
            //console.log(resp)
            let datas: any[] = resp.json()["data"];
            let num:number = 0;
            datas.forEach((el) => {
              //console.log(el)
              let data:any = new BLSplit();
              data.seqnoNew = ++num;
              data.blNo = el;
              data.isNew = "Y";
              data.ctype = this.model.ctype;
              console.log(data);
              tmp2.push(data);
            });

            this.splitStore = [];
            this.splitStore = tmp2;
          });
       }
    }

    if (this.model.generateType == "NEW") {
      if(this.codeCN){
        this.blNumberdefault = this.model['blNo'].substring(0, 9).toLocaleUpperCase();
      }
      
      /*
      let store: any[] = this.gridSplit.listStore.store;
      let tmp: any[] = [];
      if (store.length > 0) {
        let ix: number = 0;
        store.forEach((element) => {
          let nox: string;
          //element.blNo = this.codeCN ?  this.blNumberdefault + element.blNo : element.blNo; //tambahan
          tmp.push(element);
          ix++;
        });

        for (let i = ix; i < this.model.qty + ix; i++) {
          let data = new BLSplit();
          let no: Number = i + 1;
          data.blNo = this.codeCN ? this.model['blNo'].substring(0, 9) : "SSLXXXXXXXXXXX" + no;
          data.isNew = "Y";
          data.ctype = this.model.ctype;
          tmp.push(data);
        }
      } else {
        for (let i = 0; i < this.model.qty; i++) {
          let data = new BLSplit();
          let no: Number = i + 1;
          data.blNo = this.codeCN ? this.model['blNo'].substring(0, 9) : "SSLXXXXXXXXXXX" + no;
          data.isNew = "Y";
          data.ctype = this.model.ctype;
          tmp.push(data);
        }
      }
      this.gridSplit.listStore.store = [];
      this.gridSplit.listStore.store = tmp;
      this.gridSplit.loadData();
      */

      // -- new
      let store2: any[] = this.splitStore;
      let tmp2: any[] = [];
      if (store2.length > 0) {
        
        let ix: number = 0;
        store2.forEach((el) => {
          let nox: string = el.blNo; //.substr(9);
          el.blNo = this.codeCN ? nox.substring(9) : el.blNo;
          tmp2.push(el);
          ix++;
        });

        for (let i = ix; i < this.model.qty + ix; i++) {
          let data:any = new BLSplit();
          let no: Number = i + 1;
          data.seqnoNew = no;
          data.blNo = this.codeCN ? "" : "SSLXXXXXXXXXXX" + no;
          data.isNew = "Y";
          data.ctype = this.model.ctype;
          tmp2.push(data);
        }
        
      } else {
        for (let i = 0; i < this.model.qty; i++) {
          let data:any = new BLSplit();
          let no: Number = i + 1;
          data.seqnoNew = no;
          data.blNo = this.codeCN ? "" : "SSLXXXXXXXXXXX" + no;
          data.isNew = "Y";
          data.ctype = this.model.ctype;
          tmp2.push(data);
        }
      }
      this.splitStore = [];
      this.splitStore = tmp2;

      
    }

    /*
      let store:any[] = this.gridSplit.listStore.store;
      let tmp :any[] = [];
      if(store.length > 0){
        let ix:number = 0;
        store.forEach(element => {
          let nox:string
          if(this.model.generateType == 'SUFFIX'){
            nox = this.model.blNo + this.convertToNumberingScheme(ix+1);
            if(element.blNo == nox){
              tmp.push(element);
            }
          }else{
            //nox = this.model.blNo.substring(0,11) + 'XXXX'
            tmp.push(element);
          }
          ix++;
        });

        for (let i = ix; i < this.model.qty +ix; i++) {
          let data = new BLSplit;
          if(this.model.generateType == 'SUFFIX'){
            data.blNo = this.model.blNo + this.convertToNumberingScheme(i+1);
          }else{
            //data.blNo = this.model.blNo.substring(0,11) + 'XXXX'
            let no:Number = i+1;
            data.blNo = 'SSLXXXXXXXXXXX'+no;
          }
          data.isNew = 'Y';
          tmp.push(data);
        }

      }else{

        for (let i = 0; i < this.model.qty; i++) {
          let data = new BLSplit;
          if(this.model.generateType == 'SUFFIX'){
            data.blNo = this.model.blNo + this.convertToNumberingScheme(i+1);
          }else{
            //data.blNo = this.model.blNo.substring(0,11) + 'XXXX'
            let no:Number = i+1;
            data.blNo = 'SSLXXXXXXXXXXX'+no;
          }
          data.isNew = 'Y';
          tmp.push(data);
        }

      }
      this.gridSplit.listStore.store = []
      this.gridSplit.listStore.store = tmp;
      this.gridSplit.loadData();
      */

    this.disableToolbarButtons = "retrieve,retrieve2,split";
    //this.model.aftersplitQty = this.gridSplit.listStore.store.length + 1;
    this.model.aftersplitQty =  this.splitStore.length + 1;
    this.lock = true;

    this.genericUtil.closeDialog("splitDialog");
  }

  onSave() {
    console.log("** onsave **");
    this.genericUtil.closeDialog("saveDialog");

    let bookingList: any[] = [];
    this.gridBooking.listStore.store.forEach((element) => {
      this.perCodeList.forEach((el) => {
        if (element[el] != undefined && element[el] > 0) {
          /* https://samudera.atlassian.net/browse/GOS-832
          if (element.ctype == "FLR") {
            if (el == "d20") {
              el = "fr20";
            }
            if (el == "d40") {
              el = "fr40";
            }
            if (el == "d45") {
              el = "fr45";
            }
            if (el == "hc20") {
              el = "frh20";
            }
            if (el == "hc40") {
              el = "frh40";
            }
            if (el == "hc45") {
              el = "frh45";
            }
          } else if (element.ctype == "OPT") {
            if (el == "d20") {
              el = "ot20";
            }
            if (el == "d40") {
              el = "ot40";
            }
            if (el == "d45") {
              el = "ot45";
            }
            if (el == "hc20") {
              el = "oth20";
            }
            if (el == "hc40") {
              el = "oth40";
            }
            if (el == "hc45") {
              el = "oth45";
            }
          } else if (element.ctype == "REF" || element.ctype == "DRY") {
            if (el == "d20") {
              el = "r20";
            }
            if (el == "d40") {
              el = "r40";
            }
            if (el == "d45") {
              el = "r45";
            }
            if (el == "hc20") {
              el = "rh20";
            }
            if (el == "hc40") {
              el = "rh40";
            }
            if (el == "hc45") {
              el = "rh45";
            }
          } else if (element.ctype == "TNK") {
            if (el == "d20") {
              el = "tk20";
            }
            if (el == "d40") {
              el = "tk40";
            }
            if (el == "d45") {
              el = "tk45";
            }
            if (el == "hc20") {
              el = "tkh20";
            }
            if (el == "hc40") {
              el = "tkh40";
            }
            if (el == "hc45") {
              el = "tkh45";
            }
          } else if (element.ctype == "PLT") {
            if (el == "d20") {
              el = "pl20";
            }
            if (el == "d40") {
              el = "pl40";
            }
            if (el == "d45") {
              el = "pl45";
            }
            if (el == "hc20") {
              el = "plh20";
            }
            if (el == "hc40") {
              el = "plh40";
            }
            if (el == "hc45") {
              el = "plh45";
            }
          }
          */

          bookingList.push(el);
        }
      });
    });
    this.model["booking"] = bookingList;

    let mainList: any[] = [];
    this.gridMain.listStore.store.forEach((element) => {
      //console.log(element)
      let bls = new BLSplit();
      bls["blSplitBlno"] = element.blNo;
      bls["blOfficeCode"] = this.model.blOfficeCode;
      bls["blNo"] = this.model.blNo;
      bls["blSplitOfficeCode"] = this.model.blOfficeCode;
      bls["ctype"] = element.ctype;

      this.perCodeList.forEach((el) => {
        if (element[el] != undefined && element[el] > 0) {
          let nilai: number = element[el];
          /* https://samudera.atlassian.net/browse/GOS-832
          if (element.ctype == "FLR") {
            if (el == "d20") {
              el = "fr20";
            }
            if (el == "d40") {
              el = "fr40";
            }
            if (el == "d45") {
              el = "fr45";
            }
            if (el == "hc20") {
              el = "frh20";
            }
            if (el == "hc40") {
              el = "frh40";
            }
            if (el == "hc45") {
              el = "frh45";
            }
          } else if (element.ctype == "OPT") {
            if (el == "d20") {
              el = "ot20";
            }
            if (el == "d40") {
              el = "ot40";
            }
            if (el == "d45") {
              el = "ot45";
            }
            if (el == "hc20") {
              el = "oth20";
            }
            if (el == "hc40") {
              el = "oth40";
            }
            if (el == "hc45") {
              el = "oth45";
            }
          } else if (element.ctype == "REF" || element.ctype == "DRY") {
            if (el == "d20") {
              el = "r20";
            }
            if (el == "d40") {
              el = "r40";
            }
            if (el == "d45") {
              el = "r45";
            }
            if (el == "hc20") {
              el = "rh20";
            }
            if (el == "hc40") {
              el = "rh40";
            }
            if (el == "hc45") {
              el = "rh45";
            }
          } else if (element.ctype == "TNK") {
            if (el == "d20") {
              el = "tk20";
            }
            if (el == "d40") {
              el = "tk40";
            }
            if (el == "d45") {
              el = "tk45";
            }
            if (el == "hc20") {
              el = "tkh20";
            }
            if (el == "hc40") {
              el = "tkh40";
            }
            if (el == "hc45") {
              el = "tkh45";
            }
          } else if (element.ctype == "PLT") {
            if (el == "d20") {
              el = "pl20";
            }
            if (el == "d40") {
              el = "pl40";
            }
            if (el == "d45") {
              el = "pl45";
            }
            if (el == "hc20") {
              el = "plh20";
            }
            if (el == "hc40") {
              el = "plh40";
            }
            if (el == "hc45") {
              el = "plh45";
            }
          }

          */
          bls[el] = nilai;

          //bls[el] = element[el];
        }
      });

      mainList.push(bls);
    });
    this.model["main"] = mainList;

    //let splitList: any[] = [];
    let splitList2: any[] = [];
    if (this.model.methodType == "PRORATE") {
      //let checkMap = new Map<String, BLSplit>();
      let checkMap2 = new Map<String, BLSplit>();
      /*
      this.gridSplit.listStore.store.forEach((element) => {
        let bls = new BLSplit();
        bls["blSplitBlno"] = element.blNo;
        bls["blOfficeCode"] = this.model.blOfficeCode;
        bls["blNo"] = this.model.blNo;
        bls["blSplitOfficeCode"] = this.model.blOfficeCode;
        bls["ctype"] = element.ctype;
        bls["isNew"] = element.isNew;

        this.perCodeList.forEach((el) => {
          if (element[el] != undefined && element[el] > 0) {
            checkMap.set(element.blNo, bls);
          }
          bls[el] = element[el];
        });

        // https://samudera.atlassian.net/browse/GOS-832 

        if (checkMap.get(element.blNo) != null) {
          //console.log(bls)
          splitList.push(bls);
        }
      });
      */

      // --new
      let splitStoreClean:any[] = [];
      this.splitStore.forEach((element) => {
        console.log(element);
        let bls = new BLSplit();
        bls["blSplitBlno"] = this.model.generateType == "NEW" && this.codeCN ? this.blNumberdefault.toLocaleUpperCase() + element.blNo.toLocaleUpperCase() : element.blNo.toLocaleUpperCase();
        bls["blOfficeCode"] = this.model.blOfficeCode;
        bls["blNo"] = this.model.blNo;
        bls["blSplitOfficeCode"] = this.model.blOfficeCode;
        bls["ctype"] = element.ctype;
        bls["isNew"] = element.isNew;

        this.perCodeList.forEach((el) => {
          if (element[el] != undefined && element[el] > 0) {
            checkMap2.set(element.blNo, bls);
          }
          bls[el] = element[el];
        });

        if (checkMap2.get(element.blNo) != null) {
          splitList2.push(bls);
          splitStoreClean.push(element);
        }
      });

      console.log(splitStoreClean);
      this.splitStore = [];
      this.splitStore = splitStoreClean;
    } else {
      /*
      this.gridSplit.listStore.store.forEach((element) => {
        console.log(element);
        let bls = new BLSplit();
        bls["blSplitBlno"] = element.blNo;
        bls["blOfficeCode"] = this.model.blOfficeCode;
        bls["blNo"] = this.model.blNo;
        bls["blSplitOfficeCode"] = this.model.blOfficeCode;
        bls["ctype"] = element.ctype;
        bls["isNew"] = element.isNew;

        this.perCodeList.forEach((el) => {
          bls[el] = element[el];
        });

        // https://samudera.atlassian.net/browse/GOS-832
        splitList.push(bls);
      });
      */
    
      //--new
      this.splitStore.forEach((element) => {
        console.log(element);
        let bls = new BLSplit();
        bls["blSplitBlno"] = this.model.generateType == "NEW" && this.codeCN ? this.blNumberdefault.toLocaleUpperCase() + element.blNo.toLocaleUpperCase() : element.blNo.toLocaleUpperCase();
        bls["blOfficeCode"] = this.model.blOfficeCode;
        bls["blNo"] = this.model.blNo;
        bls["blSplitOfficeCode"] = this.model.blOfficeCode;
        bls["ctype"] = element.ctype;
        bls["isNew"] = element.isNew;

        this.perCodeList.forEach((el) => {
          bls[el] = element[el];
        });
        splitList2.push(bls);
      });
    }
    //console.log(splitList);
    console.log(splitList2);
    this.model["split"] = splitList2;
   
    console.log(this.model);

    this.genericUtil.showLoader();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + `/BLSplit/saveSplit?type=${this.typeSuffix}`,
        this.model
      )
      .pipe(finalize(() => {
        this.genericUtil.hideLoader();
      }))
      .subscribe((resp) => {
        console.log(resp);
        let dataRes = resp.json();
        this.genericUtil.hideLoader();
        if (this.codeCN && dataRes.status === 'nok') {
          this.msg = dataRes.message;
          this.genericUtil.showDialog("afterSaveDialog", "Information", 420, 160);
          this.isErrorSaveSplit = true;
          return;
        }
        this.isErrorSaveSplit = false;
        this.msg = "Save Successfully";
        this.genericUtil.showDialog("afterSaveDialog", "Information", 420, 160);
      });
    
  }

  onSave2() {
    console.log("** onsave2 **");

    this.genericUtil.closeDialog("saveDialog2");
    let bookingList: any[] = [];
    this.gridBooking.listStore.store.forEach((element) => {
      this.perCodeList.forEach((el) => {
        if (element[el] != undefined && element[el] > 0) {
          //
          if (element.ctype == "FLR") {
            if (el == "d20") {
              el = "fr20";
            }
            if (el == "d40") {
              el = "fr40";
            }
            if (el == "d45") {
              el = "fr45";
            }
            if (el == "hc20") {
              el = "frh20";
            }
            if (el == "hc40") {
              el = "frh40";
            }
            if (el == "hc45") {
              el = "frh45";
            }
          } else if (element.ctype == "OPT") {
            if (el == "d20") {
              el = "ot20";
            }
            if (el == "d40") {
              el = "ot40";
            }
            if (el == "d45") {
              el = "ot45";
            }
            if (el == "hc20") {
              el = "oth20";
            }
            if (el == "hc40") {
              el = "oth40";
            }
            if (el == "hc45") {
              el = "oth45";
            }
          } else if (element.ctype == "REF" || element.ctype == "DRY") {
            if (el == "d20") {
              el = "r20";
            }
            if (el == "d40") {
              el = "r40";
            }
            if (el == "d45") {
              el = "r45";
            }
            if (el == "hc20") {
              el = "rh20";
            }
            if (el == "hc40") {
              el = "rh40";
            }
            if (el == "hc45") {
              el = "rh45";
            }
          } else if (element.ctype == "TNK") {
            if (el == "d20") {
              el = "tk20";
            }
            if (el == "d40") {
              el = "tk40";
            }
            if (el == "d45") {
              el = "tk45";
            }
            if (el == "hc20") {
              el = "tkh20";
            }
            if (el == "hc40") {
              el = "tkh40";
            }
            if (el == "hc45") {
              el = "tkh45";
            }
          } else if (element.ctype == "PLT") {
            if (el == "d20") {
              el = "pl20";
            }
            if (el == "d40") {
              el = "pl40";
            }
            if (el == "d45") {
              el = "pl45";
            }
            if (el == "hc20") {
              el = "plh20";
            }
            if (el == "hc40") {
              el = "plh40";
            }
            if (el == "hc45") {
              el = "plh45";
            }
          }

          bookingList.push(el);
        }
      });
    });
    this.model["booking"] = bookingList;

    let mainList: any[] = [];
    this.gridMain.listStore.store.forEach((element) => {
      //console.log(element)
      let bls = new BLSplit();
      bls["blSplitBlno"] = element.blNo;
      bls["blOfficeCode"] = this.model.blOfficeCode;
      bls["blNo"] = this.model.blNo;
      bls["blSplitOfficeCode"] = this.model.blOfficeCode;
      bls["ctype"] = element.ctype;

      this.perCodeList.forEach((el) => {
        if (element[el] != undefined && element[el] > 0) {
          let nilai: number = element[el];
          if (element.ctype == "FLR") {
            if (el == "d20") {
              el = "fr20";
            }
            if (el == "d40") {
              el = "fr40";
            }
            if (el == "d45") {
              el = "fr45";
            }
            if (el == "hc20") {
              el = "frh20";
            }
            if (el == "hc40") {
              el = "frh40";
            }
            if (el == "hc45") {
              el = "frh45";
            }
          } else if (element.ctype == "OPT") {
            if (el == "d20") {
              el = "ot20";
            }
            if (el == "d40") {
              el = "ot40";
            }
            if (el == "d45") {
              el = "ot45";
            }
            if (el == "hc20") {
              el = "oth20";
            }
            if (el == "hc40") {
              el = "oth40";
            }
            if (el == "hc45") {
              el = "oth45";
            }
          } else if (element.ctype == "REF" || element.ctype == "DRY") {
            if (el == "d20") {
              el = "r20";
            }
            if (el == "d40") {
              el = "r40";
            }
            if (el == "d45") {
              el = "r45";
            }
            if (el == "hc20") {
              el = "rh20";
            }
            if (el == "hc40") {
              el = "rh40";
            }
            if (el == "hc45") {
              el = "rh45";
            }
          } else if (element.ctype == "TNK") {
            if (el == "d20") {
              el = "tk20";
            }
            if (el == "d40") {
              el = "tk40";
            }
            if (el == "d45") {
              el = "tk45";
            }
            if (el == "hc20") {
              el = "tkh20";
            }
            if (el == "hc40") {
              el = "tkh40";
            }
            if (el == "hc45") {
              el = "tkh45";
            }
          } else if (element.ctype == "PLT") {
            if (el == "d20") {
              el = "pl20";
            }
            if (el == "d40") {
              el = "pl40";
            }
            if (el == "d45") {
              el = "pl45";
            }
            if (el == "hc20") {
              el = "plh20";
            }
            if (el == "hc40") {
              el = "plh40";
            }
            if (el == "hc45") {
              el = "plh45";
            }
          }

          bls[el] = nilai;

          //bls[el] = element[el];
        }
      });

      mainList.push(bls);
    });
    this.model["main"] = mainList;

    let splitList: any[] = [];

    if (this.model.methodType == "PRORATE") {
      let checkMap = new Map<String, BLSplit>();

      this.gridSplit.listStore.store.forEach((element) => {
        let bls = new BLSplit();
        bls["blSplitBlno"] = element.blNo;
        bls["blOfficeCode"] = this.model.blOfficeCode;
        bls["blNo"] = this.model.blNo;
        bls["blSplitOfficeCode"] = this.model.blOfficeCode;
        bls["ctype"] = element.ctype;
        bls["isNew"] = element.isNew;

        this.perCodeList.forEach((el) => {
          if (element[el] != undefined && element[el] > 0) {
            checkMap.set(element.blNo, bls);
          }
          bls[el] = element[el];
        });

        this.perCodeList.forEach((el) => {
          if (element[el] != undefined && element[el] > 0) {
            let nilai: number = element[el];
            bls[el] = 0;
            //console.log(el + " - " + nilai);
            if (element.ctype == "FLR") {
              if (el == "d20") {
                el = "fr20";
              }
              if (el == "d40") {
                el = "fr40";
              }
              if (el == "d45") {
                el = "fr45";
              }
              if (el == "hc20") {
                el = "frh20";
              }
              if (el == "hc40") {
                el = "frh40";
              }
              if (el == "hc45") {
                el = "frh45";
              }
            } else if (element.ctype == "OPT") {
              if (el == "d20") {
                el = "ot20";
              }
              if (el == "d40") {
                el = "ot40";
              }
              if (el == "d45") {
                el = "ot45";
              }
              if (el == "hc20") {
                el = "oth20";
              }
              if (el == "hc40") {
                el = "oth40";
              }
              if (el == "hc45") {
                el = "oth45";
              }
            } else if (element.ctype == "REF" || element.ctype == "DRY") {
              if (el == "d20") {
                el = "r20";
              }
              if (el == "d40") {
                el = "r40";
              }
              if (el == "d45") {
                el = "r45";
              }
              if (el == "hc20") {
                el = "rh20";
              }
              if (el == "hc40") {
                el = "rh40";
              }
              if (el == "hc45") {
                el = "rh45";
              }
            } else if (element.ctype == "TNK") {
              if (el == "d20") {
                el = "tk20";
              }
              if (el == "d40") {
                el = "tk40";
              }
              if (el == "d45") {
                el = "tk45";
              }
              if (el == "hc20") {
                el = "tkh20";
              }
              if (el == "hc40") {
                el = "tkh40";
              }
              if (el == "hc45") {
                el = "tkh45";
              }
            } else if (element.ctype == "PLT") {
              if (el == "d20") {
                el = "pl20";
              }
              if (el == "d40") {
                el = "pl40";
              }
              if (el == "d45") {
                el = "pl45";
              }
              if (el == "hc20") {
                el = "plh20";
              }
              if (el == "hc40") {
                el = "plh40";
              }
              if (el == "hc45") {
                el = "plh45";
              }
            }

            bls[el] = nilai;
          }
        });

        if (checkMap.get(element.blNo) != null) {
          //console.log(bls)
          splitList.push(bls);
        }
      });
    } else {
      this.gridSplit.listStore.store.forEach((element) => {
        console.log(element);
        let bls = new BLSplit();
        bls["blSplitBlno"] = element.blNo;
        bls["blOfficeCode"] = this.model.blOfficeCode;
        bls["blNo"] = this.model.blNo;
        bls["blSplitOfficeCode"] = this.model.blOfficeCode;
        bls["ctype"] = element.ctype;
        bls["isNew"] = element.isNew;

        this.perCodeList.forEach((el) => {
          bls[el] = element[el];
        });

        this.perCodeList.forEach((el) => {
          if (element[el] != undefined && element[el] > 0) {
            let nilai: number = element[el];
            bls[el] = 0;
            //console.log(el + " - " + nilai);
            if (element.ctype == "FLR") {
              if (el == "d20") {
                el = "fr20";
              }
              if (el == "d40") {
                el = "fr40";
              }
              if (el == "d45") {
                el = "fr45";
              }
              if (el == "hc20") {
                el = "frh20";
              }
              if (el == "hc40") {
                el = "frh40";
              }
              if (el == "hc45") {
                el = "frh45";
              }
            } else if (element.ctype == "OPT") {
              if (el == "d20") {
                el = "ot20";
              }
              if (el == "d40") {
                el = "ot40";
              }
              if (el == "d45") {
                el = "ot45";
              }
              if (el == "hc20") {
                el = "oth20";
              }
              if (el == "hc40") {
                el = "oth40";
              }
              if (el == "hc45") {
                el = "oth45";
              }
            } else if (element.ctype == "REF" || element.ctype == "DRY") {
              if (el == "d20") {
                el = "r20";
              }
              if (el == "d40") {
                el = "r40";
              }
              if (el == "d45") {
                el = "r45";
              }
              if (el == "hc20") {
                el = "rh20";
              }
              if (el == "hc40") {
                el = "rh40";
              }
              if (el == "hc45") {
                el = "rh45";
              }
            } else if (element.ctype == "TNK") {
              if (el == "d20") {
                el = "tk20";
              }
              if (el == "d40") {
                el = "tk40";
              }
              if (el == "d45") {
                el = "tk45";
              }
              if (el == "hc20") {
                el = "tkh20";
              }
              if (el == "hc40") {
                el = "tkh40";
              }
              if (el == "hc45") {
                el = "tkh45";
              }
            } else if (element.ctype == "PLT") {
              if (el == "d20") {
                el = "pl20";
              }
              if (el == "d40") {
                el = "pl40";
              }
              if (el == "d45") {
                el = "pl45";
              }
              if (el == "hc20") {
                el = "plh20";
              }
              if (el == "hc40") {
                el = "plh40";
              }
              if (el == "hc45") {
                el = "plh45";
              }
            }

            bls[el] = nilai;
          }
        });

        splitList.push(bls);
      });
    }

    this.model["split"] = splitList;
    console.log(this.model);

    this.genericUtil.showLoader();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + `/BLSplit/saveSplit?type=${this.typeSuffix}`,
        this.model
      )
      .pipe(finalize(() => {
        this.genericUtil.hideLoader();
      }))
      .subscribe((resp) => {
        console.log(resp);
        this.genericUtil.hideLoader();
        this.msg = "Save Successfully";
        this.genericUtil.showDialog(
          "afterSaveDialog2",
          "Information",
          420,
          160
        );
      });
  }

  onCancel() {
    this.lock = true;
    this.edited = false;
    this.lockQty = true;
    this.disableToolbarButtons = "save,save2,split,retrieve2";

    this.model.blNo = "";
    this.model.aftersplitQty = 0;
    this.model.qty = 0;
    this.model.generateType = "SUFFIX";
    this.model.methodType = "FREIGHT";

    this.gridBooking.listStore.store = [];
    this.gridBooking.loadData();

    this.gridMain.listStore.store = [];
    this.gridMain.loadData();

    this.gridSplit.listStore.store = [];
    this.gridSplit.loadData();

    this.splitStore = [];
  }

  closeSplitDialog() {
    this.genericUtil.closeDialog("splitDialog");
  }

  closeSaveDialog() {
    this.genericUtil.closeDialog("saveDialog");
  }

  closeSaveDialog2() {
    this.genericUtil.closeDialog("saveDialog2");
  }

  closeMsgDialog() {
    this.genericUtil.hideLoader();
    this.genericUtil.closeDialog("msgDialog");
  }

  closeCompareDialog() {
    if (
      this.msg ==
      "Container in BL does not tally with booking. Please check your container."
    ) {
      //this.disableToolbarButtons = "retrieve,split,save";

      if (this.msgTypeQty!="") {
        this.msg = this.msgTypeQty;
        this.genericUtil.showDialog(
          "compareDialog",
          "Information",
          775,
          200
        );
      } else {
        this.genericUtil.closeDialog("compareDialog");
      }

      // if (this.compareMinMap.size > 0 && this.compareMoreMap.size > 0) {
      //   this.msg = "";
      //   var self = this;
      //   this.compareMoreMap.forEach(function (item, key, mapObj) {
      //     self.msg =
      //       self.msg +
      //       "Total of " +
      //       key.toUpperCase() +
      //       " in Booking is more than BL! Program will add " +
      //       item +
      //       " container in Main BL. <br/>";
      //   });
      // } else if (this.compareMinMap.size > 0 && this.compareMoreMap.size == 0) {
      //   this.genericUtil.closeDialog("compareDialog");
      // }
    } else {
      if (this.compareMoreMap.size > 0) {
        this.gridMain.listStore.store.forEach((element) => {
          console.log(element);

          this.compareMoreMap.forEach(function (item, key, mapObj) {
            console.log(key + " - " + item);
            let el = Number.parseInt(element[key]);
            element[key] = el + item;
          });
        });
      }

      this.genericUtil.closeDialog("compareDialog");
    }

    // if(this.totalDiff > 0){
    //   this.message(
    //     "information",
    //     "Information",
    //     "Total of " +
    //                   this.typeDiff +
    //                   " in Booking is more than BL! Program will add " +
    //                   this.totalDiff +
    //                   " container in Main BL. <br/>",
    //     "okonly",
    //     { ok: "" }
    //   );
    //   this.genericUtil.closeDialog("compareDialog");
    // }
  }

  closeAfterSaveDialog() {
    if (this.isErrorSaveSplit) {
      this.genericUtil.closeDialog("afterSaveDialog");
      return;
    }
    this.onRetrieveAfterSave();
    this.genericUtil.closeDialog("afterSaveDialog");
  }

  closeAfterSaveDialog2() {
    this.onRetrieveAfterSave2();
    this.genericUtil.closeDialog("afterSaveDialog2");
  }

  changeEventQty(event) {
    console.log("** change event qty **");
    let val = Number.parseInt(String(event.target.value).replace(/^0+/, ""));
    console.log(val);
    this.model.qty = val;

    if (isNaN(val)) {
      val = 0;
      this.model.qty = val;
    }

    if (val < 0) {
      val = 0;
      this.model.qty = val;
    }
  }

  editColumn($event) {
    const id = $event.target.id as string;
    const resultNumber = $event.target.id.replace(/[^0-9]/g, "");
    this.resultNumberBl = resultNumber;
    if (id.substring(id.length - 4) === 'blNo' && !this.codeCN) {
      this.gridSplit.editable = false;
      document.getElementById(`gridSplit.text.${resultNumber}.blNo`).style.display = 'none';
    } else {
      this.gridSplit.editable = true;
    }
    this.gridSplit.loadData();
  }

  validateRuleBl(blN: string, indexBl?: number ) {
    const isString = this.formValidation.regString.test(blN.substring(9, 11)) == false;
    const isNumber = this.formValidation.regNum.test(blN.substring(12, 15)) == false;
    console.log(isString, isNumber, blN.length !== 15, blN.substring(10, 12), blN);


    if (isString || isNumber || blN.length !== 15) {
      this.msg = `BL number split should begin ${this.model['blNo'].substring(0, 9)} followed by 2 chars (ranging from AA to ZZ) and followed by 4 digits.`;
      if (indexBl) {
        document.getElementById(`gridSplit.span.${indexBl}.blNo`).style.color = 'red';
        document.getElementById(`gridSplit.td.${indexBl}.blNo`).style.border = '1px solid red';
      } else {
        document.getElementById(`gridSplit.span.${this.resultNumberBl}.blNo`).style.color = 'red';
        document.getElementById(`gridSplit.td.${this.resultNumberBl}.blNo`).style.border = '1px solid red';
      }
      this.genericUtil.showDialog("msgDialog", "Warning", 420, 160);
      this.isInValidBl = true;
    } else {
      this.isInValidBl = false;
      if (indexBl) {
        document.getElementById(`gridSplit.span.${indexBl}.blNo`).style.color = 'black';
        document.getElementById(`gridSplit.td.${indexBl}.blNo`).style.border = '1px solid rgba(34, 36, 38, .1)';
      } else {
        document.getElementById(`gridSplit.span.${this.resultNumberBl}.blNo`).style.color = 'black';
        document.getElementById(`gridSplit.td.${this.resultNumberBl}.blNo`).style.border = '1px solid rgba(34, 36, 38, .1)';
      }
    }
  }

  validateAllBlSplit() {

  }

  qtyBlur(idx: number, field:string) {
    console.log("idx : " + idx + "- field : " + field);
    let val :number = Number.parseInt($("#"+field+"_"+idx).val());
    
    var isError: boolean = false;
    var xfield: number = 0;
    this.gridBooking.listStore.store.forEach((element) => {
      xfield = element[field];
    });

    console.log(field + ' booking : '+ xfield);

    var xfieldx: number = 0;
    this.splitStore.forEach((element) => {
      if (element.seqnoNew != idx) {
        var xfieldxx: number = Number.parseInt(element[field]);
        xfieldx += xfieldxx;
      }
    });
    console.log('jumlah '+ xfieldx);
    console.log('val : ' + val)

    this.gridMain.listStore.store.forEach((element) => {
      
        xfield = xfield - (xfieldx + val);
        console.log(xfield);

        if (xfield < 0) {
          isError = true;
        } else if (xfield == 0) {
          this.gridMain.listStore.store.forEach((element) => {
            let hit: number = 0;
            this.perCodeList.forEach((el) => {
              if (el != field) {
                if (element[el] != undefined) {
                  console.log(element[el]);
                  hit += Number.parseInt(element[el]);
                }
              }
            });
            console.log("** hit **");
            console.log(hit);
            if (hit > 0) {
              element[field] = xfield;
            } else {
              isError = true;
            }
          });
        } else {
          element[field] = xfield;
        }
    });
    this.gridMain.loadData();

    if (isError) {
      this.message(
        "information",
        "Information",
        "Total Container Can't Exceed From Main BL.",
        "okonly",
        { ok: "" }
      );
      this.splitStore.forEach((element) => {
        console.log(element)
        if(element.seqnoNew == idx){
          //console.log("** lastval 2 **");
          //console.log(this.lastVal);
          element[field] = 0; //this.lastVal;
        }
      });
      $("#"+field+"_"+idx).val(0);
    }else{
      this.splitStore.forEach((element)=>{
        if(element.seqnoNew == idx){
          element[field] = val;
        }
      })
      console.log(this.splitStore);
    }
  }
}

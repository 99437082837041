import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  Authorize,
  ComboPluginComponent,
  ConfigService,
  CookieService,
  DialogPluginComponent,
  GenericService,
  GenericUtil,
  GridPluginComponent,
  Validator,
} from "sibego-ui-library";
import { ExceptionModel } from "../../shared/models/exceptionModel";
import FormValidation from "../../../utils/formValidation";
import { finalize, takeUntil } from "rxjs/operators";
import { TransferPodService } from "./transfer-pod.service";
import { Subject } from "rxjs";
import { TransferPodBookingModel } from '../../shared/models/transferPodModel';
import { IsRowSelectable } from "ag-grid-community";
import { TransactionTsPortHomeService } from "../transaction-ts-port-home.service";
declare var $: any;

@Component({
  selector: 'app-transaction-ts-transfer-pod',
  templateUrl: './transaction-ts-transfer-pod.component.html',
  styleUrls: ['./transaction-ts-transfer-pod.component.css']})
export class TransactionTsTransferPodComponent  extends Validator implements OnInit {

  @Output() redirectToExceptionHandel = new EventEmitter<string>();

  @ViewChild("cbVesselDischarging") cbVesselDischarging: ComboPluginComponent;
  @ViewChild("cbVesselConnecting") cbVesselConnecting: ComboPluginComponent;

  @ViewChild("cbVoyageDischarging") cbVoyageDischarging: ComboPluginComponent;
  @ViewChild("cbVoyageConnecting") cbVoyageConnecting: ComboPluginComponent;

  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbLoadPortTerminal") cbLoadPortTerminal: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbDischargePortTerminal")
  cbDischargePortTerminal: ComboPluginComponent;
  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;
  @ViewChild("dialogRenom") dialogPlugin: DialogPluginComponent;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";
  dialogFrom = "";
  messageValidateDialog = "";
  noteContent = "";

  modeToolbar = false;
  loading = false;
  isError: Boolean = false;

  settingToolbar;
  settingGrid;

  dialog: any;
  settingPOT: any;
  settingLoadPort: any;
  settingDischargePort: any;
  settingDischargingVessel: any;
  settingConnectingVessel: any;
  settingDischargingVoyage: any;
  settingConnectingVoyage: any;
  defaultColumnDefs: any;
  getRowStyle: any;

  model = new TransferPodBookingModel();

  settingStatus: {
    id: string;
    type: string;
    url: string;
    urlType: string;
    placeholder: string;
    code: string;
    title: string;
  };

  defaultColumnDefsGridPortPair: any;

  validatorRules: any;
  rowData = [];
  rowDataGridPortPair = [];

  // Form Validation
  formValidation = new FormValidation();
  officeCode =
  this.cookieService.getDefaultLocationCode() == null
    ? "*"
    : this.cookieService.getDefaultLocationCode();
  settingPOT1: any;
  settingContOpr: any;
  settingVesselDischarging: any;
  settingVoyageConnecting: any;
  settingVoyageDischarging: any;
  settingVesselConnecting: any;
  getRowStyleGridPortPair: any;
  rowIndexW: any;
  rowDataPortPair: any;
  valueDialog = "";
  maxlengthDialog = 4;
  gridApi: any;
  columnApi: any;
  topGridPortPair: any;
  unRenominatedList: any;
  isRetrieve: boolean;
  labelConfirmCancelClose = "";

  private ngUnsubscribe = new Subject<boolean>();

  isRowSelectable: IsRowSelectable = (
    params: any
  ) => {
    return params.data.tsPolBlNo != '' && params.data.tsStatus !== "Archived" && params.data.tsStatus !== "Rejected" && params.data.tsStatus !== "Deleted";
  };
  lockPOT = false;

  constructor(
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef,
    private transferPodService: TransferPodService,
    private transactionTsPortHomeService: TransactionTsPortHomeService
  ) {
    super();
    this.handleSetting();
  }

  handleSetting() {
    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "search", icon: "search" },
        { name: "POT Remarks", event: "potRemarks", icon: "plus" },
        { name: "Outward Slot", event: "outwardSlot", icon: "save" },
        { name: "Reason", event: "reason", icon: "copy" },
        {
          name: "Transfer",
          event: "transfer",
          icon: "exchange",
        },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
        { name: "Export", event: "export", icon: "save" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Transfer (New POD)",
    };

    this.settingContOpr = {
      id: "cbContOpr",
      type: "select",
      url: '[ { "contOprCode" : "ALL", "contOprName" : "All" }, { "contOprCode" : "CONFIRMED", "contOprName" : "CONFIRMED" }, { "contOprCode" : "RENOMINATED", "contOprName" : "RENOMINATED" }, { "contOprCode" : "ARCHIVED", "contOprName" : "ARCHIVED" }, { "contOprCode" : "REJECTED", "contOprName" : "REJECTED" }, { "contOprCode" : "DELETED", "contOprName" : "DELETED" }]',
      urlType: "inject",
      placeholder: "All",
      code: "contOprCode",
      title: "contOprName",
    };

    this.settingLoadPort = {
      id: 'cbLoadPort',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      columns: [
        // {header: 'Location Id', field: 'locationId', width:100},
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Location Code', field: 'locationCode', width: 100 },
        { header: 'Remarks', field: 'remarks', width: 100 },
        { header: 'Valid?', field: 'isValid', width: 100 },
      ]
    }

    this.defaultColumnDefs = [
      {
        headerName: '',
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true, // only available from AG Grid 28.1
      },
      { headerName: 'Status', field: 'tsStatus', width: 100 },
      { headerName: 'Priority Booking', field: 'tsIsPriorityBooking', width: 150 },
      { headerName: 'POL B/L No.', field: 'tsPolBlNo', width: 150 },
      { headerName: 'POL BKG No.', field: 'tsPolBookingNo', width: 150 },
      { headerName: 'BKG Party', field: 'tsPolBookingPartyName', width: 200 },
      { headerName: 'Shipper', field: 'tsPolShipperName' },
      { headerName: 'Load Port', field: 'tsPolLoadPortName' },
      { headerName: 'Terminal (POL)', field: 'tsPolLoadPortTerminalName' },
      { headerName: 'Discharge Port', field: 'tsPolDischargePortName' },
      { headerName: 'Terminal (POD)', field: 'tsPolDischargePortTerminalName' },
      { headerName: 'Place of Delivery', field: 'tsPolPodlName' },

      { headerName: 'Final Destination', field: 'tsPolFdestName' },
      { headerName: 'Sp Dtls', field: 'tsPolSpecialDetailsName' },
      { headerName: 'Ctr Type', field: 'tsPolContainerType' },
      { headerName: 'DG', field: 'tsPolDg' },

      { headerName: '20', field: 'tsPol20' },
      { headerName: '40', field: 'tsPol40' },
      { headerName: '45', field: 'tsPol45' },
      { headerName: 'H20', field: 'tsPolH20' },
      { headerName: 'H40', field: 'tsPolH40' },
      { headerName: 'H45', field: 'tsPolH45' },
      { headerName: 'POL BKG Remarks', field: 'tsPolBookingRemarks', tooltipField: 'tsPolBookingRemarks' },

      { headerName: 'Discharging Vessel', field: 'tsDischargingVesselName' },
      { headerName: 'Discharging Voyage', field: 'tsDischargingVesselVoyage' },
      { headerName: 'Bound', field: 'tsDischargingVesselBound' },
      { headerName: 'Service', field: 'tsDischargingServiceCode' },
      { headerName: 'ETA POL', field: 'tsDischargingEtaPol' },
      { headerName: 'ETD POL', field: 'tsDischargingEtdPol' },
      { headerName: 'ETA POT', field: 'tsDischargingEtaPot' },
      { headerName: 'ETD POT', field: 'tsDischargingEtdPot' },

      { headerName: 'Connecting Vessel', field: 'tsConnectingVesselName', cellStyle: params => {
        if(params.data.hoursDifference > 240){
          return { color : 'blue'}
        }
        return null;
      }},
      { headerName: 'Connecting Voyage', field: 'tsConnectingVesselVoyage', cellStyle: params => {
          if(params.data.hoursDifference > 240){
            return { color : 'blue'}
          }
        return null;
      }},
      { headerName: 'Bound', field: 'tsConnectingVesselBound' },
      { headerName: 'Service', field: 'tsConnectingServiceCode' },
      { headerName: 'ETA POT', field: 'tsConnectingEtaPot' },
      { headerName: 'ETD POT', field: 'tsConnectingEtdPot' },
      { headerName: 'ETA POD', field: 'tsConnectingEtaPod' },
      { headerName: 'ETD POD', field: 'tsConnectingEtdPod' },

      {
        headerName: 'Outward Slot', field: 'tsOutwardSlotOperator',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 4, rows: 1, cols: 20 }
      },
      {
        headerName: 'POT Remarks',
        field: 'tsPotRemarks',
        tooltipField: 'tsPotRemarks',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 320 }
      },
      {
        headerName: 'Reason for Transfer', field: 'tsReasonForRenomination',
        tooltipField: 'tsReasonForRenomination',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 320 }
      },
      { headerName: 'POT BKG No.', field: 'tsPotBookingNo', tooltipField: 'tsPotBookingNo' },
      { headerName: 'POT B/L No.', field: 'tsPotBlNo', tooltipField: 'tsPotBlNo' },
      { headerName: 'Confirmed By', field: 'tsConfirmedUserId' },
      { headerName: 'Confirmed Date', field: 'tsConfirmedDate' },
      { headerName: 'Transferred By', field: 'tsRenominatedUserId' },
      { headerName: 'Transferred Date', field: 'tsRenominatedDate' },
      { headerName: 'Rejected By', field: 'tsRejectedUserId' },
      { headerName: 'Rejected Date', field: 'tsRejectedDate' },
    ];

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findPotProspectBooking/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Port of Transhipment",
      description: "",
      title: "locationName",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Is POT", field: "isPot", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingLoadPort = {
      id: 'cbLoadPort',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      columns: [
        // {header: 'Location Id', field: 'locationId', width:100},
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Location Code', field: 'locationCode', width: 100 },
        { header: 'Remarks', field: 'remarks', width: 100 },
        { header: 'Valid?', field: 'isValid', width: 100 },
      ]
    }

    this.defaultColumnDefsGridPortPair = [
      { headerName: 'Load Port', field: 'tslLoadPortName'},
      { headerName: 'Terminal(POL)', field: 'tslLoadPortTerminalName'},
      { headerName: 'DischargePort', field: 'tslDischargePortName'},
      { headerName: 'Terminal(POD)', field: 'tslDischargePortTerminalName'},
      { headerName: 'Allocated(Teus)', field: 'tslAllocatedTeus' },
      { headerName: 'Booked(Teus)', field: 'tslBookedTeus' },
      { headerName: 'Confirmed(Teus)', field: 'tslConfirmedTeus' },
      { headerName: 'Available(Teus)', field: 'tslAvailableTeus' },
    ];

    this.settingVesselConnecting = {
      id: "cbVesselConnecting",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVesselDischarging = {
      id: "cbVesselDischarging",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageDischarging = {
      id: "cbVoyageDischarging",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVoyageConnecting = {
      id: "cbVoyageConnecting",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVesselConnecting = {
      id: "cbVesselConnecting",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingGrid = {
      url: "",
      page: 10,
      columns: [
        //{header: 'ID', field: 'id', width: 50},
        //{header: 'Index', field: 'Index', width: 20},
        { header: "Load Port", field: "polName", width: 200 },
        { header: "Discharge Port", field: "podName", width: 200 },
        //{header: 'Container Type', field: 'contType', width: 200},
        { header: "Allocated (Teus)", field: "alloted", width: 200 },
        { header: "Booked (Teus)", field: "booked", width: 200 },
        { header: "Confirmed (Teus)", field: "confirm", width: 200 },
        { header: "Available (Teus)", field: "available", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      /*
      footer : {
        label : 'Total Allocation'
      },
      */
      //store: this.listStore,
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "locationName",
      sortingDirection: "ASC",
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url:this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Discharge Port --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };


    this.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted") {
        return { "background-color": "lightcoral !important;" };
      }
    };

    this.getRowStyleGridPortPair = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted") {
        return { "background-color": "lightcoral !important;" };
      }
    };
  }

  ngOnInit() {
    this.transferPodService.messageState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        if (data) {
          if (data.mess && data.type == 'nEvent') {
            this.message(
              "Information",
              "information",
              data.mess,
              "okonly",
              { ok: "" }
            );
          } else if (data.type && data.type == "event") {
            this.message('information', 'Information', data.mess, 'yesno', { yes: 'this.handleReno()', no: 'this.loading = false;' });
          }
        }
      }
    )

    this.transferPodService.statusTransferPodState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        this.unRenominatedList = res;
        this.searchEvent();
      }
    })

    // this.renominationService.modelSource$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
    //   res => {
    //     if (res) {
    //       this.model = this.renominationService.getModelReno();
    //     }
    //   }
    // )
  }

  ngAfterViewInit() {
    this.eventAfterInitView();
  }

  eventAfterInitView() {
    this.setValidatorRetrieve();
    this.model.officeCode = this.officeCode;
    this.cbPOT1.setValue("SGSIN");
    var self = this;
    setTimeout(() => {
      self.assignValueStage();
      self.disableToolbarButtons = "potRemarks,outwardSlot,reason,transfer,reject,export";
    }, 100);
  }

  assignValueStage(){
    this.transactionTsPortHomeService.getDataTransferPodBooking().subscribe(data=>{
      if(data.model && data.gird){
        this.model = data.model;
        this.cbVesselDischarging.setForceValue(data.model.vesselNameDischarging || "");
        this.cbVesselDischarging.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + this.model.bPolCode + "/{query}");
        this.cbVoyageDischarging.setForceValue(data.model.voyageDischarging || "");
        this.cbVoyageDischarging.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/discharging/" + this.officeCode + '/' + this.model.vesselIdDischarging + "/" + this.model.bound + "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPolCode + "&potCode=" + this.model.bPot1Code);
        this.cbVesselConnecting.setForceValue(data.model.vesselNameConnecting || "");
        this.cbVesselConnecting.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + this.model.bPot1Code + "/{query}");
        this.cbVoyageConnecting.setForceValue(data.model.voyageConnecting || "");
        this.cbVoyageConnecting.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" + this.officeCode + '/' + this.model.vesselIdConnecting + "/" + this.model.bound + "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPot1Code);
        this.cbLoadPort.setForceValue(data.model.bPolName);
        this.cbDischargePort.setForceValue(data.model.podName);
        this.rowData = data.gird;
        this.gridApi.setPinnedBottomRowData([
          {
            tsStatus: 'Total Unit :',
            tsPol20: data.totalD20,
            tsPol40: data.totalD40,
            tsPol45: data.totalD45,
            tsPolH20: data.totalH20,
            tsPolH40: data.totalH40,
            tsPolH45: data.totalH45,
          }
        ]);
    
      }
    })
  }

  ngOnDestroy() {
    console.log("---------ngOnDestroy Exception page");
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.dialog = {};
    this.transferPodService.callMessageProm({ type: 'nEvent', mess: '' });
    this.genericUtil.closeDialog("TransferPodDialog");
    // this.dialogPlugin.onCancel();
    // this.dialogPlugin.ngOnDestroy();
  }

  setValidatorRetrieve() {
    this.model["error-bound"] = "";
    this.model["error-bPolCode"] = "";
    this.model["error-bPot1Code"] = "";
    this.model["error-allocationValidator"] = "";

    this.validatorRules = {
      // bPolCode: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Please input Load Port",
      //     },
      //   ],
      // },
      bPot1Code: {
        rules: [
          {
            type: "empty",
            prompt: "Please input POT.",
          },
        ],
      }
    };
  }

  eventMessage(event) {
    if (event != '') {
      eval(event);
    }
  }

  changeBound(event) {}

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "search":
        this.handleSearch();
        break;
      case "potRemarks":
        this.handlePotRemarks();
        break;
      case "outwardSlot":
        this.handleOutwardSlot();
        break;
      case "transfer":
        this.handleTransfer();
        break;
      case "reason":
        this.handleReason();
        break;
      case "cancel":
        this.confirmCancelClose("cancel");
        break;
      case "export":
        this.handleExport();
        break;
      case "close":
        this.confirmCancelClose("close");
        break;
    }
  }

  confirmCancelClose(type) {
    this.labelConfirmCancelClose = type;
    let message =
      type === "cancel"
        ? "No data will be confirmed / rejected. Proceed to cancel?"
        : "No data will be confirmed / rejected. Proceed to close?";
    this.message("delete", "Confirm", message, "yesno", {
      yes: "this.confirmYes()",
      no: "",
    });
  }

  confirmYes() {
    console.log('----------------------', this.labelConfirmCancelClose);

    if (this.labelConfirmCancelClose === "cancel") {
      this.handleCancel();
    } else {
      this.router.navigateByUrl("/main/home");
    }
  }

  handleCancel() {
    this.cbVesselConnecting.setValue("");
    this.cbVesselDischarging.setValue("");
    this.cbVoyageConnecting.setValue("");
    this.cbVoyageDischarging.setValue("");
    this.cbPOT1.setValue("");
    this.cbLoadPort.setValue("");
    this.cbDischargePort.setValue("");
    this.cbVesselConnecting.disableCombo = false;
    this.cbVesselDischarging.disableCombo = false;
    this.cbVoyageConnecting.disableCombo = false;
    this.cbVoyageDischarging.disableCombo = false;
    this.model = new TransferPodBookingModel();
    this.invisibleToolbarButtons = "insert";
    this.disableToolbarButtons = "potRemarks,outwardSlot,reason,transfer,reject,export";
    this.officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();
    this.model.officeCode = this.officeCode;
    this.model.bound = "O";
    this.isRetrieve = false; //CR001
    this.rowData = [];
    this.rowDataPortPair = [];
    this.cbPOT1.setValue("SGSIN");
    this.lockPOT = false;
    this.saveStage(this.model, [], 0,0,0,0,0,0);
  }

  handleSearch() {
    this.isError = false;
    this.model["allocationValidator"] = false;
    this.model["error-allocationValidator"] = "";
    this.model["error-podCode"] = "";
    this.model["error-bPolCode"] = "";
    this.isError = this.onValidate(this.model);
    if (!this.model.bPolCode && !this.model.podCode && !this.isError) {
      this.model["error-bPolCode"] = "Please input POL";
      this.model["error-podCode"] = "Please input POD";
      this.isError = true;
    }
    if (!this.isError) {
      if (this.model.bPolCode && this.model.podCode && !this.model.vesselIdConnecting && !this.model.vesselIdDischarging) {
        this.model["error-allocationValidator"] = "Please input at least 1 vessel/voyage.";
        this.isError = true;
        return;
      }

      if (this.model.bPolCode && !this.model.vesselIdDischarging && !this.model.vesselIdConnecting && !this.model.voyageConnecting) {
        this.model["error-allocationValidator"] = "Please input Discharging Vessel.";
        this.isError = true;
        return;
      }

      // GST-188  Missing Prompter for POD when Connecting Vessel Voy = Y
      if (this.model.bPolCode && !this.model.podCode && this.model.voyageConnecting && this.model.vesselIdConnecting) {
        this.model["error-podCode"] = "Please input POD.";
        this.isError = true;
        return;
      }

      if (!this.model.voyageDischarging && this.model.vesselIdDischarging) {
        this.model["error-allocationValidator"] = "Please input Discharging Voyage.";
        this.isError = true;
        return;
      }
      if (!this.model.vesselIdConnecting && !this.model.vesselIdDischarging && !this.model.voyageDischarging && !this.model.bPolCode) {
        this.model["error-allocationValidator"] = "Please input Connecting Vessel.";
        this.isError = true;
        return;
      }
      if (!this.model.voyageConnecting && this.model.vesselIdConnecting) {
        this.model["error-allocationValidator"] = "Please input Connecting Voyage.";
        this.isError = true;
        return;
      }
      this.genericUtil.showLoader();
      this.searchEvent();
    }
  }

  searchEvent(selectedRows?) {
    this.genericUtil.showLoader();
    const disableToolbarButtons = this.disableToolbarButtons.concat(",search");
    this.disableToolbarButtons = disableToolbarButtons;
    this.transferPodService.getTransferBooking(this.model).pipe(
      finalize(() => {
        this.genericUtil.hideLoader();
        const disableToolbarButtons = this.disableToolbarButtons.replace(',search','');
        this.disableToolbarButtons = disableToolbarButtons;
      })
    ).subscribe(
      res => {
        if (res) {
          const data = res.json();
          this.handleAfterSearch(data, selectedRows);
        } else {
          this.message(
            "Information",
            "information",
            'Something went wrong',
            "okonly",
            { ok: "" }
          );
          this.saveStage(this.model, [], 0,0,0,0,0,0);
        }
      },() => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
        this.saveStage(this.model, [], 0,0,0,0,0,0);
      }
    )
  }

  handleAfterSearch(data, selectedRows?) {
    if (data.status !== 'ok') {
      this.message(
        "Information",
        "information",
        data.message,
        "okonly",
        { ok: "" }
      );
      this.rowData = [];
      this.rowDataPortPair = [];
      this.setHeader(data);
      this.saveStage(this.model, [], 0,0,0,0,0,0);
      return;
    }
    this.disableToolbarButtons = "";
    this.rowData = data.content.tsBkgRenominationList;
    this.rowDataPortPair = data.content.tsPortPairAllocations;
    this.gridApi.setPinnedBottomRowData([
      {
        tsStatus: 'Total Unit :',
        tsPol20: data.content.totalD20,
        tsPol40: data.content.totalD40,
        tsPol45: data.content.totalD45,
        tsPolH20: data.content.totalH20,
        tsPolH40: data.content.totalH40,
        tsPolH45: data.content.totalH45,
      }
    ]);
    this.setHeader(data);
    this.saveStage(this.model, data.content.tsBkgRenominationList ? data.content.tsBkgRenominationList : [], data.content.totalD20, data.content.totalD40, data.content.totalD45, data.content.totalH20, data.content.totalH40, data.content.totalH45);
    this.lockPOT = true;
    
    if (this.unRenominatedList && this.unRenominatedList.length > 0) {
      this.unRenominatedList.forEach(e => {
        for (const row of this.rowData) {
          if (e == row.id) {
            row.tsStatus = "Unable to transfer."
          }
        }
      })
      this.unRenominatedList = [];
      this.gridApi.setRowData(this.rowData);
    }else{
      setTimeout(() => {
        if(selectedRows != undefined){
          this.gridApi.forEachNode((node)=> {
            selectedRows.forEach(selectRow => {
              if(selectRow.id ===  node.data.id){
                node.setSelected(true);
              }  
            });
          })
          this.gridApi.redrawRows()
        }
      }, 200);
    }
  }

  setHeader(data) {
    this.model.serviceConnecting = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsConnectingServiceCode : "";
    this.model.serviceDischarging = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsDischargingServiceCode : "";

    this.model.tsConnectingEtaPod = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsConnectingEtaPod : "";
    this.model.tsConnectingEtaPot = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsConnectingEtaPot : "";
    this.model.tsConnectingEtdPod = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsConnectingEtdPod : "";
    this.model.tsConnectingEtdPot = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsConnectingEtdPot : "";
    this.model.tsDischargingEtaPol = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsDischargingEtaPol : "";
    this.model.tsDischargingEtaPot = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsDischargingEtaPot : "";
    this.model.tsDischargingEtdPol = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsDischargingEtdPol : "";
    this.model.tsDischargingEtdPot = data.content.tsProspectHeader ? data.content.tsProspectHeader.tsDischargingEtdPot : "";
  }

  saveStage(model, gird, totalD20, totalD40, totalD45, totalH20, totalH40, totalH45){
    this.gridApi
    let paramData = {
      model: model,
      gird: gird,
      totalD20: totalD20,
      totalD40: totalD40,
      totalD45: totalD45,
      totalH20: totalH20,
      totalH40: totalH40,
      totalH45: totalH45,
    }
    this.transactionTsPortHomeService.setDataTransferPodBooking(paramData);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }

  onGridPortReady(params) {
    this.topGridPortPair = params.api;
    this.topGridPortPair.hideOverlay();
  }

  rowDoubleClick(event) {}

  onSelectionChanged(event) { }

  changeEventPOT1(event) {
    if (event.locationCode != null) {
      this.model.bPot1Code = event.locationCode;
      this.model.bPot1Name = event.locationName;
      this.cbVesselConnecting.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + event.locationCode + "/{query}");
      this.model['error-bPot1Code'] = "";
      this.isError = false;
    } else {
      this.model.bPot1Code = "";
      this.model.bPot1Name = "";
    }
  }

  changeEventVesselDischarging(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselIdDischarging = event.vesselId;
      this.model.vesselCodeDischarging = event.vesselCode;
      this.model.vesselNameDischarging = event.vesselName;
      this.cbVoyageDischarging.setValue("");
      this.cbVoyageDischarging.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/discharging/" + this.officeCode + '/' + this.model.vesselIdDischarging + "/" + this.model.bound + "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPolCode + "&potCode=" + this.model.bPot1Code);
      this.model.voyageDischarging = "";
      this.model.tsDischargingEtaPol = "";
      this.model.tsDischargingEtaPot = "";
      this.model.tsDischargingEtdPol = "";
      this.model.tsDischargingEtdPot = "";
      this.model.serviceDischarging = "";
    } else {
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
      this.cbVoyageDischarging.setValue("");
      this.model.voyageDischarging = "";
      this.model.tsDischargingEtaPol = "";
      this.model.tsDischargingEtaPot = "";
      this.model.tsDischargingEtdPol = "";
      this.model.tsDischargingEtdPot = "";
      this.model.serviceDischarging = "";
    }
  }

  changeEventVesselConnecting(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselIdConnecting = event.vesselId;
      this.model.vesselCodeConnecting = event.vesselCode;
      this.model.vesselNameConnecting = event.vesselName;
      this.cbVoyageConnecting.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" + this.officeCode + '/' + this.model.vesselIdConnecting + "/" + this.model.bound + "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPot1Code);
      this.cbVoyageConnecting.setValue("");
      this.model.voyageConnecting = "";
      this.model.tsConnectingEtaPod = "";
      this.model.tsConnectingEtaPot = "";
      this.model.tsConnectingEtdPod = "";
      this.model.tsConnectingEtdPot = "";
      this.model.serviceConnecting = "";
    } else {
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
      this.cbVoyageConnecting.setValue("");
      this.model.voyageConnecting = "";
      this.model.tsConnectingEtaPod = "";
      this.model.tsConnectingEtaPot = "";
      this.model.tsConnectingEtdPod = "";
      this.model.tsConnectingEtdPot = "";
      this.model.serviceConnecting = "";
    }
  }

  changeEventBound(event) {
    // this.cbVoyage.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //   "/MasterSailingSchedules/distinctVoyage/" +
    //   this.model.vesselId +
    //   "/" +
    //   this.model.bound.toUpperCase() +
    //   "/{query}/" +
    //   this.officeCode
    // );
  }

  changeEventVoyageDischarging(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.voyageDischarging = event.voyage;
    } else {
      this.model.voyageDischarging = event.voyage;
    }
  }

  changeEventVoyageConnecting(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.voyageConnecting = event.voyage;
    } else {
      this.model.voyageConnecting = event.voyage;
    }
  }

  changeEventLoadPort(event) {
    if (event.locationCode != null) {
      this.model.bPolCode = event.locationCode;
      this.model.bPolName = event.locationName;
      this.cbVesselDischarging.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + event.locationCode + "/{query}");
      this.model['error-bPolCode'] = "";
      if (this.model.bPot1Code) {
        this.isError = false;
      }
      this.cbVesselDischarging.setValue("");
      this.cbVoyageDischarging.setValue("");
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
      this.model.voyageDischarging = "";
    } else {
      //this.cbLoadPortTerminal.setUrl("");
      //this.cbLoadPortTerminal.setValue("");
      this.model.bPolCode = "";
      this.model.bPolName = "";
      this.cbVesselDischarging.setValue("");
      this.cbVoyageDischarging.setValue("");
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
      this.model.voyageDischarging = "";
    }
  }

  changeEventDischargePort(event) {
    // console.log(event);
    var self = this;
    if (event.locationCode != null) {
      this.model.podId = event.locationId;
      this.model.podCode = event.locationCode;
      this.model.podName = event.locationName;
      this.cbVesselConnecting.setValue("");
      this.cbVoyageConnecting.setValue("");
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
      this.model.voyageConnecting = "";
      this.model['error-podCode'] = "";
      this.model['error-bPolCode'] = "";
      if (this.model.bPot1Code) {
        this.isError = false;
      }
    } else {
      this.model.podCode = "";
      this.model.podName = "";
      this.cbVesselConnecting.setValue("");
      this.cbVoyageConnecting.setValue("");
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
      this.model.voyageConnecting = "";
    }
  }

  closeRenominateDialog(event){
    this.genericUtil.closeDialog("TransferPodDialog");
  }

  handleRowSelected(event) {
    const data = event.data;
    const currentNode = event.node;
    const isSelected =  currentNode.isSelected();
    event.api.forEachNode((node) => {
      if (node.data.tsPolBookingNo === data.tsPolBookingNo) node.setSelected(isSelected);

      if (node.data.isCombinedRecord === 'Y' && node.data.combinedBundleKey === currentNode.data.combinedBundleKey){
        node.setSelected(isSelected);
      }
    })
  }

  onCellValueChanged(params) {
    this.rowIndexW = params.rowIndex;
    const colId = params.column.getId();

    let url;

    switch (colId) {
      case "tsPotRemarks":
        url = "/TsBookingRenomination/saveField/tsPotRemarks";
        break;
      case "tsOutwardSlotOperator":
        url = "/TsBookingRenomination/saveField/tsOutwardSlotOperator";
        break;
      case "tsReasonForRenomination":
        url = "/TsBookingRenomination/saveField/tsReasonForRenomination";
        break;
      default:
        break;
    }

    let body = {
      updatedContent: params.newValue,
      ids: [params.data.id]
    }
    let urlReqest = this.configService.config.BASE_API.toString() + url;
    this.genericUtil.showLoader();
    this.genericService.POST(urlReqest, body)
    .pipe(finalize(()=>{this.genericUtil.hideLoader()}))
    .subscribe(
      (resp) => {
        if (resp.json()["status"] == "ok") {
          // this.searchEvent();
        } else {
          this.message("error", "Error", resp.json().message, "okonly", {
            ok: "",
          });
        }
      },
      (error) => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any,
    id?: string
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
      id: 'dialog-transfer-pod'
    };
    setTimeout(() => {
      $("#dialog-transfer-pod").modal("setting", "closable", false);
      $("#dialog-transfer-pod").modal("show");
    }, 10);
  }

  defaultForm() {
    // reset form/page to default
  }

  handlePotRemarks(){
    const selectedRows = this.gridApi.getSelectedRows();
    if(selectedRows.length > 0){
      this.dialogFrom = "POT_REMARKS";
      this.noteContent = "Note: This remark will apply to ALL SELECTED records.";
      this.genericUtil.showDialog("DialogContent", "POT Remarks", 500, 270);
      this.maxlengthDialog = 320;
    }else{
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to insert POT remarks',
        "okonly",
        { ok: "" }
      );
    }
  }

  handleOutwardSlot(){
    const selectedRows = this.gridApi.getSelectedRows();
    if(selectedRows.length > 0){
      this.dialogFrom = "OUTWARD_SLOT";
      this.noteContent = "Note: This Outward Slot apply to ALL SELECTED records.";
      this.genericUtil.showDialog("DialogContent", "Outward Slot", 500, 270);
      this.maxlengthDialog = 4;
    }else{
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to change Outward Slot.',
        "okonly",
        { ok: "" }
      );
    }
  }

  clickConfirm(){
    const selectedRows = this.gridApi.getSelectedRows();
    if(selectedRows.length > 0){
      this.handleConfirm();
    }else{
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to confirm.',
        "okonly",
        { ok: "" }
      );
    }
  }

  handleConfirm(){
    const selectedRows = this.gridApi.getSelectedRows();
    let urlReqest = this.configService.config.BASE_API.toString() + "/TsBookingRenomination/confirmAll";
    let body = {
      userId: localStorage.getItem("logged").split("|")[0],
      tsBkgProspects: selectedRows
    }
    this.genericService.POST(urlReqest, body).subscribe(
      (resp) => {
        if (resp.json()["status"] == "ok") {
          this.searchEvent();
          if(resp.json()["unconfirmedList"].length > 0){
            this.unRenominatedList = resp.json()["unconfirmedList"].map(obj => ({...obj, isConfirm: 'N'}));
            this.unRenominatedList
            if(resp.json()["unconfirmedList"].length > 0){
              this.handleUnconfirmedList();
            }
          }
        } else {
          this.message("error", "Error", resp.json().message, "okonly", {
            ok: "",
          });
        }
      },
      (error) => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  handleUnconfirmedList(){
    let heightDialog = 215;
    if(this.unRenominatedList.length > 3){
      heightDialog = 450;
    }
    if(this.unRenominatedList.length == 3){
      heightDialog = 350;
    }
    if(this.unRenominatedList.length == 2){
      heightDialog = 300;
    }
    if(this.unRenominatedList.length == 1){
      heightDialog = 250;
    }
    this.genericUtil.showDialog(
      `UnconfirmedList`,
      "Unconfirmed List",
      1000,
      heightDialog
    );
  }

  returnTime (connectingEtaPotDate, dischargingEtdPotDate) {
    let msDate1 = new Date(connectingEtaPotDate);
    let msDate2 = new Date(dischargingEtdPotDate);
    let ispositiveNumbers = true;
    if(Number(msDate1) - Number(msDate2) < 0){
      ispositiveNumbers = false;
    }else{
      ispositiveNumbers = true;
    }
    let ms = Math.abs(Number(msDate1) - Number(msDate2))
    const days = Math.floor(ms / (24*60*60*1000));
    const daysms = ms % (24*60*60*1000);
    const hours = Math.floor(daysms / (60*60*1000));
    const hoursms = ms % (60*60*1000);
    const minutes = Math.floor(hoursms / (60*1000));
    const minutesms = ms % (60*1000);
    const sec = Math.floor(minutesms / 1000);

    let daysInfo = days === 0 ? "" : `${days} days`;
    return ispositiveNumbers ? `${daysInfo} ${hours} hours ${minutes} minutes` : `-${daysInfo} ${hours} hours ${minutes} minutes`;
  }

  handleReason(){
    const selectedRows = this.gridApi.getSelectedRows();
    if(selectedRows.length > 0){
      this.dialogFrom = "REASON";
      this.noteContent = "Note: This reason will apply to ALL SELECTED records.";
      this.genericUtil.showDialog(
        "DialogContent",
        "Reason for Transfer",
        500,
        270
      );
      this.maxlengthDialog = 320;
    }else{
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to insert reason.',
        "okonly",
        { ok: "" }
      );
    }
  }


  valueDialogChange(event) {
    if (event) {
      this.messageValidateDialog = "";
    } else {
      switch (this.dialogFrom) {
        case "POT_REMARKS":
          this.messageValidateDialog = "Please input remarks";
          break;
        case "OUTWARD_SLOT":
          this.messageValidateDialog = "Please input Outward Slot";
          break;
        case "REASON":
          this.messageValidateDialog = "Please input reason";
          break;
        default:
          break;
      }
    }
  }

  cancelDialog() {
    this.valueDialog = "";
    this.messageValidateDialog = "";
  }

  closeDialog() {
    this.genericUtil.closeDialog("DialogContent");
    this.valueDialog = "";
    this.messageValidateDialog = "";
  }

  closeDialogRemarksOutReason() {
    this.genericUtil.closeDialog("DialogContent");
    this.valueDialog = "";
    this.messageValidateDialog = "";
  }

  saveDialog() {
    if (!this.valueDialog) {
      this.closeDialogRemarksOutReason();
      return;
    }
    this.messageValidateDialog = "";
    let url = "";

    switch (this.dialogFrom) {
      case "POT_REMARKS":
        url = "/TsBookingRenomination/saveField/tsPotRemarks";
        break;
      case "OUTWARD_SLOT":
        url = "/TsBookingRenomination/saveField/tsOutwardSlotOperator";
        break;
      case "REASON":
        url = "/TsBookingRenomination/saveField/tsReasonForRenomination";
        break;
      default:
        break;
    }

    const selectedRows = this.gridApi.getSelectedRows();
    console.log("IDs", selectedRows.map(item=> item.id));
    let body = {
      updatedContent: this.valueDialog,
      ids: selectedRows.map(item=> item.id)
    }
    let urlReqest = this.configService.config.BASE_API.toString() + url;
    this.genericUtil.showLoader();
    this.genericService.POST(urlReqest, body)
    .pipe(finalize(()=>{this.genericUtil.hideLoader()}))
    .subscribe(
      (resp) => {
        if (resp.json()["status"] == "ok") {
          this.closeDialogRemarksOutReason();
          this.searchEvent(selectedRows);
        } else {
          this.message("error", "Error", resp.json().message, "okonly", {
            ok: "",
          });
        }
      },
      (error) => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  handleTransfer() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length < 1) {
      return this.message(
        "Information",
        "information",
        'Please select at least 1 record to Transfer.',
        "okonly",
        { ok: "" }
      );
    }
    this.transferPodService.updateModel(this.model);
    this.transferPodService.updateModelStatus(true);
    this.model.selectedRows = selectedRows;
    this.genericUtil.showDialog(
      "TransferPodDialog",
      "New Connecting Vessel/Voyage",
      800,
      500
    );
  }

  handleExport(){
    this.genericUtil.showLoader();

    let vesselName = "";
    let voyageName = "";

    if(this.model.vesselNameConnecting){
      vesselName = this.model.vesselNameConnecting;
      voyageName = this.model.voyageConnecting;
    }

    if(this.model.vesselNameDischarging || (this.model.vesselNameDischarging && this.model.vesselNameConnecting)){
      vesselName = this.model.vesselNameDischarging;
      voyageName = this.model.voyageDischarging;
    }
    var formatName = `${vesselName} V.${voyageName} Renomination.xls`;
    var uri = this.configService.config.BASE_API.toString() +
    "?q=" + `/Mreport/exportTranshipmentReport?pot=${this.model.bPot1Code}&status=${this.model.status}&pol=${this.model.bPolCode}&pod=${this.model.podCode}&dischargingVesselId=${this.model.vesselIdDischarging}&dischargingVoyage=${this.model.voyageDischarging}&dischargingVesselBound=${this.model.bound}&connectingVesselId=${this.model.vesselIdConnecting}&connectingVoyage=${this.model.voyageConnecting}&connectingVesselBound=${this.model.bound}&dischargingServiceCode=${this.model.serviceDischarging}&connectingServiceCode=${this.model.serviceConnecting}&reportName=test&reportType=Renom`
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
        self.genericUtil.hideLoader();
      },
    });
  }

  changeEventContOpr(event) {
    this.model.status = event.code;
  }

  handleReno() {
    const renominationBookings = [];
    this.model.selectedRows.forEach(e => renominationBookings.push(e.id))

    console.log("# portPairAllocations -->")
    console.log(this.model.rowDataPortPair)
    const body = {
      userId: this.cookieService.getName(),
      vesselId: this.model.vesselId,
      vesselCode: this.model.vesselCode,
      vesselName: this.model.vesselName,
      voyage: this.model.voyage,
      bound: this.model.bound,
      serviceCode: this.model.newConnectingService,
      renominationBookings,
      portPairAllocations: this.model.rowDataPortPair
    }
    this.transferPodService.handleCheckValidBookingList(body).subscribe(
      res => {
        const data = res.json();
        if (data) {
          this.transferPodService.handleAfterTransfer(data);
        }
      }
    )
  }
}

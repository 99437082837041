import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild,ChangeDetectorRef, } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent
  ,PdfPluginComponent,GenericService,ConfigService,CookieService, GenericUtil,DialogPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
import FormValidation from "../../../src/utils/formValidation";

declare  var $:any;

export class Param {
  blOfficeCode: string = "";
  blOceanVesselId: string = "";
  blOceanVesselCode: string = "";
  blOceanVesselName: string = "";
  blOceanVesselVoyage: string = "";
  blOceanVesselBound: string = "";

  containerOwnership: string = "ALL";
  user: string = "";

  constructor() {}
}

@Component({
  selector: 'app-discrepancies_containers_in_CLL_and_BL_page',
  templateUrl: './discrepancies_containers_in_CLL_and_BL_page.component.html',
  styleUrls: ['./discrepancies_containers_in_CLL_and_BL_page.component.css']
})
export class DiscrepanciesContainersInCLLandBLPageComponent extends Validator  implements OnInit, AfterViewInit {

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;
 
  dialog:any;

  /* Parameter for information into Footer */
  info = '';
  formValidation = new FormValidation();
  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = 'discrepancies_containers_in_CLL_and_BL';

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;


  // lock
  // cButtonLock = true;
  // dButtonLock = true;

  /* Model */
  model = new Param();

  radioDiscrepanciesContainersByVessel = "Y";
  radioContainerOwnership = 'ALL';

  isEnter: boolean = false;
  isError: Boolean = false;
  isErrorAdd: boolean = false;
  validatorRules = {};

  msg: string = "";
  store: any[] = [];

  newDialogOpt;

  progressBarValue = 0;
  progressBarMessage = "";
  subscribe;

  blFMDialogMessage = "";

  mpDuplicate = new Map<number, String>();
  mpSameVessels = new Map<number, String>();
  mpMainVessels = new Map<number, String>();

  constructor(private auth:Authorize, 
    private cdr: ChangeDetectorRef,
    private genericService:GenericService, 
    private configService: ConfigService,
    private cookieService: CookieService,
    private genericUtil:GenericUtil, 
    private router: Router) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Run", event: "discrepanciesContainers", icon: "play" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'DISCREPANCIES CONTAINERS IN CLL and BL(s)'
    }

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setValidatorByVessel();
    this.model.blOceanVesselBound = "O";
    this.cdr.detectChanges();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "discrepanciesContainers":
        this.handleDiscrepanciesContainers();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        // handle close event
        this.genericUtil.hideLoader();
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  setValidatorByVessel() {
    this.isError = false;
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";

    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },

      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
          },
        ],
      },

      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Bound should not be empty.",
          },
        ],
      },
    };
  }

  changeEventVessel(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
      this.model.blOceanVesselName = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;
      this.model.blOceanVesselName = event.vesselName;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBound +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");
    }
  }

  changeEventVoyage(event) {
    if (event.voyage == null) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;
    }
  }

  changeEventDiscrepanciesContainersByVessel(event) {
    console.log(event);
    if (event.target.checked) {
      this.setValidatorByVessel();
      this.radioContainerOwnership = 'ALL';
      this.radioDiscrepanciesContainersByVessel = "Y";
      this.model.blOceanVesselBound = "O";
      this.disableToolbarButtons = "";
    }
  }

  changeEventContainerOwnership(event) {
    if (event.target.value == "ALL") {
      this.radioContainerOwnership = "ALL";
      this.model.containerOwnership = "ALL";
    }

    if (event.target.value == "SOC") {
      this.radioContainerOwnership = "SOC";
      this.model.containerOwnership = "SOC";
    }

    if (event.target.value == "COC") {
      this.radioContainerOwnership = "COC";
      this.model.containerOwnership = "COC";
    }

    if (event.target.value == "SLOT") {
      this.radioContainerOwnership = "SLOT";
      this.model.containerOwnership = "SLOT";
    }
  }

  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
   // $('#dialog').modal("setting", "closable", false).modal('show');
   $("#dialog").modal("show");
  }
  

  handleCancel() {
    this.model = new Param();
    this.model.blOceanVesselBound = "O";
    this.model["blOceanVesselName"] = "";

    this.setValidatorByVessel();
    this.radioContainerOwnership = "ALL";
    this.radioDiscrepanciesContainersByVessel = "Y";
    this.disableToolbarButtons = "";

    this.cbVessel.setValue("");
    this.cbVoyage.setUrl("");
    this.cbVoyage.setValue("");

    this.genericUtil.hideLoader();

    if (this.radioDiscrepanciesContainersByVessel == "Y") {
      this.model["radioDiscrepanciesContainersByVessel"] = "Y";
      this.disableToolbarButtons = "";
    }

    if (this.radioDiscrepanciesContainersByVessel == "N") {
      this.model["radioDiscrepanciesContainersByVessel"] = "N";
    }
  }



  handleDiscrepanciesContainers() {
    console.log("** handleDiscrepanciesContainers2 **");
    if (this.radioDiscrepanciesContainersByVessel == "Y") {
      this.model["radioDiscrepanciesContainersByVessel"] = "Y";
      this.model.blOceanVesselBound = "O";
      this.setValidatorByVessel();
    }

    if (this.radioDiscrepanciesContainersByVessel == "N") {
      this.model["radioDiscrepanciesContainersByVessel"] = "N";
      this.model.blOceanVesselBound = "O";
      this.model["blOceanVesselName"] = "";
    }

    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.user = this.cookieService.getName();

    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      console.log(this.model);
      //this.loading = true;
      this.genericUtil.showLoader();
      this.disableToolbarButtons = "discrepanciesContainers,cancel";

        this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
          "/shaTallyList/genDiscrepancyReport",
          this.model
          //"http://localhost:16000/shaTallyList/genDiscrepancyReport
        )
        .subscribe(
          (resp) => {

            if (resp.status == 200 ) { //return 500

              if (resp._body !=null) {

                let body:any[]
                
                console.log(resp._body);

                if(resp._body.toUpperCase().endsWith('.PDF')){

                  console.log('generate report');

                  this.genericUtil.hideLoader();
                  this.disableToolbarButtons = "";
                  var sPdfUrl=this.configService.config.pdfUrl + '/download/' + resp._body;
                  console.log(sPdfUrl);

                  $('#pdfDiscrepanciesContainersReport').find('#pdfDiscrepanciesContainers-viewer').empty();
                  console.log('report empty()');
                  $('#pdfDiscrepanciesContainersReport').find('#pdfDiscrepanciesContainersReport-viewer').append('<object id="o" data="' + sPdfUrl + '" type="application/pdf" width="100%" height="100%"></object>');
                  console.log('report append');
                  this.showPDF();
                  this.subscribe.unsubscribe();
                  this.genericUtil.closeDialog("progressDialog");

                }else{
                  this.genericUtil.hideLoader();
                  this.disableToolbarButtons = "";
                  this.message(
                    "warning",
                    "Warning",
                    resp._body,
                    "okonly",
                    { ok: "this.loading=false;" }
                  );
                }
              }

              this.loading = false;
              console.log('this.loading = false;');

            } else {
              this.genericUtil.hideLoader();
              this.message(
                "warning",
                "Warning",
                "The Discrepancies Containers report failed to generate.",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
          },
          (error) => {
            this.loading = false;
            console.log(error.message);
            this.info = error.message;
          }
        );
    
    }
  }

  closeMsgDialog() {
    this.genericUtil.closeDialog("msgDialog");
  }

  printReport() {
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";

    var jasperURL =
      this.configService.config.jasperUrl +
      "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/freightMatchReport.pdf?sessionId=" +
      this.cookieService.getName();
    var fileName =
      "FreightMatchReport" +
      this.cookieService.getName().toUpperCase() +
      ".pdf";

    var postData = {};
    postData["fileName"] = fileName;
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl, postData).subscribe((resp) => {
      if (resp.ok) {
        var result = resp.json();

        this.genericUtil.hideLoader();
        this.disableToolbarButtons = "";

        if (result["message"] == "success") {
          // this.pdfContainer.setPDFURL(
          //   this.configService.config.pdfUrl + result["filePath"]
          // );
          // this.pdfContainer.showPDF();
        }

        //this.loading = false;
        this.subscribe.unsubscribe();
        this.genericUtil.closeDialog("progressDialog");
      }
    });
  }

  showPDF() {
    const self = this;

    this.newDialogOpt = {
      title: 'PDF Preview',
      modal: true,
      closeText: 'hide',
      closeOnEscape: false,
      width: 1020,
      height: 690,
      open: function(event, ui) {
          // hide close button.
          $('.ui-dialog').css('z-index', 103);
          $('.ui-widget-overlay').css('z-index', 102);

          $('body').first().css('overflow', 'hidden');
      },
      close: function(event, ui) {
        $('body').first().css('overflow', ' auto');
        self.clearPDFPlugin();
        // console.log('this.afterProcess')
       
      }
    };

    this.showDialog('pdfDiscrepanciesContainersReport', this.newDialogOpt);

  }

  clearPDFPlugin() {
    $('#pdfDiscrepanciesContainersReport').find('#pdfDiscrepanciesContainersReport-viewer').empty();
    this.closeDialog('pdfDiscrepanciesContainersReport');
  }

  showDialog(id: string, options: any) {
    $('#' + id).dialog(options).dialog('open');
  }

  closeDialog(id: string) {
    $('#' + id).dialog('close');
    $('#' + id).dialog('destroy');
  }

  onProgress() {
    $("#recap-progress-bar").progress({
      percent: this.progressBarValue,
    });
  }



}

import { finalize, takeUntil } from 'rxjs/operators';
import { TransferPodService } from '../../transfer-pod.service';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ComboPluginComponent, ConfigService, CookieService, DialogPluginComponent, GenericService, GenericUtil } from "sibego-ui-library";
import { TransferPodBookingModel } from '../../../../shared/models/transferPodModel';
import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: "app-transfer-pod-dialog",
  templateUrl: "./transfer-pod-dialog.component.html",
  styleUrls: ["./transfer-pod-dialog.component.css"],
})
export class TransferPodDialogComponent implements OnInit, OnDestroy {
  @ViewChild("cbNewVessel") cbNewVessel: ComboPluginComponent;
  @ViewChild("cbNewVoyage") cbNewVoyage: ComboPluginComponent;

  @Output() closeRenominateDialog = new EventEmitter<string>();

  settingNewVessel;
  settingNewVoyage;

  newVesselValue = "";
  newVesselVoyage = "";
  bound = "";
  columnDefsGridPortPair: any[];
  topGridPortPair: any;
  model = new TransferPodBookingModel();
  officeCode =
  this.cookieService.getDefaultLocationCode() == null
    ? "*"
    : this.cookieService.getDefaultLocationCode();
  rowDataPortPair: any;
  loading = false;
  unRenomList = [];
  messageShowInformation: null;
  private ngUnsubscribe = new Subject<boolean>();
  unqualifiedData: any;
  
  constructor(
    private configService: ConfigService,
    private transferPodService: TransferPodService,
    private cookieService: CookieService,
    private genericUtil: GenericUtil,
    private genericService: GenericService) {
    this.settingNewVessel = {
      id: "cbNewVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    this.settingNewVoyage = {
      id: "cbNewVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.columnDefsGridPortPair = [
      { headerName: 'Load Port', field: 'tslLoadPortName', width: 120 },
      { headerName: 'Terminal(POL)', field: 'tslLoadPortTerminalName', width: 120 },
      { headerName: 'DischargePort', field: 'tslDischargePortName', width: 120 },
      { headerName: 'Terminal(POD)', field: 'tslDischargePortTerminalName', width: 120 },
      { headerName: 'Allocated(Teus)', field: 'tslAllocatedTeus', width: 120 },
      { headerName: 'Booked(Teus)', field: 'tslBookedTeus', width: 120 },
      { headerName: 'Confirmed(Teus)', field: 'tslConfirmedTeus', width: 120 },
      { headerName: 'Available(Teus)', field: 'tslAvailableTeus', width: 120 },
    ];
  }

  ngOnInit() {
    this.transferPodService.modelSource$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      res => {
          this.model = this.transferPodService.getModelReno();
          this.cbNewVessel.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + this.model.bPot1Code + "/{query}");
        }
    )

    this.transferPodService.statusAfterTransfer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        if (data) {
          this.unRenomList = data.notRenominatedList;
          this.unqualifiedData = data.unqualifiedData;
          if (data.notRenominatedList.length < 1) {
            this.transferPodService.updateStatusTransferPod(this.unqualifiedData);
            this.transferPodService.callMessageProm({ type: 'nEvent', mess: data.message });
            this.resetDialog();
            return this.genericUtil.closeDialog("TransferPodDialog");
          }
          this.handleShowList();
        }
        }
    )
  }

  ngOnDestroy() {
    console.log("---------ngOnDestroy Exception page");
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  changeEventNewVessel(event) {
    if (event) {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;
      this.cbNewVoyage.setUrl(
      this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" + 
      this.officeCode + '/' + this.model.vesselId + "/" + this.model.bound +
      "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPot1Code
      );
       
      this.cbNewVoyage.clearSelect();
      this.model.voyage = "";
      this.model.newConnectingService = "";
    } else {
      this.model.vesselId = "";
    }
  }
  
  changeEventNewVoyage(event) { 
    if (event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined) {
      this.model.voyage = event.voyage;
      this.model.newConnectingService = event.serviceCode;
      this.loading = true;
      setTimeout(() => {
        this.transferPodService.getListPortPair(this.model).pipe(finalize(() => this.loading = false)).subscribe(
          res => {
            const data = res.json();
            if (data.status == "NOK") {
              this.transferPodService.callMessageProm({type: 'nEvent', mess: data.message});
              return;
            }
            this.rowDataPortPair = data.content.tsPortPairAllocationLines;
            this.model.tsRotation = data.content.rotation;
          }
        )
      }, 50);
    } else {
      this.model.voyage = "";
      this.model.newConnectingService = "";
    }
  }

  onGridPortReady(params) {
    this.topGridPortPair = params.api;
    this.topGridPortPair.hideOverlay();
  }

  cancelDialog() {
    this.resetDialog();
  }

  resetDialog(){
    this.cbNewVessel.clearSelect();
    this.model.vesselId = "";
    this.model.vesselCode = "";
    this.model.vesselName = "";

    this.cbNewVoyage.clearSelect();
    this.model.voyage =  "";

    this.model.newConnectingService = "";

    this.rowDataPortPair = [];

    this.model.tsRotation = "";
  }

  saveDialog() {
    if (!this.model.vesselId) {
      return this.transferPodService.callMessageProm({ type: 'nEvent',mess: 'Please input Connecting Vessel.'});
    }
    if (!this.model.voyage) {
      return this.transferPodService.callMessageProm({ type: 'nEvent',mess: 'Please input Connecting Voyage.'});
    }
    for (const e of this.model.selectedRows) {
      if (!e.tsReasonForRenomination) {
        return this.transferPodService.callMessageProm({ type: 'nEvent',mess: 'Please input reason for transfer.'});
      }
    }
    this.model.rowDataPortPair =  this.topGridPortPair.getSelectedRows(); //this.rowDataPortPair;
    if(this.model.rowDataPortPair.length == 0){
      return this.transferPodService.callMessageProm({ type: 'nEvent',mess: 'Please select port for transfer.'});
    }
    this.transferPodService.updateModel(this.model);
    this.transferPodService.callMessageProm({ type: 'event',mess: 'Selected record(s) will be transferred. Proceed to Transfer?'});
    // this.message('information', 'Information', 'Selected record(s) will be rejected. Proceed to reject?', 'yesno', { yes: 'this.handleRenominate()', no: 'this.loading = false;' });
  }

  closeUnRenomList(){
    this.closePopUps();
    this.unRenomList = [];
  }

  onClickProcessConnection(status:any,item:any){
    this.unRenomList.map(x =>{
      if(item.tsPolBookingNo === x.tsPolBookingNo){
        x.isConfirm = status 
      }
    });
    console.log(this.unRenomList);
  }


  confirmUnRenomList() {
    this.loading = true;
    let tsBkgProspects = this.unRenomList.map(item =>{
      return {
        id: item.id,
        isMandatory: item.isConfirm
      }
    })
    let body = {
      userId: localStorage.getItem("logged").split("|")[0],
      vesselId: this.model.vesselId,
      vesselCode: this.model.vesselCode,
      vesselName: this.model.vesselName,
      voyage: this.model.voyage,
      bound: this.model.bound,
      serviceCode: this.model.newConnectingService,
      portPairAllocations: this.model.rowDataPortPair,
      tsBkgProspects: tsBkgProspects
    }
    let urlReqest = this.configService.config.BASE_API.toString() + "/TsBookingRenomination/transferSpecific";
    this.genericService.POST(urlReqest, body).pipe(finalize(() => this.loading = false)).subscribe(
      (resp) => {
        if (resp.json()["status"] == "ok") {
          this.closePopUps();
          // this.genericUtil.showDialog(
          //   "showInformation",
          //   "Information",
          //   550,
          //   150
          // );
          this.transferPodService.callMessageProm({ type: 'nEvent', mess: resp.json().message });
          this.transferPodService.updateStatusTransferPod(this.unqualifiedData);
          this.messageShowInformation = null;
        } else {
          this.transferPodService.callMessageProm({ type: 'nEvent', mess: resp.json().message});
        }
      },
      (error) => {
        this.transferPodService.callMessageProm({ type: 'nEvent', mess: "An error has occurred. Please report to Administrator!"});
      }
    );
  }

  returnTime (connectingEtaPotDate, dischargingEtdPotDate) {
    let msDate1 = new Date(connectingEtaPotDate);
    let msDate2 = new Date(dischargingEtdPotDate);
    let ispositiveNumbers = true;    
    if(Number(msDate1) - Number(msDate2) < 0){
      ispositiveNumbers = false;
    }else{
      ispositiveNumbers = true;
    }
    let ms = Math.abs(Number(msDate1) - Number(msDate2))
    const days = Math.floor(ms / (24*60*60*1000));
    const daysms = ms % (24*60*60*1000);
    const hours = Math.floor(daysms / (60*60*1000));
    const hoursms = ms % (60*60*1000);
    const minutes = Math.floor(hoursms / (60*1000));
    const minutesms = ms % (60*1000);
    const sec = Math.floor(minutesms / 1000);

    let daysInfo = days === 0 ? "" : `${days} days`;
    return ispositiveNumbers ? `${daysInfo} ${hours} hours ${minutes} minutes` : `-${daysInfo} ${hours} hours ${minutes} minutes`;
  }

  closeDialog() {
    this.resetDialog();
    this.closeRenominateDialog.emit("close-transfer-pod");
  }

  handleShowList(){
    let heightDialog;

    if (this.unRenomList.length < 4) {
      heightDialog = 550;
    } else {
      heightDialog = 600;
    }

    this.genericUtil.showDialog(
      `unRenomList`,
      "Transfer (New POD)",
      1000,
      heightDialog
    );
  }


  eventMessage(event) {
    if (event != '') {
      eval(event);
    }
  }

  closePopUps() {
    this.resetDialog();
    this.genericUtil.closeDialog("unRenomList");
    this.genericUtil.closeDialog("TransferPodDialog");
  }

  rowDoubleClick(params) {
    let dt = params.data;
  }

  onSelectionChanged(params) {
    const selectedRows = this.topGridPortPair.getSelectedRows();
  }

  // handleRenominate() {
  //   const renominationBookings = [];
  //   this.model.selectedRows.forEach(e => renominationBookings.push(e.id))
  //   const body = {
  //     userId: this.cookieService.getName(),
  //     vesselId: this.model.vesselId,
  //     vesselCode: this.model.vesselCode,
  //     vesselName: this.model.vesselName,
  //     voyage: this.model.voyage,
  //     bound: this.model.bound,
  //     serviceCode: this.model.serviceConnecting,
  //     renominationBookings,
  //     portPairAllocations: this.rowDataPortPair
  //   }
  //   this.renominationService.handleCheckValidBookingList(body).subscribe(
  //     res => {
  //       const data = res.json();
  //       if (data) {
  //         this.unRenomList = data.notRenominatedList;
  //         this.unqualifiedData = data.unqualifiedData;
  //         this.renominationService.updateStatusRenominate(this.unqualifiedData);
  //         console.log(data.notRenominatedList);
          
  //         if (data.notRenominatedList.length < 1) {
  //           this.renominationService.callMessageProm({ type: 'nEvent', mess: data.message });
  //           return this.genericUtil.closeDialog("unRenomList");
  //         }
  //         this.handleShowList();
  //       }
  //     }
  //   )
  // }
}

import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
  OnDestroy,
  HostListener,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { Location, formatDate, LocationStrategy, DatePipe } from "@angular/common";
import {
  Booking,
  BookingDetail,
  BookingFreightCharge,
  MSailingSchedule,
  specialCharUtil,
  sha_booking_edi,
} from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  DialogPluginComponent,
  GridPluginComponent,
  ComboPluginComponent,
  DatetimePluginComponent,
  CalendarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  PdfPluginComponent,
  TextareaPluginComponent,
  DatePluginComponent,
} from "sibego-ui-library";
import { finalize, isEmpty } from "rxjs/internal/operators";
import {
  BookingContainerInfo,
  BookingMisc,
  BookingExtra,
} from "../shared/models/booking";
import { MasterBookingExtra } from "../shared/models/masterBookingExtra";
import * as timeEntry from "../../assets/js/jquery.plugin.js";
import * as timeEntryLib from "../../assets/js/jquery.timeentry.js";
import FormValidation from "../../utils/formValidation";
import * as moment from "moment";
import { LoadingOverlayComponent } from "ag-grid-community/dist/lib/rendering/overlays/loadingOverlayComponent";
import { log } from "console";
import { ReturnStatement } from "@angular/compiler";

declare var $: any;

export class ContainerType {
  containerTypeCode: String = "";
  containerTypeName: String = "";
  group: String = "";
}

@Component({
  selector: "app-transaction-booking-maintenance",
  templateUrl: "./transaction-booking-maintenance-page.component.html",
  styleUrls: ["./transaction-booking-maintenance-page.component.css"],
})
export class TransactionBookingMaintenancePageComponent
  extends Validator
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbSupplier") cbSupplier: ComboPluginComponent;
  @ViewChild("cbBookingParty") cbBookingParty: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;
  @ViewChild("cbShipper") cbShipper: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbPOT2") cbPOT2: ComboPluginComponent;
  @ViewChild("cbFreightParty") cbFreightParty: ComboPluginComponent;
  @ViewChild("cbPODL") cbPODL: ComboPluginComponent;
  @ViewChild("cbPOT3") cbPOT3: ComboPluginComponent;
  @ViewChild("cbPOR") cbPOR: ComboPluginComponent;
  @ViewChild("cbFinalDest") cbFinalDest: ComboPluginComponent;
  @ViewChild("cbCommodity") cbCommodity: ComboPluginComponent;
  @ViewChild("cbReceiptType") cbReceiptType: ComboPluginComponent;
  @ViewChild("cbDeliveryType") cbDeliveryType: ComboPluginComponent;
  @ViewChild("cbFeederVessel") cbFeederVessel: ComboPluginComponent;
  @ViewChild("cbFeederVoyage") cbFeederVoyage: ComboPluginComponent;
  @ViewChild("cbExVessel") cbExVessel: ComboPluginComponent;
  // @ViewChild('cbExVoyage') cbExVoyage: ComboPluginComponent;
  @ViewChild("cbNextVessel") cbNextVessel: ComboPluginComponent;
  @ViewChild("cbNextVoyage") cbNextVoyage: ComboPluginComponent;
  @ViewChild("cbHaulier") cbHaulier: ComboPluginComponent;
  @ViewChild("cbSocOPR") cbSocOPR: ComboPluginComponent;
  @ViewChild("cbCocOPR") cbCocOPR: ComboPluginComponent;
  @ViewChild("cbSlotOPR") cbSlotOPR: ComboPluginComponent;
  @ViewChild("cbSlotCntrOPR") cbSlotCntrOPR: ComboPluginComponent;
  @ViewChild("cbPackageType") cbPackageType: ComboPluginComponent;
  @ViewChild("cbSpecialDet") cbSpecialDet: ComboPluginComponent;
  @ViewChild("cbReturnTo") cbReturnTo: ComboPluginComponent;
  @ViewChild("cbReturnToPor") cbReturnToPor: ComboPluginComponent;
  @ViewChild("cbReturnToPol") cbReturnToPol: ComboPluginComponent;
  @ViewChild("cbReleaseAt") cbReleaseAt: ComboPluginComponent;
  @ViewChild("cbPackedAt") cbPackedAt: ComboPluginComponent;
  @ViewChild("cbStuffAt") cbStuffAt: ComboPluginComponent;

  @ViewChild("cbPORTerminal") cbPORTerminal: ComboPluginComponent;
  @ViewChild("cbLoadPortTerminal") cbLoadPortTerminal: ComboPluginComponent;
  @ViewChild("cbDischargePortTerminal")
  cbDischargePortTerminal: ComboPluginComponent;
  @ViewChild("cbPODLTerminal") cbPODLTerminal: ComboPluginComponent;
  @ViewChild("cbFinalDestTerminal") cbFinalDestTerminal: ComboPluginComponent;
  @ViewChild("cbPOT1Terminal") cbPOT1Terminal: ComboPluginComponent;
  @ViewChild("cbPOT2Terminal") cbPOT2Terminal: ComboPluginComponent;
  @ViewChild("cbPOT3Terminal") cbPOT3Terminal: ComboPluginComponent;

  @ViewChild("dtRequestReleaseDate")
  dtRequestReleaseDate: CalendarPluginComponent;
  @ViewChild("dtExpReturnDate") dtExpReturnDate: CalendarPluginComponent;

  // @ViewChild('gridNew') gridNew: GridPluginComponent;
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("grid2") grid2: GridPluginComponent;
  @ViewChild("grid3") grid3: GridPluginComponent;
  @ViewChild("grid4") grid4: GridPluginComponent;
  @ViewChild("grid5") grid5: GridPluginComponent;
  @ViewChild("gridWeightProfiles") gridWeightProfiles: GridPluginComponent;
  @ViewChild("gridContainerProfiles")
  gridContainerProfiles: GridPluginComponent;

  @ViewChild("gridFreightCharges") gridFreightCharges: GridPluginComponent;
  @ViewChild("gridFreightCurrencySummary")
  gridFreightCurrencySummary: GridPluginComponent;
  @ViewChild("gridFreightPerSummary")
  gridFreightPerSummary: GridPluginComponent;

  @ViewChild("gridContainerInfo") gridContainerInfo: GridPluginComponent;

  @ViewChild("dialogPlugin") dialogPlugin: DialogPluginComponent;

  // container info component
  @ViewChild("cbBContainerReceiptType")
  cbBContainerReceiptType: ComboPluginComponent;
  @ViewChild("cbBContainerPackageType")
  cbBContainerPackageType: ComboPluginComponent;
  @ViewChild("cbReceiptTypeExtra") cbReceiptTypeExtra: ComboPluginComponent;
  @ViewChild("cbVGMMethod") cbVGMMethod: ComboPluginComponent;

  // @ViewChild("dateReleaseAt") dateReleaseAt: DatetimePluginComponent;
  @ViewChild('dateReleaseAt') dateReleaseAt: DatePluginComponent;

  @ViewChild("dateReturnToPor") dateReturnToPor: DatetimePluginComponent;
  @ViewChild("dateReturnToPorGateInClosing")
  dateReturnToPorGateInClosing: DatetimePluginComponent;
  @ViewChild("dateReturnToPorVgmClosing")
  dateReturnToPorVgmClosing: DatetimePluginComponent;
  // @ViewChild("dateReeferCollection")
  // dateReeferCollection: DatetimePluginComponent;
  @ViewChild('dateReeferCollection') dateReeferCollection: DatePluginComponent;

  @ViewChild("dateReturnToPol") dateReturnToPol: DatetimePluginComponent;
  @ViewChild("dateReturnToPolGateInClosing")
  dateReturnToPolGateInClosing: DatetimePluginComponent;
  @ViewChild("dateReturnToPolVgmClosing")
  dateReturnToPolVgmClosing: DatetimePluginComponent;

  @ViewChild("dateStuffAtPor") dateStuffAtPor: DatetimePluginComponent;
  @ViewChild("dateStuffAtPorGateInClosing")
  dateStuffAtPorGateInClosing: DatetimePluginComponent;
  @ViewChild("dateStuffAtPorVgmClosing")
  dateStuffAtPorVgmClosing: DatetimePluginComponent;

  @ViewChild("pdfContainer") pdfContainer: PdfPluginComponent;
  @ViewChild("cbBContainerFlashPointScale")
  cbBContainerFlashPointScale: ComboPluginComponent;
  @ViewChild("txtRemarks") txtRemarks: TextareaPluginComponent;
  @ViewChild("txtContainerOtherDetails")
  txtContainerOtherDetails: TextareaPluginComponent;
  @ViewChild("txtTerminalRemarks") txtTerminalRemarks: TextareaPluginComponent;
  @ViewChild("txtReason") txtReason: TextareaPluginComponent;

  @ViewChild("openStack") openStack: DatePluginComponent;
  @ViewChild("clossingTime") clossingTime: DatePluginComponent;

  @Output() clicked = new EventEmitter();

  innerWidth: any;
  bRmarksFormat: any;
  isPriority = false;
  isBookingRequest: boolean;
  H20Saved: any;
  H40Saved: Number;
  H45Saved: number;
  D20Saved: Number;
  D40Saved: Number;
  D45Saved: Number;
  isEngKongContainer: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    console.log("inner width : ", this.innerWidth);
  }

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;
  frmDialogMessage: String = "Deleted sucessfully.";

  printLoading = false;
  loading = false;
  lock = true;
  lockTNK = false;
  lockUC = false;
  lockSTD = true;
  appStatusLabel: String = "";
  tabStatus: String = "tab1";
  tabStatus1: string = "active";
  tabStatus2: string = "active";
  tabStatus3: string = "active";
  tabStatus4: string = "active";
  tabStatus5: string = "active";

  lockContainerInfo: Boolean = true;
  lockBtnContainerNew: Boolean = false;
  lockBtnContainerSaveUpdate: Boolean = true;
  lockBtnContainerDelete: Boolean = true;
  lockBtnContainerCancel: Boolean = true;
  fromRetrieve: Boolean = true;

  lockReeferTemp = true;
  lockReeferTempSO = true;
  lockVentNumber = true;
  lockNature = true;
  lockHumidity = true;
  lockIsDry = true;

  lockSOOPR1 = true;
  lockSOOPR2 = true;

  trigerInit: Boolean = false;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "back";
  disableToolbarButtons = "";

  negativeMsg =
    "To input negative value, please indicate - in front of the value";

  modeToolbar = false;
  model = new Booking();
  bookingExtra = new BookingExtra();
  bookingContainerInfo = new BookingContainerInfo();
  bookingMisc = new BookingMisc();
  sCharUtil = new specialCharUtil();
  formValidation = new FormValidation();
  shaBookingEdi = new sha_booking_edi();


  errorUnitContainer: Boolean = false;
  currentMenu;
  viewOnlyStatus = false;
  viewSOCStatus = false;
  viewCOCStatus = false;

  isAfterSaveCopy = false;

  globalALL: Number = 0;
  stopByAll = "";
  stopDetection: Number = 0;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingGridAllocationNew;
  settingGridAllocation;
  settingGridAllocation2;
  settingGridAllocation3;
  settingGridAllocation4;
  settingGridAllocation5;
  settingGridFreightCurrencySummary;
  settingGridFreightPerSummary;
  settingGridWeightProfiles;
  settingGridContainerProfiles;
  settingGridFreightCharges;
  settingGridContainerInfo;
  settingBContainerReceiptType;

  settingSupplier;
  settingBookingParty;
  settingShipper;
  settingFreightParty;
  settingPOR;
  settingPORTerminal;
  settingLoadPort;
  settingLoadPortTerminal;
  settingDischargePort;
  settingDischargePortTerminal;
  settingPODL;
  settingPODLTerminal;
  settingFinalDest;
  settingFinalDestTerminal;
  settingSpecialDet;
  settingPOT1;
  settingPOT1Terminal;
  settingPOT2;
  settingPOT2Terminal;
  settingPOT3;
  settingPOT3Terminal;
  settingCommodity;
  settingReceiptType;
  settingDeliveryType;
  settingFeederVessel;
  settingFeederVoyage;
  settingExVessel;
  settingExVoyage;
  settingNextVessel;
  settingNextVoyage;
  settingHaulier;
  settingReturnTo;
  settingReturnToPor;
  settingReturnToPol;
  settingReleaseAt;
  settingStuffAt;
  settingPackedAt;
  settingPackageType;
  settingSocOPR;
  settingCocOPR;
  settingSlotOPR;
  settingSlotCntrOPR;
  lockNextVessel: Boolean = true;
  settingCbVGMMethod;

  settingReqReleaseDate;
  settingExpReturnDate;
  settingReeferCollection;
  typeReqReleaseDate = "calendarBL";
  typeExpReturnDate = "calendarBL";
  //typeReeferCollection = 'calendarBL';

  settingPDF;

  validatorRules = {};

  containerTypes: ContainerType[] = [];
  containerSlots = new Map<String, String>();
  containerSlots2 = new Map<String, String>();

  bookingDetails = new Map<String, Map<String, BookingDetail>>();

  isSocBookingParty: Boolean = false;
  isCocBookingParty: Boolean = false;
  isSlotBookingParty: Boolean = false;
  isCOCLocalExceed: Boolean = false;

  isErrorContainerInfo: Boolean = false;

  isPOLCanalPassing: Boolean = false;

  nvoccBookingParty: Boolean = false;
  nvoccShipper: Boolean = false;
  nvoccFreightParty: Boolean = false;
  isError: Boolean = false;
  isErrorDel: Boolean = false;
  isNew: Boolean = false;
  isModify: Boolean = false;
  isCopy: Boolean = false;
  isCopySuccess: Boolean = false;
  isInit: Boolean = true;
  lockEdit: Boolean = false;
  lockCombine: Boolean = false;
  lockSplit: Boolean = false;
  serviceCode: String = "";
  currentContainerType = "STD";
  previousContainerType = "";
  d20: Number = 0;
  d40: Number = 0;
  d45: Number = 0;
  h20: Number = 0;
  h40: Number = 0;
  h45: Number = 0;
  bHumidity: Number;
  bVentNumber: Number;
  bVentOption: String = "O";
  bDegree: Number;
  bDegreeSO: Number;
  bDegreeTemp: string = "C";
  bDegreeTempSO: String = "C";
  bNatureTemp: String = "N/A";
  bSetOption: String = "N";
  bDegreeSign: String = "+";
  bVent;
  killSlot: Number = 0;
  doorOpen: String = "N";
  UCH: Number = 0;
  UCL: Number = 0;
  UCW: Number = 0;
  OH: String = "N";
  OW: String = "N";
  OL: String = "N";
  heightMeasure: Number = 0;
  leftMeasure: Number = 0;
  rightMeasure: Number = 0;
  frontMeasure: Number = 0;
  endMeasure: Number = 0;

  lockSOC: Boolean = false;
  lockCOC: Boolean = false;
  lockSLOT: Boolean = false;

  lockSOCOPR: Boolean = true;
  lockCOCOPR: Boolean = true;
  lockSLOTOPR: Boolean = true;
  lockSLOTCNTROPR: Boolean = true;

  lockWeight: Boolean = true;
  lockDetention: Boolean = true;
  lockDemurrage: Boolean = true;
  lockDetentionPol: Boolean = true;
  lockDemurragePol: Boolean = true;
  lockOH: Boolean = true;
  lockOW: Boolean = true;
  lockOL: Boolean = true;
  lockOOG: Boolean = true;

  lockPORTerminal: Boolean = true;
  lockPOLTerminal: Boolean = true;
  lockPODTerminal: Boolean = true;
  lockPODLTerminal: Boolean = true;
  lockFDESTTerminal: Boolean = true;
  lockPOT1Terminal: Boolean = true;
  lockPOT2Terminal: Boolean = true;
  lockPOT3Terminal: Boolean = true;

  rotation: String = "";
  cleanRotation: String = "";
  canalRotation: any = [];
  strParams: string = "";
  strParams2: string = "";
  ChinaBookingselectedList: String[] = [];
  defaultBooked: Number = 0;
  defaultBooked2: String = "";
  tsData: any;
  prev: String = "";
  labelMenu : String ="";

  refreightEffectiveFrom: String = "";
  refreightExpiryOn: String = "";
  refreightModifiedBy: String = "";
  refreightModifiedDate: String = "";
  refreightMessage: String = "";
  refreightRefNo: String = "";
  refreightAccountPIC: String = "";
  refreightApprovalBy: String = "";
  refreightApprovedDate: String = "";

  isCRB: boolean = false;
  isCHINASiteEDI_NewBkg: boolean = false;


  checkLockAfterSave: boolean = false;
  checkReasonBeforeDelete: boolean = false;

  defaultLocation: String = "";
  defaultCountry: String = "";
  haveTerminal: string = "N";
  originLocation: string = "NNN";

  typeDate = "calendar";
  settingDateReturnToGateIn;
  settingCommonDate;
  bContainerInfoMode: String = "insert";

  typeSizeComboLoading: boolean = false;
  typeSizeComboDisabled: boolean = true;
  hitUrl: string = this.configService.config.BASE_API.toString();
  settingCbBContainerPackageType;
  settingCbReceiptTypeExtra;
  settingDateReleaseAt;
  settingDateReturnToPor;
  settingDateReturnToPorGateInClosing;
  settingDateReturnToPorVgmClosing;
  settingDateReturnToPol;
  settingDateReturnToPolGateInClosing;
  settingDateReturnToPolVgmClosing;
  settingDateStuffAtPor;
  settingDateStuffAtPorGateInClosing;
  settingDateStuffAtPorVgmClosing;
  settingTxtRemarks;
  settingTxtReason;
  settingContainerOtherDetails;
  settingTerminalRemarks;
  bufferNextVoyage: String = "";

  settingCbBContainerFlashPointScale;
  previousOperatorCode: string = "";

  group: string = "1";

  columnDefs;
  rowData: any[];

  settingOpenStack;
  settingClossingTime;
  newDialogOpt;
  openStackDT: String = "";
  closingTimeDT: String = "";
  undefineExVessel: String = "";

  pinnedColumns;

  backToBookingFlag: Boolean = false;
  fpAcctCode = "<< SOC: >>";
  fpAcctCodeSOC: String = "";
  fpAcctCodeCOC: String = "";
  fpAcctCodeSLOT: String = "";

  custIdForCbFreightParty = "*";
  custIdForCbShipper = "*";
  custIdForCbBooking = "*";

  searchFpIsValid = "";
  searchFpIsFreightParty = "";

  searchShIsValid = "";
  searchShIsShipper = "";
  siteOffice =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  searchBkIsValid = "";
  searchBkIsBookingParty = "";

  retrieveBookingString = "";
  retrieveBookingMiscString ="";
  retrieveBookingExtraString = "";
  retrieveBookingDetailString = "";
  retrieveBookingFreightChargeString = "";
  retrieveBookingContainerInfoString = "";
  contactFromBooking = "";
  telephoneFromBooking = "";
  attrCustChanged: Boolean = false;

  retrieveSocContainerOperatorCode: string = "";
  retrieveCocContainerOperatorCode: string = "";
  retrieveSlotContainerOperatorCode: string = "";

  bookingNumberPrefixValueDefaute = "";
  bookingNumberValueDefaute = "";
  bookingNumberValue = "";
  shipperPortnetReterence: any = "";
  errorMessageBookingNumber = "";
  errorMessagePortnetReference = "";
  regexBookingNumber = /[a-zA-Z]{2}[0-9]{4}/;
  regexCharNumOnlyWOSpace = /[A-Za-z0-9]$/;
  regexAlphanumericSomeSpecialCharacters = /[a-zA-Z0-9-./\\()_ ]/;
  regexAlphanumericSomeSpecialCharactersForPaste = /[^a-zA-Z0-9-./\\()_ ]/;
  isDisableUC = true;
  lockTerminalTab = true;
  isDisablebPrint = false;
  bookingMiscCheckboxPrint = false;

  isCheckedDepot = false;

  // GOS-653 Inconsistent extraction of location code for BKG Reference detected
  originalBookingRef = "";

  // GSO-1063 Add SLOT LC Checkbox
  isDisablebSlotLC = true;

  // GOS-874 & GOS-869
  get isChinaSite() {
    return this.siteOffice.substring(0,2) === 'CN';
  }

  //GOS-912
  currentBookingTaken: any = 0;

  get isSingaporeSite() {
    return this.siteOffice === 'SGSIN';
  }

  get isReeferDryTypeInSing() {
    // GSO-1033
    return this.isSingaporeSite && this.model['bContainerOwnership'] == 'COC' && this.currentContainerType == "DRY";
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private datePipe: DatePipe
  ) {
    super();

    if (!router.navigated) {
      localStorage.removeItem("pinned-columns");
    }

    locationStrategy.onPopState(() => {
      let path = window.location.href;
      const indexMain = path.indexOf("main/");

      path = path.substr(indexMain + 5);

      if (path !== "transaction-booking-flag") {
        console.log("triggered");
        localStorage.removeItem("pinned-columns");
      }
    });

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterContainerTypes/findGeneral/*/*/*/1/10/ASC/dateCreated"
      )
      .subscribe((resp) => {
        // console.log(resp.json());
        let det: any[] = resp.json()["content"];

        det.forEach((element) => {
          let ct = new ContainerType();
          ct.containerTypeCode = element["containerTypeCode"];
          ct.containerTypeName = element["containerTypeName"];
          ct.group = element["group"];
          this.containerTypes.push(ct);

          if (ct.group == "1") {
            this.containerSlots.set(ct.containerTypeCode, ct.containerTypeName);
          }

          if (ct.group == "2") {
            this.containerSlots2.set(
              ct.containerTypeCode,
              ct.containerTypeName
            );
          }
        });

        console.log("** group slot 1 **");
        console.log(this.containerSlots);

        console.log("** group slot 2 **");
        console.log(this.containerSlots2);
      });

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "New", event: "new", icon: "plus" },
        { name: "Modify", event: "modify", icon: "write" },
        { name: "Save", event: "save", icon: "checkmark" },
        { name: "Save & Copy", event: "copy", icon: "copy" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Print", event: "print", icon: "file outline" },
        { name: "Delete", event: "delete", icon: "minus circle" },
        { name: "Back", event: "back", icon: "arrow left" },
        { name: "Close", event: "close", icon: "remove circle" },
        { name: "Send To Depot", event: "sendDepot", icon: "exchange" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: this.labels(),
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 150 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "-- Voyage --",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 150 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 150 },
      ],
      maxlength: 20
    };

    this.settingGridAllocationNew = {
      id: "allocation",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/calculateAllocation",
      //url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Description", field: "allotmentCode", width: 100 },
        { header: "Allotted", field: "alloted", width: 100 },
        { header: "Booked", field: "booked", width: 100 },
        { header: "Available", field: "available", width: 100 },
        { header: "Resv", field: "reserved", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      width: 500,
      height: 200,
      minHeight: 200,
    };

    this.settingGridAllocation = {
      id: "allocation",
      //url: this.configService.config.BASE_API.toString() + '/MasterBookings/calculateAllocation2',
      //url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      url: "",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Description", field: "allotmentCode", width: 100 },
        { header: "Allotted", field: "alloted", width: 100 },
        { header: "Booked", field: "booked", width: 100 },
        { header: "Available", field: "available", width: 100 },
        { header: "Resv", field: "reserved", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      width: 500,
      height: 200,
      minHeight: 200,
    };

    this.columnDefs = [
      { headerName: "No", field: "no", width: 57 },
      { headerName: "Description", field: "allotmentCode", width: 110 },
      { headerName: "Allotted", field: "alloted", width: 85 },
      { headerName: "Booked", field: "booked", width: 85 },
      {
        headerName: "Available",
        field: "available",
        width: 85,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
      },
      { headerName: "Resv", field: "reserved", width: 85 },
    ];

    this.settingGridAllocation2 = {
      id: "allocation2",
      url: "",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Description", field: "allotmentCode", width: 100 },
        { header: "Allotted", field: "alloted", width: 100 },
        { header: "Booked", field: "booked", width: 100 },
        { header: "Available", field: "available", width: 100 },
        { header: "Resv", field: "reserved", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      width: 500,
      height: 200,
      minHeight: 200,
    };

    this.settingGridAllocation3 = {
      id: "allocation3",
      url: "",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Description", field: "allotmentCode", width: 100 },
        { header: "Allotted", field: "alloted", width: 100 },
        { header: "Booked", field: "booked", width: 100 },
        { header: "Available", field: "available", width: 100 },
        { header: "Resv", field: "reserved", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      width: 500,
      height: 200,
      minHeight: 200,
    };

    this.settingGridAllocation4 = {
      id: "allocation4",
      url: "",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Description", field: "allotmentCode", width: 100 },
        { header: "Allotted", field: "alloted", width: 100 },
        { header: "Booked", field: "booked", width: 100 },
        { header: "Available", field: "available", width: 100 },
        { header: "Resv", field: "reserved", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      width: 500,
      height: 200,
      minHeight: 200,
    };

    this.settingGridAllocation5 = {
      id: "allocation5",
      url: "",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Description", field: "allotmentCode", width: 100 },
        { header: "Allotted", field: "alloted", width: 100 },
        { header: "Booked", field: "booked", width: 100 },
        { header: "Available", field: "available", width: 100 },
        { header: "Resv", field: "reserved", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      width: 500,
      height: 200,
      minHeight: 200,
    };

    this.settingGridFreightPerSummary = {
      id: "gridFreightPerSummary",
      url: "",
      page: 5,
      columns: [
        { header: "Container Type", field: "perCode", width: 75 },
        { header: "Freight Reference No", field: "recordId", width: 75 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "recordId",
      sortingDirection: "ASC",
      height: 150,
      minHeight: 150,
    };

    this.settingGridFreightCurrencySummary = {
      id: "gridFreightCurrencySummary",
      url: "",
      page: 5,
      columns: [
        { header: "Currency", field: "currencyCode", width: 75 },
        { header: "Amount", field: "totalPerCurrency", width: 75 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      height: 150,
      minHeight: 150,
    };

    this.settingGridWeightProfiles = {
      id: "gridWeightProfiles",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/findWeightCalculationByOfficeCodeVesselIdVoyageBound/",
      page: 10,
      columns: [
        {
          header: "Description",
          field: "description",
          width: 275,
          type: "string",
        },
        { header: "Total", field: "total", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      width: 410,
      height: 230,
    };

    this.settingGridContainerProfiles = {
      id: "gridContainerProfiles",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/findContainerProfilesByOfficeCodeVesselIdVoyageBound",
      page: 10,
      columns: [
        { header: "Type", field: "cType", width: 100, type: "string" },
        { header: "D20", field: "20", width: 70 },
        { header: "D40", field: "40", width: 70 },
        { header: "D45", field: "45", width: 70 },
        { header: "H20", field: "h20", width: 70 },
        { header: "H40", field: "h40", width: 70 },
        { header: "H45", field: "h45", width: 70 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      width: 410,
      height: 200,
      minHeight: 200,
    };

    this.settingGridFreightCharges = {
      url: "",
      page: 20,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "FAI Grp", field: "faiGroup", width: 100 },
        { header: "Charge Code", field: "chargeCode", width: 100 },
        { header: "Per", field: "perCode", width: 100 },
        { header: "Container Type", field: "containerTypeName", width: 100 },
        { header: "Unit No", field: "quantity", width: 100 },
        { header: "Rate", field: "rate", width: 100 },
        { header: "Currency", field: "currencyCode", width: 100 },
        { header: "Amount", field: "chargeAmount", width: 100 },
        { header: "P/C", field: "pc", width: 100 },
        { header: "Payer", field: "payerName", width: 100 },
        { header: "Place of Payment", field: "popName", width: 100 },
        { header: "Reference No.", field: "recordId", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      height: 220,
      minHeight: 220,
    };

    this.settingSupplier = {
      id: "cbSupplier",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlSupplier/customerName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Supplier",
      title: "customerName",
      isMark: true,
      description: "",
      columns: [
        {
          header: "Customer Name",
          field: "customerName",
          width: 300,
          type: "string",
        },
        { header: "NVOCC?", field: "isNvocc", width: 125 },
        { header: "Valid?", field: "isSupplier", width: 125 },
      ],
    };

    this.settingBookingParty = {
      id: "cbBookingParty",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerBookingParty/{query}/1/10',
      url: "",
      //url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlBookingParty/customerName={query}',
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Booking Party --",
      title: "customerName",
      isMark: true,
      description: "",
      columns: [
        {
          header: "Customer Name",
          field: "customerName",
          width: 300,
          type: "string",
        },
        { header: "NVOCC?", field: "isNvocc", width: 125 },
        { header: "Valid?", field: "isBookingParty", width: 125 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
      maxlength: 50
    };

    this.settingShipper = {
      id: "cbShipper",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      url: "",
      //url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}',
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Shipper --",
      title: "customerName",
      description: "",
      isMark: true,
      columns: [
        {
          header: "Customer Name",
          field: "customerName",
          width: 300,
          type: "string",
        },
        { header: "NVOCC?", field: "isNvocc", width: 125 },
        { header: "Valid?", field: "isShipper", width: 125 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
      maxlength: 50
    };

    this.settingFreightParty = {
      id: "cbFreightParty",
      type: "search enter", // search | select | select input
      //      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerPayer/{query}/1/10',
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Freight Party --",
      title: "customerName",
      description: "",
      isMark: true,
      columns: [
        {
          header: "Customer Name",
          field: "customerName",
          width: 300,
          type: "string",
        },
        { header: "NVOCC?", field: "isNvocc", width: 125 },
        { header: "Valid?", field: "isFreightParty", width: 125 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
      maxlength: 50
    };

    this.settingPOR = {
      id: "cbPOR",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Place of Receipt --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingPORTerminal = {
      id: "cbPORTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Place of Receipt Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}",
        "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Load Port --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "ETA", field: "arrivalDate", width: 150 },
        { header: "ETD", field: "sailDate", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingLoadPortTerminal = {
      id: "cbLoadPortTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Load Port Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Discharge Port --",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "ETA", field: "arrivalDate", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingDischargePortTerminal = {
      id: "cbDischargePortTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Discharge Port Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPODL = {
      id: "cbPODL",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
        // "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Place of Delivery --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingPODLTerminal = {
      id: "cbPODLTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Place of Delivery Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingFinalDest = {
      id: "cbFinalDest",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupFDest/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Final Destination --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingFinalDestTerminal = {
      id: "cbFinalDestTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Final Destination Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingSpecialDet = {
      id: "cbSpecialDet",
      type: "search enter", // search | select | select input
      // url         : this.configService.config.BASE_API.toString() + '/MasterSpecialDetails/findGeneral/*/*/{query}/1/5/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterSpecialDetails/findByComboBoxControl/specialDetailCodeOrName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Special Detail --",
      title: "specialDetailName",
      description: "",
      isMark: true,
      columns: [
        {
          header: "Special Detail Code",
          field: "specialDetailCode",
          width: 150,
        },
        {
          header: "Special Detail Name",
          field: "specialDetailName",
          width: 300,
        },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 30
    };

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Port of Transhipment 1 --",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "ETA", field: "arrivalDate", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingPOT1Terminal = {
      id: "cbPOT1Terminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Port of Transhipment 1 Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPOT2 = {
      id: "cbPOT2",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Port of Transhipment 2 --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "", width: 150 },
        { header: "Terminal Name", field: "", width: 150 },
        { header: "ETA", field: "", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingPOT2Terminal = {
      id: "cbPOT2Terminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Port of Transhipment 2 Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPOT3 = {
      id: "cbPOT3",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Port of Transhipment 3 --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "", width: 150 },
        { header: "Terminal Name", field: "", width: 150 },
        { header: "ETA", field: "", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingPOT3Terminal = {
      id: "cbPOT3Terminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Port of Transhipment 3 Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCommodity = {
      id: "cbCommodity",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCommodities/findGeneral/*/*/{query}/1/5/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}",

      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Commodity --",
      title: "commodityName",
      description: "",
      isMark: true,
      columns: [
        { header: "Commodity Name", field: "commodityName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 30
    };

    this.settingReceiptType = {
      id: "cbReceiptType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Rec Type",
      title: "receiptTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Code", field: "receiptTypeCode", width: 150 },
        { header: "Receipt Type Name", field: "receiptTypeName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      minlength: 2,
      maxlength: 5
    };

    this.settingDeliveryType = {
      id: "cbDeliveryType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Delv Type",
      title: "deliveryTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Code", field: "deliveryTypeCode", width: 150 },
        { header: "Receipt Type Name", field: "deliveryTypeName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 5
    };

    this.settingFeederVessel = {
      id: "cbFeederVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Feeder Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 150 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingFeederVoyage = {
      id: "cbFeederVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Feeder Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 150 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 150 },
      ],
    };

    this.settingExVessel = {
      id: "cbExVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselNameAndPSAVesselCode={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "-- Ex Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 150 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Vessel Abbr. Name", field: "psaVesselCode", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingNextVessel = {
      id: "cbNextVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/PortPairAllocation/findConnectingVesselAndVoyageForTSBooking/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "-- Next Vessel --",
      maxlength: 50,
      title: "nextVessel",
      description: "",
      isMark: true,
      columns: [
        { header: "Next Vessel", field: "nextVessel", width: 150 },
        { header: "Next Voyage", field: "nextVoyage", width: 200 },
        { header: "ETA(POT)", field: "eta(pot)", width: 50 },
        { header: "ETD(POT)", field: "etd(pot)", width: 50 },
      ]
    };

    this.settingNextVoyage = {
      id: "cbNextVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "-- Next Voyage --",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 150 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingHaulier = {
      id: "cbHaulier",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlHaulier/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "-- Haulier --",
      title: "customerName",
      description: "",
      isMark: true,
      columns: [
        {
          header: "Customer Name",
          field: "customerName",
          width: 300,
          type: "string",
        },
        { header: "NVOCC?", field: "isNvocc", width: 125 },
        { header: "Valid?", field: "isValid", width: 125 },
      ],
    };

    this.settingReturnTo = {
      id: "cbReturnTo",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/*/*/{query}/1/5/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Return To --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Country Code", field: "depoCode", width: 150 },
        { header: "Country Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingReturnToPor = {
      id: "cbReturnToPor",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
      url: "",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "-- Return To Por --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Country Code", field: "depoCode", width: 150 },
        { header: "Country Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingReturnToPol = {
      id: "cbReturnToPol",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
      url: "",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "-- Return To Pol--",
      title: "depoName",
      description: "",
      columns: [
        { header: "Country Code", field: "depoCode", width: 150 },
        { header: "Country Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingReleaseAt = {
      id: "cbReleaseAt",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "-- Release At --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPackedAt = {
      id: "cbPackedAt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/*/*/{query}/1/5/*/*",
      //url         : '',
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Packed At --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingStuffAt = {
      id: "cbStuffAt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/*/*/{query}/1/5/*/*",
      //url         : '',
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "-- Stuff At --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPackageType = {
      id: "cbPackageType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid", // default
      placeholder: "-- Package Type --",
      title: "packageTypeName",
      description: "",
      columns: [
        { header: "Package Type Code", field: "packageTypeCode", width: 150 },
        { header: "Package Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 30
    };

    this.settingSocOPR = {
      id: "cbSocOPR",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "opr",
      title: "opr",
      allowRemove: false,
    };

    this.settingCocOPR = {
      id: "cbCocOPR",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "opr",
      title: "opr",
      allowRemove: false,
    };

    this.settingSlotOPR = {
      id: "cbSlotOPR",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "opr",
      title: "opr",
      allowRemove: false,
    };

    this.settingSlotCntrOPR = {
      id: "cbSlotCntrOPR",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "opr",
      title: "opr",
      allowRemove: true,
    };

    this.settingReqReleaseDate = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    this.settingExpReturnDate = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    /* this.settingReeferCollection = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    };*/

    this.settingTxtRemarks = {
      id: "txtRemarks",
      name: "remarks",
      placeholder: "-- REMARKS --",
      cols: 30,
      rows: 7,
      maxlength: 320,
      border: 350,
    };
    this.settingTxtReason = {
      id: "txtReason",
      name: "reason",
      placeholder: "-- REASON --",
      cols: 30,
      rows: 7,
      maxlength: 320,
      border: 350,
    };

    this.settingContainerOtherDetails = {
      id: "txtContainerOtherDetails",
      name: "bContainerOtherDetails",
      placeholder: "-- OTHER DETAILS --",
      cols: 30,
      rows: 7,
      maxlength: 50,
      border: 365,
    };

    // comment

    this.settingTerminalRemarks = {
      id: "txtTerminalRemarks",
      name: "txtTerminalRemarks",
      placeholder: "-- REMARKS --",
      cols: 30,
      rows: 7,
      maxlength: 200,
      border: 400,
    };

    this.settingGridContainerInfo = {
      id: "gridContainerInfo",
      url: "",
      page: 20,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "Container Number", field: "bContainerNo", width: 100 },
        { header: "Package Type", field: "bPackageTypeName", width: 100 },
        { header: "Cont Operator", field: "bContOperator", width: 100 },
        { header: "Cont Type", field: "bContType", width: 100 },
        { header: "Gross Weight", field: "bGrossWeight", width: 100 },
        { header: "Gross Meas", field: "bGrossMeas", width: 100 },
        { header: "Nett Wt", field: "bNettWeight", width: 100 },
        { header: "IMO", field: "bIMO", width: 100 },
        { header: "UNNO", field: "bUNNO", width: 100 },
        { header: "Flash Point", field: "bFlashPoint", width: 100 },
        { header: "DG Ref", field: "bDGRef", width: 100 },
        { header: "Scale", field: "bFlashPointScale", width: 100 },
        { header: "Receipt Type", field: "bReceiptType", width: 100 },
        { header: "Cell Location", field: "bCellLocation", width: 100 },
        { header: "VGM Enabled", field: "bVGMEnabledFlag", width: 100 },
        { header: "VGM Type", field: "bVGMType", width: 100 },
        { header: "VGM Weight", field: "bVGMWeight", width: 100 },
        { header: "Other Details", field: "bOtherDetailsClean", width: 100 },
        { header: "Vent (%)", field: "bVent", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
      height: 200,
      minHeight: 200,
    };

    this.settingBContainerReceiptType = {
      id: "cbBContainerReceiptType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "R/Type",
      title: "receiptTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "R/Type Code", field: "receiptTypeCode", width: 150 },
        { header: "R/Type Name", field: "receiptTypeName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    this.settingCbVGMMethod = {
      id: "settingCbVGMMethod",
      type: "select", // search | select | select input
      url: "assets/json/booking/vgmMethod.json",
      placeholder: "SEARCH ...",
      code: "vgmMethodCode",
      title: "vgmMethodName",
    };

    this.settingDateReturnToGateIn = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };
    this.settingCommonDate = {
      modeCal: true,
      type: "dateCustom",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    this.settingDateReleaseAt = {
      id: "dateReleaseAt",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      autoHide: true,
      customtext: "",
    };
    this.settingDateReturnToPor = {
      id: "dateReturnToPor",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingDateReturnToPorGateInClosing = {
      id: "dateReturnToPorGateInClosing",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingDateReturnToPorVgmClosing = {
      id: "dateReturnToPorVgmClosing",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingDateReturnToPol = {
      id: "dateReturnToPol",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingDateReturnToPolGateInClosing = {
      id: "dateReturnTopolGateInClosing",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingDateReturnToPolVgmClosing = {
      id: "dateReturnTopolVgmClosing",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingDateStuffAtPor = {
      id: "dateStuffAtPor",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingDateStuffAtPorGateInClosing = {
      id: "dateStuffAtporGateInClosing",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingDateStuffAtPorVgmClosing = {
      id: "dateStuffAtporVgmClosing",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };
    this.settingReeferCollection = {
      id: "dateReeferCollection",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };

    this.settingCbBContainerPackageType = {
      id: "cbBContainerPackageType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Pkg. Type Code", field: "packageTypeCode", width: 175 },
        { header: "Pkg. Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbReceiptTypeExtra = {
      id: "cbReceiptTypeExtra",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "R/Type",
      title: "receiptTypeCode",
      description: "",
      isMark: true,
      align: "right",
      columns: [
        { header: "R/Type Code", field: "receiptTypeCode", width: 150 },
        { header: "R/Type Name", field: "receiptTypeName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPDF = {
      id: "pdfContainer",
      url: "",
      hidePrint: false,
    };

    this.settingCbBContainerFlashPointScale = {
      id: "cbBContainerFlashPointScale",
      type: "select", // search | select | select input
      url: "assets/json/booking/bFlashPointScale.json",
      placeholder: "",
      code: "flashPointScaleCode",
      title: "flashPointScaleName",
    };

    this.settingOpenStack = {
      id: "openStackDate",
      placeholder: "Date Time",
      autoHide: true,
      type: "date", // date|custom
      format: "dd-mm-yyyy",
      customtext: "",
    };

    this.settingClossingTime = {
      id: "clossingTimeDate",
      placeholder: "Date Time",
      autoHide: true,
      type: "date", // date|custom
      format: "dd-mm-yyyy",
      customtext: "",
    };
  }

  labels(){

    this.labelMenu = "";
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("SOC")) {
      this.labelMenu = "Booking Maintenance SOC";
    } else if  (this.currentMenu.includes("COC")) {
      this.labelMenu = "Booking Maintenance COC";
    }else{
      this.labelMenu = "Booking Maintenance";
    }

    return this.labelMenu;

  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;

    console.log("inner width : ", this.innerWidth);
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);

    console.log("Pinned Columns : ", localStorage.getItem("pinnedColumns"));

    this.rowData = [];
    this.newDialogOpt = {
      title: "Print Booking Confirmation",
      modal: true,
      closeText: "hide",
      closeOnEscape: false,
      width: 500,
      height: 200,
      open: function (event, ui) {
        //hide close button.
        //// console.log($(this).css("z-index","102"));
        $(".ui-dialog").css("z-index", 103);
        $(".ui-widget-overlay").css("z-index", 102);
        $(this)
          .parent()
          .children()
          .children(".ui-dialog-titlebar-close")
          .hide();
        $("body").first().css("overflow", "hidden");
      },
      close: function (event, ui) {
        $("body").first().css("overflow", " auto");
      },
    };

    $("#timeReleaseAt").timeEntry({ show24Hours: true });
    $("#timeReturnToPor").timeEntry({ show24Hours: true });
    $("#timeReturnToPorClosing").timeEntry({ show24Hours: true });
    $("#timeReturnToPorVgm").timeEntry({ show24Hours: true });
    $("#timeReturnToPol").timeEntry({ show24Hours: true });
    $("#timeReturnToPolClosing").timeEntry({ show24Hours: true });
    $("#timeReturnToPolVgm").timeEntry({ show24Hours: true });
    $("#timeStuffAtPor").timeEntry({ show24Hours: true });
    $("#timeStuffAtPorClosing").timeEntry({ show24Hours: true });
    $("#timeStuffAtPorVgm").timeEntry({ show24Hours: true });

    $(".menu .item").tab();

    this.bookingContainerInfo = new BookingContainerInfo();
    this.bookingMisc = new BookingMisc();
    this.bookingExtra.bRemarkIsPrinted = "Y";

    this.defaultCheckboxOnlySing();

    // hack to open POL when port /default office code <> SGSIN
    if (this.cookieService.getDefaultLocationCode() !== "SGSIN") {
      this.isCRB = true;
    }

    if (this.model.bShipmentType === "C") {
      this.cbBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlBookingParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/"+this.custIdForCbBooking+"/customerName={query}"
      );
      this.cbShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlShipper/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerName={query}"
      );
    } else {
      this.cbBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlBookingPartyCountryBased/" +
          // this.cookieService.getDefaultLocationCountry().toString() +
          // "/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/"+this.custIdForCbBooking+"/customerName={query}"+
          this.sCharUtil.encodeUrl("|")+
          "countryCode="+this.cookieService.getDefaultLocationCountry().toString()
      );
      this.cbShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlShipperCountryBased/" +
          // this.cookieService.getDefaultLocationCountry().toString() +
          // "/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerName={query}"+
          this.sCharUtil.encodeUrl("|")+
          "countryCode="+this.cookieService.getDefaultLocationCountry().toString()
      );
    }

    var loc = this.cookieService.getCookie("defaultLocation").split("|");
    this.defaultLocation = loc[0];
    this.defaultCountry = loc[2];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];


    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }


    this.init();
    if (this.currentMenu.includes("(SOC)")) {
      this.SOCMenu();
    }

    if (this.currentMenu.includes("(COC)")) {
     this.COCMenu();
    }
    var self = this;
    setTimeout(() => {
      this.initTab1();
      this.clearReeferDetailsField();

      this.clearReleaseAtDate();
      this.clearReeferCollectionDate();

      if (localStorage.getItem("new-booking-from-flag") != null) {
        console.log("** new-booking-from-flag **");
        self.strParams = "";
        self.strParams = localStorage.getItem("new-booking-from-flag");
        if (self.strParams != null) {
          self.fromRetrieve = false;
          self.invisibleToolbarButtons = "";
          self.prev = "booking-flag";
          self.isNew = true;
          self.isModify = true;
          self.lock = false;
          self.lockEdit = true;
          self.disableToolbarButtons = "retrieve,new,modify,print,delete,sendDepot";
          //hardcode
          if (self.viewOnlyStatus) {
            self.disableToolbarButtons =
              "new,modify,print,delete,save,copy,cancel,sendDepot";
          }

          if (self.viewSOCStatus){
           // self.lockSLOT = true;
            self.lockCOC = true;
          }

          if(self.viewCOCStatus){
            self.lockSLOT = true;
            //self.lockSOC = true;
            self.model.bContainerOwnership = "COC";
          }

          if (self.cookieService.getDefaultLocationCode() == "SGSIN") {
            self.lockSOOPR1 = false;
            self.lockSOOPR2 = false;
          }

          //this.setValidatorSave();
          let par: any = JSON.parse(self.strParams);

          self.model.bOceanVesselBound = par.bOceanVesselBound;

          if(par.bContainerOwnership.split('|').length > 2){
            self.model.bContainerOwnership = 'SOC';
          }else{
            self.model.bContainerOwnership = par.bContainerOwnership.split('|')[0];
          }

          self.changeEventContainerOwnershipX(self.model.bContainerOwnership);

          if (par.bOceanVesselCode != "") {
            self.cbVessel.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterVessels/findByComboBoxControl/vesselId={query}"
            );
            self.cbVessel.setValue(
              String(par.bOceanVesselId).trim(),
              function (callbackVessel) {
                self.cbVessel.setUrl(
                  self.configService.config.BASE_API.toString() +
                    "/MasterVessels/findByComboBoxControl/vesselName={query}"
                );

                self.model.bOceanVesselId = callbackVessel.vesselId;
                self.model.bOceanVesselCode = callbackVessel.vesselCode;

                if (par.bOceanVesselVoyage != "") {
                  self.cbVoyage.setUrl(
                    self.configService.config.BASE_API.toString() +
                      "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
                      self.cookieService.getDefaultLocationCode() +
                      "/" +
                      par.bOceanVesselId +
                      "/" +
                      par.bOceanVesselBound +
                      "/voyage={query}"
                  );
                  self.cbVoyage.setValue(
                    par.bOceanVesselVoyage,
                    function (callbackVoyage) {
                      self.model.bOceanVesselVoyage = callbackVoyage.voyage;
                      self.serviceCode = callbackVoyage.serviceCode;

                      self.cbVoyage.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
                          self.cookieService.getDefaultLocationCode() +
                          "/" +
                          par.bOceanVesselId +
                          "/" +
                          par.bOceanVesselBound +
                          "/voyage_like={query}"
                      );

                      if (self.model.bPot1Code  != "" &&
                          self.model.bPot1Code  != undefined) {
                            self.cbDischargePort.setUrl(
                                self.configService.config.BASE_API.toString() +
                                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                                  self.cookieService.getDefaultLocationCode() +
                                  "/" +
                                  self.model.bOceanVesselId +
                                  "/" +
                                  self.sCharUtil.htmlEncode(
                                    String(self.model.bOceanVesselVoyage).trim()
                                  ) +
                                  "/" +
                                  self.model.bOceanVesselBound +
                                  "/{query}"
                              );
                      } else {
                        self.cbDischargePort.setUrl(
                          self.configService.config.BASE_API.toString() +
                            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                            self.model.bOfficeCode+"/"+
                            self.model.bOceanVesselId+"/"+
                            self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
                            self.model.bOceanVesselBound+"/{query}"
                        );
                      }

                      self.cbPOT1.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                          self.cookieService.getDefaultLocationCode() +
                          "/" +
                          self.model.bOceanVesselId +
                          "/" +
                          self.sCharUtil.htmlEncode(
                            String(self.model.bOceanVesselVoyage).trim()
                          ) +
                          "/" +
                          self.model.bOceanVesselBound +
                          "/{query}"
                      );

                      self.checkValidSchedule(
                        self.model.bOceanVesselId,
                        self.model.bOceanVesselVoyage,
                        self.model.bOceanVesselBound,
                        self.model.bOfficeCode
                      );

                      self.getRotation(
                        self.model.bOceanVesselId,
                        self.model.bOceanVesselVoyage,
                        self.model.bOceanVesselBound,
                        self.model.bOfficeCode
                      );

                      /**
                       * Click "New" from BKG Flag Status: check if container ownership is COC or not:
                      */
                      let callbackOnSuccess = () => {
                        if(self.model.ETA && self.isSingaporeSite && self.model['bContainerOwnership'] === 'COC'){
                          self.calculationReleaseAtDate();
                          self.initiallyReleaseAt();
                        }else{
                          self.clearReleaseAtDate();
                        }
                      };

                      self.getAllocationAndEstimateArrivalDate(
                        self.model.bOceanVesselId,
                        self.model.bOceanVesselVoyage,
                        self.model.bOceanVesselBound,
                        self.model.bOfficeCode,'',
                        callbackOnSuccess
                      );



                      //self.getWeightProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);
                      //self.getContainerProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);

                      self.genericUtil.hideLoader();
                    }
                  );
                } else {
                  self.genericUtil.hideLoader();
                }
              }
            );
          } else {
            self.genericUtil.hideLoader();
          }
        } else {
          self.genericUtil.hideLoader();
        }
      }

      if (localStorage.getItem("retrieve-booking-from-flag") != null) {
        self.strParams = "";
        self.strParams = localStorage.getItem("retrieve-booking-from-flag");
        if (self.strParams != null) {
          let par: any = JSON.parse(self.strParams);
          self.fromRetrieve = true;
          self.prev = "booking-flag";
          self.isInit = false;
          self.isNew = false;
          self.lockEdit = true;
          self.invisibleToolbarButtons = "";
          localStorage.removeItem("retrieve-booking-from-flag");
          self.setValidatorSave();


          let selectedList: String[] = String(par.selected).split("-");
          self.model.bPrefix = selectedList[0];
          self.model.bNo = selectedList[1];
          console.log("dari booking flag retrieve");
          self.retrieveEvent(function (callbackRetrieve) {
            if (callbackRetrieve.bStatus == "D") {
              self.disableToolbarButtons =
                "retrieve,new,modify,save,copy,cancel,print,delete";
            } else if (
              callbackRetrieve.bContainerOwnership == "COCTS" &&
              callbackRetrieve.bOfficeCodeTs != "" &&
              callbackRetrieve.bOfficeCodeTs != undefined &&
              callbackRetrieve.bPrefixTs != "" &&
              callbackRetrieve.bPrefixTs != undefined &&
              callbackRetrieve.bNoTs != "" &&
              callbackRetrieve.bNoTs != undefined
            ) {
              self.disableToolbarButtons =
                "retrieve,new,modify,save,copy,cancel,print,delete,sendDepot";
            } else {
              if (callbackRetrieve.bIsUnlocked == "N") {
                if (callbackRetrieve.bPermanentLocked === "Y") {
                  self.dialogPlugin.show(
                    "information",
                    "Information",
                    "Vessel departed more than 1 month. View only?",
                    "yesno",
                    {
                      yes: "this.viewOnlyStatus = true; this.genericUtil.hideLoader();",
                      no: "this.handleCancel(); this.genericUtil.hideLoader();",
                    }
                  );
                } else {
                  self.dialogPlugin.show(
                    "warning",
                    "Information",
                    "Unable to update booking because vessel ETD already exceeds 9 days from the current date.",
                    "okonly",
                    { ok: " this.genericUtil.hideLoader();" }
                  );
                }

                self.appStatusLabel = "-- LOCKED --";
                self.disableToolbarButtons =
                  "retrieve,new,modify,save,copy,print,delete,sendDepot";
              } else {
                console.log("#4");
                self.disableToolbarButtons = "retrieve,save,copy";
                //hardcode
                if (localStorage.getItem("view-only-mode") != undefined) {
                  self.viewOnlyStatus = true;
                }

                if (self.viewOnlyStatus) {
                  self.disableToolbarButtons =
                    "retrieve,new,modify,print,delete,save,copy,sendDepot";
                }
                console.log(
                  "callbackRetrieve.isCombine = " + callbackRetrieve.isCombine
                );

                if (callbackRetrieve.isCombine == "Y") {
                  self.lockCombine = true;
                }

                if (callbackRetrieve.isSplit == "Y") {
                  self.lockSplit = false; //true
                }

                self.cbPOT1.setUrl(
                  self.configService.config.BASE_API.toString() +
                    "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                    self.cookieService.getDefaultLocationCode() +
                    "/" +
                    self.model.bOceanVesselId +
                    "/" +
                    self.sCharUtil.htmlEncode(
                      String(self.model.bOceanVesselVoyage).trim()
                    ) +
                    "/" +
                    self.model.bOceanVesselBound +
                    "/{query}"
                );

                self.genericUtil.hideLoader();
              }
            }

            self.setDataAfterRetrieve();
          });
        }
      }

      //handle redirection from vessel listing
      if (localStorage.getItem("new-booking-from-vessel-listing") != null) {
        console.log("*** new-booking-from-vessel-listing ***");
        self.invisibleToolbarButtons = "";
        self.strParams = "";
        self.strParams = localStorage.getItem(
          "new-booking-from-vessel-listing"
        );
        if (self.strParams != null) {
          self.prev = "vessel-listing";

          let par: any = JSON.parse(self.strParams);
          console.log(par);

          self.model.bPrefix = "SUDU";
          self.model.bNo = "";
          self.model.bOceanVesselBound = "O";
          self.model.bOceanVesselId = par.vesselId;
          self.model.bOceanVesselCode = par.vesselCode;
          self.model.bOceanVesselVoyage = par.voyage;

          //GSO-387
          console.log("dari vessel listing");
          console.log("SOC : "+localStorage.getItem(
            "vessel-listing-last-searched-viewSOCStatus"
          ));
          console.log("COC : "+localStorage.getItem(
            "vessel-listing-last-searched-viewCOCStatus"
          ));
          if (
            localStorage.getItem(
              "vessel-listing-last-searched-viewSOCStatus"
            ) == "true") {
            console.log("SOC MASUK");
            self.model.bContainerOwnership = "SOC";
            self.lockCOC = true;
            self.lockSOC = false;
            self.lockSLOT = false;
          }

          if(localStorage.getItem(
            "vessel-listing-last-searched-viewCOCStatus"
          ) == "true"){
            console.log("COC MASUK");
            self.lockSLOT = true;
            self.lockSOC = false;
            self.lockCOC = false;
            self.model.bContainerOwnership = "COC";
          }
          /*
          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/MasterBookings/getSailSchedule/" +
                self.model.bOceanVesselId +
                "/" +
                this.sCharUtil.htmlEncode(
                  String(self.model.bOceanVesselVoyage).trim()
                ) +
                "/" +
                self.model.bOceanVesselBound +
                "/" +
                self.cookieService.getDefaultLocationCode()
            )*/
            this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/MasterBookings/getSailSchedule/" +
                self.model.bOceanVesselId +
                "/" +
                this.sCharUtil.htmlEncode(
                  String(self.model.bOceanVesselVoyage).trim()
                ) +
                "/" +
                self.model.bOceanVesselBound +
                "/" +
                self.cookieService.getDefaultLocationCode()
            )
            .subscribe((resp) => {
              let data = resp.json();

              this.model.ETA = String(data["ETA"]).trim().replace(/\//gi, "-");
              this.model.ETD = String(data["ETD"]).replace(/\//g, "-");
              this.model.ATA = String(data["ATA"]).replace(/\//g, "-");
              this.model.ATD = String(data["ATD"]).replace(/\//g, "-");

              this.genericService
                .POST(
                  this.configService.config.BASE_API.toString() +
                    "/MasterBookings/calculateAllocation2",
                  self.model
                )
                .subscribe((resp) => {
                  let dataAlloc: any[] = resp.json()["content"];
                  console.log(dataAlloc);

                  this.genericUtil.hideLoader();

                  self.cbVessel.setUrl(
                    self.configService.config.BASE_API.toString() +
                      "/MasterVessels/findByComboBoxControl/vesselId={query}"
                  );
                  self.cbVessel.setValue(
                    String(par.vesselId).trim(),
                    function (callbackVessel) {
                      self.cbVessel.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterVessels/findByComboBoxControl/vesselName={query}"
                      );

                      self.cbVoyage.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
                          self.cookieService.getDefaultLocationCode() +
                          "/" +
                          par.vesselId +
                          "/" +
                          par.bound +
                          "/voyage={query}"
                      );
                      self.cbVoyage.setValue(
                        par.voyage,
                        function (callbackVoyage) {
                          self.cbVoyage.setUrl(
                            self.configService.config.BASE_API.toString() +
                              "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
                              self.cookieService.getDefaultLocationCode() +
                              "/" +
                              par.vesselId +
                              "/" +
                              par.bound +
                              "/voyage_like={query}"
                          );

                          // ------
                          self.setValidatorNew();
                          self.model["allocationValidator"] = false;
                          if (
                            self.model.bOceanVesselId !== "" &&
                            self.model.bOceanVesselVoyage !== "" &&
                            dataAlloc.length === 0
                          ) {
                            self.model["allocationValidator"] = true;
                          }
                          self.isError = self.onValidate(self.model);
                          if (!self.isError) {
                            self.lock = false;
                            self.isNew = true;
                            self.lock = false;
                            self.lockEdit = true;
                            self.disableToolbarButtons =
                              "retrieve,new,modify,print,delete";
                            //hardcode
                            if (self.viewOnlyStatus) {
                              self.disableToolbarButtons =
                                "new,modify,print,delete,save,copy,cancel";
                            }

                            self.serviceCode = callbackVoyage.serviceCode;
                            // Terminal Load Port
                            if (
                              localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") != null
                            ) {
                              if (
                                localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") != "*"
                              ) {

                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() +
                                      "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
                                  );
                                } else {
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                  );
                                }
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                );

                                let vlTerminalLoadCode = localStorage.getItem(
                                  "vessel-listing-last-searched-valTerminalLoadCode"
                                );
                                self.cbLoadPortTerminal.setValue(vlTerminalLoadCode);

                              } else {
                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbLoadPortTerminal.setValue(
                                    par.terminalCodeName
                                  );
                                    self.cbLoadPortTerminal.setUrl(
                                      self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                    );
                                } else {
                                  self.cbLoadPortTerminal.setValue(
                                    par.terminalCodeName
                                  );
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                  );
                                }

                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );

                              }
                            }else {
                              if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                );

                              } else {
                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                );
                              }

                            }

                            //Load Port
                            if (
                              localStorage.getItem("vessel-listing-last-searched-valPortLoadCode") != null
                            ) {
                              if (
                                localStorage.getItem("vessel-listing-last-searched-valPortLoadCode") != "*"
                              ) {

                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbLoadPort.setUrl(
                                    self.configService.config.BASE_API.toString() +
                                      "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
                                  );
                                } else {
                                  self.cbLoadPort.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                  );
                                }

                                let vlLoadPort = localStorage.getItem(
                                  "vessel-listing-last-searched-valPortLoadCode"
                                );
                                self.cbLoadPort.setValue(vlLoadPort);

                              } else {
                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                    self.cbLoadPort.setUrl(
                                      self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                    );
                                    // self.cbLoadPortTerminal.setUrl(
                                    //   self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                    // );
                                } else {
                                  self.cbLoadPort.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                  );

                                }

                                self.cbLoadPort.setValue(
                                  par.portofLoadingCode
                                );

                              }
                            }else {
                              if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                self.cbLoadPort.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                );

                              } else {
                                self.cbLoadPort.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                );
                              }
                              self.cbLoadPort.setValue(
                                par.portofLoadingName
                              );
                            }

                            //Discharge Port
                            if (
                              localStorage.getItem("vessel-listing-last-searched-valPortDischargeCode") != null
                            ) {
                              if (
                                localStorage.getItem("vessel-listing-last-searched-valPortDischargeCode") != "*"
                              ) {

                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbDischargePort.setUrl(
                                    self.configService.config.BASE_API.toString() +
                                      "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
                                  );
                                } else {
                                  self.cbDischargePort.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                  );
                                }

                                let vlDischargePort = localStorage.getItem(
                                  "vessel-listing-last-searched-valPortDischargeCode"
                                );
                                self.cbDischargePort.setValue(vlDischargePort);

                              } else {
                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                    self.cbDischargePort.setUrl(
                                      self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                    );

                                } else {
                                  self.cbDischargePort.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                  );

                                }

                                self.cbDischargePort.setValue(
                                  par.portofDischarge
                                );

                              }
                            }else {
                              if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                self.cbDischargePort.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                );

                              } else {
                                self.cbDischargePort.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                );
                              }
                              self.cbDischargePort.setValue(
                                par.portofDischarge
                              );
                            }

                                                        // Terminal Load Port
                            if (
                              localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") != null
                            ) {
                              if (
                                localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") != "*"
                              ) {

                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() +
                                      "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
                                  );
                                } else {
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                  );
                                }
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                );

                                let vlTerminalLoadCode = localStorage.getItem(
                                  "vessel-listing-last-searched-valTerminalLoadCode"
                                );
                                self.cbLoadPortTerminal.setValue(vlTerminalLoadCode);

                              } else {
                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbLoadPortTerminal.setValue(
                                    par.terminalCodeName
                                  );
                                    self.cbLoadPortTerminal.setUrl(
                                      self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                    );
                                } else {
                                  self.cbLoadPortTerminal.setValue(
                                    par.terminalCodeName
                                  );
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                  );
                                }

                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );

                              }
                            }else {
                              if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                );

                              } else {
                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                );
                              }

                            }

                                                        // Terminal Load Port
                            if (
                              localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") != null
                            ) {
                              if (
                                localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") != "*"
                              ) {

                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() +
                                      "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
                                  );
                                } else {
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                  );
                                }
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                );

                                let vlTerminalLoadCode = localStorage.getItem(
                                  "vessel-listing-last-searched-valTerminalLoadCode"
                                );
                                self.cbLoadPortTerminal.setValue(vlTerminalLoadCode);

                              } else {
                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbLoadPortTerminal.setValue(
                                    par.terminalCodeName
                                  );
                                    self.cbLoadPortTerminal.setUrl(
                                      self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                    );
                                } else {
                                  self.cbLoadPortTerminal.setValue(
                                    par.terminalCodeName
                                  );
                                  self.cbLoadPortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                  );
                                }

                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );

                              }
                            }else {
                              if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                );

                              } else {
                                self.cbLoadPortTerminal.setValue(
                                  par.terminalCodeName
                                );
                                self.cbLoadPortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                );
                              }

                            }

                            // Terminal Discharge Port
                            if (
                              localStorage.getItem("vessel-listing-last-searched-valTerminalDischargeCode") != null
                            ) {
                              if (
                                localStorage.getItem("vessel-listing-last-searched-valTerminalDischargeCode") != "*"
                              ) {

                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbDischargePortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() +
                                      "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
                                  );
                                } else {
                                  self.cbDischargePortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                  );
                                }
                                self.cbDischargePortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                                );

                                let vlTerminalDischargeCode = localStorage.getItem(
                                  "vessel-listing-last-searched-valTerminalDischargeCode"
                                );
                                self.cbDischargePortTerminal.setValue(vlTerminalDischargeCode);

                              } else {
                                if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                  self.cbDischargePortTerminal.setValue(
                                    par.terminalCodeDischargeName
                                  );
                                    self.cbDischargePortTerminal.setUrl(
                                      self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                    );
                                } else {
                                  self.cbDischargePortTerminal.setValue(
                                    par.terminalCodeDischargeName
                                  );
                                  self.cbDischargePortTerminal.setUrl(
                                    self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                  );
                                }
                                self.cbDischargePortTerminal.setValue(
                                  par.terminalCodeDischargeName
                                );

                              }
                            }else {
                              if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                                self.cbDischargePortTerminal.setValue(
                                  par.terminalCodeDischargeName
                                );
                                self.cbDischargePortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                );

                              } else {
                                self.cbDischargePortTerminal.setValue(
                                  par.terminalCodeDischargeName
                                );
                                self.cbDischargePortTerminal.setUrl(
                                  self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}'
                                );
                              }

                            }

                            // if (
                            //   localStorage.getItem(
                            //     "vessel-listing-last-searched-valTerminalDischargeCode"
                            //   ) != null
                            // ) {
                            //   if (
                            //     localStorage.getItem(
                            //       "vessel-listing-last-searched-valTerminalDischargeCode"
                            //     ) != "*"
                            //   ) {

                            //     if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                            //       self.cbDischargePort.setUrl(
                            //         self.configService.config.BASE_API.toString() +
                            //           "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
                            //       );
                            //     } else {
                            //       // GOS-493
                            //       // Commented out by GLG, if it already listed under vessel listing, means the rotation is valid, no need to use this api
                            //       // Just use the direct resolve API
                            //       /*
                            //        self.cbDischargePort.setUrl(
                            //           self.configService.config.BASE_API.toString() +
                            //             "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                            //             self.cookieService.getDefaultLocationCode()+"/"+
                            //             self.model.bOceanVesselId+"/"+
                            //             self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
                            //             self.model.bOceanVesselBound+"/{query}"
                            //         );
                            //        */
                            //       self.cbDischargePort.setUrl(
                            //         self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                            //       );




                            //     }

                            //     let vlPOD = localStorage.getItem(
                            //       "vessel-listing-last-searched-valTerminalDischargeCode"
                            //     );
                            //     self.cbDischargePort.setValue(vlPOD);
                            //   } else {
                            //     if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                            //       // GOS-493
                            //       // Commented out by GLG, if it already listed under vessel listing, means the rotation is valid, no need to use this api
                            //       // Just use the direct resolve API
                            //       /*
                            //        self.cbDischargePort.setUrl(
                            //           self.configService.config.BASE_API.toString() +
                            //             "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                            //             self.cookieService.getDefaultLocationCode() +
                            //             "/" +
                            //             self.model.bOceanVesselId +
                            //             "/" +
                            //             self.sCharUtil.htmlEncode(
                            //               String(
                            //                 self.model.bOceanVesselVoyage
                            //               ).trim()
                            //             ) +
                            //             "/" +
                            //             self.model.bOceanVesselBound +
                            //             "/{query}"
                            //         */
                            //         self.cbDischargePort.setUrl(
                            //           self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                            //         );


                            //     } else {
                            //       // GOS-493
                            //       // Commented out by GLG, if it already listed under vessel listing, means the rotation is valid, no need to use this api
                            //       // Just use the direct resolve API
                            //       /*
                            //          self.cbDischargePort.setUrl(
                            //           self.configService.config.BASE_API.toString() +
                            //             "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                            //             self.cookieService.getDefaultLocationCode()+"/"+
                            //             self.model.bOceanVesselId+"/"+
                            //             self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
                            //             self.model.bOceanVesselBound+"/{query}"
                            //           );
                            //         */
                            //       self.cbDischargePort.setUrl(
                            //         self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                            //       );

                            //     }

                            //     self.cbDischargePort.setValue(
                            //       par.portofDischarge
                            //     );
                            //   }
                            // } else {
                            //   if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                            //     // GOS-493
                            //     // Commented out by GLG, if it already listed under vessel listing, means the rotation is valid, no need to use this api
                            //     // Just use the direct resolve API
                            //     /*
                            //    self.cbDischargePort.setUrl(
                            //       self.configService.config.BASE_API.toString() +
                            //         "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                            //         self.cookieService.getDefaultLocationCode() +
                            //         "/" +
                            //         self.model.bOceanVesselId +
                            //         "/" +
                            //         self.sCharUtil.htmlEncode(
                            //           String(self.model.bOceanVesselVoyage).trim()
                            //         ) +
                            //         "/" +
                            //         self.model.bOceanVesselBound +
                            //         "/{query}"
                            //     );
                            //      */
                            //     self.cbDischargePort.setUrl(
                            //       self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                            //     );

                            //   } else {
                            //     // GOS-493
                            //     // Commented out by GLG, if it already listed under vessel listing, means the rotation is valid, no need to use this api
                            //     // Just use the direct resolve API
                            //     /*
                            //    self.cbDischargePort.setUrl(
                            //       self.configService.config.BASE_API.toString() +
                            //         "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                            //         self.cookieService.getDefaultLocationCode()+"/"+
                            //         self.model.bOceanVesselId+"/"+
                            //         self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
                            //         self.model.bOceanVesselBound+"/{query}"
                            //     );
                            //      */
                            //     self.cbDischargePort.setUrl(
                            //       self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}'
                            //     );

                            //   }
                            //   self.cbDischargePort.setValue(
                            //     par.portofDischarge
                            //   );
                            // }
                            /*
                      self.cbFinalDest.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}');
                      //self.cbFinalDest.setValue(par.portofDischarge, function (callbackFDest) {
                      self.cbFinalDest.setValue(String(self.model.bPodCode), function (callbackFDest) {
                        self.cbFinalDest.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}');
                      });
                      self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
                      */

                            self.getRotation(
                              self.model.bOceanVesselId,
                              self.model.bOceanVesselVoyage,
                              self.model.bOceanVesselBound,
                              self.model.bOfficeCode
                            );
                            self.getAllocationAndEstimateArrivalDate(
                              self.model.bOceanVesselId,
                              self.model.bOceanVesselVoyage,
                              self.model.bOceanVesselBound,
                              self.model.bOfficeCode,''
                            );

                            if (
                              self.cookieService.getDefaultLocationCode() ==
                              "SGSIN"
                            ) {
                              self.lockSOOPR1 = false;
                              self.lockSOOPR2 = false;
                            }

                            // self.cbDischargePort.setUrl(
                            //   self.configService.config.BASE_API.toString() +
                            //     "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+self.model.bOfficeCode+"/"+self.model.bOceanVesselId+"/"+self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+self.model.bOceanVesselBound+"/locationName={query}"
                            // );
                            if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
                              self.cbDischargePort.setUrl(
                                self.configService.config.BASE_API.toString() +
                                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                                  self.cookieService.getDefaultLocationCode() +
                                  "/" +
                                  self.model.bOceanVesselId +
                                  "/" +
                                  self.sCharUtil.htmlEncode(
                                    String(self.model.bOceanVesselVoyage).trim()
                                  ) +
                                  "/" +
                                  self.model.bOceanVesselBound +
                                  "/{query}"
                              );
                            } else {
                              self.cbDischargePort.setUrl(
                                self.configService.config.BASE_API.toString() +
                                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                                  self.cookieService.getDefaultLocationCode()+"/"+
                                  self.model.bOceanVesselId+"/"+
                                  self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
                                  self.model.bOceanVesselBound+"/{query}"
                              );
                            }
                          }

                          //-------------
                        }
                      );
                    }
                  );
                });
            });
        }
        // GOS-765 Unable to Input Remarks upon Creation of New Bkg in Bkg Maintenance Screen
        self.isModify = true;
      }

      //Handle refirection from Shanghai Processed Booking
      if (localStorage.getItem("retrieve-booking-from-shanghai-processed-edi") != null ||
      self.ChinaBookingselectedList.length>0) {
        self.strParams = "";
        self.strParams = localStorage.getItem("retrieve-booking-from-shanghai-processed-edi");
        self.strParams2 = "";
        self.strParams2= localStorage.getItem("Shanghai-booking-process-data-grid");

        if ((self.strParams!=null && self.strParams2!=null) || self.ChinaBookingselectedList.length>0) {

          if(self.ChinaBookingselectedList==null || self.ChinaBookingselectedList.length<1){
          let par2: any=JSON.parse(self.strParams2);
          self.ChinaBookingselectedList = String(par2.bNoList).split(",");
          }

          let par: any = JSON.parse(self.strParams);
          self.fromRetrieve = true;
          self.prev = "shanghai-processed-booking";
          self.isInit = false;
          self.isNew = true;
          self.isModify = true;
          self.lock = false;
          self.lockEdit = true;

          // self.lockOOG=false;
          // self.lockCombine=true;
          // self.lockReeferTemp == false;

          self.invisibleToolbarButtons = "";

          self.setValidatorSave();
          this.isCHINASiteEDI_NewBkg=true;
          // let selectedList: String[] = String(par.selected).split("-");
          // self.model.bPrefix = selectedList[0];
          // self.model.bNo = selectedList[1];
          self.model = new Booking();
          self.model.bPrefix="SSL";
          //self.model.bNo=String(par.selected).replace("SSL","");
          self.model.bNo=String(self.ChinaBookingselectedList[0]).replace("SSL","");
          self.ChinaBookingselectedList.splice(0,1);
          if(self.ChinaBookingselectedList.length<1){
          localStorage.removeItem("retrieve-booking-from-shanghai-processed-edi");
          }

          self.model.bOceanVesselBound = par.bOceanVesselBound;

          if(par.bContainerOwnership.split('|').length > 2){
            self.model.bContainerOwnership = par.bContainerOwnership;
          }else{
            self.model.bContainerOwnership = par.bContainerOwnership.split('|')[0];
          }

          self.model.bOfficeCode= par.bOfficeCode;
          self.model.bOceanVesselId = par.bOceanVesselId
          self.model.bOceanVesselCode = par.bOceanVesselCode;
          self.model.bOceanVesselVoyage = par.bOceanVesselVoyage;

          self.model.bPot1Code = par.bPot1Code;
          self.model.bPolCode = par.bPolCode;
          self.model.bPodCode = par.bPodCode;
          self.model.bFdestCode = par.bFDestCode;
          self.model.bContainerStatus = par.bContainerStatus;
          self.model.shaEdiId=par.bEDIid;

          console.log("from Shanghai proccesed booking retrieve");

          //self.disableToolbarButtons = "retrieve,new,modify,copy,print,delete,sendDepot";
          self.retrieveShanghaiBookingEvent(par.bEDIid ,function (callbackRetrieve) {

            self.disableToolbarButtons = "retrieve,new,modify,copy,print,delete,sendDepot";


                if (callbackRetrieve.isCombine == "Y") {
                  self.lockCombine = true;
                }

                if (callbackRetrieve.isSplit == "Y") {
                  self.lockSplit = false; //true
                }

                self.cbPOT1.setUrl(
                  self.configService.config.BASE_API.toString() +
                    "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                    self.cookieService.getDefaultLocationCode() +
                    "/" +
                    self.model.bOceanVesselId +
                    "/" +
                    self.sCharUtil.htmlEncode(
                      String(self.model.bOceanVesselVoyage).trim()
                    ) +
                    "/" +
                    self.model.bOceanVesselBound +
                    "/{query}"
                );

                self.genericUtil.hideLoader();

            self.setDataAfterRetrieve();
          });


        }
      }

      // GSO-1042 show Send To Depot btn only Sin site
      if(!this.isSingaporeSite){
        this.invisibleToolbarButtons = "sendDepot";
      }

    }, 100);

    this.cdr.detectChanges();
  }

  defaultCheckboxOnlySing() {
    //GOS-498 To set print checkboxes for POL & POD freetime sections as UNCHECK/UNTICK by default (SIN SITE only)
    setTimeout(() => {
      if (this.isSingaporeSite) {
        this.isDisablebPrint = false;
        this.bookingExtra.bDetentionDemurragePodIsPrinted = "N";
        this.bookingExtra.bDetentionDemurragePolIsPrinted = "N";
      } else {
        this.isDisablebPrint = true;
        this.bookingExtra.bDetentionDemurragePodIsPrinted = "Y";
        this.bookingExtra.bDetentionDemurragePolIsPrinted = "Y";
      }
    }, 0)
  }

  // GOS-630 - Set "PRINT" checkbox for Booking Remarks as CHECKED/TICKED by default (ALL sites EXCEPT SIN)
  setPrintRemark(){
    setTimeout(() => {
      if (this.isSingaporeSite) {
        this.bookingExtra.bRemarkIsPrinted = "N";
      } else {
        this.bookingExtra.bRemarkIsPrinted = "Y";
      }
    }, 0)
  }

  ngOnDestroy() {
    localStorage.removeItem("pinnedColumns");
  }

  onClear() {
    this.model = new Booking();
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.cbBookingParty.setValue("");
    this.cbShipper.setValue("");
    this.cbFreightParty.setValue("");
    this.cbDischargePort.setValue("");
    this.cbPODL.setValue("");
    this.cbFinalDest.setValue("");
    this.cbSpecialDet.setValue("");
    this.cbPOT1.setValue("");
    this.cbPOT2.setValue("");
    this.cbPOT3.setValue("");
    this.cbCommodity.setValue("");
    this.cbFeederVessel.setValue("");
    this.cbFeederVoyage.setValue("");
    this.cbSupplier.setValue("");
    this.cbExVessel.setValue("");
    this.txtRemarks.setValue("");
    this.txtReason.setValue("");
    //  this.cbExVoyage.setValue('');
    this.cbNextVessel.setValue("");
    this.cbNextVoyage.setValue("");
    this.cbNextVoyage.disableCombo = false;
    //this.cbHaulier.setValue('');
    //this.cbReturnTo.setValue('');
    //this.cbReleaseAt.setValue('');
    //this.cbPackedAt.setValue('');
    this.cbPackageType.setValue("");
    //this.dtExpReturnDate.resetCalendar();
    //this.dtReeferCollection.resetCalendar();
    //this.dtRequestReleaseDate.resetCalendar();

    this.cbSocOPR.clearSelect();
    this.cbSocOPR.listStore.store = [];

    this.cbSlotOPR.clearSelect();
    this.cbSlotOPR.listStore.store = [];

    this.cbSlotCntrOPR.clearSelect();
    this.cbSlotCntrOPR.listStore.store = [];

    this.isSocBookingParty = false;
    this.isCocBookingParty = false;
    this.isSlotBookingParty = false;

    this.nvoccBookingParty = false;
    this.nvoccFreightParty = false;
    this.nvoccShipper = false;

    this.serviceCode = "";
    this.rotation = "";
    this.cleanRotation = "";
    this.OH = "N";
    this.OW = "N";
    this.OL = "N";
    this.heightMeasure = 0;
    this.leftMeasure = 0;
    this.rightMeasure = 0;
    this.frontMeasure = 0;
    this.endMeasure = 0;
    this.UCH = 0;
    this.UCL = 0;
    this.UCW = 0;
    this.d20 = 0;
    this.d40 = 0;
    this.d45 = 0;
    this.h20 = 0;
    this.h40 = 0;
    this.h45 = 0;
    this.killSlot = 0;
    this.doorOpen = "N";
    this.bDegree = null;
    this.bDegreeSO = null;
    this.bDegreeTemp = "C";
    this.bDegreeTempSO = "C";

    /*
    this.gridNew.setBody(this.model);
    this.gridNew.onClear();
    */

    //this.grid.setBody(this.model);
    //this.grid.onClear();

    this.gridFreightCharges.onClear();
    this.gridFreightPerSummary.onClear();
    this.gridFreightCurrencySummary.onClear();
    this.refreightEffectiveFrom = "";
    this.refreightExpiryOn = "";
    this.refreightModifiedBy = "";
    this.refreightModifiedDate = "";
    this.refreightAccountPIC = "";
    this.refreightApprovalBy = "";
    this.refreightApprovedDate = "";

    this.gridContainerInfo.onClear();

    this.bookingContainerInfo = new BookingContainerInfo();
    this.bookingMisc = new BookingMisc();
    this.bookingExtra = new BookingExtra();
    this.defaultCheckboxOnlySing();
    this.setPrintRemark();

    this.cbHaulier.setValue("");
    this.cbReceiptTypeExtra.setValue("");
    this.cbReleaseAt.setValue("");
    this.cbReturnToPor.setValue("");
    this.dateReturnToPor.setValue("");
    this.dateReturnToPorGateInClosing.setValue("");
    this.dateReturnToPorVgmClosing.setValue("");
    this.cbReturnToPol.setValue("");
    this.dateReturnToPol.setValue("");
    this.dateReturnToPolGateInClosing.setValue("");
    this.dateReturnToPolVgmClosing.setValue("");
    this.cbStuffAt.setValue("");
    this.dateStuffAtPor.setValue("");
    this.dateStuffAtPorGateInClosing.setValue("");
    this.dateStuffAtPorVgmClosing.setValue("");
    this.clearReleaseAtDate();
    this.clearReeferCollectionDate();

    this.txtTerminalRemarks.setValue("");

    this.bookingDetails.clear();
    let list = new Map<String, BookingDetail>();
    this.bookingDetails.set(this.currentContainerType, list);

    //reset time
    $("#timeReturnToPor").val("");
    $("#timeReturnToPorClosing").val("");
    $("#timeReturnToPorVgm").val("");
    $("#timeReturnToPol").val("");
    $("#timeReturnToPolClosing").val("");
    $("#timeReturnToPolVgm").val("");
    $("#timeStuffAtPor").val("");
    $("#timeStuffAtPorClosing").val("");
    $("#timeStuffAtPorVgm").val("");

    this.clearReeferDetailsField();
    this.clearReleaseAtDate();

    /*
    this.grid2.setBody(this.model);
    this.grid2.onClear();

    this.grid3.setBody(this.model);
    this.grid3.onClear();

    this.grid4.setBody(this.model);
    this.grid4.onClear();

    this.grid5.setBody(this.model);
    this.grid5.onClear();
    */

    /*
    this.gridNew.setBody(this.model);
    this.gridNew.loadData();

    this.grid.setBody(this.model);
    this.grid.loadData();

    this.grid2.setBody(this.model);
    this.grid2.loadData();

    this.grid3.setBody(this.model);
    this.grid3.loadData();

    this.grid4.setBody(this.model);
    this.grid4.loadData();

    this.grid5.setBody(this.model);
    this.grid5.loadData();
    */
  }

  init() {
    var self = this;
    this.onClear();
    this.setValidatorInit();
    this.lock = true;
    this.lockTNK = false;
    this.lockEdit = false;
    this.isError = false;
    this.isInit = true;
    this.lockCombine = false;
    this.lockSplit = false;
    this.isModify = false;
    this.isPOLCanalPassing = false;
    $("#tsBkg").prop("checked", false);
    /*
    if(this.strParams == ''){
      this.invisibleToolbarButtons = 'close';
    }else{
      this.invisibleToolbarButtons = '';
    }
    */
    this.model.bOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.bOceanVesselBound = "O";
    this.model.bPrefix = "SUDU";
     this.disableToolbarButtons = "modify,save,copy,cancel,print,delete,sendDepot";
    //hardcode
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "new,modify,print,delete,save,copy,cancel";
    }

    // this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneral/'+this.model.bOfficeCode+'/*/*/{query}/1/5/*/*');
    //this.cbPackedAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneral/'+this.model.bOfficeCode+'/*/*/{query}/1/5/*/*');
    //this.cbReturnTo.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneral/'+this.model.bOfficeCode+'/*/*/{query}/1/5/*/*');

    if (this.cookieService.getDefaultLocationCode() != null) {
      let loc: string = this.cookieService.getDefaultLocationCode();
      let locName: string = this.cookieService.getDefaultLocationName();
      if (loc == "MMYGN") {
        loc = "MMRGN";
        locName = "YANGON";
      } else if (loc == "INGIN") {
        loc = "INMUN";
        locName = "MUNDRA";
      } else if (loc == "INBOM") {
        loc = "INNSA";
        locName = "NHAVA SHEVA, INDIA";
      } else if (loc == 'THSGZ') {
        loc = "INSGK";
        locName = "SONGKHLA NEW PORT CODE";
      }

      this.cbLoadPort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
      );
      this.cbLoadPort.setForceValue(locName);
      this.model.bPolCode = loc;
      this.cbLoadPort.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails3/" +
         "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.bOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.bOceanVesselBound +
          "/{query}"
      );

      // this.cbLoadPort.setValue(loc, function (callbackPOL) {
      //   self.model.bPolCode = loc;
      //   // console.log(callbackPOL.locationBookingRef);
      //   self.model.bPolBooking = callbackPOL.locationBookingRef;
      //   //self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}');
      //   self.cbLoadPort.setUrl(
      //     self.configService.config.BASE_API.toString() +
      //       "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails3/" +
      //       self.cookieService.getDefaultLocationCode() +
      //       "/" +
      //       self.model.bOceanVesselId +
      //       "/" +
      //       self.sCharUtil.htmlEncode(
      //         String(self.model.bOceanVesselVoyage).trim()
      //       ) +
      //       "/" +
      //       self.model.bOceanVesselBound +
      //       "/{query}"
      //   );
      // });

      this.cbPOR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
      );

      this.cbPOR.setForceValue(locName);
      this.model.bPorCode = loc;

      this.cbPOR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
      );

      // this.cbPOR.setValue(loc, function (callbackPOR) {
      //   self.model.bPorCode = loc;
      //   self.cbPOR.setUrl(
      //     self.configService.config.BASE_API.toString() +
      //       "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}"
      //   );
      // });
    }

    this.cbReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
    );
    //this.cbReceiptType.setValue("CY");
    this.cbReceiptType.setForceValue("CY");
    this.model.bReceiptType = "CY";

    this.cbDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
    );
    // this.cbDeliveryType.setValue("CY");
    this.cbDeliveryType.setForceValue("CY");
    this.model.bDeliveryType = "CY";

    this.currentContainerType = "STD";
    this.lockSTD = false;
    this.model.bContainerOwnership = "SOC";
    this.model.bSocOperatorCode = "SA";
    this.model.bContainerStatus = "FULL";

    this.lockSOC = false;
    this.lockCOC = false;
    this.lockSLOT = false;

    this.lockSOCOPR = false;
    this.lockCOCOPR = true;
    this.lockSLOTOPR = true;
    this.lockSLOTCNTROPR = true;

    this.model.bIsFinished = "N";
    this.model.bIsTransmittedToPsa = "N";
    this.model.bTransmitToSds = "N";
    this.model.bReleaseOrderPrinted = "N";
    this.model.bFreightType = "P";
    this.model.bShipmentType = "N";
    this.model.bDgClass = "N";
    this.model.bIhc = "N";
    this.model.bWeightClass = "M";
    this.model.bIsTransmittedToPsa = "N";
    this.model.bStatus = "N";
    this.model.bIsFinished = "N";
    this.model.bReleaseOrderPrinted = "N";
    this.model.bDetention = "N";
    this.model.bDemurrage = "N";
    this.bookingExtra.bDetentionPol = "N";
    this.bookingExtra.bDemurragePol = "N";
    this.bookingExtra.bIsDry = "N";
    this.bookingExtra.bVent = "N";
    this.model.bTsBooking = "N";
    this.model.bIsReserve = "N";
    this.model.bSocOperatorCode = "SA";
    this.model.bCocOperatorCode = "";
    this.model.bOutwardSlotOperatorCode = "";
    //GOS-110
    this.fpAcctCodeCOC = "";
    this.fpAcctCodeSLOT = "";
    this.fpAcctCodeSOC = "";
    this.fpAcctCode = "<< SOC: >>";
    //END GOS-110

    this.cbCocOPR.clearSelect();
    this.cbCocOPR.listStore.store = [];
    this.cbCocOPR.url = "";

    this.bookingDetails.clear();
    let list = new Map<String, BookingDetail>();
    this.bookingDetails.set(this.currentContainerType, list);

    this.txtRemarks.setValue("");
    this.txtReason.setValue("");
    this.txtTerminalRemarks.setValue("");
    this.txtContainerOtherDetails.setValue("");

    this.cbLoadPortTerminal.setForceValue("");
    this.cbPOT1Terminal.setForceValue("");
    this.cbPODLTerminal.setForceValue("");

    let uri =
      this.configService.config.BASE_API.toString() +
      "/MasterLocations/findById/" +
      this.cookieService.getDefaultLocationId();

    this.genericService.GET(uri).subscribe((resp) => {
      if (resp.ok) {
        this.model.bPolBooking = resp.json()["locationBookingRef"];
        this.originalBookingRef = resp.json()["locationBookingRef"];
        this.model.bPolName = resp.json()["locationName"];
      }
    });

    window.onbeforeunload = function () {
      return "Dude, are you sure you want to leave? Think of the kittens!";
    };
  }

  checkValidSchedule(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ): String {
    let validSch: String = "";
    console.log("CEK VALID 1 : " + validSch);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        let dtx: any[];
        let valid: String;
        dtx = resp.json()["content"];
        validSch = dtx[0]["isValid"];
        if (validSch == "N") {
          this.dialogPlugin.show(
            "information",
            "Information",
            "Schedule is invalid, unable to create booking.",
            "okonly",
            { ok: "/*this.handleCancel();*/" }
          );
          this.handleCancel();
        }
      });
    console.log("CEK VALID 3 : " + validSch);

    return validSch;
  }

  COCMenu(){
      this.lockSLOT = true;
      this.viewCOCStatus = true;
      this.model.bContainerOwnership = "COC";

  }

  SOCMenu(){

      this.lockCOC = true;
      this.viewSOCStatus = true;
      this.model.bContainerOwnership = "SOC";


  }

  initNew() {
    console.log("** init New **");
    let data: any = this.model;
    this.onClear();
    this.fromRetrieve =false;

    var self = this;
    // console.log("vessel : " + data.bOceanVesselCode);
    //this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/*/{query}/1/100/*/*');
    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselId={query}"
    );
    this.cbVessel.setValue(
      String(data.bOceanVesselId).trim(),
      function (callbackVessel) {
        // console.log("** callback after vessel **");
        // console.log(callbackVessel);

        //self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*');
        self.cbVessel.setUrl(
          self.configService.config.BASE_API.toString() +
            "/MasterVessels/findByComboBoxControl/vesselName={query}"
        );

        self.model.bOceanVesselId = callbackVessel.vesselId;
        self.model.bOceanVesselCode = callbackVessel.vesselCode;
        self.model.bOceanVesselName = callbackVessel.vesselName;
        self.model.bOceanVesselBound = data.bOceanVesselBound;
        self.model.bOfficeCode = data.bOfficeCode;
        self.model.bPrefix = data.bPrefix;

        if (data.bIsReserve == "Y") {
          self.model.bPrefix = "RESV";
        } else {
          self.model.bPrefix = "SUDU";
        }

        console.log("### reset pol ###");
        console.log("self.model.bPolCode = " + self.model.bPolCode);
        //reset pol
        //self.model.bPolCode = '';

        //self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ data.bOceanVesselId+"/"+ data.bOceanVesselBound+"/{query}/" + data.bOfficeCode);
        self.cbVoyage.setUrl(
          self.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
            data.bOfficeCode +
            "/" +
            data.bOceanVesselId +
            "/" +
            data.bOceanVesselBound +
            "/voyage={query}"
        );
        self.cbVoyage.setValue(
          data.bOceanVesselVoyage,
          function (callbackVoyage) {
            // console.log("** callback after voyage **");
            // console.log(callbackVoyage);
            self.model.bOceanVesselVoyage = callbackVoyage.voyage;
            self.cbVoyage.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
                data.bOfficeCode +
                "/" +
                data.bOceanVesselId +
                "/" +
                data.bOceanVesselBound +
                "/voyage_like={query}"
            );

            self.serviceCode = callbackVoyage.serviceCode;
            if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
              self.cbDischargePort.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                  self.cookieService.getDefaultLocationCode() +
                  "/" +
                  self.model.bOceanVesselId +
                  "/" +
                  self.sCharUtil.htmlEncode(
                    String(self.model.bOceanVesselVoyage).trim()
                  ) +
                  "/" +
                  self.model.bOceanVesselBound +
                  "/{query}"
              );
            } else {
              self.cbDischargePort.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                  self.cookieService.getDefaultLocationCode()+"/"+
                  self.model.bOceanVesselId+"/"+
                  self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
                  self.model.bOceanVesselBound+"/{query}"
              );
            }

            self.cbPOT1.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                self.cookieService.getDefaultLocationCode() +
                "/" +
                self.model.bOceanVesselId +
                "/" +
                self.sCharUtil.htmlEncode(
                  String(self.model.bOceanVesselVoyage).trim()
                ) +
                "/" +
                self.model.bOceanVesselBound +
                "/{query}"
            );

            self.checkValidSchedule(
              self.model.bOceanVesselId,
              self.model.bOceanVesselVoyage,
              self.model.bOceanVesselBound,
              self.model.bOfficeCode
            );

            console.log("--- debug before get rotation ----");

            self.getRotation(
              self.model.bOceanVesselId,
              self.model.bOceanVesselVoyage,
              self.model.bOceanVesselBound,
              self.model.bOfficeCode
            );
            self.getAllocationAndEstimateArrivalDate(
              self.model.bOceanVesselId,
              self.model.bOceanVesselVoyage,
              self.model.bOceanVesselBound,
              self.model.bOfficeCode,''
            );
            self.getWeightProfiles(
              self.model.bOfficeCode,
              self.model.bOceanVesselId,
              self.model.bOceanVesselVoyage,
              self.model.bOceanVesselBound
            );
            self.getContainerProfiles(
              self.model.bOfficeCode,
              self.model.bOceanVesselId,
              self.model.bOceanVesselVoyage,
              self.model.bOceanVesselBound
            );

            // self.cbDischargePort.setUrl(
            //   self.configService.config.BASE_API.toString() +
            //     "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+self.model.bOfficeCode+"/"+self.model.bOceanVesselId+"/"+self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+self.model.bOceanVesselBound+"/locationName={query}"
            // );

            if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
              self.cbDischargePort.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                  self.cookieService.getDefaultLocationCode() +
                  "/" +
                  self.model.bOceanVesselId +
                  "/" +
                  self.sCharUtil.htmlEncode(
                    String(self.model.bOceanVesselVoyage).trim()
                  ) +
                  "/" +
                  self.model.bOceanVesselBound +
                  "/{query}"
              );
            } else {
              self.cbDischargePort.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                  self.cookieService.getDefaultLocationCode()+"/"+
                  self.model.bOceanVesselId+"/"+
                  self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
                  self.model.bOceanVesselBound+"/{query}"
              );
            }

          }
        );
      }
    );

    this.cbReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
    );
    this.cbReceiptType.setValue("CY");
    this.model.bReceiptType = "CY";

    this.cbDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
    );
    this.cbDeliveryType.setValue("CY");
    this.model.bDeliveryType = "CY";

    this.model.bContainerOwnership = "SOC";
    this.model.bSocOperatorCode = "SA";
    this.model.bContainerStatus = "FULL";
    //babiiii
    this.disableToolbarButtons = "retrieve,new,modify,print,delete,sendDepot";
    //hardcode
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "new,modify,print,delete,save,copy,cancel,sendDepot";
    }

    if (this.cookieService.getDefaultLocationCode() != null) {
      let loc: string = this.cookieService.getDefaultLocationCode();
      if (loc == "MMYGN") {
        loc = "MMRGN";
      } else if (loc == "INGIN") {
        loc = "INMUN";
      } else if (loc == "INBOM") {
        loc = "INNSA";
      } else if (loc == "THSGZ") {
        loc = "THSGK";
      }

      this.cbPOR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
      );
      this.cbPOR.setValue(loc, function (callbackPOR) {
        self.model.bPorCode = loc;
        self.cbPOR.setUrl(
          self.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
        );
      });

      if (this.cookieService.getDefaultLocationCode() == "SGSIN") {
        this.cbLoadPort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        //this.cbLoadPort.setForceValue(this.cookieService.getDefaultLocationCode());
        this.model.bPolCode = this.cookieService.getDefaultLocationCode();
        this.cbLoadPort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}"
        );

        let uri =
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findById/" +
          this.cookieService.getDefaultLocationId();

        this.genericService.GET(uri).subscribe((resp) => {
          if (resp.ok) {
            this.model.bPolBooking = resp.json()["locationBookingRef"];
            this.originalBookingRef = resp.json()["locationBookingRef"];
            //this.model.bPolName = resp.json()["locationName"].toString();
            this.cbLoadPort.setForceValue(resp.json()["locationName"]);
            this.cbLoadPortTerminal.setForceValue(resp.json()["terminalName"]);
          }
        });


        // this.cbLoadPort.setValue(
        //   this.cookieService.getDefaultLocationCode(),
        //   function (callbackPOL) {
        //     self.model.bPolCode = self.cookieService.getDefaultLocationCode();
        //     self.cbLoadPort.setUrl(
        //       self.configService.config.BASE_API.toString() +
        //         "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}"
        //     );
        //   }
        // );
      }
    }

    this.lockCombine = false;
    this.lockSplit = false;
    this.lock = false;
    this.lockTNK = false;
    this.lockSOC = false;
    this.lockCOC = false;
    this.lockSLOT = false;

    this.lockSOCOPR = false;
    this.lockCOCOPR = true;
    this.lockSLOTOPR = true;
    this.lockSLOTCNTROPR = true;

    this.model.bIsFinished = "N";
    this.model.bIsTransmittedToPsa = "N";
    this.model.bTransmitToSds = "N";
    this.model.bReleaseOrderPrinted = "N";
    this.model.bFreightType = "P";
    this.model.bShipmentType = "N";
    this.model.bDgClass = "N";
    this.model.bIhc = "N";
    this.model.bWeightClass = "M";
    this.model.bIsTransmittedToPsa = "N";
    this.model.bStatus = "N";
    this.model.bIsFinished = "N";
    this.model.bReleaseOrderPrinted = "N";
    this.model.bDetention = "N";
    this.model.bDemurrage = "N";
    this.bookingExtra.bDetentionPol = "N";
    this.bookingExtra.bDemurragePol = "N";
    this.bookingExtra.bIsDry = "N";
    this.bookingExtra.bVent = "N";
    this.model.bTsBooking = "N";
    this.model.bIsReserve = "N";
    this.model.bSocOperatorCode = "SA";
    this.model.bCocOperatorCode = "";
    this.model.bOutwardSlotOperatorCode = "";
    this.model.bPodTerminalName = "";
    this.model.bPolTerminalName = "";
    this.model.bPot1TerminalName = "";
    this.model.bPodTerminalCode = "";
    this.model.bPolTerminalCode = "";
    this.model.bPot1TerminalCode = "";
    this.cbPOT1Terminal.setForceValue("");
    this.cbLoadPortTerminal.setForceValue("");
    this.cbDischargePortTerminal.setForceValue("");
    $("#tsBkg").prop("checked", false);//gos 256

    //updated as per miki email per 20-march-2018
    this.currentContainerType = "STD";
    this.lockSTD = false;
    //update as per 10-april-2018
    this.cbCocOPR.clearSelect();
    this.cbCocOPR.listStore.store = [];
    this.cbCocOPR.url = "";

    // GSO-1063
    this.checkDisableSlotLc();

    //GOS 110

    this.fpAcctCodeCOC = "";
    this.fpAcctCodeSLOT = "";
    this.fpAcctCodeSOC = "";

    this.lockUC = false;

    this.lockOOG = true;

    this.fpAcctCode = "<< SOC: >>";
    if (
      this.currentContainerType == "OPT" ||
      this.currentContainerType == "PLT" ||
      this.currentContainerType == "FLR"
    ) {
      this.lockOOG = false;
      this.lockSTD = true;
    }

    this.lockReeferTemp = true;
    this.lockIsDry = true;
    if (this.currentContainerType == "REF") {
      this.lockReeferTemp = false;
      this.lockSTD = true;
    }
    this.dateReeferCollection.setDisable(this.lockReeferTemp);
    this.clearReeferDetailsField();

    if (this.cookieService.getDefaultLocationCode() == "SGSIN") {
      this.lockSOOPR1 = false;
      this.lockSOOPR2 = false;
    }

    this.custIdForCbFreightParty = "*";
    this.custIdForCbBooking = "*";
    this.custIdForCbShipper = "*";

    this.cbFreightParty.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerName={query}"
    );

    this.cbBookingParty.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/"+this.custIdForCbBooking+"/customerName={query}"
    );

    this.cbShipper.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerName={query}"
    );

    if (this.currentMenu.includes("(SOC)")) {
      this.SOCMenu();
    }

    if (this.currentMenu.includes("(COC)")) {
     this.COCMenu();
    }

    // GOS-722 Cannot edit "Remark" field
    this.isModify = true;
  }

  initTab1() {
    //this.gridNew.loadData();
    //this.grid.loadData();
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
  }

  initTab2() {
    //this.grid2.listStore.store = this.grid.listStore.store;
    //this.grid2.loadData();
    this.tabStatus = "tab2";
    this.tabStatus1 = "";
    this.tabStatus2 = "active";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
    // this.model.etaNextVessel = "";
    // this.model.etdNextVessel = "";
    // this.cbNextVoyage.disableCombo = false;

    if(this.model.bPot1Code == 'SGSIN' && this.model.bContainerOwnership == "COC"){
      const totalTeus = this.d20 as any + this.h20 + (this.d40 as any + this.d45 + this.h40 + this.h45)*2;
      let destinationPortTS: String = 'zzz';
      if(this.model.bPot2Code!=''){
        destinationPortTS=this.model.bPot2Code;
      }
      else if(this.model.bPodCode!=''){
        destinationPortTS=this.model.bPodCode;
      }
      
      this.settingNextVessel = {
        id: "cbNextVessel",
        type: "search enter", // search | select | select input
        url:
          this.configService.config.BASE_API.toString() +
          "/PortPairAllocation/findConnectingVesselAndVoyageForTSBooking/vesselName={query}",
        maxChars: 0,
        template: "grid", // default
        placeholder: "-- Next Vessel --",
        maxlength: 50,
        title: "nextVessel",
        description: "",
        isMark: true,
        columns: [
          { header: "Next Vessel", field: "nextVessel", width: 150 },
          { header: "Next Voyage", field: "nextVoyage", width: 200 },
          { header: "ETA(POT)", field: "eta(pot)", width: 50 },
          { header: "ETD(POT)", field: "etd(pot)", width: 50 },
        ],
        titleMessage: 'No Results',
        detailMessage: 'No available Next Vessel and Voyage',
        rowStyle:{
          "pinned": { "target" : "hoursDifference"}
        }
      };
      this.cbNextVessel.setUrl(
        this.configService.config.BASE_API.toString() +
        `/PortPairAllocation/findConnectingVesselAndVoyageForTSBooking/${this.model.bOfficeCode}/${this.model.bOceanVesselId}/${this.model.bOceanVesselVoyage}/${this.model.bOceanVesselBound}/${this.model.bPolCode ? this.model.bPolCode : 'zzz'}/${destinationPortTS}/${this.model.bPot1Code}/${totalTeus}/{query}/${this.model.bOfficeCode}/${this.model.bPrefix}/${this.model.bNo == '' ? '*':this.model.bNo}`);
    } else {
      this.settingNextVessel = {
        id: "cbNextVessel",
        type: "search enter", // search | select | select input
        //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*',
        url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
        maxChars: 0,
        template: "grid", // default
        placeholder: "-- Vessel --",
        title: "vesselName",
        description: "",
        isMark: true,
        columns: [
          { header: "Vessel Code", field: "vesselCode", width: 150 },
          { header: "Vessel Name", field: "vesselName", width: 200 },
          { header: "Valid", field: "isValid", width: 50 },
        ],
        maxlength: 50
      };
      this.cbNextVessel.setUrl(
        this.configService.config.BASE_API.toString() + "/MasterVessels/findByComboBoxControl/vesselName={query}"
      );
      this.cbNextVessel.columns = [
        { header: "Vessel Code", field: "vesselCode", width: 150 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ];
      this.cbNextVessel._settings.title = "vesselName";

      this.settingNextVoyage = {
        id: "cbNextVoyage",
        type: "search enter", // search | select | select input
        url: "",
        maxChars: 0,
        template: "grid", // default
        placeholder: "-- Voyage --",
        title: "voyage",
        description: "",
        isMark: true,
        columns: [
          { header: "Voyage", field: "voyage", width: 150 },
          { header: "Bound", field: "bound", width: 100 },
          { header: "Service", field: "serviceCode", width: 150 },
        ],
        maxlength: 20
      };
      this.cbNextVoyage.columns = [
        { header: "Voyage", field: "voyage", width: 150 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 150 },
      ];
      this.cbNextVoyage._settings.title = "voyage";
      this.cbNextVoyage.disableCombo = false;
    }
  }

  initTab3() {
    //this.grid3.listStore.store = this.grid.listStore.store;
    //this.grid3.loadthis.model();
    if (this.lock == false) {
      this.refreightProcess();
    } else {
      this.refreightProcessSilent();
    }
    this.tabStatus = "tab3";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "active";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
  }

  initTab4() {
    // this.grid4.listStore.store = this.grid.listStore.store;
    // this.grid4.loadData();
    this.tabStatus = "tab4";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "active";
    this.tabStatus5 = "";

    if (this.currentContainerType === "UC") {
      this.lockBtnContainerNew = true;
      this.lockSTD = true;
    } else {
      if (
        this.model.bContainerOwnership === "COC" ||
        this.model.bContainerOwnership === "COCTS"
      ) {
        console.log(
          "this.model.bCocCntrOperatorCode = " + this.model.bCocCntrOperatorCode
        );
        console.log(
          "this.model.bCocOperatorCode = " + this.model.bCocOperatorCode
        );
        if (
          this.model.bCocCntrOperatorCode === "SA" &&
          this.model.bCocOperatorCode === "SA"
        ) {
          this.lockBtnContainerNew = false;
        } else {
          this.lockBtnContainerNew = true;
        }
      } else if (
        this.model.bContainerOwnership === "SOC" ||
        this.model.bContainerOwnership === "SOCTS"
      ) {
        console.log(
          "this.model.bSocCntrOperatorCode = " + this.model.bSocCntrOperatorCode
        );
        console.log(
          "this.model.bSocOperatorCode = " + this.model.bSocOperatorCode
        );
        if (
          this.model.bSocCntrOperatorCode === "AX" &&
          this.model.bSocOperatorCode === "SA"
        ) {
          this.lockBtnContainerNew = false;
        } else {
          this.lockBtnContainerNew = true;
        }
      } else {
        this.lockBtnContainerNew = true;
      }
    }
  }

  initTab5() {
    //this.grid5.listStore.store = this.grid.listStore.store;
    //this.grid5.loadData();
    this.tabStatus = "tab5";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "active";
  }

  setContainerValueUC(
    list: Map<String, BookingDetail>
  ): Map<String, BookingDetail> {
    let det = new BookingDetail();
    det.bOfficeCode = this.model.bOfficeCode;
    det.bPrefix = this.model.bPrefix;
    det.bNo = this.model.bNo;
    det.bContainerType = this.currentContainerType;
    det.bContainerSize = "20";
    det.bContainerHeight = "86";
    det.bQuantity = 0;

    list.set("2086", det);

    return list;
  }

  setContainerValue(
    list: Map<String, BookingDetail>
  ): Map<String, BookingDetail> {
    if (this.d20 > 0) {
      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "20";
      det.bContainerHeight = "86";
      det.bQuantity = this.d20;

      list.set("2086", det);
    }
    if (this.d40 > 0) {
      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "40";
      det.bContainerHeight = "86";
      det.bQuantity = this.d40;

      list.set("4086", det);
    }
    if (this.d45 > 0) {
      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "45";
      det.bContainerHeight = "86";
      det.bQuantity = this.d45;

      list.set("4586", det);
    }
    if (this.h20 > 0) {
      //if(this.currentContainerType != 'TNK'){
      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "20";
      det.bContainerHeight = "96";
      det.bQuantity = this.h20;

      list.set("2096", det);
      //}else{
      //  this.h20 = 0;
      //}
    }
    if (this.h40 > 0) {
      //if(this.currentContainerType != 'TNK'){
      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "40";
      det.bContainerHeight = "96";
      det.bQuantity = this.h40;

      list.set("4096", det);
      //}else{
      //  this.h40 = 0;
      //}
    }
    if (this.h45 > 0) {
      //if(this.currentContainerType != 'TNK'){
      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "45";
      det.bContainerHeight = "96";
      det.bQuantity = this.h45;

      list.set("4596", det);
      //}else{
      //  this.h45 = 0;
      //}
    }
    return list;
  }

  setValidatorInit() {
    this.validatorRules = {
      allocationValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Allocation has not been set for this vessel, unable to create booking.",
          },
        ],
      },
    };
  }

  setValidatorReasonBeforeDelete() {
    this.validatorRules = {
      bReason: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Reason.",
          },
        ],
      },
    };
  }

  setValidatorNew() {
    this.clearValidatorError();
    this.validatorRules = {
      bOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      bOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      bOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      allocationValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Allocation has not been set for this vessel, unable to create booking.",
          },
        ],
      },
    };
  }

  setValidatorRetrieve() {
    this.clearValidatorError();
    const regChargeName = /^[a-zA-Z0-9]*$/;
    this.validatorRules = {
      bPrefix: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Prefix.",
          },
        ],
      },
      bNo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Booking No.",
          },
          {
            type: "modifiedPattern",
            pattern: regChargeName,
            prompt: "Booking Number only accept alphanumeric",
          },
        ],
      },
    };
  }

  setValidatorSave() {
    this.clearValidatorError();
    this.validatorRules = {
      bOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      bOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      bOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      bBookingPartyCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Booking Party.",
          },
        ],
      },
      bShipperCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Shipper.",
          },
        ],
      },
      bFreightPartyCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Freight Party.",
          },
        ],
      },
      bPorCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Receipt.",
          },
        ],
      },
      bPolCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Load Port.",
          },
        ],
      },
      bPodCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Discharge Port.",
          },
        ],
      },
      bPodlCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Delivery.",
          },
        ],
      },
      bReceiptType: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Receipt Type.",
          },
        ],
      },
      bDeliveryType: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Delivery Type.",
          },
        ],
      },
      rotationValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input rotation.",
          },
        ],
      },
      unitContainerValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input Unit Container.",
          },
        ],
      },
      allocationValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Allocation has not been set for this vessel, unable to create booking.",
          },
        ],
      },
      operatorCodeValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input Operator Code.",
          },
        ],
      },
      coctsExceedValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Allocation for COC T/S is exceeded.",
          },
        ],
      },
      cocLocalExceedValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Allocation for COC LOCAL is exceeded.",
          },
        ],
      },
      slotOperatorCodeValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Missing CNTR Code.",
          },
        ],
      },
      /*
      podAllocationValidator: {
        rules:[{
            type  : 'custom',
            prompt: 'Allocation of Discharge Port is not yet created. Unable to create booking.'
        }]
      },
      */
      bContactPerson: {
        rules: [
          {
            type: "empty",
            prompt: "Please input CTC PAX.",
          },
        ],
      },
      feederValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input Feeder Voyage.",
          },
        ],
      },
      bExMotherVessel: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      bExMotherVesselVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      NextVesselValidator: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      NextVoyageValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input Next Voyage.",
          },
        ],
      },
      ReeferDegreeValidator: {
        rules: [
          {
            type: "custom",
            prompt: `Please fill in valid reefer temperature.`,
          },
        ],
      },
      ReeferDegreeSOValidator: {
        rules: [
          {
            type: "custom",
            prompt: `Please fill in valid ${
              this.bookingExtra.bSetOption === "C" ? "Pre-cooling" : "Pre-set"
            } temperature.`,
          },
        ],
      },
      VentNumberValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please fill in valid ventilation value",
          },
        ],
      },
      ReeferCollectionDateValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please fill in reefer collection",
          },
        ],
      },
      POLPODValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Port of Discharge should not be identical with Port of Loading.",
          },
        ],
      },
      POLPOTValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Port of Transshipment 1 should not be identical with Port of Loading.",
          },
        ],
      },
      PODPOTValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Port of Discharge should not be identical with Port of Transshipment 1.",
          },
        ],
      },
      bookingPartyValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Booking Party is not compatible with Container Ownership.",
          },
        ],
      },
      POLPODDetDemValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Unable to save, POL and POD Free Time Cannot Exceed 60 days.",
          },
        ],
      },
      ucValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input UC Dimensions.",
          },
        ],
      },
      difUnitContainerValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Container booked VS container QTY under Container Information tab is not tally.",
          },
        ],
      },
      humidityValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please fill valid humidity value",
          },
        ],
      },
      reeferDryValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please fill in reefer collection date",
          },
        ],
      },
    };
  }

  clearValidatorError() {
    this.model["error-bOceanVesselBound"] = "";
    this.model["error-bOceanVesselCode"] = "";
    this.model["error-bOceanVesselVoyage"] = "";
    this.model["error-allocationValidator"] = "";
    this.model["error-bNo"] = "";
    this.model["error-bPrefix"] = "";
    this.model["error-bBookingPartyCode"] = "";
    this.model["error-bReason"] = "";
    this.model["error-bShipperCode"] = "";
    this.model["error-bFreightPartyCode"] = "";
    this.model["error-bPorCode"] = "";
    this.model["error-bPolCode"] = "";
    this.model["error-bPodCode"] = "";
    this.model["error-bPodlCode"] = "";
    this.model["error-bReceiptType"] = "";
    this.model["error-bDeliveryType"] = "";

    this.model["error-unitContainerValidator"] = "";
    this.model["error-operatorCodeValidator"] = "";
    this.model["error-SlotOperatorCodeValidator"] = "";
    this.model["error-coctsExceedValidator"] = "";
    this.model["error-cocLocalExceedValidator"] = "";
    this.model["error-rotationValidator"] = "";
    this.model["error-feederValidator"] = "";
    this.model["error-ExVoyageValidator"] = "";
    this.model["error-bExMotherVesselVoyage"] = "";
    this.model["error-NextVoyageValidator"] = "";
    this.model["error-ReeferDegreeValidator"] = "";
    this.model["error-ReeferDegreeSOValidator"] = "";
    this.model["error-ReeferCollectionDateValidator"] = "";
    this.model["error-VentNumberValidator"] = "";
    this.model["error-bContactPerson"] = "";
    this.model["error-POLPODValidator"] = "";
    this.model["error-POLPOTValidator"] = "";
    this.model["error-PODPOTValidator"] = "";
    this.model["error-bookingPartyValidator"] = "";
    this.model["error-ucValidator"] = "";
    this.model["error-difUnitContainerValidator"] = "";
    this.model["error-negativeValidator"] = "";
    this.model["error-degreeValidator"] = "";
    this.model["error-degreeSOValidator"] = "";
    this.model["error-humidityValidator"] = "";
    this.model["error-POLPODDetDemValidator"] = "";
    this.model["error-reeferDryValidator"] = "";

    this.model.allocationValidator = false;
    this.model.unitContainerValidator = false;
    this.model.operatorCodeValidator = false;
    this.model.coctsExceedValidator = false;
    this.model.cocLocalExceedValidator = false;
    this.model.podAllocationValidator = false;
    this.model.rotationValidator = false;
    this.model.feederValidator = false;
    this.model.ExVoyageValidator = false;
    this.model.NextVoyageValidator = false;
    this.model.ReeferDegreeValidator = false;
    this.model.ReeferDegreeSOValidator = false;
    this.model.ReeferCollectionDateValidator = false;
    this.model.VentNumberValidator = false;
    this.model.POLPODValidator = false;
    this.model.POLPOTValidator = false;
    this.model.PODPOTValidator = false;
    this.model.bookingPartyValidator = false;
    this.model.difUnitContainerValidator = false;
    this.model.humidityValidator = false;
    this.model.POLPODDetDemValidator = false;
    this.model.reeferDryValidator = false;
  }

  openBookingAllotment() {
    // console.log("** open booking allotment **");
    $("#sidebar-booking").addClass("visible");
    //$('#sidebar-booking').sidebar('toggle');
    $("#openBookingAllotment").css("display", "none");
    $("#loader")
      .css({ "z-index": "102", opacity: "0.5" })
      .removeClass("inverted")
      .addClass("active");
    $("#loader").find(".spinner").css("display", "none");
    this.getWeightProfiles(
      this.model.bOfficeCode,
      this.model.bOceanVesselId,
      this.model.bOceanVesselVoyage,
      this.model.bOceanVesselBound
    );
    this.getContainerProfiles(
      this.model.bOfficeCode,
      this.model.bOceanVesselId,
      this.model.bOceanVesselVoyage,
      this.model.bOceanVesselBound
    );
  }

  closeBookingAllotment() {
    // console.log('** close booking allotment **');
    $("#sidebar-booking").removeClass("visible");
    //$('#sidebar-booking').sidebar('toggle');
    $("#loader").find(".spinner").css("display", "block");
    $("#loader")
      .css({ "z-index": "60", opacity: "1" })
      .removeClass("active")
      .addClass("inverted");
    $("#openBookingAllotment").css("display", "block");
  }

  //message(txtIcon: String, txtHeader: String, txtContent: String, btns: String, eve:any){
  // this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
  //  this.dialog1.show(txtIcon,txtHeader,txtContent,btns,eve);
  // }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  changeEventTS(event) {
    if (event.target.checked) {
       // when clicked must rest the final dest and disabled
       this.cbFinalDest.setValue("");
       this.model.bFdestCode = "";
       this.model.bFdestTerminalCode = "";
      this.model.bTsBooking = "Y";


    } else {
      this.model.bTsBooking = "N";
    }
  }

  changeEventbSlotLC(event) {
    if (event.target.checked) {
      this.model.bSlotLC = "Y";


    } else {
      this.model.bSlotLC = "N";
    }
  }

  changeEventReserve(event) {
    if (event.target.checked) {
      this.model.bIsReserve = "Y";
      this.model.bPrefix = "RESV";
    } else {
      this.model.bIsReserve = "N";
      this.model.bPrefix = "SUDU";
    }
  }

  changeEventDry(event) {
    if (event.target.checked) {
      this.bookingExtra.bIsDry = "Y";
    } else {
      this.bookingExtra.bIsDry = "N";
    }
  }

  changeEventCbPrintPOD(event) {
    if (event.target.checked) {
      this.bookingExtra.bDetentionDemurragePodIsPrinted = "Y";
    } else {
      this.bookingExtra.bDetentionDemurragePodIsPrinted = "N";
    }
  }

  changeEventCbPrintPOL(event) {
    if (event.target.checked) {
      this.bookingExtra.bDetentionDemurragePolIsPrinted = "Y";
    } else {
      this.bookingExtra.bDetentionDemurragePolIsPrinted = "N";
    }
  }

  changeEventVessel(event) {
    // console.log('Change Event Vessel');
    // console.log(event);
    if (event.vesselId == null) {
      this.model.bOceanVesselId = "";
      this.model.bOceanVesselCode = "";
    } else {
      this.model.bOceanVesselId = event.vesselId;
      this.model.bOceanVesselCode = event.vesselCode;
      this.model["error-bOceanVesselCode"] = "";

      this.model.bOceanVesselBound = "O";
      this.cbVoyage.disableCombo = false;

      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+event.vesselId+'/'+this.model.bOceanVesselBound+'/{query}/' + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.bOceanVesselBound +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");

      this.model.ETA = "";
      this.model.ETD = "";
      this.model.ATA = "";
      this.model.ATD = "";

      this.serviceCode = "";
    }
  }
  changeEventVoyage(event) {
    console.log("** Change Event Voyage **");

    console.log(event);
    if (event.voyage == null) {
      this.model.bOceanVesselVoyage = "";
    } else {
      this.model.bOceanVesselVoyage = event.voyage;
      this.model["error-bOceanVesselVoyage"] = "";
      this.serviceCode = event.serviceCode;
      if (this.model.bPot1Code  != "" && this.model.bPot1Code  != undefined) {
          this.cbDischargePort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
            this.cookieService.getDefaultLocationCode() +
            "/" +
            this.model.bOceanVesselId +
            "/" +
            this.sCharUtil.htmlEncode(
              String(this.model.bOceanVesselVoyage).trim()
            ) +
            "/" +
            this.model.bOceanVesselBound +
            "/{query}"
        );
      } else {
        this.cbDischargePort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
            this.cookieService.getDefaultLocationCode()+"/"+
            this.model.bOceanVesselId+"/"+
            this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim())+"/"+
            this.model.bOceanVesselBound+"/{query}"
        );
      }

      this.cbPOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.bOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.bOceanVesselBound +
          "/{query}"
      );

      //another hack
      if (this.model.bNextMotherVesselVoyage != "") {
        this.bufferNextVoyage = this.model.bNextMotherVesselVoyage;
      }

      //this.getRotation(this.model.bOceanVesselId,this.model.bOceanVesselVoyage,this.model.bOceanVesselBound,this.cookieService.getDefaultLocationCode());

      this.genericUtil.showLoader();
      this.trigerInit = true;

      this.getAllocationAndEstimateArrivalDate(
        this.model.bOceanVesselId,
        this.model.bOceanVesselVoyage,
        this.model.bOceanVesselBound,
        this.cookieService.getDefaultLocationCode(),''
      ,()=>{
          // GSO-1029 when the ETA value changes, recalculate RELEASE AT DATE
          if(this.model.ETA && this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'){
            this.calculationReleaseAtDate();
          }
      });
      // when all done render the rotation
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/getPortOfRotationForBookingMaintenance/" +
          this.model.bOfficeCode +
          "/" +
          this.model.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim()) +
          "/" +
          this.model.bOceanVesselBound
        )
        .subscribe((resp) => {
          if (resp.json()["content"].length > 0) {
            this.rotation = resp.json()["content"][0]["rotation"];
            this.cleanRotation = resp.json()["content"][0]["cleanRotation"];
            this.canalRotation = resp.json()["content"][0]["hidenPortList"];
          }
        });
    }
  }

  gridEvent(event) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "click":
        break;

      default:
        let strEvent: String = event;
        if (strEvent != "") {
          let arr: String[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  gridAllocationEvent(event) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "click":
        break;

      case "afterLoad":
        // console.log('*** this is after load event **');

        if (this.grid.listStore.store.length > 0) {
          // console.log('defaultBooked : ' + this.grid.listStore.store[0]['booked']);
          this.defaultBooked = this.grid.listStore.store[0]["booked"];

          this.defaultBooked2 = "";
          this.grid.listStore.store.forEach((element) => {
            // console.log(element);
            if (element.allotmentCode != "VS") {
              this.defaultBooked2 = this.defaultBooked2 + element.booked + ";";
            }
          });

          // console.log('default booked 2 : ' + this.defaultBooked2);

          this.grid2.listStore.store = this.grid.listStore.store;
          this.grid2.loadData();

          this.grid3.listStore.store = this.grid.listStore.store;
          this.grid3.loadData();

          //this.grid4.listStore.store = this.grid.listStore.store;
          //this.grid4.loadData();

          this.grid5.listStore.store = this.grid.listStore.store;
          this.grid5.loadData();
        }

        var self = this;
        /*
        if(localStorage.getItem('new-booking-from-vessel-listing') != null){

          setTimeout(() => {

            self.model['allocationValidator'] = false;
            self.model['error-allocationValidator'] = '';
            // console.log('self.grid.listStore.store.length = ' + self.grid.listStore.store.length);
            if(self.grid.listStore.store.length == 0){
              self.lock = true;
              self.lockEdit = false;
              self.model['error-allocationValidator']='Allocation has not been set for this vessel, unable to create booking.';
              self.model['allocationValidator'] = true;
              self.isError = self.onValidate(self.model);
              // console.log('self.isError = ' + self.isError);
              if(!self.isError){
                self.isNew = true;
                self.lock = false;
                self.lockEdit = true;
                self.setValidatorSave();
                self.disableToolbarButtons = 'retrieve,new,modify,print,delete';
              }
            }else{
              self.disableToolbarButtons = 'retrieve,new,modify,print,delete';
            }

            localStorage.removeItem('new-booking-from-vessel-listing');
            self.genericUtil.hideLoader();

          }, 5000);

        }
        */

        //fired when booking was created through button new-booking
        /*
        if(localStorage.getItem('new-booking-from-flag') != null ){
          self.model['allocationValidator'] = false;
          self.model['error-allocationValidator'] = '';
          // console.log('self.grid.listStore.store.length = ' + self.grid.listStore.store.length);
          if(self.grid.listStore.store.length == 0){
            self.lock = true;
            self.lockEdit = false;
            self.model['error-allocationValidator']='Allocation has not been set for this vessel, unable to create booking.';
            self.model['allocationValidator'] = true;
            self.isError = self.onValidate(self.model);
            // console.log('self.isError = ' + self.isError);
            if(!self.isError){
              self.isNew = true;
              self.lock = false;
              self.lockEdit = true;
              self.setValidatorSave();
              self.disableToolbarButtons = 'retrieve,new,modify,print,delete';
            }
          }else{
            //this.disableToolbarButtons = 'retrieve,new,modify,print,delete';
          }

          localStorage.removeItem('new-booking-from-vessel-listing');
          self.genericUtil.hideLoader();
        }
        */
        break;

      default:
        let strEvent: String = event;
        if (strEvent != "") {
          let arr: String[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  onRetrieve() {
    this.setValidatorRetrieve();
    // console.log(this.model);
    this.handleRetrieve();
  }

  toolbarEvent(event: any) {
    // console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.onRetrieve();
        break;
      case "new":
        //console.log('isInit = ' + this.isInit);

        let currentDate = new Date();
        let etdDate = new Date();
        etdDate = new Date(this.model.ETD.toString());

        // console.log("ETD awal = " + etdDate);
        // console.log("ETD = " + this.addDays(etdDate, 9));
        // console.log("NOW = " + currentDate);
        currentDate = new Date(this.genericUtil.getPSIDate());
        this.custIdForCbBooking = "*";
        this.custIdForCbFreightParty = "*";
        this.custIdForCbShipper = "*";
        this.bookingMiscCheckboxPrint = false;
        this.isCheckedDepot = false;
        if (
          this.model.ETD != null &&
          this.model.ETD !== undefined &&
          this.model.ETD !== ""
        ) {
          //jira gmo-18 remove validation
          //if(this.addDays(etdDate,7) > currentDate){
          if (this.isInit) {
            console.log("#1");
            this.setValidatorNew();
            this.handleNew();
          } else {
            console.log("#2");
            this.isNew = true;
            this.isInit = false;
            this.initNew();
          }
          /* }else{
            this.dialogPlugin.show('information','Information', 'Unable to create booking because vessel ETD already exceeds 7 days from the current date.', 'okonly', { ok: 'this.loading=false;'});
          }*/
        } else {
          console.log("#3");
          this.setValidatorNew();
          this.handleNew();
        }

        //this.initNew();
        break;
      case "modify":
        // if(this.isPOLCanalPassing){
        //   this.dialogPlugin.show(
        //     "information",
        //     "Information",
        //     "The call purpose of this bookings is CANAL PASING, you may not modify this booking",
        //     "okonly",
        //     { ok: "/*this.handleCancel();*/" }
        //   );
        // } else {
          this.handleModify();
        //}
        /*
        let currentDate2 = new Date();
        let etdDate2 = new Date();
        etdDate2= new Date(this.model.ETD.toString());

        console.log('ETD awal = ' +etdDate2);
        console.log('ETD = ' +this.addDays(etdDate2,7));
        console.log('NOW = ' +currentDate2);
        currentDate2 = new Date(this.genericUtil.getPSIDate());
        if(this.model.ETD != null && this.model.ETD !== undefined && this.model.ETD !== ''){
          if(this.addDays(etdDate2,7) > currentDate2){
            this.handleModify();
          }else{
            this.dialogPlugin.show('information','Information', 'Unable to create booking because vessel ETD already exceeds 7 days from the current date.', 'okonly', { ok: 'this.loading=false;'});
          }
        }else{
          this.handleModify();
        }
        */
        break;
      case "delete":
        this.handleDelete();
        break;
      case "save":
        if (
          this.model.bExMotherVesselVoyage != null &&
          this.model.bExMotherVesselVoyage != "" &&
          this.model.bExMotherVesselVoyage != undefined &&
          this.model.bExMotherVesselId != "" &&
          this.model.bExMotherVesselId != undefined &&
          this.model.bExMotherVesselId != null
        ) {
          let hitUrl =
            this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/checkDuplicateBoundMFromBooking/" +
            this.model.bOfficeCode +
            "/" +
            this.model.bExMotherVesselId +
            "/" +
            this.sCharUtil.htmlEncode(
              this.model.bExMotherVesselVoyage.toUpperCase()
            ) +
            "/M";
          this.genericService.GET(hitUrl).subscribe((resp) => {
            if (resp.json()["status"].toUpperCase() == "OK") {
              //create bound M
              let msg: string = "";
              msg +=
                "Schedule for vessel <b>" +
                this.model.bExMotherVesselName +
                " " +
                this.model.bExMotherVesselVoyage.toUpperCase() +
                "</b> is not yet registered. <br />";
              msg += "System will now register this schedule?";

              this.dialogPlugin.show(
                "information",
                "Information",
                msg,
                "yesno",
                { yes: "this.registerBoundM();", no: "" }
              );
            } else {
              this.handleSave();
            }
          });
        } else {
          if (this.handleValidateExVoyageVessel()) return;
          this.handleSave();
        }

        break;
      case "copy":
        this.isCopy = true;
        if (
          this.model.bExMotherVesselVoyage != null &&
          this.model.bExMotherVesselVoyage != "" &&
          this.model.bExMotherVesselVoyage != undefined &&
          this.model.bExMotherVesselId != "" &&
          this.model.bExMotherVesselId != undefined &&
          this.model.bExMotherVesselId != null
        ) {
          let hitUrl =
            this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/checkDuplicateBoundMFromBooking/" +
            this.model.bOfficeCode +
            "/" +
            this.model.bExMotherVesselId +
            "/" +
            this.sCharUtil.htmlEncode(
              this.model.bExMotherVesselVoyage.toUpperCase()
            ) +
            "/M";
          this.genericService.GET(hitUrl).subscribe((resp) => {
            if (resp.json()["status"].toUpperCase() == "OK") {
              //create bound M
              let msg: string = "";
              msg +=
                "Schedule for vessel <b>" +
                this.model.bExMotherVesselName +
                " " +
                this.model.bExMotherVesselVoyage.toUpperCase() +
                "</b> is not yet registered. <br />";
              msg += "System will now register this schedule?";

              this.dialogPlugin.show(
                "information",
                "Information",
                msg,
                "yesno",
                { yes: "this.registerBoundM();", no: "" }
              );
            } else {
              this.handleSave();
            }
          });
        } else {
          if (this.handleValidateExVoyageVessel()) return;
          this.handleSave();
        }
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        /*
        if(this.strParams != ''){
          localStorage.setItem('close-booking-to-flag',this.strParams);
          this.router.navigateByUrl('/main/transaction-booking-flag');
        }else{
          this.router.navigateByUrl('/main/home');
        }
        */

        this.doUnlockByUser(
          this.model.bOfficeCode,
          this.cookieService.getName()
        );

        this.strParams = "";
        localStorage.removeItem("new-booking-from-flag");
        localStorage.removeItem("vessel-listing-last-searched");
        localStorage.removeItem("new-booking-from-vessel-listing");

        localStorage.removeItem("vessel-listing-last-searched-url");
        localStorage.removeItem("vessel-listing-last-searched-search");
        localStorage.removeItem("vessel-listing-last-searched-valPortLoadCode");
        localStorage.removeItem(
          "vessel-listing-last-searched-valTerminalLoadCode"
        );
        localStorage.removeItem(
          "vessel-listing-last-searched-valPortDischargeCode"
        );
        localStorage.removeItem(
          "vessel-listing-last-searched-valTerminalDischargeCode"
        );
        localStorage.removeItem("vessel-listing-last-searched-valBound");
        localStorage.removeItem("vessel-listing-last-searched-valPeriodFrom");
        localStorage.removeItem("vessel-listing-last-searched-valPeriodTo");
        localStorage.removeItem("vessel-listing-last-searched-valOfficeCode");

        this.router.navigateByUrl("/main/home");
        break;
      case "print":
        //$('#loader').css({'z-index':'50','margin-left':'19px','width':'calc(100% - 40px)'}).dimmer('show');
        if (this.model.bNo == "" && this.model.bPrefix == "") {
          this.dialogPlugin.show(
            "information",
            "Information",
            "please insert Bno ",
            "okonly",
            { ok: "" }
          );
        } else {
          this.loading = true;
          //this.genericUtil.showDialog('printDialog', 'B/L Print List', 350, 200);
          if (this.cookieService.getDefaultLocationCode().startsWith("ID")) {
            this.showDialogs("printDialog", this.newDialogOpt);
            this.openStack.init();
            this.clossingTime.init();
            $("#openStackTime").timeEntry({ show24Hours: true });
            $("#clossingTimeTime").timeEntry({ show24Hours: true });
          } else {
            this.printBookingConfirmation();
          }
          //this.printBookingConfirmation();
        }
        break;
      case "back":
        this.handleBack();
        break;
      case "sendDepot":
        this.handleSendDepot();
        break;
      default:
        let str: String = event;
        // console.log('str = ' + str);
        break;
    }
  }

  addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  changeEventBookingParty(event) {
    console.log("** change event Booking Party **");
    console.log(" socCntnrOperatorCode: "+this.model.bSocCntrOperatorCode);
    if (
      event.customerId != null &&
      event.customerId != "" &&
      event.customerId != undefined
    ) {
      this.model.bBookingPartyCode = event.customerId;
      this.model.bBookingPartyName = event.customerName;
      var self = this;

      this.cbSocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSocOperator/" +
          this.model.bBookingPartyCode
      );
      // this.cbSocOPR.loadSelect();

      this.cbSocOPR.loadSelect(function (clsocoper) {
        if (
          (self.model.bContainerOwnership == "SOC" ||
            self.model.bContainerOwnership == "SOCTS") &&
          self.cbSocOPR.listStore.store.length > 0
        ) {
          // console.log("before socCntnrOperatorCode: "+self.model.bSocCntrOperatorCode);
          let locationCode = self.cookieService.getDefaultLocationCode();
          var contOprAvailable = false;
          for (var i = 0;i<self.cbSocOPR.listStore.store.length;i++) {
            console.log("self.cbSocOPR.listStore.store[i][code]: "+self.cbSocOPR.listStore.store[i]["code"].toString());
            if (self.cbSocOPR.listStore.store[i]["code"].toString() == self.model.bSocCntrOperatorCode) {
              if (locationCode === "SGSIN") {
                if (self.cbSocOPR.listStore.store.length === 1) {
                  self.cbSocOPR.setValue(self.cbSocOPR.listStore.store[i]["code"]);
                  self.model.bSocCntrOperatorCode = self.cbSocOPR.listStore.store[i]["code"].toString();
                } else {
                  self.cbSocOPR.setValue("");
                  self.model.bSocCntrOperatorCode = "";
                }
              } else {
                self.model.bSocCntrOperatorCode = self.cbSocOPR.listStore.store[i]["code"].toString();
                self.cbSocOPR.setValue(self.cbSocOPR.listStore.store[i]["code"]);
              }
              contOprAvailable = true;
              break;
            }
          }

          if (!contOprAvailable) {
            if (locationCode === "SGSIN") {
              if (self.cbSocOPR.listStore.store.length === 1) {
                self.model.bSocCntrOperatorCode = self.cbSocOPR.listStore.store[0]["code"].toString();
                self.cbSocOPR.setValue(self.cbSocOPR.listStore.store[0]["code"]);
              } else {
                self.cbSocOPR.setValue("");
                self.model.bSocCntrOperatorCode = "";
              }
            } else {
              self.model.bSocCntrOperatorCode = self.cbSocOPR.listStore.store[0]["code"].toString();
              self.cbSocOPR.setValue(self.cbSocOPR.listStore.store[0]["code"]);
            }
          }
          // console.log("after socCntnrOperatorCode: "+self.model.bSocCntrOperatorCode);
        }
      });

      this.cbCocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCocOperator/" +
          this.model.bBookingPartyCode
      );
      //this.cbCocOPR.loadSelect();
      //var self= this;
      this.cbCocOPR.loadSelect(function (clcocoper) {
        if (
          (self.model.bContainerOwnership == "COC" ||
            self.model.bContainerOwnership == "COCTS") &&
          self.cbCocOPR.listStore.store.length > 0
        ) {

          var contOprAvailable = false;
          for (var i = 0;i<self.cbCocOPR.listStore.store.length;i++) {
            console.log("self.cbCocOPR.listStore.store[i][code]: "+self.cbCocOPR.listStore.store[i]["code"].toString());
            if (self.cbCocOPR.listStore.store[i]["code"].toString() == self.model.bCocCntrOperatorCode) {
              self.model.bCocCntrOperatorCode = self.cbCocOPR.listStore.store[i]["code"].toString();
              self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[i]["code"]);
              contOprAvailable = true;
              self.lockCOCOPR = false; self.lockSOOPR2 = false;
              break;
            }
          }

          if (!contOprAvailable) {
            self.model.bCocCntrOperatorCode = self.cbCocOPR.listStore.store[0]["code"].toString();
            self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[0]["code"]);
            self.lockCOCOPR = false; self.lockSOOPR2 = false;
          }
        }
      });
      this.model["error-operatorCodeValidator"] = "";

      //this.cbCocOPR.setValue(this.cbCocOPR.listStore.store[0]['code'].toString());

      this.cbSlotOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          this.model.bBookingPartyCode
      );
      this.cbSlotOPR.loadSelect();

      this.cbSlotCntrOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          this.model.bBookingPartyCode
      );
      this.cbSlotCntrOPR.loadSelect();

      this.lockSLOTOPR = false;
      this.lockSLOTCNTROPR = false;

      if (this.model.bShipperCode == "" && event.isShipper == "Y") {
        this.cbShipper.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlShipper/customerId={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerId={query}"
        );
        this.cbShipper.setValue(event.customerId, function (callbackShippers) {
          self.model.bShipperCode = callbackShippers.customerId;
          if (callbackShippers.isNvocc == "Y") {
            self.nvoccShipper = true;
          } else {
            self.nvoccShipper = false;
          }

          if (self.model.bShipmentType === "C") {
            self.cbShipper.setUrl(
              self.configService.config.BASE_API.toString() +
                // "/MasterCustomers/findByComboBoxControlShipper/customerName={query}"
                "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+self.custIdForCbShipper+"/customerName={query}"
            );
          } else {
            self.cbShipper.setUrl(
              self.configService.config.BASE_API.toString() +
                // "/MasterCustomers/findByComboBoxControlShipperCountryBased/" +
                // self.cookieService.getDefaultLocationCountry().toString() +
                // "/customerName={query}"
                "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+self.custIdForCbShipper+"/customerName={query}"+
                self.sCharUtil.encodeUrl("|")+
                "countryCode="+self.cookieService.getDefaultLocationCountry().toString()
            );
          }
          //self.cbShipper.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}');
        });
      }

      if (this.model.bFreightPartyCode == "" && event.isPayer == "Y") {
        this.cbFreightParty.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerId={query}"
        );
        this.cbFreightParty.setValue(
          event.customerId,
          function (callbackFreightParty) {
            self.model.bFreightPartyCode = callbackFreightParty.customerId;
            if (callbackFreightParty.isNvocc == "Y") {
              self.nvoccFreightParty = true;
            } else {
              self.nvoccFreightParty = false;
            }
            self.cbFreightParty.setUrl(
              self.configService.config.BASE_API.toString() +
                // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
                "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+self.custIdForCbFreightParty+"/customerName={query}"
            );
          }
        );
      }

      if (
        this.model.bFreightPartyCode == "" &&
        event.isNotifyParty == "Y" &&
        event.isShipper == "Y" &&
        event.isBookingParty == "Y" &&
        event.isConsignee == "Y"
      ) {
        // GOS-310
        // console.log("masuk masuk");
        // this.cbFreightParty.setUrl(
        //   this.configService.config.BASE_API.toString() +
        //     // "/MasterCustomers/findByComboBoxControlIncludeFreightParty/customerId={query}"
        //     "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerId={query}"
        // );
        // this.cbFreightParty.setValue(
        //   event.customerId,
        //   function (callbackFreightParty) {
        //     self.model.bFreightPartyCode = callbackFreightParty.customerId;
        //     if (callbackFreightParty.isNvocc == "Y") {
        //       self.nvoccFreightParty = true;
        //     } else {
        //       self.nvoccFreightParty = false;
        //     }
        //     self.cbFreightParty.setUrl(
        //       self.configService.config.BASE_API.toString() +
        //         // "/MasterCustomers/findByComboBoxControlIncludeFreightParty/customerName={query}"
        //         "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+self.custIdForCbFreightParty+"/customerName={query}"
        //     );
        //   }
        // );

        // console.log(this.model.bFreightPartyCode);
        // console.log(this.model["bFreightPartyCode"]);
        // console.log(this.model.bFreightPartyCode);
        // // Acct Code Freight Party
        // this.fpAcctCode = "<<";
        // if (event.isNvocc == "Y") {
        //   this.fpAcctCode += " NVOCC ";
        //   console.log("hore 2");
        // }
        // this.fpAcctCodeCOC = event["cocAccountCode"];
        // this.fpAcctCodeSOC = event["socAccountCode"];
        // this.fpAcctCodeSLOT = event["slotAccountCode"];
        // if (
        //   this.model.bContainerOwnership == "COC" ||
        //   this.model.bContainerOwnership == "COCTS"
        // ) {
        //   if (event["cocAccountCode"] !== "") {
        //     this.fpAcctCode += " COC: " + event["cocAccountCode"];
        //   } else {
        //     this.fpAcctCode += " COC: ";
        //   }
        // } else if (
        //   this.model.bContainerOwnership == "SOC" ||
        //   this.model.bContainerOwnership == "SOCTS"
        // ) {
        //   if (event["socAccountCode"] !== "") {
        //     this.fpAcctCode += " SOC: " + event["socAccountCode"];
        //   } else {
        //     this.fpAcctCode += " SOC: ";
        //   }
        // } else if (this.model.bContainerOwnership == "SLOT") {
        //   if (event["slotAccountCode"] !== "") {
        //     this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
        //   } else {
        //     this.fpAcctCode += " SLOT: ";
        //   }
        // }
        // this.fpAcctCode += " >>";
        // End of GOS-310

        let uri =
          this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerId="+
          event.customerId.toString();

        var self = this;
        this.genericService.GET(uri).subscribe((resp) => {
            if (resp.ok) {
              var datas = resp.json()['content'];
              if (datas.length != 0) {
                datas.forEach(function (data) {
                  self.model.bFreightPartyCode = data.customerId;
                  self.cbFreightParty.setForceValue(data.customerName);
                  if (data.isNvocc == "Y") {
                    self.nvoccFreightParty = true;
                  } else {
                    self.nvoccFreightParty = false;
                  }

                  self.cbFreightParty.setUrl(
                    self.configService.config.BASE_API.toString() +
                      // "/MasterCustomers/findByComboBoxControlIncludeFreightParty/customerName={query}"
                      "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+self.custIdForCbFreightParty+"/customerName={query}"
                  );

                  self.fpAcctCode = "<<";
                  if (event.isNvocc == "Y") {
                    self.fpAcctCode += " NVOCC ";
                  }
                  self.fpAcctCodeCOC = event["cocAccountCode"];
                  self.fpAcctCodeSOC = event["socAccountCode"];
                  self.fpAcctCodeSLOT = event["slotAccountCode"];
                  if (
                    self.model.bContainerOwnership == "COC" ||
                    self.model.bContainerOwnership == "COCTS"
                  ) {
                    if (event["cocAccountCode"] !== "") {
                      self.fpAcctCode += " COC: " + event["cocAccountCode"];
                    } else {
                      self.fpAcctCode += " COC: ";
                    }
                  } else if (
                    self.model.bContainerOwnership == "SOC" ||
                    self.model.bContainerOwnership == "SOCTS"
                  ) {
                    if (event["socAccountCode"] !== "") {
                      self.fpAcctCode += " SOC: " + event["socAccountCode"];
                    } else {
                      self.fpAcctCode += " SOC: ";
                    }
                  } else if (self.model.bContainerOwnership == "SLOT") {
                    if (event["slotAccountCode"] !== "") {
                      self.fpAcctCode += " SLOT: " + event["slotAccountCode"];
                    } else {
                      self.fpAcctCode += " SLOT: ";
                    }
                  }
                  self.fpAcctCode += " >>";
                });
              } else {
                self.cbFreightParty.setForceValue("");
                self.fpAcctCode = "";
              }
            }
        });
      }

      if (event.isNvocc == "Y") {
        this.nvoccBookingParty = true;
      } else {
        this.nvoccBookingParty = false;
      }

      if (event.isSoc == "Y") {
        this.isSocBookingParty = true;
      } else {
        this.isSocBookingParty = false;
      }

      if (event.isCoc == "Y") {
        this.isCocBookingParty = true;
      } else {
        this.isCocBookingParty = false;
      }

      if (event.isSlot == "Y") {
        this.isSlotBookingParty = true;
      } else {
        this.isSlotBookingParty = false;
      }

      // Contact Person
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterCustomers/findCustomerAddressByCustomerId/" +
           event.customerId+"/Y"
        )
        .subscribe((resp) => {
          let res: any[] = resp.json()["content"];
          console.log("res : "+res);
          console.log(res.length);
          let det: any[] = resp.json()["content"][0];
          if (res.length > 0) {
            if (
              det["customerContactPerson"] != undefined &&
              det["customerContactPerson"] != " "
            ) {
              this.model.bContactPerson = det["customerContactPerson"];
              // this.contactFromBooking = det["customerContactPerson"];
              this.attrCustChanged = true;
            }
            if (
              det["customerTelNo"] != undefined &&
              det["customerTelNo"] != " "
            ) {
              this.model.bTelephone = det["customerTelNo"];
              // this.telephoneFromBooking = det["customerTelNo"];
              this.attrCustChanged = true;
            }
          }
        });

      console.log("masuk 1");

      this.searchBkIsBookingParty = event.isBookingParty;
      this.searchBkIsValid = event.isValid;
    } else {
      console.log("masuk else");
      this.model.bBookingPartyCode = "";
      this.model.bBookingPartyName = "";
      this.nvoccBookingParty = false;
      this.isSocBookingParty = false;
      this.isCocBookingParty = false;
      this.isSlotBookingParty = false;
      // this.contactFromBooking = "";
      // this.telephoneFromBooking = "";
      if (this.model.bFreightPartyCode == "") {
        this.fpAcctCodeCOC = "";
        this.fpAcctCodeSLOT = "";
        this.fpAcctCodeSOC = "";
        this.fpAcctCode = "";
      } //GOS 110

      this.cbSocOPR.clearSelect();
      this.cbSocOPR.listStore.store = [];

      this.cbCocOPR.clearSelect();
      this.cbCocOPR.listStore.store = [];
      this.cbCocOPR.url = "";

      this.cbSlotOPR.clearSelect();
      this.cbSlotOPR.listStore.store = [];

      this.cbSlotCntrOPR.clearSelect();
      this.cbSlotCntrOPR.listStore.store = [];

      this.searchBkIsBookingParty = "";
      this.searchBkIsValid = "";
    }
    if (this.model.bShipmentType === "C") {
      this.cbBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlBookingParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/"+this.custIdForCbBooking+"/customerName={query}"
      );
    } else {
      this.cbBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlBookingPartyCountryBased/" +
          // this.cookieService.getDefaultLocationCountry().toString() +
          // "/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/"+this.custIdForCbBooking+"/customerName={query}"+
          this.sCharUtil.encodeUrl("|")+
          "countryCode="+this.cookieService.getDefaultLocationCountry().toString()
      );
    }
    //this.cbBookingParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlBookingParty/customerName={query}');
  }

  changeEventShipper(event) {
    console.log("** change event shipper **");
    // console.log(event);

    if (event.customerId != null) {
      var self = this;
      this.model.bShipperCode = event.customerId;
      this.model.bShipperName = event.customerName;
      console.log(this.model.bShipperName);

      if (this.model.bFreightPartyCode == "" && event.isPayer == "Y") {
        this.cbFreightParty.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerId={query}"
        );
        this.cbFreightParty.setValue(
          event.customerId,
          function (callbackFreightParty) {
            self.model.bFreightPartyCode = callbackFreightParty.customerId;
            if (callbackFreightParty.isNvocc == "Y") {
              self.nvoccFreightParty = true;
            } else {
              self.nvoccFreightParty = false;
            }
            self.cbFreightParty.setUrl(
              self.configService.config.BASE_API.toString() +
                // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
                "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+self.custIdForCbFreightParty+"/customerName={query}"
            );
          }
        );

        //GOS-110 patch if load freight party from shipper

        this.fpAcctCode = "<<";
        if (event.isNvocc == "Y") {
          this.fpAcctCode += " NVOCC ";
          console.log("hore 2");
        }
        this.fpAcctCodeCOC = event["cocAccountCode"];
        this.fpAcctCodeSOC = event["socAccountCode"];
        this.fpAcctCodeSLOT = event["slotAccountCode"];
        if (
          this.model.bContainerOwnership == "COC" ||
          this.model.bContainerOwnership == "COCTS"
        ) {
          if (event["cocAccountCode"] !== "") {
            this.fpAcctCode += " COC: " + event["cocAccountCode"];
          } else {
            this.fpAcctCode += " COC: ";
          }
        } else if (
          this.model.bContainerOwnership == "SOC" ||
          this.model.bContainerOwnership == "SOCTS"
        ) {
          if (event["socAccountCode"] !== "") {
            this.fpAcctCode += " SOC: " + event["socAccountCode"];
          } else {
            this.fpAcctCode += " SOC: ";
          }
        } else if (this.model.bContainerOwnership == "SLOT") {
          if (event["slotAccountCode"] !== "") {
            this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
          } else {
            this.fpAcctCode += " SLOT: ";
          }
        }
        this.fpAcctCode += " >>";

        //end patch
      }

      if (event.isNvocc == "Y") {
        this.nvoccShipper = true;
      } else {
        this.nvoccShipper = false;
      }

      this.searchShIsShipper = event.isShipper;
      this.searchShIsValid = event.isValid;
    } else {
      this.model.bShipperCode = "";
      this.model.bShipperName = "";
      this.searchShIsShipper = "";
      this.searchShIsValid = "";
      this.nvoccShipper = false;
    }
    //this.cbShipper.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}');
    if (this.model.bShipmentType === "C") {
      this.cbShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlShipper/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerName={query}"
      );
    } else {
      this.cbShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlShipperCountryBased/" +
          // this.cookieService.getDefaultLocationCountry().toString() +
          // "/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerName={query}"+
          this.sCharUtil.encodeUrl("|")+
          "countryCode="+this.cookieService.getDefaultLocationCountry().toString()
      );
    }
  }

  changeEventFreightParty(event) {
    console.log(event);
    //console.log(this.model.bContainerOwnership + "cacacaa")
    if (event.customerId != null) {
      this.model.bFreightPartyCode = event.customerId;
      if (event.isNvocc == "Y") {
        this.nvoccFreightParty = true;
      }

      this.fpAcctCode = "<<";
      if (this.nvoccFreightParty) {
        console.log("hore");
        this.fpAcctCode += " NVOCC ";
      }
      this.fpAcctCodeCOC = event["cocAccountCode"];
      this.fpAcctCodeSOC = event["socAccountCode"];
      this.fpAcctCodeSLOT = event["slotAccountCode"];

      if (
        this.model.bContainerOwnership == "COC" ||
        this.model.bContainerOwnership == "COCTS"
      ) {
        if (event["cocAccountCode"] !== "") {
          this.fpAcctCode += " COC: " + event["cocAccountCode"];
        } else {
          this.fpAcctCode += " COC: ";
        }
      } else if (
        this.model.bContainerOwnership == "SOC" ||
        this.model.bContainerOwnership == "SOCTS"
      ) {
        if (event["socAccountCode"] !== "") {
          this.fpAcctCode += " SOC: " + event["socAccountCode"];
        } else {
          this.fpAcctCode += " SOC: ";
        }
      } else if (this.model.bContainerOwnership == "SLOT") {
        if (event["slotAccountCode"] !== "") {
          this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
        } else {
          this.fpAcctCode += " SLOT: ";
        }
      }

      this.fpAcctCode += " >>";
      console.log("masuk");

      this.searchFpIsFreightParty = event.isFreightParty;
      this.searchFpIsValid = event.isValid;
    } else {
      this.model.bFreightPartyCode = "";
      this.nvoccFreightParty = false;
      this.fpAcctCode = "";
      this.searchFpIsFreightParty = "";
      this.searchFpIsValid = "";
    }
    this.cbFreightParty.setUrl(
      this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
        "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerName={query}"
    );
  }

  changeEventPOR(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPorCode = event.locationCode;

      if (event.hasTerminal == "Y") {
        this.lockPORTerminal = false;
      } else {
        this.lockPORTerminal = true;
      }
      this.cbPORTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbPORTerminal.setValue("");

      this.populateReceiptTypeExtra();
    } else {
      this.model.bPorCode = "";

      this.cbPORTerminal.setUrl("");
      this.cbPORTerminal.setValue("");
    }
  }

  changeEventPORTerminal(event) {
    if (event.locationCode != null) {
      this.model.bPorTerminalCode = event.locationCode;
    } else {
      this.model.bPorTerminalCode = "";
    }
  }

  changeEventLoadPort(event) {
    console.log(event);
    console.log("insinde LOAD PORT rotations");
    console.log(this.rotation);
    console.log(this.cleanRotation);
    if (event.locationCode != null) {
      console.log("#1");
      this.model.bPolCode = event.locationCode;
      console.log("bebfore assignnment > " + this.model.bPolBooking);
      this.model.bPolBooking = event.locationBookingRef;

      // GOS-653
      this.originalBookingRef = event.originalBookingRef;

      console.log("after assignnment > " + this.model.bPolBooking);
      this.model.etdPOL = event.sailDate;
      if (event.isValid == "Y") {
        this.model.bPodTerminalName = event.terminalName;
        this.cbLoadPortTerminal.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
        );
        this.cbLoadPortTerminal.setValue(event.terminalCode);
        this.cbLoadPortTerminal.loadSelect();
        this.model.bPolTerminalCode = event.terminalCode;
        this.model.bPolTerminalName = event.terminalName;
      } else {
        this.cbLoadPortTerminal.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
        );
        this.cbLoadPortTerminal.setValue("");
      }
      /**
       * CANAL PASSING not shown after GOS-134
       */

      if (event.hasTerminal === "Y") {
        this.lockPOLTerminal = false;
      } else {
        this.lockPOLTerminal = true;
      }

      this.populateReceiptTypeExtra();
      if(event.arrivalDate){
        this.model.ETA = event.arrivalDate ? event.arrivalDate.replace(/\//gi,"-") : "";
        // GSO-1029 when the ETA value changes, recalculate RELEASE AT DATE
        if(this.model.ETA && this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'){
          this.calculationReleaseAtDate();
        }
      }else{
        this.model.ETA = "";
      }

      this.model.ETD = event.sailDate ? event.sailDate.replace(/\//gi,"-") : "";
    } else {
      console.log("#2");
      this.cbLoadPort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
          // "/MasterLocations/findByComboBoxAllowedPOL/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.bOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.bOceanVesselBound +
          "/" +
          event
      );

      console.log(event);
      console.log("bebfore assignnment blank > " + this.model.bPolBooking);
      this.model.bPolCode = "";
      this.model.bPolBooking = "";
      console.log("bebfore assignnment not blank> " + this.model.bPolBooking);


      this.cbLoadPortTerminal.setUrl("");
      this.cbLoadPortTerminal.setValue("");
      this.model.bPolTerminalCode = "";
      this.model.bPolTerminalName = "";
    }
  }

  changeEventLoadPortTerminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPolTerminalCode = event.locationCode;
    } else {
      this.model.bPolTerminalCode = "";
    }
  }

  changeEventDischargePort(event) {
    console.log("** changeEventDischargePort **");
    console.log(event);

    var self = this;
    this.haveTerminal = "N";
    this.originLocation = "NNN";
    if (event.locationCode != null) {
      this.model.bPodBooking = event.locationBookingRef;

      if (event.terminalCode != "") {
        this.originLocation = event.originLocationCode;
        this.haveTerminal = "Y";
      }

      this.cbDischargePort.setForceValue(event.locationName);
      this.model.bPodCode = event.locationCode;
      this.model.bPodName = event.locationName;
      this.model.bPodTerminalName = event.terminalName;

      console.log("this.model.bPodCode = " + this.model.bPodCode);
      /*
      let terminalName:string = '';

      if(localStorage.getItem("new-booking-from-vessel-listing") != null){
        let strParams = localStorage.getItem("new-booking-from-vessel-listing");
        if(strParams != null){
          let par:any = JSON.parse(strParams);
          console.log(par)
          terminalName = par.terminalCodeDischargeName;
        }
      }else{
        terminalName = '';
      }

      if(terminalName == ''){
        if(this.model.bPodlCode == ''){
          this.cbPODL.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}')
          //this.cbPODL.setValue(event.locationCode,function (callbackPODL) {
          this.cbPODL.setValue(String(this.model.bPodCode),function (callbackPODL) {
              self.model.bPodlCode = event.locationCode;
            self.cbPODL.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}')
          });

          self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');


        }
      }else{
        if(this.model.bPodlCode == '' && event.terminalName == terminalName){
          this.cbPODL.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}')
          //this.cbPODL.setValue(event.locationCode,function (callbackPODL) {
          this.cbPODL.setValue(String(this.model.bPodCode),function (callbackPODL) {
              self.model.bPodlCode = event.locationCode;
            self.cbPODL.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}')
          });



          self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');

        }
      }
      */



      if (this.model.bPodlCode == "" || this.isSingaporeSite) {
          this.cbPODL.setUrl(
            this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}",
            // "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+this.model.bOfficeCode+"/"+this.model.bOceanVesselId+"/"+this.model.bOceanVesselVoyage+"/"+this.model.bOceanVesselBound+"/locationCode={query}"
          );
          //this.cbPODL.setValue(event.locationCode,function (callbackPODL) {
          this.cbPODL.setValue(
            String(this.model.bPodCode),
            function (callbackPODL) {
              self.model.bPodlCode = event.locationCode;
              self.cbPODL.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
                  // "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+this.model.bOfficeCode+"/"+this.model.bOceanVesselId+"/"+this.model.bOceanVesselVoyage+"/"+this.model.bOceanVesselBound+"/locationName={query}"
              );
            }
        );


        //console.log(this.cbPODL.getValue().locationName)
         //gos 256
            if ((this.model.bContainerOwnership == "SOC"  || this.model.bContainerOwnership == "SLOT") &&this.model.bPodCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){
              console.log('checked')
              $("#tsBkg").prop("checked", true);
               //gos 281
               this.model.bTsBooking = 'Y';

            }else{
              console.log('unchecked')
              $("#tsBkg").prop("checked", false);
               //gos 281
               this.model.bTsBooking = 'N';
            }
              console.log('llulu');
      }



      this.cbPOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.bOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.bOceanVesselBound +
          "/{query}"
      );
      /*
     // jangan auto populated
    if(this.prev == 'vessel-listing'){
      this.cbFinalDest.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}');
      //self.cbFinalDest.setValue(par.portofDischarge, function (callbackFDest) {
      self.cbFinalDest.setValue(String(self.model.bPodCode), function (callbackFDest) {
        self.model.bFdestCode = event.locationCode;
        self.cbFinalDest.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}');
      });
    }
    */

      if (event.hasTerminal == "Y") {
        this.lockPODTerminal = false;
      } else {
        this.lockPODTerminal = true;
      }

      if(event.terminalName){
        this.cbDischargePortTerminal.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterTerminals/findTerminalByLocation/" +
            event.locationId +
            "/{query}"
        );
        this.cbDischargePortTerminal.setForceValue(event.terminalName);
      }
      this.model.bPodTerminalCode = event.terminalCode;
      this.model.bPodTerminalName = event.locationName;
    } else {
      this.model.bPodCode = "";
      this.model.bPodName = "";
      this.model.bPodBooking = "";
      this.cbDischargePortTerminal.setUrl("");
      this.cbDischargePortTerminal.setValue("");
      this.model.bPodTerminalCode = "";
      this.model.bPodTerminalName = "";
    }

    setTimeout(function () {

      self.setDetail();

      //self.grid.setBody(self.model);
      //self.grid.loadData();

      // self.genericService
      //   .POST(
      //     self.configService.config.BASE_API.toString() +
      //       "/MasterBookings/calculateAllocation2",
      //     self.model
      //   )
      //   .subscribe((resp) => {
      //     let dataAlloc: any[] = resp.json()["content"];
      //     console.log(dataAlloc);
      //     self.grid.listStore.store = dataAlloc;
      //     self.grid.loadData();
      //   });
      self.loadDataAlloc();

      /*
      self.grid2.setBody(self.model);
      self.grid2.loadData();

      self.grid3.setBody(self.model);
      self.grid3.loadData();

      self.grid4.setBody(self.model);
      self.grid4.loadData();

      self.grid5.setBody(self.model);
      self.grid5.loadData();
      */
    }, 150);


  }

  changeEventDischargePortTerminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPodTerminalCode = event.locationCode;
    } else {
      this.model.bPodTerminalCode = "";
    }
  }

  changeEventPODL(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPodlCode = event.locationCode;

      if (event.hasTerminal == "Y") {
        this.lockPODLTerminal = false;
      } else {
        this.lockPODLTerminal = true;
      }

      this.cbPODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbPODLTerminal.setValue("");
    } else {
      this.model.bPodlCode = "";

      this.cbPODLTerminal.setUrl("");
      this.cbPODLTerminal.setValue("");
      this.model.bPodlTerminalCode = "";
    }

    if ((this.model.bContainerOwnership == "SOC" || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



      $("#tsBkg").prop("checked", true);
       //gos 281
       this.model.bTsBooking = 'Y';


    }else{
      $("#tsBkg").prop("checked", false);
       //gos 281
       this.model.bTsBooking = 'N';
    }


  }

  changeEventPODLTerminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPodlTerminalCode = event.locationCode;
    } else {
      this.model.bPodlTerminalCode = "";
    }
  }

  changeEventFinalDest(event) {
     console.log('ooioi');
     console.log($('#cbFinalDest').val());
    if (event.locationCode != null) {
      this.model.bFdestCode = event.locationCode;

      if ((this.model.bContainerOwnership == "SOC"  || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN'  && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



        $("#tsBkg").prop("checked", true);
         //gos 281
         this.model.bTsBooking = 'Y';


      }

      if (event.hasTerminal == "Y") {
        this.lockFDESTTerminal = false;
      } else {
        this.lockFDESTTerminal = true;
      }

      this.cbFinalDestTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbFinalDestTerminal.setValue("");
    } else {

      this.model.bFdestCode = "";
      this.cbFinalDestTerminal.setUrl("");
      this.cbFinalDestTerminal.setValue("");

      if($('#cbFinalDest').val()== '')
      {
        if ((this.model.bContainerOwnership == "SOC"  || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN'  && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



          $("#tsBkg").prop("checked", true);
           //gos 281
           this.model.bTsBooking = 'Y';


        }

      }else{

      $("#tsBkg").prop("checked", false);
       //gos 281
       this.model.bTsBooking = 'N';
      }

    }
   // console.log(this.cbFinalDest.getValue().locationName)
    //console.log(this.cbFinalDest.getValue().length)
    // if ((this.model.bContainerOwnership == "SOC"  || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN'  && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



    //   $("#tsBkg").prop("checked", true);
    //    //gos 281
    //    this.model.bTsBooking = 'Y';


    // }else{
      // $("#tsBkg").prop("checked", false);
      //  //gos 281
      //  this.model.bTsBooking = 'N';
   // }


  }

  changeEventFinalDestTerminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bFdestTerminalCode = event.locationCode;
    } else {
      this.model.bFdestTerminalCode = "";
    }
  }

  changeEventSpecialDet(event) {
    // console.log(event);
    if (event.specialDetailId != null) {
      this.model.bSpecialDetailCode = event.specialDetailId;
    } else {
      this.model.bSpecialDetailCode = "";
    }
    this.cbSpecialDet.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSpecialDetails/findByComboBoxControl/specialDetailCodeOrName={query}"
    );
  }

  changeEventPOT1(event) {
    console.log(event);
    console.log("change event pot fired");
    this.haveTerminal = "N";
    this.originLocation = "NNN";
    if (event.locationCode != null) {
      if (event.terminalCode != "") {
        this.haveTerminal = "Y";
        this.originLocation = event.originLocationCode;
      }

      this.cbPOT1.setForceValue(event.locationName);
      this.model.bPot1Code = event.locationCode;
      this.model.bPot1Name = event.locationName;
      this.model.bPot1TerminalName = event.terminalName;
      this.model.bPot1TerminalCode = event.terminalCode;
      this.cbDischargePortTerminal.setValue("");
      this.model.bPodTerminalCode = "";
      this.model.bPodTerminalName = "";
      // this.model.bPodlCode = "";

      if (event.hasTerminal == "Y") {
        this.lockPOT1Terminal = false;
      } else {
        this.lockPOT1Terminal = true;
      }

      this.cbPOT1Terminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbPOT1Terminal.setForceValue(event.terminalName);
      if (this.model.bPot1Code  != "" && this.model.bPot1Code  != undefined) {
        this.cbDischargePort.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}"
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
        );
      } else {
        this.cbDischargePort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
            this.cookieService.getDefaultLocationCode()+"/"+
            this.model.bOceanVesselId+"/"+
            this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim())+"/"+
            this.model.bOceanVesselBound+"/{query}"
        );
      }


      if (this.model.bNo === "" || this.model.bNo === null) {
        this.cbDischargePort.setValue("");
        this.model.bPodCode = "";
      }
      this.cbNextVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.bNextMotherVesselId +
          "/" +
          this.model.bOceanVesselBound +
          "/{query}/" +
          event.locationCode
      );

      this.lockNextVessel = false;
    } else {
      this.model.bPot1Code = "";
      this.model.bPot1Name = "";
      this.cbPOT1Terminal.setUrl("");
      this.cbPOT1Terminal.setValue("");
      this.model.bPot1TerminalCode = "";
      this.model.bPot1TerminalName = "";

      if (this.model.bPot1Code  != "" && this.model.bPot1Code  != undefined) {
        this.cbDischargePort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
            this.cookieService.getDefaultLocationCode() +
            "/" +
            this.model.bOceanVesselId +
            "/" +
            this.sCharUtil.htmlEncode(
              String(this.model.bOceanVesselVoyage).trim()
            ) +
            "/" +
            this.model.bOceanVesselBound +
            "/{query}"
        );
      } else {
        this.cbDischargePort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
            this.cookieService.getDefaultLocationCode()+"/"+
            this.model.bOceanVesselId+"/"+
            this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim())+"/"+
            this.model.bOceanVesselBound+"/{query}"
        );
      }


      if (this.model.bNo === "" || this.model.bNo === null) {
        this.cbDischargePort.setValue("");
        this.model.bPodCode = "";
      }

      this.lockNextVessel = true;
      this.cbNextVessel.setValue("");
      this.cbNextVoyage.setValue("");

      this.model.bNextMotherVesselId = "";
      this.model.bNextMotherVesselCode = "";
      this.model.bNextMotherVesselVoyage = "";
      this.cbDischargePortTerminal.setValue("");
      this.model.bPodTerminalCode = "";
      this.model.bPodTerminalName = "";
    }

    if ((this.model.bContainerOwnership == "SOC" || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == "" && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



      $("#tsBkg").prop("checked", true);
       //gos 281
       this.model.bTsBooking = 'Y';


    }else{
      $("#tsBkg").prop("checked", false);
       //gos 281
       this.model.bTsBooking = 'N';
    }

  }

  changeEventPOT1Terminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPot1TerminalCode = event.locationCode;
    } else {
      this.model.bPot1TerminalCode = "";
    }
  }

  changeEventPOT2(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPot2Code = event.locationCode;

      if (event.hasTerminal == "Y") {
        this.lockPOT2Terminal = false;
      } else {
        this.lockPOT2Terminal = true;
      }

      this.cbPOT2Terminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbPOT2Terminal.setValue("");
    } else {
      this.model.bPot2Code = "";

      this.cbPOT2Terminal.setUrl("");
      this.cbPOT2Terminal.setValue("");
    }
    this.cbPOT2.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );

    if ((this.model.bContainerOwnership == "SOC" || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



      $("#tsBkg").prop("checked", true);
       //gos 281
       this.model.bTsBooking = 'Y';


    }else{
      $("#tsBkg").prop("checked", false);
       //gos 281
       this.model.bTsBooking = 'N';
    }
  }

  changeEventPOT2Terminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPot2TerminalCode = event.locationCode;
    } else {
      this.model.bPot2TerminalCode = "";
    }
  }

  changeEventPOT3(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPot3Code = event.locationCode;

      if (event.hasTerminal == "Y") {
        this.lockPOT3Terminal = false;
      } else {
        this.lockPOT3Terminal = true;
      }

      this.cbPOT3Terminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbPOT3Terminal.setValue("");
    } else {
      this.model.bPot3Code = "";

      this.cbPOT3Terminal.setUrl("");
      this.cbPOT3Terminal.setValue("");
    }
    this.cbPOT3.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );

    if ((this.model.bContainerOwnership == "SOC" || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



      $("#tsBkg").prop("checked", true);
       //gos 281
       this.model.bTsBooking = 'Y';


    }else{
      $("#tsBkg").prop("checked", false);
       //gos 281
       this.model.bTsBooking = 'N';
    }
  }

  changeEventPOT3Terminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPot3TerminalCode = event.locationCode;
    } else {
      this.model.bPot3TerminalCode = "";
    }
  }

  changeEventCommodity(event) {
    // console.log(event);
    if (
      event.commodityId != null &&
      event.commodityId !== "" &&
      event.commodityId !== undefined
    ) {
      this.model.bCommodityId = event.commodityId;
      this.model.bCommodityCode = event.commodityId;
    } else {
      this.model.bCommodityId = "";
      this.model.bCommodityCode = "";
    }
    this.cbCommodity.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}"
    );
  }

  changeEventReceiptType(event) {
    console.log(event);
    console.log(event.length)
    console.log(this.cbReceiptType)
    if(event.length < 2) {
      return;
    }

    if (event.receiptTypeCode != null) {
      this.model.bReceiptType = event.receiptTypeCode;
    } else {
      this.model.bReceiptType = "";
    }
    this.cbReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}"
    );
  }

  changeEventDeliveryType(event) {
    // console.log(event);
    if (event.deliveryTypeCode != null) {
      this.model.bDeliveryType = event.deliveryTypeCode;
    } else {
      this.model.bDeliveryType = "";
    }
    this.cbDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}"
    );
  }

  changeEventFeederVessel(event) {
    // console.log(event);
    if (event.vesselId == null) {
      this.model.bFeederVesselId = "";
      this.model.bFeederVesselCode = "";
    } else {
      this.model.bFeederVesselId = event.vesselId;
      this.model.bFeederVesselCode = event.vesselCode;

      this.cbFeederVoyage.disableCombo = false;
      this.cbFeederVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          event.vesselId +
          "/" +
          this.model.bOceanVesselBound +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbFeederVoyage.setValue("");
    }
  }

  changeEventFeederVoyage(event) {
    // console.log(event);
    if (event.voyage == null) {
      this.model.bFeederVesselVoyage = "";
    } else {
      this.model.bFeederVesselVoyage = event.voyage;
    }
  }

  changeEventSupplier(event) {
    // console.log(event);
    if (event.customerId != null) {
      this.model.bSupplierCode = event.customerId;
      this.model.bSupplierName = event.customerName;
    } else {
      this.model.bSupplierCode = "";
      this.model.bSupplierName = "";
    }
    this.cbSupplier.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlSupplier/customerName={query}"
    );
  }

  changeEventExVessel(event) {
    // console.log(event);
    this.checkIdenticalVesVoy(
      "bNextMotherVesselId",
      event.vesselId,
      "Ex Vessel & Next Vessel can't have identical value."
    )
      .then(() => {
        if (event.vesselId == null) {
          this.undefineExVessel = "";
          this.model.bExMotherVesselId = "";
          this.model.bExMotherVesselCode = "";
          this.model.bExMotherVesselName = "";
        }
        if (event.vesselId == undefined) {
          //gos-779
          this.undefineExVessel = "";
        } else {
          this.undefineExVessel = "";
          this.model.bExMotherVesselId = event.vesselId;
          this.model.bExMotherVesselCode = event.vesselCode;
          this.model.bExMotherVesselName = event.vesselName;
        }
      })
      .catch(() => {
        this.undefineExVessel = "";
        this.model.bExMotherVesselId = "";
        this.model.bExMotherVesselCode = "";
        this.model.bExMotherVesselName = "";
        this.cbExVessel.setValue("");
      });
  }

  changeEventExVoyage(event) {
    // console.log('** change event ex voyage **');
    if (event.voyage == null) {
      this.model.bExMotherVesselVoyage = "";
    } else {
      this.model.bExMotherVesselVoyage = event.voyage;
    }
  }

  changeEventNextVessel(event) {
    if (event.title == null) {
      this.model.bNextMotherVesselId = "";
      this.model.bNextMotherVesselCode = "";
      this.model.bNextMotherVesselVoyage = "";
      this.model.bNextMotherVesselBound = "";
      this.model['etaNextVessel'] = "";
      this.model['etdNextVessel'] = "";
      this.cbNextVoyage.setForceValue("");
    } else {
      if (this.model.bContainerOwnership == 'COC' && this.model.bPot1Code != '') {
        console.log(event.nextVesselId);

        this.model.bNextMotherVesselId = event.nextVesselId;
        this.model.bNextMotherVesselCode = event.nextVesselCode;
        this.model.bNextMotherVesselVoyage = event.nextVoyage;
        this.model.bNextMotherVesselBound = event.nextVesselBound;
        this.model['etaNextVessel'] = event['eta(pot)'];
        this.model['etdNextVessel'] = event['etd(pot)'];
        this.cbNextVoyage.setForceValue(event.nextVoyage);
        this.cbNextVoyage.disableCombo = true;
      } else {
        // this.model.bNextMotherVesselVoyage = event.nextVoyage;
        // this.model.bNextMotherVesselBound = event.nextVesselBound;
        // this.cbNextVoyage.setForceValue(event.nextVoyage);

        /*
        this.cbNextVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
            this.cookieService.getDefaultLocationCode() +
            "/" +
            event.vesselId +
            "/" +
            this.model.bOceanVesselBound +
            "/voyage_like={query}"
        );
         */
          this.cbNextVoyage.setUrl(
            this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyage/" +
            event.vesselId +
            "/" +
            this.model.bOceanVesselBound +
            "/{query}/" +
            this.model.bPot1Code
          );
        this.model.bNextMotherVesselId = event.vesselId;
        this.model.bNextMotherVesselCode = event.vesselCode;
        // this.model['etaNextVessel'] = this.model['ETA'];
        // this.model['etdNextVessel'] = this.model['ETD'];
      }
    }
  }

  changeEventNextVoyage(event) {
    this.checkIdenticalVesVoy(
      "bExMotherVesselVoyage",
      event.voyage,
      "Ex Voyage & Next Voyage can't have identical value."
    )
      .then(() => {
        if (
          event.voyage != null &&
          event.voyage != undefined &&
          event.voyage != ""
        ) {
          this.cbNextVoyage.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/distinctVoyage/" +
              this.model["bNextMotherVesselId"] +
              "/" +
              this.model.bOceanVesselBound +
              "/{query}/" +
              this.model.bPot1Code
          );
          console.log(
            "Value Next Voayage before assignment " +
              this.model.bNextMotherVesselVoyage
          );
          this.model.bNextMotherVesselVoyage = event.voyage;
          console.log(this.model);
          // this.model['etaNextVessel'] = event['eta(pot)'];
          // this.model['etdNextVessel'] = event['etd(pot)'];
          this.model.etaNextVessel = this.model.ETA;
          this.model.etdNextVessel = this.model.ETD;
          console.log(
            "Value Next Voayage after assignment " +
              this.model.bNextMotherVesselVoyage
          );
        } else {
          console.log(
            "*Value Next Voayage before assignment " +
              this.model.bNextMotherVesselVoyage
          );
          this.model.bNextMotherVesselVoyage = "";
          console.log(
            "*Value Next Voayage after assignment " +
              this.model.bNextMotherVesselVoyage
          );
        }
      })
      .catch(() => {
        this.model.bNextMotherVesselVoyage = "";
        this.cbNextVoyage.setValue("");
        this.model.etaNextVessel = "";
        this.model.etdNextVessel = "";
      });
  }

  changeEventHaulier(event) {
    // console.log(event);
    if (
      event.customerId != null &&
      event.customerId != "" &&
      event.customerId != undefined
    ) {
      this.bookingMisc.bHaulierId = event.customerId;
      if (this.bookingMisc.bContactPerson == "") {
        this.bookingMisc.bContactPerson = event.contactInfo;
      }
    } else {
      this.bookingMisc.bHaulierId = "";
    }
    this.cbHaulier.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlHaulier/customerName={query}"
    );
  }

  changeEventReturnTo(event) {
    // console.log(event);
    if (event.depoCode != null) {
      this.model.bReturnToCode = event.depoCode;
    } else {
      this.model.bReturnToCode = "";
    }
  }

  changeEventPackedAt(event) {
    // console.log(event);
    if (event.depoCode != null) {
      this.model.bPackedAtCode = event.depoCode;
    } else {
      this.model.bPackedAtCode = "";
    }
  }

  changeEventPackageType(event) {
    // console.log(event);
    if (event.packageTypeCode != null) {
      this.model.bPackageTypeCode = event.packageTypeCode;
    } else {
      this.model.bPackageTypeCode = "";
    }
  }

  changeEventShipmentType(event) {
    this.model.bShipmentType = event.target.value;
    if (this.model.bShipmentType == "C") {
      this.isCRB = true;
    } else {
      this.isCRB = false;
    }
  }

  changeEventIhc(event) {
    this.model.bIhc = event.target.value;
  }

  changeEventFreightType(event) {
    this.model.bFreightType = event.target.value;
  }

  changeEventDGClass(event) {
    this.model.bDgClass = event.target.value;

    if (this.model.bDgClass == "N") {
      if (this.gridContainerInfo.listStore.store.length > 0) {
        for (
          let i = 0;
          i < this.gridContainerInfo.listStore.store.length;
          i++
        ) {
          let bContainerInfo: BookingContainerInfo =
            this.gridContainerInfo.listStore.store[i];
          bContainerInfo.bIMO = "";
          bContainerInfo.bUNNO = "";
          bContainerInfo.bDGRef = "";
          bContainerInfo.bFlashPoint = 0.0;
          bContainerInfo.bFlashPointScale = "";

          this.gridContainerInfo.listStore.updateData(i, bContainerInfo);
        }
      }
    }
  }

  changeEventDegree(event) {
    if (event.target.value != null) {
      let val = Number.parseInt(event.target.value);
      if (isNaN(val)) {
        this.bookingExtra.bReeferDegree = "";
      } else {
        this.bookingExtra.bReeferDegree =
          this.bDegree.toString() + this.bDegreeTemp;
      }
    } else {
      this.bookingExtra.bReeferDegree = "";
    }
    // console.log(this.bookingExtra.bReeferDegree);
  }

  changeEventDegreeSO(event) {
    if (event.target.value != null) {
      let val = Number.parseInt(event.target.value);
      if (isNaN(val)) {
        this.bookingExtra.bDegreeSo = "";
      } else {
        this.bookingExtra.bDegreeSo =
          this.bDegreeSO.toString() + this.bDegreeTempSO;
      }
    } else {
      this.bookingExtra.bDegreeSo = "";
    }
    // console.log(this.bookingExtra.bReeferDegree);
  }

  onSelectDegreeTemp(val) {
    this.bDegreeTemp = val;
    if (this.bookingExtra.bReeferDegree != "") {
      this.bookingExtra.bReeferDegree =
        this.bDegree.toString() + this.bDegreeTemp;
    }
    // console.log(this.bookingExtra.bReeferDegree);
  }

  onSelectDegreeTempSO(val) {
    this.bDegreeTempSO = val;
    if (this.bookingExtra.bDegreeSo != "") {
      this.bookingExtra.bDegreeSo =
        this.bDegreeSO.toString() + this.bDegreeTempSO;
    }
    // console.log(this.bookingExtra.bReeferDegree);
  }

  onSelectVentOption(val) {
    this.bookingExtra.bVentOption = val;
  }

  onSelectNatureTemp(val) {
    this.bookingExtra.bNatureTemp = val;
    // console.log(this.model.bReeferDegree);
  }

  changeEventWeightClass(event) {
    this.model.bWeightClass = event.target.value;
    this.model.bWeight = 0;
    if (event.target.value == "C") {
      this.lockWeight = false;
    } else {
      this.lockWeight = true;
    }

    if (event.target.value == "N") {
      this.model.bContainerStatus = "EMPTY";
    } else {
      this.model.bContainerStatus = "FULL";
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();

    /*
    this.grid2.setBody(this.model);
    this.grid2.loadData();

    this.grid3.setBody(this.model);
    this.grid3.loadData();

    this.grid4.setBody(this.model);
    this.grid4.loadData();

    this.grid5.setBody(this.model);
    this.grid5.loadData();
    */
  }

  changeEventWeight(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.model.bWeight = 0;
    }

    if (val < 0) {
      val = 0;
      this.model.bWeight = 0;
    }
  }

  changeEventKillSlot(event) {
    // Kill Slot is numeric and can be input 1-3 chars only. ( Numeric )
    let value = event.target.value;
    if (value.length > 3){
      value = value.slice(0, 3);
      this.killSlot = value.slice(0, 3);
    }

    let val = Number.parseInt(value);
    if (val && isNaN(val)) {
      val = 0;
      this.killSlot = 0;
    }

    if (val && val < 0) {
      val = 0;
      this.killSlot = 0;
    }

    // console.log(this.model.bookingDetails.length)
    if (this.model.bookingDetails.length > 0) {
      this.setDetail();
      //this.grid.setBody(this.model);
      //this.grid.loadData();
      this.loadDataAlloc();
    }
  }

  changeEventDoorOpen(event) {
    this.doorOpen = event.target.value;
  }

  changeEventSetOption(event) {
    this.bookingExtra.bSetOption = event.target.value;
  }

  changeEventVent(event) {
    this.bookingExtra.bVent = event.target.value;
    if (this.bookingExtra.bVent == "O") {
      this.lockVentNumber = false;
    } else if (this.bookingExtra.bVent == "N") {
      this.lockVentNumber = true;
    } else {
      this.bookingContainerInfo.bVent = "";
      this.lockVentNumber = true;
      this.bookingExtra.bVentNumber = 0;
    }
  }

  eventChangeVentNumber(event) {
    let total = this.bookingExtra.bVentOption === 'C' ? 180 : 100;
    if (event && event <= total) {
      this.isError = false;
      this.model['error-VentNumberValidator'] = '';
    } else if (event > total) {
      this.isError = true;
      this.model['error-VentNumberValidator'] = 'Vent value must be lower than or equal ' + total;
    }
    else {
      this.isError = true;
      this.model['error-VentNumberValidator'] = 'Please insert a valid vent unit';
    }
  }

  changeEventSocOPR(event) {
    // console.log('** change event SOC OPR **');
    this.previousOperatorCode = this.model.bCocCntrOperatorCode.toString();

    if (event.code != null) {
      // console.log(event.code);
      this.model.bSocCntrOperatorCode = event.code;
      this.bookingContainerInfo.bContOperator = event.code;
      if (this.gridContainerInfo.listStore.store.length > 0) {
        if (event.code === "AX") {
          this.gridContainerInfo.url = "";
          for (
            let i = 0;
            i < this.gridContainerInfo.listStore.store.length;
            i++
          ) {
            this.gridContainerInfo.listStore.store[i].bContOperator =
              event.code;
          }
          this.gridContainerInfo.loadData();
        } else {
          this.genericUtil.showDialog(
            "bkgConfirmChangeOperatorCodeSOC",
            "Information",
            350,
            150
          );
        }
      }
    } else {
      console.log("change soc cntr operator code");
      if (this.retrieveSocContainerOperatorCode == "" && this.retrieveSocContainerOperatorCode == null) {
        this.model.bSocCntrOperatorCode = "";
      }
    }
  }

  changeEventCocOPR(event) {
    // console.log('** change event COC OPR **');
    this.previousOperatorCode = this.model.bCocCntrOperatorCode.toString();

    if (event.code != null) {
      // console.log(event.code);

      this.model.bCocCntrOperatorCode = event.code;

      this.bookingContainerInfo.bContOperator = event.code;
      if (this.gridContainerInfo.listStore.store.length > 0) {
        if (event.code === "SA") {
          this.gridContainerInfo.url = "";
          for (
            let i = 0;
            i < this.gridContainerInfo.listStore.store.length;
            i++
          ) {
            this.gridContainerInfo.listStore.store[i].bContOperator =
              this.model.bCocCntrOperatorCode;
          }
          this.gridContainerInfo.loadData();
        } else {
          this.genericUtil.showDialog(
            "bkgConfirmChangeOperatorCode",
            "Information",
            350,
            150
          );
        }
      }
    } else {
      if (this.retrieveCocContainerOperatorCode == "" && this.retrieveCocContainerOperatorCode == null) {
        this.model.bCocCntrOperatorCode = "";
      }
    }
  }

  changeEventSlotOPR(event) {
    // console.log('** change event slot opr **');
    if (event.code != null) {
      // console.log(event.code);
      this.model.bSlotOperatorCode = event.code;

      if (this.cookieService.getDefaultLocationCode() != "SGSIN") {
        this.model.bOutwardSlotOperatorCode = event.code;
        this.cbSlotCntrOPR.setValue(
          String(this.model.bOutwardSlotOperatorCode)
        );
      }
    } else {
      this.model.bSlotOperatorCode = "";
    }
    this.bookingContainerInfo.bContOperator = event.code;
    if (this.gridContainerInfo.listStore.store.length > 0) {
      this.gridContainerInfo.url = "";
      for (let i = 0; i < this.gridContainerInfo.listStore.store.length; i++) {
        this.gridContainerInfo.listStore.store[i].bContOperator = event.code;
      }
      this.gridContainerInfo.loadData();
    }
    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  changeEventSlotCntrOPR(event) {
    // console.log('** change event slot opr **');
    if (event.code != null) {
      // console.log(event.code);
      this.model.bOutwardSlotOperatorCode = event.code;
    } else {
      this.model.bOutwardSlotOperatorCode = "";
    }
    /*
    this.bookingContainerInfo.bContOperator = event.code;
    if(this.gridContainerInfo.listStore.store.length > 0){
      this.gridContainerInfo.url = '';
      for(let i=0; i<this.gridContainerInfo.listStore.store.length; i++){
        this.gridContainerInfo.listStore.store[i].bContOperator = event.code;
      }
      this.gridContainerInfo.loadData();
    }
    */
    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  changeEventOH(event) {
    /*
    this.OH = event.target.checked;
    if(this.OH){
      this.lockOH = false;
    }else{
      this.lockOH = true;
      this.heightMeasure = 0;
    }
     */
    if (event.target.checked) {
      this.OH = "Y";
      this.lockOH = false;
    } else {
      this.OH = "N";
      this.lockOH = true;
      this.heightMeasure = 0;
    }
  }

  changeEventOW(event) {
    /*
    this.OW = event.target.checked;
    if(this.OW){
      this.lockOW = false;
    }else{
      this.lockOW = true;
      this.leftMeasure = 0;
      this.rightMeasure = 0;
    }
    */
    if (event.target.checked) {
      this.OW = "Y";
      this.lockOW = false;
    } else {
      this.OW = "N";
      this.lockOW = true;
      this.leftMeasure = 0;
      this.rightMeasure = 0;
    }
  }

  changeEventOL(event) {
    /*
    this.OL = event.target.checked;
    if(this.OL){
      this.lockOL = false;
    }else{
      this.lockOL = true;
      this.frontMeasure = 0;
      this.endMeasure = 0;
    }
    */
    if (event.target.checked) {
      this.OL = "Y";
      this.lockOL = false;
    } else {
      this.OL = "N";
      this.lockOL = true;
      this.frontMeasure = 0;
      this.endMeasure = 0;
    }
  }

  changeEventHeightMeasure(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.heightMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.heightMeasure = 0;
    }
  }

  changeEventLeftMeasure(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.leftMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.leftMeasure = 0;
    }
  }

  changeEventRightMeasure(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.rightMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.rightMeasure = 0;
    }
  }

  changeEventFrontMeasure(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.frontMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.frontMeasure = 0;
    }
  }

  changeEventEndMeasure(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.endMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.endMeasure = 0;
    }
  }

  changeEventDetention(event) {
    this.model.bDetention = event.target.value;
    if (this.model.bDetention == "R") {
      this.lockDetention = false;
    } else {
      this.lockDetention = true;
      this.model.bDetentionRequest = 0;
    }
  }

  changeEventDetentionPOL(event) {
    this.bookingExtra.bDetentionPol = event.target.value;
    if(this.model.ETA && this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'){
      this.calculationReleaseAtDate();
    }
    if (this.bookingExtra.bDetentionPol == "R") {
      this.lockDetentionPol = false;
    } else {
      this.lockDetentionPol = true;
      this.bookingExtra.bDetentionRequestPol = 0;
    }
    // this.calculateReleaseDateWhenTypeDry();
  }

  changeEventDemurragePol(event) {
    this.bookingExtra.bDemurragePol = event.target.value;
    if (this.bookingExtra.bDemurragePol == "R") {
      this.lockDemurragePol = false;
    } else {
      this.lockDemurragePol = true;
      this.bookingExtra.bDemurrageRequestPol = 0;
    }
  }

  changeEventDetentionReq(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.model.bDetentionRequest = 0;
    }

    if (val < 0) {
      val = 0;
      this.model.bDetentionRequest = 0;
    }
  }

  changeEventDemurrage(event) {
    this.model.bDemurrage = event.target.value;
    if (this.model.bDemurrage == "R") {
      this.lockDemurrage = false;
    } else {
      this.lockDemurrage = true;
      this.model.bDemurrageRequest = 0;
    }
  }

  changeEventDemurrageReq(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.model.bDemurrageRequest = 0;
    }

    if (val < 0) {
      val = 0;
      this.model.bDemurrageRequest = 0;
    }
  }

  changeEventContainerOwnershipX(containerOwnerShip) {
    //Reset Relese at
    this.cbReleaseAt.setValue("");
    this.bookingMisc.bReleaseAt = "";
    // console.log("contianerowner");
     console.log(this.model.bContainerOwnership);
     console.log(this.model.bPodlCode);
     console.log(this.model.bFdestCode);
     //console.log(this.cbFinalDest.getValue());
     //console.log(this.cbFinalDest.getValue().length)
    // console.log(this.cbPODL.getValue())
    // console.log(event.target.value);
    // console.log(event);

    this.model.bContainerOwnership = containerOwnerShip;
    if ( this.model.bContainerOwnership == "SOC" ||  this.model.bContainerOwnership == "SOCTS" ) {
      this.lockSOCOPR = false;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
      this.cbSlotOPR.clearSelect();
      this.cbSlotCntrOPR.clearSelect();

      this.model.bSocOperatorCode = "SA";
      this.model.bCocOperatorCode = "";
      this.model.bCocCntrOperatorCode = "";
      this.model.bOutwardSlotOperatorCode = "";
      this.model.bSlotOperatorCode = "";

      this.model.bDetention = "N";
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = "N";
      this.model.bDemurrageRequest = 0;

      this.bookingExtra.bDetentionPol = "N";
      this.bookingExtra.bDetentionRequestPol = 0;
      this.bookingExtra.bDemurragePol = "N";
      this.bookingExtra.bDemurrageRequestPol = 0;

      this.lockDetention = true;
      this.lockDemurrage = true;
      this.lockDetentionPol = true;
      this.lockDemurragePol = true;

      //reset coc
      this.cbCocOPR.setValue("");
    } else if ( this.model.bContainerOwnership == "COC") {
      this.lockSOCOPR = true;
      this.lockCOCOPR = false;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
      this.cbSocOPR.clearSelect();
      this.cbSlotOPR.clearSelect();
      this.cbSlotCntrOPR.clearSelect();

      this.model.bSocOperatorCode = "";
      this.model.bCocOperatorCode = "SA";
      this.model.bCocCntrOperatorCode = "";

      this.cbCocOPR.clearSelect();
      this.cbCocOPR.listStore.store = [];

      //this.cbCocOPR.setValue('SA');
      var self = this;
      this.cbCocOPR.loadSelect(function (clcocoper) {
        if (self.cbCocOPR.listStore.store.length > 0) {
          self.model.bCocCntrOperatorCode =
            self.cbCocOPR.listStore.store[0]["code"].toString();
          self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[0]["code"]);
        }
      });

      /*
      this.cbCocOPR.setValue(this.cbCocOPR.listStore.store[0]['code'].toString(),function(cbCntrOpr){
        self.model.bCocCntrOperatorCode = self.cbCocOPR.listStore.store[0]['code'].toString();
        self.model['error-operatorCodeValidator'] = '';
        // console.log('COC OPR ' + self.model.bCocCntrOperatorCode );
      });
      */

      this.model.bOutwardSlotOperatorCode = "";
      this.model.bSlotOperatorCode = "";

      this.model.bDetention = "S";
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = "S";
      this.model.bDemurrageRequest = 0;

      this.bookingExtra.bDetentionPol = "S";
      this.bookingExtra.bDetentionRequestPol = 0;
      this.bookingExtra.bDemurragePol = "S";
      this.bookingExtra.bDemurrageRequestPol = 0;

      this.lockDetention = true;
      this.lockDemurrage = true;
      this.lockDetentionPol = true;
      this.lockDemurragePol = true;
    } else if ( this.model.bContainerOwnership == "COCTS") {
      this.lockSOCOPR = true;
      this.lockCOCOPR = false;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
      this.cbSocOPR.clearSelect();
      this.cbSlotOPR.clearSelect();
      this.cbSlotCntrOPR.clearSelect();

      this.model.bSocOperatorCode = "";
      this.model.bCocOperatorCode = "SA";
      this.model.bCocCntrOperatorCode = "";

      this.cbCocOPR.clearSelect();
      this.cbCocOPR.listStore.store = [];

      var self = this;
      this.cbCocOPR.loadSelect(function (clcocoper) {
        if (self.cbCocOPR.listStore.store.length > 0) {
          self.model.bCocCntrOperatorCode =
            self.cbCocOPR.listStore.store[0]["code"].toString();
          self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[0]["code"]);
        }
      });

      this.model.bOutwardSlotOperatorCode = "";
      this.model.bSlotOperatorCode = "";

      this.model.bDetention = "N";
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = "N";
      this.model.bDemurrageRequest = 0;

      this.bookingExtra.bDetentionPol = "N";
      this.bookingExtra.bDetentionRequestPol = 0;
      this.bookingExtra.bDemurragePol = "N";
      this.bookingExtra.bDemurrageRequestPol = 0;

      this.lockDetention = true;
      this.lockDemurrage = true;
      this.lockDetentionPol = true;
      this.lockDemurragePol = true;
    } else if (this.model.bContainerOwnership == "SLOT") {
      if (this.currentContainerType == "UC") {
        this.currentContainerType = "STD";
        this.lockSTD = false;
        this.lockUC = false;
        this.model["error-unitContainerValidator"] = "";
      } else {
        this.lockSOCOPR = true;
        this.lockCOCOPR = true;
        this.lockSLOTOPR = false;
        this.lockSLOTCNTROPR = false;
        this.cbSocOPR.clearSelect();

        this.model.bSocOperatorCode = "";
        this.model.bCocOperatorCode = "";
        this.model.bCocCntrOperatorCode = "";
        this.model.bOutwardSlotOperatorCode = "";
        this.model.bSlotOperatorCode = "";

        this.model.bDetention = "N";
        this.model.bDetentionRequest = 0;
        this.model.bDemurrage = "N";
        this.model.bDemurrageRequest = 0;

        this.bookingExtra.bDetentionPol = "N";
        this.bookingExtra.bDetentionRequestPol = 0;
        this.bookingExtra.bDemurragePol = "N";
        this.bookingExtra.bDemurrageRequestPol = 0;

        this.lockDetention = true;
        this.lockDemurrage = true;
        this.lockDetentionPol = true;
        this.lockDemurragePol = true;

        //reset coc
        this.cbCocOPR.setValue("");
      }
    } else {
      this.lockSOCOPR = true;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
    }

    this.bookingDetails.clear();
    let list = new Map<String, BookingDetail>();
    this.bookingDetails.set(
      this.currentContainerType,
      this.setContainerValue(list)
    );

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();

    this.isCOCLocalExceed = false;
    if (this.model.bContainerOwnership == "COC") {
      var check: Boolean = this.checkCocLocalExceedForLabel();
      // console.log('checkCocLocalExceed ==> ')
      // console.log(check)
      if (!check) {
        this.isCOCLocalExceed = true;
      }

      // KHUSUS SITE SINGAPORE & COC LOCAL RELEASE AT ENG KONG
      if (this.cookieService.getDefaultLocationCode() == "SGSIN") {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/LocalMasterDepos/findGeneral/SGSIN/Y/*/*/1/10/ASC/depoName"
          )
          .subscribe((resp) => {
            const dtx = resp.json()["content"];
            if (dtx.length == 1) {
              this.cbReleaseAt.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/LocalMasterDepos/findByComboBoxControl/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/depoId={query}"
              );
              this.cbReleaseAt.setValue(dtx[0]["depoId"]); // GSO-116,
            } else if (dtx.length > 1) {
              //do nothing at the moment
            } else if (dtx.length <= 0) {
              this.cbReleaseAt.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/LocalMasterDepos/findByComboBoxControl/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/depoName={query}"
              );
              this.cbReleaseAt.setValue("");
              this.bookingMisc.bReleaseAt = "";
            }
          });

        //this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoId={query}');
        //this.cbReleaseAt.setValue('DEPSINSFLT'); // GSO-116,hard locked to eng kong
        //this.cbReleaseAt.setForceValue("ENG KONG CONTAINER AGENCIES PTE LTD CTC: MR JAMES ONG T: 6863 6023")
        //this.bookingMisc.bReleaseAt = 'ENG KONG CTR - SIN'
      }
    }

    /*
    this.grid2.setBody(this.model);
    this.grid2.loadData();

    this.grid3.setBody(this.model);
    this.grid3.loadData();

    this.grid4.setBody(this.model);
    this.grid4.loadData();

    this.grid5.setBody(this.model);
    this.grid5.loadData();
    */

    // soc, coc. slot
    console.log("mari kita lihat");
    console.log(this.fpAcctCodeCOC);
    console.log(this.fpAcctCodeSOC);
    console.log(this.fpAcctCodeSLOT);
    console.log(this.nvoccBookingParty);
    console.log(this.nvoccFreightParty);
    console.log(this.nvoccShipper);

    this.fpAcctCode = "<<";
    if (this.nvoccFreightParty) {
      console.log("hore");
      this.fpAcctCode += " NVOCC ";
    }
    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      if (this.fpAcctCodeCOC !== "") {
        this.fpAcctCode += " COC: " + this.fpAcctCodeCOC;
      } else {
        this.fpAcctCode += " COC: ";
      }
    } else if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      if (this.fpAcctCodeSOC !== "") {
        this.fpAcctCode += " SOC: " + this.fpAcctCodeSOC;
      } else {
        this.fpAcctCode += " SOC: ";
      }
    } else if (this.model.bContainerOwnership == "SLOT") {
      if (this.fpAcctCodeSLOT !== "") {
        this.fpAcctCode += " SLOT: " + this.fpAcctCodeSLOT;
      } else {
        this.fpAcctCode += " SLOT: ";
      }
    }
    this.fpAcctCode += " >>";

    if ((this.model.bContainerOwnership == "SOC" || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



      $("#tsBkg").prop("checked", true);
       //gos 281
       this.model.bTsBooking = 'Y';


    }else{
      $("#tsBkg").prop("checked", false);
       //gos 281
       this.model.bTsBooking = 'N';
    }


  }

  async changeEventContainerOwnership(event) {
    //Reset Relese at
    this.bookingMisc.bReleaseAt = "";
    this.clearReleaseAtDate();
    this.clearReeferCollectionDate();
    // console.log("contianerowner");
     console.log(this.model.bContainerOwnership);
     console.log(this.model.bPodlCode);
     console.log(this.model.bFdestCode);
     //console.log(this.cbFinalDest.getValue());
     //console.log(this.cbFinalDest.getValue().length)
    // console.log(this.cbPODL.getValue())
    // console.log(event.target.value);
    // console.log(event);



    this.model.bContainerOwnership = event.target.value;
    if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      this.lockSOCOPR = false;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
      this.cbSlotOPR.clearSelect();
      this.cbSlotCntrOPR.clearSelect();

      this.model.bSocOperatorCode = "SA";
      this.model.bCocOperatorCode = "";
      this.model.bCocCntrOperatorCode = "";
      this.model.bOutwardSlotOperatorCode = "";
      this.model.bSlotOperatorCode = "";

      this.model.bDetention = "N";
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = "N";
      this.model.bDemurrageRequest = 0;

      this.bookingExtra.bDetentionPol = "N";
      this.bookingExtra.bDetentionRequestPol = 0;
      this.bookingExtra.bDemurragePol = "N";
      this.bookingExtra.bDemurrageRequestPol = 0;

      this.lockDetention = true;
      this.lockDemurrage = true;
      this.lockDetentionPol = true;
      this.lockDemurragePol = true;

      //reset coc
      this.cbCocOPR.setValue("");
      //GOS-482 reset CNTR OPR
      if(this.cookieService.getDefaultLocationCode() === "SGSIN"){
        if(this.cbSocOPR.listStore.store.length === 1){
          this.cbSocOPR.setValue(this.cbSocOPR.listStore.store[0]["code"]);
          this.model.bSocCntrOperatorCode = this.cbSocOPR.listStore.store[0]["code"];
        }else{
          this.cbSocOPR.setValue("");
          this.model.bSocCntrOperatorCode = "";
        }
      }
    } else if (
      this.model.bContainerOwnership == "COC"
    ) {
      this.lockSOCOPR = true;
      this.lockCOCOPR = false;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
      this.cbSocOPR.clearSelect();
      this.cbSlotOPR.clearSelect();
      this.cbSlotCntrOPR.clearSelect();

      this.model.bSocOperatorCode = "";
      this.model.bCocOperatorCode = "SA";
      this.model.bCocCntrOperatorCode = "";

      this.cbCocOPR.clearSelect();
      this.cbCocOPR.listStore.store = [];

      //this.cbCocOPR.setValue('SA');
      var self = this;
      this.cbCocOPR.loadSelect(function (clcocoper) {
        if (self.cbCocOPR.listStore.store.length > 0) {
          self.model.bCocCntrOperatorCode =
            self.cbCocOPR.listStore.store[0]["code"].toString();
          self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[0]["code"]);
        }
      });

      /*
      this.cbCocOPR.setValue(this.cbCocOPR.listStore.store[0]['code'].toString(),function(cbCntrOpr){
        self.model.bCocCntrOperatorCode = self.cbCocOPR.listStore.store[0]['code'].toString();
        self.model['error-operatorCodeValidator'] = '';
        // console.log('COC OPR ' + self.model.bCocCntrOperatorCode );
      });
      */

      this.model.bOutwardSlotOperatorCode = "";
      this.model.bSlotOperatorCode = "";

      this.model.bDetention = "S";
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = "S";
      this.model.bDemurrageRequest = 0;

      this.bookingExtra.bDetentionPol = "S";
      this.bookingExtra.bDetentionRequestPol = 0;
      this.bookingExtra.bDemurragePol = "S";
      this.bookingExtra.bDemurrageRequestPol = 0;

      this.lockDetention = true;
      this.lockDemurrage = true;
      this.lockDetentionPol = true;
      this.lockDemurragePol = true;
      // this.calculateReleaseDateWhenTypeDry();
    } else if (
      this.model.bContainerOwnership == "COCTS"
    ) {
      this.lockSOCOPR = true;
      this.lockCOCOPR = false;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
      this.cbSocOPR.clearSelect();
      this.cbSlotOPR.clearSelect();
      this.cbSlotCntrOPR.clearSelect();

      this.model.bSocOperatorCode = "";
      this.model.bCocOperatorCode = "SA";
      this.model.bCocCntrOperatorCode = "";

      this.cbCocOPR.clearSelect();
      this.cbCocOPR.listStore.store = [];

      var self = this;
      this.cbCocOPR.loadSelect(function (clcocoper) {
        if (self.cbCocOPR.listStore.store.length > 0) {
          self.model.bCocCntrOperatorCode =
            self.cbCocOPR.listStore.store[0]["code"].toString();
          self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[0]["code"]);
        }
      });

      this.model.bOutwardSlotOperatorCode = "";
      this.model.bSlotOperatorCode = "";

      this.model.bDetention = "N";
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = "N";
      this.model.bDemurrageRequest = 0;

      this.bookingExtra.bDetentionPol = "N";
      this.bookingExtra.bDetentionRequestPol = 0;
      this.bookingExtra.bDemurragePol = "N";
      this.bookingExtra.bDemurrageRequestPol = 0;

      this.lockDetention = true;
      this.lockDemurrage = true;
      this.lockDetentionPol = true;
      this.lockDemurragePol = true;
    } else if (this.model.bContainerOwnership == "SLOT") {
      if (this.currentContainerType == "UC") {
        this.currentContainerType = "STD";
        this.lockSTD = false;
        this.lockUC = false;
        this.model["error-unitContainerValidator"] = "";
      } else {
        this.lockSOCOPR = true;
        this.lockCOCOPR = true;
        this.lockSLOTOPR = false;
        this.lockSLOTCNTROPR = false;
        this.cbSocOPR.clearSelect();

        this.model.bSocOperatorCode = "";
        this.model.bSocCntrOperatorCode = "";
        this.model.bCocOperatorCode = "";
        this.model.bCocCntrOperatorCode = "";
        this.model.bOutwardSlotOperatorCode = "";
        this.model.bSlotOperatorCode = "";

        this.model.bDetention = "N";
        this.model.bDetentionRequest = 0;
        this.model.bDemurrage = "N";
        this.model.bDemurrageRequest = 0;

        this.bookingExtra.bDetentionPol = "N";
        this.bookingExtra.bDetentionRequestPol = 0;
        this.bookingExtra.bDemurragePol = "N";
        this.bookingExtra.bDemurrageRequestPol = 0;

        this.lockDetention = true;
        this.lockDemurrage = true;
        this.lockDetentionPol = true;
        this.lockDemurragePol = true;

        //reset coc
        this.cbCocOPR.setValue("");
      }
    } else {
      this.lockSOCOPR = true;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
    }

    this.bookingDetails.clear();
    let list = new Map<String, BookingDetail>();
    this.bookingDetails.set(
      this.currentContainerType,
      this.setContainerValue(list)
    );

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();

    this.isCOCLocalExceed = false;
    if (this.model.bContainerOwnership == "COC") {
      var check: Boolean = this.checkCocLocalExceedForLabel();
      // console.log('checkCocLocalExceed ==> ')
      // console.log(check)
      if (!check) {
        this.isCOCLocalExceed = true;
      }

      // KHUSUS SITE SINGAPORE & COC LOCAL RELEASE AT ENG KONG
      if (this.cookieService.getDefaultLocationCode() == "SGSIN") {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/LocalMasterDepos/findGeneral/SGSIN/Y/*/*/1/10/ASC/depoName"
          )
          .subscribe((resp) => {
            const dtx = resp.json()["content"];
            if (dtx.length == 1) {
              this.cbReleaseAt.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/LocalMasterDepos/findByComboBoxControl/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/depoId={query}"
              );
              this.cbReleaseAt.setValue(dtx[0]["depoId"]); // GSO-116,
            } else if (dtx.length > 1) {
              //do nothing at the moment
            } else if (dtx.length <= 0) {
              this.cbReleaseAt.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/LocalMasterDepos/findByComboBoxControl/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/depoName={query}"
              );
              this.cbReleaseAt.setValue("");
              this.bookingMisc.bReleaseAt = "";
            }
          });

        //this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoId={query}');
        //this.cbReleaseAt.setValue('DEPSINSFLT'); // GSO-116,hard locked to eng kong
        //this.cbReleaseAt.setForceValue("ENG KONG CONTAINER AGENCIES PTE LTD CTC: MR JAMES ONG T: 6863 6023")
        //this.bookingMisc.bReleaseAt = 'ENG KONG CTR - SIN'
      }
    }

    if(this.model.ETA && this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'){
      if (this.currentContainerType == "REF") {
        // GSO-1029: Release date same as Reefer Collection date
        // Alternative for *dateReleaseAt.setValue()*
        this.setDatePluginValue(this.settingDateReleaseAt.id, await this.getDatePluginValue(this.settingReeferCollection.id));
      } else {
        this.calculationReleaseAtDate();
      }
    }else{
      this.clearReleaseAtDate();
    }

    /*
    this.grid2.setBody(this.model);
    this.grid2.loadData();

    this.grid3.setBody(this.model);
    this.grid3.loadData();

    this.grid4.setBody(this.model);
    this.grid4.loadData();

    this.grid5.setBody(this.model);
    this.grid5.loadData();
    */

    // soc, coc. slot
    console.log("mari kita lihat");
    console.log(this.fpAcctCodeCOC);
    console.log(this.fpAcctCodeSOC);
    console.log(this.fpAcctCodeSLOT);
    console.log(this.nvoccBookingParty);
    console.log(this.nvoccFreightParty);
    console.log(this.nvoccShipper);

    this.fpAcctCode = "<<";
    if (this.nvoccFreightParty) {
      console.log("hore");
      this.fpAcctCode += " NVOCC ";
    }
    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      if (this.fpAcctCodeCOC !== "") {
        this.fpAcctCode += " COC: " + this.fpAcctCodeCOC;
      } else {
        this.fpAcctCode += " COC: ";
      }
    } else if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      if (this.fpAcctCodeSOC !== "") {
        this.fpAcctCode += " SOC: " + this.fpAcctCodeSOC;
      } else {
        this.fpAcctCode += " SOC: ";
      }
    } else if (this.model.bContainerOwnership == "SLOT") {
      if (this.fpAcctCodeSLOT !== "") {
        this.fpAcctCode += " SLOT: " + this.fpAcctCodeSLOT;
      } else {
        this.fpAcctCode += " SLOT: ";
      }
    }
    this.fpAcctCode += " >>";

    if ((this.model.bContainerOwnership == "SOC" || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){



      $("#tsBkg").prop("checked", true);
       //gos 281
       this.model.bTsBooking = 'Y';


    }else{
      $("#tsBkg").prop("checked", false);
       //gos 281
       this.model.bTsBooking = 'N';
    }
    // changeEventContainerOwnership
    this.initiallyReleaseAt();

    if(this.currentContainerType === "REF"){
      // Alternative for *dateReeferCollection.setValue()*
      this.setDatePluginValue(this.settingReeferCollection.id, moment(this.model.ETA.toString())
      .subtract(5, "d")
      .format("YYYY-MM-DD"))
    }

    // GSO-1063 Add SLOT LC Checkbox: SLOT LC check box can only allowed to checked when Container Ownership=SLOT
    if(this.model.bContainerOwnership == "SLOT"){
      this.isDisablebSlotLC = false;
    }else{
      this.isDisablebSlotLC = true;
      this.model['bSlotLC'] = "N";
    }

    // GOS-806  When container type = Reefer, Reefer Collection Date field SHOULD NOT be reflecting for SOC, SOC TS & SLOT ownerships
    if(this.model.bContainerOwnership != "COC"){
      this.clearReeferCollectionDate();
    }

  }

  clearReeferDetailsField() {
    this.bDegree = undefined;
    this.bDegreeTemp = "C";
    this.lockReeferTemp = true;
    this.bookingExtra.bIsDry = "N";
    this.lockNature = true;
    this.bookingExtra.bNatureTemp = "N";
    this.bookingExtra.bSetOption = "N";

    this.bDegreeSO = undefined;
    this.bDegreeTempSO = "C";
    this.bookingExtra.bReeferDegree = "";
    this.bookingExtra.bDegreeSo = "";
    /*this.bookingExtra.bReeferDegree =
      this.bDegree.toString() + this.bDegreeTemp;

    this.bookingExtra.bDegreeSo =
      this.bDegreeSO.toString() + this.bDegreeTempSO;*/

    this.bookingExtra.bSetOption = "N";
    this.bookingExtra.bVent = "N";
    this.bookingExtra.bNatureTemp = "N";
    this.bookingExtra.bVentNumber = 0;
    this.bookingExtra.bHumidity = "";

    this.dateReeferCollection.setDisable(true);
    this.clearReeferCollectionDate();
    this.lockVentNumber = true;
    this.bookingExtra.bVentOption = "";

    this.lockHumidity = true;
  }

  changeEventContainerType(event) {
    console.log("** change event container type **");
    this.previousContainerType = this.currentContainerType.toString();

    this.currentContainerType = event.target.value;
    console.log("previous " + this.previousContainerType);
    console.log("current " + this.currentContainerType);

    this.dateReeferCollection.setDisable(true);
    this.lockHumidity = true;
    this.clearReeferCollectionDate();

    if (this.previousContainerType == "REF") {
      this.clearReeferDetailsField();
    }

    if (this.currentContainerType == "STD") {
      //this.lockTNK = true;
      this.lockSTD = false; //enable based on miki email on 20 march 2017
    }

    if (this.currentContainerType == "TNK") {
      //this.lockTNK = true;
      this.lockTNK = false; //enable based on miki email on 20 march 2017
      this.lockSTD = true;
      this.clearReaseAtValue();
      this.clearReleaseAtDate();
    } else {
      this.lockTNK = false;
    }

    this.model["error-unitContainerValidator"] = "";
    if (this.currentContainerType === "UC") {
      this.isDisableUC = false;
      //this.lockUC = true;

      this.bookingDetails.clear();
      this.lockSTD = true;
      let list = new Map<String, BookingDetail>();
      this.bookingDetails.set(
        this.currentContainerType,
        this.setContainerValueUC(list)
      );

      this.d20 = 0;
      this.h20 = 0;
      this.d40 = 0;
      this.h40 = 0;
      this.d45 = 0;
      this.h45 = 0;

      if (this.gridContainerInfo.listStore.store.length > 0) {
        this.genericUtil.showDialog(
          "bkgConfirmUCChange",
          "Information",
          350,
          150
        );
      }
      this.clearReaseAtValue();
      this.clearReleaseAtDate();
    } else {
      this.lockUC = false;
      this.isDisableUC = true;
      this.UCH = 0;
      this.UCL = 0;
      this.UCW = 0;
    }

    if (this.currentContainerType == "DRY") {
      this.bookingExtra.bIsDry = "Y";
      this.bookingExtra.bSetOption = "N";
      this.bookingExtra.bVent = "N";
      this.bookingExtra.bNatureTemp = "N";
      this.bookingExtra.bVentNumber = 0;
      this.bookingExtra.bHumidity = null;
      this.lockHumidity = true;

      this.bDegree = 0;
      this.bDegreeSO = 0;
      this.bookingExtra.bReeferDegree = "";
      this.bookingExtra.bDegreeSo = "";
      this.lockSTD = true;
      this.dateReeferCollection.setDisable(false);
      this.lockVentNumber = true;
      this.bookingExtra.bVentOption = "";
      this.lockNature = true;
      // this.calculateReleaseDateWhenTypeDry();
    } else {
      this.bookingExtra.bIsDry = "N";
    }

    if (this.currentContainerType != "UC") {
      if (this.model.bContainerOwnership == "SLOT") {
        //console.log(this.bookingDetails);

        if (this.bookingDetails.has(this.currentContainerType)) {
          console.log("## 1");

          this.d20 = 0;
          this.h20 = 0;
          this.d40 = 0;
          this.h40 = 0;
          this.d45 = 0;
          this.h45 = 0;

          for (let entry of Array.from(this.bookingDetails.entries())) {
            if (entry[0] == this.currentContainerType) {
              for (let val of Array.from(entry[1].entries())) {
                if (val[0] == "2086") {
                  this.d20 = val[1].bQuantity;
                  console.log(this.d20);

                } else if (val[0] == "2096") {
                  this.h20 = val[1].bQuantity;
                } else if (val[0] == "4086") {
                  this.d40 = val[1].bQuantity;
                } else if (val[0] == "4096") {
                  this.h40 = val[1].bQuantity;
                } else if (val[0] == "4586") {
                  this.d45 = val[1].bQuantity;
                } else if (val[0] == "4596") {
                  this.h45 = val[1].bQuantity;
                }
              }
            }
          }
        } else {
          console.log("## 2");

          this.d20 = 0;
          this.h20 = 0;
          this.d40 = 0;
          this.h40 = 0;
          this.d45 = 0;
          this.h45 = 0;

          var chkGroup: string = this.group;

          if (this.containerSlots.get(this.currentContainerType)) {
            chkGroup = "1";
            //this.group = "1"
          }

          if (this.containerSlots2.get(this.currentContainerType)) {
            chkGroup = "2";
            //this.group = "2"
          }

          console.log(chkGroup);

          if (this.group != chkGroup) {
            this.group = chkGroup;
            this.bookingDetails.clear();
          }

          let list = new Map<String, BookingDetail>();
          this.bookingDetails.set(this.currentContainerType, list);
        }
      } else {
        // console.log(this.currentContainerType);
        this.bookingDetails.clear();
        let list = new Map<String, BookingDetail>();
        this.bookingDetails.set(
          this.currentContainerType,
          this.setContainerValue(list)
        );
      }
    }

    this.lockOOG = true;
    if (
      this.currentContainerType == "OPT" ||
      this.currentContainerType == "PLT" ||
      this.currentContainerType == "FLR"
    ) {
      this.lockOOG = false;
      this.lockSTD = true;
    } else {
      this.OH = "N";
      this.OL = "N";
      this.OW = "N";
      this.heightMeasure = 0;
      this.leftMeasure = 0;
      this.rightMeasure = 0;
      this.frontMeasure = 0;
      this.endMeasure = 0;
    }

    this.lockReeferTemp = true;

    if(this.model.ETA && this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'){
      if (this.currentContainerType == "REF") {
        // GSO-1029: Release date same as Reefer Collection date
        // Alternative for *dateReeferCollection.setValue()*
        this.setDatePluginValue(this.settingReeferCollection.id, this.getDatePluginValue(this.settingDateReleaseAt.id));
      } else {
        this.calculationReleaseAtDate();
      }
    }else{
      this.clearReleaseAtDate();
      this.clearReeferCollectionDate();
    }

    if (this.currentContainerType == "REF") {
      console.log("jangan lihat aku malu : " + this.lockCombine);
      this.lockReeferTemp = false;
      this.lockNature = false;
      this.lockHumidity = false;
      this.lockSTD = true;
      this.bookingExtra.bSetOption = "C";
      this.bookingExtra.bVent = "C";
      this.dateReeferCollection.setDisable(false);
      // Alternative for *dateReeferCollection.setValue()*
      this.setDatePluginValue(this.settingReeferCollection.id, moment(this.model.ETA.toString())
      .subtract(5, "d")
      .format("YYYY-MM-DD"))

      // GOS-806  When container type = Reefer, Reefer Collection Date field SHOULD NOT be reflecting for SOC, SOC TS & SLOT ownerships
      if(this.model.bContainerOwnership != "COC"){
        this.clearReeferCollectionDate();
      }

    } else {
      this.bDegree = null;
      this.bDegreeSO = null;
    }

    this.initiallyReleaseAt();

  }

  changeEventGrossWt(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.bookingContainerInfo.bGrossWeight = 0;
    }

    if (val < 0) {
      val = 0;
      this.bookingContainerInfo.bGrossWeight = 0;
    }
  }

  changeEventGrossMeas(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.bookingContainerInfo.bGrossMeas = 0;
    }

    if (val < 0) {
      val = 0;
      this.bookingContainerInfo.bGrossMeas = 0;
    }
  }

  changeEventNettWt(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.bookingContainerInfo.bNettWeight = 0;
    }

    if (val < 0) {
      val = 0;
      this.bookingContainerInfo.bNettWeight = 0;
    }
  }

  changeEventFlashPoint(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.bookingContainerInfo.bFlashPoint = 0;
    }
  }

  changeEventD20(event) {
    let value = event.target.value;
    if (value.length > 5){
      value = value.slice(0, 5);
      this.d20 = value.slice(0, 5);
    }

    if (this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage) {
      this.dialogPlugin.show(
        "information",
        "Information",
        "Next Vessel and Voyage details will be removed, confirm to proceed?",
        "yesno",
        {
          yes: "this.continueChangeD20()",
          no: "this.pauseEditD20()",
        }
        );

        return;
    }

    this.D20Saved = Number.parseInt(event.target.value);
    let val = Number.parseInt(value);

    if (isNaN(val)) {
      val = 0;
      this.d20 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d20 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("2086") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("2086");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "20";
      det.bContainerHeight = "86";
      det.bQuantity = val;
      this.bookingDetails.get(this.currentContainerType).set("2086", det);
    } else {
      // console.log('#2');
      this.bookingDetails.get(this.currentContainerType).delete("2086");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  continueChangeD20() {
    this.removeNextVesselVoyage();
    let val = Number.parseInt(this.d20 as any);
    this.D20Saved = val;

    if (isNaN(val)) {
      val = 0;
      this.d20 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d20 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("2086") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("2086");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "20";
      det.bContainerHeight = "86";
      det.bQuantity = val;
      this.bookingDetails.get(this.currentContainerType).set("2086", det);
    } else {
      // console.log('#2');
      this.bookingDetails.get(this.currentContainerType).delete("2086");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  pauseEditD20() {
    this.loading = false;
    this.d20 = this.D20Saved || 0;
    return false;
  }

  async changeEventH20(event) {
    let value = event.target.value;

    if (value.length > 5){
      value = value.slice(0, 5);
      this.h20 = value.slice(0, 5);
    }

    if (this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage) {
      this.dialogPlugin.show(
        "information",
        "Information",
        "Next Vessel and Voyage details will be removed, confirm to proceed?",
        "yesno",
        {
          yes: "this.continueChangeH20()",
          no: "this.pauseEditH20()",
        }
      );

      return;
    }
    this.H20Saved = Number.parseInt(event.target.value);

    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.h20 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h20 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("2096") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("2096");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "20";
      det.bContainerHeight = "96";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("2096", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("2096");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  continueChangeH20() {
    const value = this.h20 as any;
    this.H20Saved = value;
    this.removeNextVesselVoyage();
    let val = Number.parseInt(value);
    if (isNaN(val)) {
      val = 0;
      this.h20 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h20 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("2096") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("2096");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "20";
      det.bContainerHeight = "96";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("2096", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("2096");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  pauseEditH20() {
    this.loading = false;
    this.h20 = this.H20Saved || 0;
    return false;
  }

  changeEventD40(event) {
    let value = event.target.value;
    if (value.length > 5){
      value = value.slice(0, 5);
      this.d40 = value.slice(0, 5);
    }

    if (this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage) {
      this.dialogPlugin.show(
        "information",
        "Information",
        "Next Vessel and Voyage details will be removed, confirm to proceed?",
        "yesno",
        {
          yes: "this.continueChangeD40()",
          no: "this.pauseEditD40()",
        }
        );

        return;
    }
    this.D40Saved = Number.parseInt(event.target.value);
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.d40 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d40 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("4086") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("4086");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "40";
      det.bContainerHeight = "86";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("4086", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("4086");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  continueChangeD40() {
    this.removeNextVesselVoyage();
    let val = Number.parseInt(this.d40 as any);
    this.D40Saved = val;
    if (isNaN(val)) {
      val = 0;
      this.d40 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d40 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("4086") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("4086");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "40";
      det.bContainerHeight = "86";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("4086", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("4086");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  pauseEditD40() {
    this.loading = false;
    this.d40 = this.D40Saved || 0;
    return false;
  }

  changeEventH40(event) {
    let value = event.target.value;
    if (value.length > 5){
      value = value.slice(0, 5);
      this.h40 = value.slice(0, 5);
    }

    if (this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage) {
      this.dialogPlugin.show(
        "information",
        "Information",
        "Next Vessel and Voyage details will be removed, confirm to proceed?",
        "yesno",
        {
          yes: "this.continueChangeH40()",
          no: "this.pauseEditH40()",
        }
      );

      return;
    }
    this.H40Saved = Number.parseInt(event.target.value);


    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.h40 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h40 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("4096") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("4096");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "40";
      det.bContainerHeight = "96";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("4096", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("4096");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  continueChangeH40() {
    this.removeNextVesselVoyage();
    let val = Number.parseInt(this.h40 as any);
    this.H40Saved = val;
    if (isNaN(val)) {
      val = 0;
      this.h40 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h40 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("4096") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("4096");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "40";
      det.bContainerHeight = "96";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("4096", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("4096");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  pauseEditH40() {
    this.loading = false;
    this.h40 = this.H40Saved || 0;
    return false;
  }

  changeEventD45(event) {
    let value = event.target.value;
    if (value.length > 5){
      value = value.slice(0, 5);
      this.d45 = value.slice(0, 5);
    }

    if (this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage) {
      this.dialogPlugin.show(
        "information",
        "Information",
        "Next Vessel and Voyage details will be removed, confirm to proceed?",
        "yesno",
        {
          yes: "this.continueChangeD45()",
          no: "this.pauseEditD45()",
        }
        );

        return;
    }
    this.D45Saved = Number.parseInt(event.target.value);

    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.d45 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d45 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("4586") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("4586");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "45";
      det.bContainerHeight = "86";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("4586", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("4586");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  continueChangeD45() {
    this.removeNextVesselVoyage();
    let val = Number.parseInt(this.d45 as any);
    this.D45Saved = val;

    if (isNaN(val)) {
      val = 0;
      this.d45 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d45 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("4586") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("4586");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "45";
      det.bContainerHeight = "86";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("4586", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("4586");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  pauseEditD45() {
    this.loading = false;
    this.d45 = this.D45Saved || 0;
    return false;
  }

  changeEventH45(event) {
    let value = event.target.value;
    if (value.length > 5){
      value = value.slice(0, 5);
      this.h45 = value.slice(0, 5);
    }

    if (this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage) {
      this.dialogPlugin.show(
        "information",
        "Information",
        "Next Vessel and Voyage details will be removed, confirm to proceed?",
        "yesno",
        {
          yes: "this.continueChangeH45()",
          no: "this.pauseEditH45()",
        }
      );

      return;
    }
    this.H45Saved = Number.parseInt(event.target.value);

    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.h45 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h45 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("4596") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("4596");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "45";
      det.bContainerHeight = "96";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("4596", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("4596");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  continueChangeH45() {
    this.H45Saved = Number.parseInt(this.h45 as any);
    this.removeNextVesselVoyage();

    let val = Number.parseInt(this.h45 as any);
    if (isNaN(val)) {
      val = 0;
      this.h45 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h45 = 0;
    }

    if (val > 0) {
      if (
        this.bookingDetails.get(this.currentContainerType).get("4596") != null
      ) {
        this.bookingDetails.get(this.currentContainerType).delete("4596");
      }

      let det = new BookingDetail();
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = "45";
      det.bContainerHeight = "96";
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set("4596", det);
    } else {
      this.bookingDetails.get(this.currentContainerType).delete("4596");
    }

    this.setDetail();

    //this.grid.setBody(this.model);
    //this.grid.loadData();
    this.loadDataAlloc();
  }

  pauseEditH45() {
    this.loading = false;
    this.h45 = this.H45Saved || 0;
    return false;
  }

  handleCancel() {

    if(this.isCHINASiteEDI_NewBkg==true){

      if(this.ChinaBookingselectedList.length<1 || this.ChinaBookingselectedList== null){
         this.isCHINASiteEDI_NewBkg=false;

         this.dialogPlugin.show(
          "information",
          "Information",
          "All data will be cleared.",
          "okonly",
          {
            ok: "this.router.navigate(['/main/edi-process-edi-booking']);"
          }
        );
         //this.router.navigate(["/main/edi-process-edi-booking"]);
      }
      else{
        this.dialogPlugin.show(
          "information",
          "Information",
          "All data will be cleared.",
          "okonly",
          {
            ok: "this.loading = false;this.ngAfterViewInit();"
          }
        );
      }

    }else{
      this.fromRetrieve =true;
      this.appStatusLabel = "";
      this.haveTerminal = "N";
      this.originLocation = "NNN";
      this.gridContainerInfo.onClear();
      this.bookingExtra = new BookingExtra();
      this.defaultCheckboxOnlySing();
      this.setPrintRemark();
      this.bookingContainerInfo = new BookingContainerInfo();
      this.bDegree = 0;
      this.bDegreeTemp = "C";
      this.bDegreeTempSO = "C";
      this.model.bookingContainerInfo = [];
      this.onContainerInfo__Cancel();
      this.printBCFormReset();
      this.undefineExVessel = "";
      this.fpAcctCode = "<< SOC: >>";

      this.searchFpIsValid = "";
      this.searchFpIsFreightParty = "";

      this.searchShIsValid = "";
      this.searchShIsShipper = "";

      this.searchBkIsValid = "";
      this.searchBkIsBookingParty = "";
      this.isAfterSaveCopy = false;
      this.isCopySuccess = false;
      this.bRmarksFormat = "";
      this.cbNextVoyage.disableCombo = false;

      this.isCheckedDepot = false;
      /*
      let mdLock = new Booking();
      mdLock.bOfficeCode = this.model.bOfficeCode;
      mdLock.bPrefix = this.model.bPrefix;
      mdLock.bNo = this.model.bNo;
      mdLock.bModifiedUserid = this.cookieService.getName();
      this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterBookings/setUnlockBooking',mdLock).subscribe((resp)=> {})
      */
      this.doUnlock(
        this.model.bOfficeCode,
        this.model.bPrefix,
        this.model.bNo,
        this.cookieService.getName()
      );
      this.disableToolbarButtons = "modify,save,copy,cancel,print,delete,sendDepot";
      /*
      this.cbLoadPort.setValue(this.cookieService.getCookie('defaultLocationCode'));

      this.cbPOR.setValue(this.cookieService.getDefaultLocationCode());

      this.cbReceiptType.setValue('CY');
      this.model.bReceiptType = 'CY';

      this.cbDeliveryType.setValue('CY');
      this.model.bDeliveryType = 'CY';
      */
      this.init();
      this.initTab1();
      this.isNew = false;
      this.isModify = false;
      this.isPOLCanalPassing = false;
      //this.strParams = '';
      localStorage.removeItem("new-booking-from-flag");

      // GSO-1063 Add SLOT LC Checkbox
      this.isDisablebSlotLC = true;

    }

  }

  handleRetrieve() {
    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      // console.log('retrieve : ' + this.model.bNo);
      this.genericUtil.showLoader();

      var self = this;
      this.retrieveEvent(function (callbackRetrieve) {
        console.log(callbackRetrieve);

        // console.log('Is Unlock => ' + callbackRetrieve.bIsUnlocked);

        if (callbackRetrieve.bStatus == "D") {
          // console.log('#1')
          self.disableToolbarButtons =
            "retrieve,new,modify,save,copy,print,delete";
        } else if (
          callbackRetrieve.bContainerOwnership == "COCTS" &&
          callbackRetrieve.bOfficeCodeTs != "" &&
          callbackRetrieve.bOfficeCodeTs != undefined &&
          callbackRetrieve.bPrefixTs != "" &&
          callbackRetrieve.bPrefixTs != undefined &&
          callbackRetrieve.bNoTs != "" &&
          callbackRetrieve.bNoTs != undefined
        ) {
          // console.log('#2')
          self.disableToolbarButtons =
            "retrieve,new,modify,save,copy,cancel,print,delete";
        } else {
          /*
          if(callbackRetrieve.bIsUnlocked == 'N'){
            self.appStatusLabel = '-- LOCKED --';
            //self.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
            self.disableToolbarButtons = 'retrieve,new,modify,save,copy,print,delete';
          }else{


            if(callbackRetrieve.bLock['bNo'] != undefined){
              console.log(callbackRetrieve.bLock['bUserId']);
              console.log(self.cookieService.getName())
              if(callbackRetrieve.bLock['bUserId'] !=  self.cookieService.getName().toUpperCase()){
                self.appStatusLabel = '-- LOCKED by ' + callbackRetrieve.bLock['bUserId'] + ' --';
                self.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
              }else{
                self.disableToolbarButtons = 'retrieve,save,copy';
              }
            }else{
              // console.log('#3')
              self.disableToolbarButtons = 'retrieve,save,copy';
            }

          }
          */ // <-- dipindah ke modify
          //self.model.bLockBy = callbackRetrieve.bLock['bUserId'];
          //self.disableToolbarButtons = 'retrieve,save,copy';

          /*
          self.genericService.GET(self.configService.config.BASE_API.toString() + '/MasterBookings/getSailSchedule/' + callbackRetrieve.bOceanVesselId + '/' + callbackRetrieve.bOceanVesselVoyage + '/' + callbackRetrieve.bOceanVesselBound + '/' + callbackRetrieve.bOfficeCode).subscribe((resp) =>{

              // console.log(resp.json());
              let data = resp.json();

              self.model.ETA = String(data['ETA']).trim().replace(/\//gi,"-");
              console.log('ETA : ' +  self.model.ETA);

              self.model.ETD = String(data['ETD']).replace(/\//g,"-");
              console.log('ETD : ' +  self.model.ETD);

              self.model.ATA = String(data['ATA']).replace(/\//g,"-");
              console.log('ATA : ' +  self.model.ATA);

              self.model.ATD = String(data['ATD']).replace(/\//g,"-");
              console.log('ATD : ' +  self.model.ATD);

              let currentDate2 = new Date();

              let etdDate2 = new Date();
              etdDate2= new Date(self.model.ETD.toString());

              currentDate2 = new Date(self.genericUtil.getPSIDate());
              if(self.model.ETD != null && self.model.ETD !== undefined && self.model.ETD !== ''){
                let add = self.addDays(etdDate2,7)
                console.log('add : ' + add)
                console.log('currentDate2 : ' + currentDate2)

                if(add > currentDate2){
                  self.disableToolbarButtons = 'retrieve,save,copy';
                }else{
                  //lock7days
                  self.dialogPlugin.show('warning', 'Information', "Unable to update booking because vessel ETD already exceeds 7 days from the current date.", 'okonly', { ok: ' this.genericUtil.hideLoader();'});
                  self.disableToolbarButtons = 'retrieve,new,modify,save,copy,print,delete';
                }
              }else{
                self.disableToolbarButtons = 'retrieve,save,copy';
              }


              console.log("callbackRetrieve.isCombine = " + callbackRetrieve.isCombine)

              self.genericUtil.hideLoader();
              self.isInit = false;
              self.lockEdit = true;
              self.isModify = false;

              if(callbackRetrieve.isCombine == "Y"){
                self.lockCombine = true;
              }

              if(callbackRetrieve.isSplit == "Y"){
                self.lockSplit = true;
              }


          })
          */

         if (callbackRetrieve.bIsUnlocked == "N") {
            if (callbackRetrieve.bPermanentLocked === "Y") {
              self.dialogPlugin.show(
                "information",
                "Information",
                "Vessel departed more than 1 month. View only?",
                "yesno",
                {
                  yes: "this.viewOnlyStatus = true; this.genericUtil.hideLoader();",
                  no: "this.handleCancel(); this.genericUtil.hideLoader();",
                }
              );
            } else {
              self.dialogPlugin.show(
                "warning",
                "Information",
                "Unable to update booking because vessel ETD already exceeds 9 days from the current date.",
                "okonly",
                { ok: " this.genericUtil.hideLoader();" }
              );
            }
            self.appStatusLabel = "-- LOCKED --";
            self.disableToolbarButtons =
              "retrieve,new,modify,save,copy,print,delete";
          } else {
            self.disableToolbarButtons = "retrieve,save,copy";
            //hardcode
            if (self.viewOnlyStatus) {
              self.disableToolbarButtons =
                "retrieve,new,modify,print,delete,save,copy";
            }

            self.genericUtil.hideLoader();
            self.isInit = false;
            self.lockEdit = true;
            self.isModify = false;

            if (callbackRetrieve.isCombine == "Y") {
              self.lockCombine = true;
            }

            if (callbackRetrieve.isSplit == "Y") {
              self.lockSplit = false;
            }

            if (self.cookieService.getDefaultLocationCode() == "SGSIN") {
              self.lockSOOPR1 = false;
              self.lockSOOPR2 = false;
            }
          }
        }

        self.setDataAfterRetrieve();
      });
    } else {
      window.scrollTo(0, 0);
    }
  }

  retrieveEvent(callback?: (data) => void) {
    console.log("** retrieve event **");
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getBookingById/" +
          this.model.bOfficeCode +
          "/" +
          this.model.bPrefix.toUpperCase() +
          "/" +
          String(this.model.bNo.toUpperCase()).toUpperCase().trim()
      )
      .subscribe((resp) => {
        const data = resp.json();
        console.log("Cek data nih : ", data);
        console.log(data.isCombine);
        if (data.status === "OK") {
          if (callback) {
            /*
          if(data.bLock['bNo'] !== undefined) {
            console.log(data.bLock);
            if(data.bLock['bUserId'] !== this.cookieService.getName().toUpperCase()){
              this.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
              this.dialogPlugin.show('information', 'Information', 'Booking is locked by ' + data.bLock['bUserId'] + '.', 'okonly',{ok: 'this.genericUtil.hideLoader();'});
            }
          }
          */ // <-- dipindah di modify

            //this.model.bLockBy = data.bLock['bUserId'];

            if (data.bStatus === "D") {
              let msgDeleted = "Booking already deleted.";
              if (data.bOceanVesselId === "VS00553") {
                msgDeleted = "No record found.";
              }else{
                //GSO-1048 Enable <Cancel> <Back><Close>& <Send to Depot>, the rest button DISABLE.
                if(this.isSingaporeSite){
                  this.disableToolbarButtons = "retrieve,new,modify,save,copy,print,delete";
                  this.setData(data);
                  callback(data);
                }
              }
              this.dialogPlugin.show(
                "warning",
                "Information",
                msgDeleted,
                "okonly",
                { ok: " this.genericUtil.hideLoader();" }
              );
            } else {
              this.setData(data);
              callback(data);
            }
          }
        } else {
          this.dialogPlugin.show(
            "information",
            "Information",
            "No record(s) found.",
            "okonly",
            { ok: "" }
          );
          this.genericUtil.hideLoader();
        }
      });
  }

  retrieveShanghaiBookingEvent(ediID: any, callback?: (data) => void) {
    console.log("** retrieve shanghai booking event **");
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        "/sha/getChinaEdiLinesByEdiBNo/" +
        ediID +
         "/" +
         "SSL" + String(this.model.bNo.toUpperCase()).toUpperCase().trim()+
          "/ASC/bno"
      )
      .subscribe((resp) => {
        const data = resp.json()["content"];

        if (data!=null && data!="" && data!= undefined) {
          if (callback) {

              this.setShanghaiData(data);

              callback(data);

          }
        } else {
          this.dialogPlugin.show(
            "information",
            "Information",
            "No record(s) found.",
            "okonly",
            { ok: "" }
          );
          this.genericUtil.hideLoader();
        }
      });
  }

  handleNew() {
    // console.log('** handle new **');

    this.model["allocationValidator"] = false;
    //if (this.model.bOceanVesselId !== '' && this.model.bOceanVesselVoyage !== '' && this.grid.listStore.store.length === 0) {
    if (
      this.model.bOceanVesselId !== "" &&
      this.model.bOceanVesselVoyage !== "" &&
      this.rowData.length === 0
    ) {
      this.model["allocationValidator"] = true;
    }
    this.custIdForCbFreightParty = "*";
    this.custIdForCbShipper = "*";
    this.custIdForCbBooking = "*";
    this.isAfterSaveCopy = false;

    this.cbFreightParty.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerName={query}"
    );

    this.cbBookingParty.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/"+this.custIdForCbBooking+"/customerName={query}"
    );

    this.cbShipper.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerName={query}"
    );

    this.searchFpIsValid = "";
    this.searchFpIsFreightParty = "";

    this.searchShIsValid = "";
    this.searchShIsShipper = "";

    this.searchBkIsValid = "";
    this.searchBkIsBookingParty = "";



    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      this.disableToolbarButtons = "retrieve,new,modify,print,delete";
      this.lock = false;
      this.lockEdit = true;
      this.isNew = true;
      this.isInit = false;
      this.isModify = false;
      this.setValidatorSave();

      this.initNew();
    } else {
      window.scrollTo(0, 0);
    }
  }

  handleModify() {
    // GOS-912
    if(this.model.bookingDetails.length > 0) {
      this.model.bookingDetails.forEach((dt) => {
        this.currentBookingTaken += (Number(dt.bContainerSize) >= 40 ) ? (Number(dt.bQuantity)*2) : Number(dt.bQuantity);
      });
    }
    /*
    let mdLock = new Booking();
    mdLock.bOfficeCode = this.model.bOfficeCode;
    mdLock.bPrefix = this.model.bPrefix;
    mdLock.bNo = this.model.bNo;
    mdLock.bModifiedUserid = this.cookieService.getName()
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterBookings/setLockBooking',mdLock).subscribe((resp)=> {
    */
    //  this.getLock(this.model.bOfficeCode,this.model.bPrefix, this.model.bNo,this.cookieService.getName() )
    this.isAfterSaveCopy = false;
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getLockBooking/" +
          this.model.bOfficeCode +
          "/" +
          this.model.bPrefix +
          "/" +
          this.model.bNo
      )
      .subscribe((resp) => {
        console.log(resp.json()["content"]);

        let det: any[] = resp.json()["content"];
        if (det["bUserId"] != undefined) {
          //this.model.bIsUnlocked = "N"
          this.model["bLockBy"] = det["bUserId"];
        } else {
          //this.model.bIsUnlocked = "Y"
          this.model["bLockBy"] = "";
        }

        console.log("this.model.bIsUnlocked = " + this.model.bIsUnlocked);
        console.log("this.model['bLockBy'] = " + this.model["bLockBy"]);

        /*
      if(this.model.bIsUnlocked == "Y" || (this.model['bLockBy']  != "" && this.model['bLockBy'] == this.cookieService.getName().toUpperCase())) {

          this.doLock(this.model.bOfficeCode,this.model.bPrefix, this.model.bNo,this.cookieService.getName())

          this.disableToolbarButtons = 'retrieve,new,modify,print,delete';

          let isLock : boolean = false
          if(this.lockCombine){
            isLock = true;
          }
          if(this.lockSplit){
            isLock = true;
          }

          console.log(this.lockCombine + ' - ' + this.lockSplit)

          if(!isLock){
            this.lock = false;
            this.lockEdit = true;
            this.setValidatorSave();
            this.isInit = false;

          }

          this.isModify = true;

          console.log("this.lockReeferTemp : " + this.lockReeferTemp)

          //fast hack pot issue
          this.cbPOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/{query}');

          console.log('MMother vessel id before set pot ' + this.model.bNextMotherVesselId);
          console.log('MMother vessel voyage before set pot ' + this.model.bNextMotherVesselVoyage);

          if(this.model.bPot1Code != null && this.model.bPot1Code != '' && this.model.bPot1Code != undefined){
            this.lockNextVessel = false;
            console.log('MMother vessel id ' + this.model.bNextMotherVesselId);
            console.log('MMother vessel voyage ' + this.model.bNextMotherVesselVoyage);

            this.cbNextVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+this.model.bNextMotherVesselId+'/'+this.model.bOceanVesselBound+'/{query}/' + this.model.bPot1Code);
            console.log('Value Next Voayage before set value ' + this.model.bNextMotherVesselVoyage);
            this.cbNextVoyage.setValue(String(this.model.bNextMotherVesselVoyage).trim());
            console.log('Value Next Voayage after set value ' + this.model.bNextMotherVesselVoyage);

          }

          this.populateReceiptTypeExtra();
      }else{
        this.disableToolbarButtons = 'retrieve,new,modify,save,copy,print,delete';
        this.dialogPlugin.show('information', 'Information', 'Booking is locked by ' + this.model['bLockBy'] + '.', 'okonly',{ok: 'this.genericUtil.hideLoader();'});
      }
      */

        if (
          this.model["bLockBy"] == "" ||
          (this.model["bLockBy"] != "" &&
            this.model["bLockBy"] == this.cookieService.getName().toUpperCase())
        ) {
          this.doLock(
            this.model.bOfficeCode,
            this.model.bPrefix,
            this.model.bNo,
            this.cookieService.getName()
          );
          this.disableToolbarButtons = "retrieve,new,modify,print,delete,sendDepot";

          let isLock: boolean = false;
          if (this.lockCombine) {
            isLock = true;
            //GOS-75
            this.dialogPlugin.show(
              "information",
              "Information",
              "Combined BL(s) detected. Approach Docs to uncombine.",
              "okonly",
              { ok: "this.genericUtil.hideLoader();" }
            );
          }
          if (this.lockSplit) {
            isLock = true;
          }
          console.log(this.lockCombine + " - " + this.lockSplit);
          console.log("current container type : " + this.currentContainerType);

          if (!isLock) {
            this.lock = false;
            this.lockEdit = true;
            this.setValidatorSave();
            this.isInit = false;
            // GSO-1063
            this.checkDisableSlotLc();
          }

          this.isModify = true;

          if (this.cookieService.getDefaultLocationCode() == "SGSIN") {
            this.lockSOOPR1 = false;
            this.lockSOOPR2 = false;
          }

          console.log("this.lockReeferTemp : " + this.lockReeferTemp);
          console.log("this.lockSTD : " + this.lockSTD);

          //fast hack pot issue
          this.cbPOT1.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
              this.cookieService.getDefaultLocationCode() +
              "/" +
              this.model.bOceanVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.bOceanVesselVoyage).trim()
              ) +
              "/" +
              this.model.bOceanVesselBound +
              "/{query}"
          );

          console.log(
            "MMother vessel id before set pot " + this.model.bNextMotherVesselId
          );
          console.log(
            "MMother vessel voyage before set pot " +
              this.model.bNextMotherVesselVoyage
          );

          if (
            this.model.bPot1Code != null &&
            this.model.bPot1Code != "" &&
            this.model.bPot1Code != undefined
          ) {
            this.lockNextVessel = false;
            console.log("MMother vessel id " + this.model.bNextMotherVesselId);
            console.log(
              "MMother vessel voyage " + this.model.bNextMotherVesselVoyage
            );

            this.cbNextVoyage.setUrl(
              this.configService.config.BASE_API.toString() +
                "/MasterSailingSchedules/distinctVoyage/" +
                this.model.bNextMotherVesselId +
                "/" +
                this.model.bOceanVesselBound +
                "/{query}/" +
                this.model.bPot1Code
            );
            console.log(
              "Value Next Voayage before set value " +
                this.model.bNextMotherVesselVoyage
            );
            // this.cbNextVoyage.setValue(
            //   String(this.model.bNextMotherVesselVoyage)
            // );
            console.log(
              "Value Next Voayage after set value " +
                this.model.bNextMotherVesselVoyage
            );
          }

          this.populateReceiptTypeExtra();
          this.handleCheckStatusDepot(true);
        } else {
          //lock by user
          this.disableToolbarButtons =
            "retrieve,new,modify,save,copy,print,delete,sendDepot";
          this.dialogPlugin.show(
            "information",
            "Information",
            "Booking is locked by " + this.model["bLockBy"] + ".",
            "okonly",
            { ok: "this.genericUtil.hideLoader();" }
          );
        }
      });

    //patch GSO-116, should be forbidden to doing this, because we will update data on user behalf
    if (this.model.bContainerOwnership == "COC") {
      // KHUSUS SITE SINGAPORE & COC LOCAL RELEASE AT ENG KONG
      if (this.cookieService.getDefaultLocationCode() == "SGSIN") {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/LocalMasterDepos/findGeneral/SGSIN/Y/*/*/1/10/ASC/depoName"
          )
          .subscribe((resp) => {
            const dtx = resp.json()["content"];
            if (dtx.length == 1) {
              this.cbReleaseAt.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/LocalMasterDepos/findByComboBoxControl/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/depoId={query}"
              );
              this.cbReleaseAt.setValue(dtx[0]["depoId"]); // GSO-116,
            } else if (dtx.length > 1) {
              // TBA in case the pesky bizapp comment back
              //hurray bizapp commented back, now added
              //this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoName={query}');
              // this.cbReleaseAt.setValue(''); // GSO-116,
            } else if (dtx.length <= 0) {
              this.cbReleaseAt.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/LocalMasterDepos/findByComboBoxControl/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/depoName={query}"
              );
              this.cbReleaseAt.setValue(""); // GSO-116,
              this.bookingMisc.bReleaseAt = "";
            }
          });
      }
    }

    //GSO-1034 Issue : Unable to edit free time directly, need to reselect “Standard“, “Request“ follow by  input of 10 days (Incorrect behavior)
    if (this.bookingExtra.bDetentionPol == "R") {
      this.lockDetentionPol = false;
    } else {
      this.lockDetentionPol = true;
    }

    console.log("master booking extra : ", this.model.bookingExtra);
  }

  async setDetail() {
    this.model.bookingDetails = [];

    for (let entry of Array.from(this.bookingDetails.entries())) {
      for (let det of Array.from(entry[1].entries())) {
        // console.log(det[1]);
        det[1].bKillSlot = this.killSlot;
        det[1].bDoorOpen = this.doorOpen;
        det[1].bOverHeight = this.OH;
        det[1].bOverHeightCm = this.heightMeasure;
        det[1].bOverWidth = this.OW;
        det[1].bOverWidthLeftCm = this.leftMeasure;
        det[1].bOverWidthRightCm = this.rightMeasure;
        det[1].bOverLength = this.OL;
        det[1].bOverLengthFrontCm = this.frontMeasure;
        det[1].bOverLengthBackCm = this.endMeasure;

        det[1].bUch = this.UCH;
        det[1].bUcl = this.UCL;
        det[1].bUcw = this.UCW;

        this.model.bookingDetails.push(det[1]);
      }
    }
    // patched by gilang to include the container info list
    this.model.bookingContainerInfo = [];
    if (this.gridContainerInfo.listStore.store.length > 0) {
      for (let i = 0; i < this.gridContainerInfo.listStore.store.length; i++) {
        this.model.bookingContainerInfo.push(
          this.gridContainerInfo.listStore.store[i]
        );
      }
    }

    this.bookingMisc.bOfficeCode = this.model.bOfficeCode;
    this.bookingMisc.bPrefix = this.model.bPrefix;
    this.bookingMisc.bNo = this.model.bNo;

    this.bookingMisc.bReleaseAtDate = await this.getDatePluginValue(this.settingDateReleaseAt.id);
    this.bookingMisc.bReleaseAtTime = $("#timeReleaseAt").val();

    this.bookingMisc.bReturnToPorGateInDate = this.dateReturnToPor.getValue();
    this.bookingMisc.bReturnToPorGateInTime = $("#timeReturnToPor").val();
    this.bookingMisc.bReturnToPorClosingDate =
      this.dateReturnToPorGateInClosing.getValue();
    this.bookingMisc.bReturnToPorClosingTime = $(
      "#timeReturnToPorClosing"
    ).val();
    this.bookingMisc.bReturnToPorVgmDate =
      this.dateReturnToPorVgmClosing.getValue();
    this.bookingMisc.bReturnToPorVgmTime = $("#timeReturnToPorVgm").val();

    this.bookingMisc.bReturnToPolGateInDate = this.dateReturnToPol.getValue();
    this.bookingMisc.bReturnToPolGateInTime = $("#timeReturnToPol").val();
    this.bookingMisc.bReturnToPolClosingDate =
      this.dateReturnToPolGateInClosing.getValue();
    this.bookingMisc.bReturnToPolClosingTime = $(
      "#timeReturnToPolClosing"
    ).val();
    this.bookingMisc.bReturnToPolVgmDate =
      this.dateReturnToPolVgmClosing.getValue();
    this.bookingMisc.bReturnToPolVgmTime = $("#timeReturnToPolVgm").val();

    this.bookingMisc.bStuffAtPorGateInDate = this.dateStuffAtPor.getValue();
    this.bookingMisc.bStuffAtPorGateInTime = $("#timeStuffAtPor").val();
    this.bookingMisc.bStuffAtPorClosingDate =
      this.dateStuffAtPorGateInClosing.getValue();
    this.bookingMisc.bStuffAtPorClosingTime = $("#timeStuffAtPorClosing").val();
    this.bookingMisc.bStuffAtPorVgmDate =
      this.dateStuffAtPorVgmClosing.getValue();
    this.bookingMisc.bStuffAtPorVgmTime = $("#timeStuffAtPorVgm").val();
    this.bookingMisc.bAdditionalRemarks = this.txtTerminalRemarks.getValue();

    console.log("dari set detail ");
    console.log(this.model);
    console.log('256256');
    console.log(this.model.bPodlCode);
    console.log(this.model.bContainerOwnership);
    console.log(this.model.bFdestCode)

    this.bookingMisc.bPrint = this.bookingMiscCheckboxPrint === true ? "Y" : "N";
    //console.log(this.model.bookingMisc);
    this.model.bookingMisc = this.bookingMisc;
    this.model.bookingExtra = this.bookingExtra;
    console.log("dari set detail 2");
    console.log(this.bookingMisc);
    console.log(this.model.bookingMisc);
  }

  async handleSave() {

    console.log("** Handle Save ** ");
    console.log("POD nya : " + this.model.bPodCode);
    console.log("Ex Vessel Name : ", this.model.bExMotherVesselName);
    console.log("Model Booking Noted 2 : ", this.model);

    this.model.bFeederVesselBound = "O";
    this.model.bExMotherVesselBound = "M";
    this.model.bNextMotherVesselBound = "O";
    this.model.bRemarks = this.txtRemarks.getValue();
    this.bRmarksFormat = this.model.bRemarks.split('↵').join('\r\n');

    //this.model.bRequestReleaseDate = this.dtRequestReleaseDate.dateP.nativeElement.value;
    //this.model.bExpectedReturnDate = this.dtExpReturnDate.dateP.nativeElement.value;
    this.bookingExtra.bReeferCollectionDate = await this.getDatePluginValue(this.settingReeferCollection.id);
    // console.log('this.bDegree => ' +this.bDegree)

    console.log("nojan is here");
    console.log(this.bookingExtra);

    if (this.UCH == undefined) {
      this.UCH = 0;
    }

    if (this.UCL == undefined) {
      this.UCL = 0;
    }

    if (this.UCW == undefined) {
      this.UCW = 0;
    }

    // console.log('this.model.bReeferDegree => ' + this.model.bReeferDegree)
    this.setDetail();

    console.log("this.model.bookingExtra : ", this.model.bookingExtra);
    console.log("this.bookingExtra : ", this.bookingExtra);

    if (this.model.bNo == "" ||  this.isCHINASiteEDI_NewBkg==true) {
      this.model.bStatus = "N";
      this.model.bCreatedUserid = this.cookieService.getName();
      this.model.bCreatedDate = this.genericUtil.getPSIDate();

      this.model.bModifiedUserid = this.cookieService.getName();
      this.model.bModifiedDate = this.genericUtil.getPSIDate();
    } else {
      this.model.bStatus = "U";
      this.model.bModifiedUserid = this.cookieService.getName();
      this.model.bModifiedDate = this.genericUtil.getPSIDate();
    }
    console.log("Check validation before validdation save");
    console.log(
      "the value of next mother vessel voyage = " +
        this.model.bNextMotherVesselVoyage
    );

    this.setValidatorSave();
    this.model["bookingPartyValidator"] = this.checkBookingPartyValidator();

    // if(this.isChinaSite==true||this.isCHINASiteEDI_NewBkg==true) {
    //   this.model["rotationValidator"] =false;
    // }
    // else{
    this.model["rotationValidator"] = this.checkRotation();
    // }

    console.log(this.lockUC);
    if (this.lockUC == false) {
      this.model["unitContainerValidator"] = this.checkUnitContainer();
    }

    if (this.model["unitContainerValidator"] == false) {
      this.model["allocationValidator"] = this.checkAllocationValidator();
    }
    this.model["POLPODDetDemValidator"]=false
    if(Number(this.bookingExtra.bDetentionRequestPol) > 60 || Number(this.bookingExtra.bDemurrageRequestPol) > 60
      || Number(this.model.bDetentionRequest) > 60 || Number(this.model.bDemurrageRequest) > 60){
      this.model["POLPODDetDemValidator"]=true;
      window.scrollTo(0, 0);
    }

    this.model["operatorCodeValidator"] = this.checkOperatorCode();
    this.model["slotOperatorCodeValidator"] = this.checkSlotOperatorCode();
    this.model["coctsExceedValidator"] = this.checkCoctsExceed();
    this.model["cocLocalExceedValidator"] = this.checkCocLocalExceed();
    //this.model['podAllocationValidator'] = this.checkPodAllocation();

    this.model["feederValidator"] = this.checkFeederVoyage();
    this.model["ExVoyageValidator"] = this.checkExVoyage();
    this.model["NextVoyageValidator"] = this.checkNextVoyage();
    if (this.currentContainerType == "REF") {
      this.model["ReeferDegreeValidator"] = this.checkReeferDegree();
      this.model["ReeferDegreeSOValidator"] = this.checkReeferDegreeSO();
      this.model["VentNumberValidator"] = this.checkVentNumber();
      // GOS-806 When container type = Reefer, Reefer Collection Date field SHOULD NOT be reflecting for SOC, SOC TS & SLOT ownerships
      if(this.model.bContainerOwnership != "COCTS"){
        this.model["ReeferCollectionDateValidator"] =
        this.checkReeferCollectionDate();
      }
      this.model["humidityValidator"] = this.checkHumidity();
    }
    if (this.isReeferDryTypeInSing) {
      this.model.reeferDryValidator =  await this.getDatePluginValue(this.settingReeferCollection.id) ? false : true;
      this.model["error-reeferDryValidator"] = "Please fill in reefer collection date.";
    }
    this.model["ucValidator"] = this.checkUC();

    //disable the validation for singapore site, coz we have feature to upload container from QLEC
    //This validation will hinder the booking qty to be modified and saved
    if(!this.isSingaporeSite) {
      this.model["difUnitContainerValidator"] = this.checkDifUnitContainer();
    }

    /*
    this.model['allocationValidator'] = false;
    if(this.model.bOceanVesselId != '' && this.model.bOceanVesselVoyage != '' && this.grid.listStore.store.length == 0){
      this.model['allocationValidator'] = true;
    }
    */
    this.bookingExtra.bReeferDegree =
      (this.bDegree != undefined ? this.bDegree.toString() : "") +
      this.bDegreeTemp;
    this.bookingExtra.bDegreeSo =
      (this.bDegreeSO != undefined ? this.bDegreeSO.toString() : "") +
      this.bDegreeTempSO;
    /*this.bookingExtra.bReeferDegree =
      this.bDegree.toString() + this.bDegreeTemp;

    this.bookingExtra.bDegreeSo =
      this.bDegreeSO.toString() + this.bDegreeTempSO;*/
    this.bookingExtra.bNextVesselVoyagePriority = this.model.bNextVesselVoyagePriority;

    this.model.bookingExtra = {
      ...new BookingExtra(),
      ...this.bookingExtra,
    };

    this.defaultCheckboxOnlySing();

    this.model["POLPODValidator"] = false;
    if (this.model.bPolCode != "" && this.model.bPodCode != "") {
      this.model["POLPODValidator"] =
        this.model.bPolCode == this.model.bPodCode ? true : false;
    }

    this.model["POLPOTValidator"] = false;
    if (this.model.bPolCode != "" && this.model.bPot1Code != "") {
      this.model["POLPOTValidator"] =
        this.model.bPolCode == this.model.bPot1Code ? true : false;
    }

    this.model["PODPOTValidator"] = false;
    if (this.model.bPodCode != "" && this.model.bPot1Code != "") {
      this.model["PODPOTValidator"] =
        this.model.bPodCode == this.model.bPot1Code ? true : false;
    }

    console.log(this.model["error-unitContainerValidator"]);
    console.log("** before validate **");
    console.log(this.model);
    this.isError = this.onValidate(this.model);
    console.log(this.isCopy);
    console.log(this.isNew);
    if (this.isAfterSaveCopy) {
      this.validateAfterSaveNew();
    }

    if (!this.isError) {
      this.stopBookingValidation();
      var self = this;
      var locWarn = this.model.bPodCode;
      var opMesg = "";
      setTimeout(function () {
        if (self.stopDetection > 0) {
          console.log("STOP detection " + self.stopDetection);
          //check if there any all booking stop data
          /*
          if(self.globalALL == 1){
                self.dialogPlugin.show('save','Saving data','All bookings stopped by '+self.stopByAll.toUpperCase()+'.', 'okonly', { ok: 'this.loading = false;return false'});
          }else{
                if(self.model.bPot1Code != '')locWarn = self.model.bPot1Code
              // else {
                  var cntReplace = self.model.bContainerOwnership
                  if(self.model.bContainerOwnership == 'SOC')cntReplace = 'SOC LOCAL'
                  if(self.model.bContainerOwnership == 'COC')cntReplace = 'COC LOCAL'
                  if(self.model.bContainerOwnership == 'COCTS')cntReplace = 'COC T/S'
                  if(self.model.bContainerOwnership == 'SOCTS')cntReplace = 'SOC T/S'

                  if(self.model.bContainerOwnership == 'SOC'|| self.model.bContainerOwnership == 'SOCTS')
                  opMesg = self.model.bSocCntrOperatorCode.toString()
                else if(self.model.bContainerOwnership == 'COC' || self.model.bContainerOwnership == 'COCTS')
                    opMesg = self.model.bCocCntrOperatorCode.toString()
                else
                    opMesg = self.model.bSlotOperatorCode.toString()

                  if(self.model.bSlotOperatorCode != ''||self.model.bSocCntrOperatorCode != ''||self.model.bCocCntrOperatorCode != ''){
                    self.dialogPlugin.show('save','Information',locWarn+', '+self.model.bContainerStatus.replace('FULL','LADEN')+', '+cntReplace+', '+opMesg+' bookings stopped by '+self.stopByAll.toUpperCase()+'.', 'okonly', { ok: 'this.loading = false;return false'});
                  }else{
                    self.dialogPlugin.show('save','Information',locWarn+', '+self.model.bContainerStatus.replace('FULL','LADEN')+', '+cntReplace+' bookings stopped by '+self.stopByAll.toUpperCase()+'.', 'okonly', { ok: 'this.loading = false;return false'});
                  }
                //}

          }
            */
          self.dialogPlugin.show(
            "save",
            "Saving data",
            "Booking has been stopped.",
            "okonly",
            { ok: "this.loading = false;return false" }
          );
        } else {
          if (!self.isError) {
            //debug glg
            /*
            if(this.model.bPot1Code != '' && this.model.bContainerOwnership == 'COC'){
              this.genericService.POST(this.configService.config.BASE_API.toString() + '/Transshipment/inquireConnectionVessel', this.model).subscribe((resp)=> {
                if(resp.status == 200){
                  if(resp.json()['content']['current'] == undefined){
                    this.dialogPlugin.show('information','Information','System is unable to find any available 2nd leg vessel suitable for this booking !. Please create transshipment request to POT: ' + this.model.bPot1Name, 'okonly', { ok: 'this.loading=false;'});
                    return
                  }
                  let msg:String = '';
                  this.tsData = resp.json()['content']['current'];
                  msg+='<table border='0' width='100%'><tr><td colspan='6'>Earliest connection available is:</td></tr>';
                  msg+='<tr><td><strong>'+resp.json()['content']['current']['vesselName']+'</strong></td><td><strong>'+resp.json()['content']['current']['voyage']+'</strong></td><td><strong>ETA: </strong></td><td><strong>'+resp.json()['content']['current']['eta']+'</strong></td><td><strong>Est POD Arrival:</strong></td><td><strong>'+resp.json()['content']['current']['arrival']+'</strong></td></tr>';
                  msg+='<tr><td colspann='6'>&nbsp;</td></tr>';
                  //msg+='<strong>' +  + ' ' + resp.json()['content']['current']['voyage'] + '</strong> ETA: ' + resp.json()['content']['current']['eta'] + ' Arrival: '++'<br /><br />';
                  msg+='<tr><td colspan='6'>Next available connection: </td></tr>';

                  for(let i=0; i< resp.json()['content']['next'].length; i++){
                    msg+='<tr><td>'+resp.json()['content']['next'][i]['vesselName']+'</td><td>'+ resp.json()['content']['next'][i]['voyage']+'</td><td>ETA: </td><td>'+resp.json()['content']['next'][i]['eta']+'</td><td>Est POD Arrival:</td><td>'+resp.json()['content']['next'][i]['arrival']+'</td></tr>';
                    //msg+=resp.json()['content']['next'][i]['vesselName'] + ' ' + resp.json()['content']['next'][i]['voyage'] + ' ETA: ' + resp.json()['content']['next'][i]['eta'] + ' Arrival: '+resp.json()['content']['next'][i]['arrival']+ '<br />';
                  }

                  msg += '</table>';
                  this.dialogPlugin.show('information','Connection Information',msg,'yesno',{yes:'this.setNextVesselVoyage()',no:''});

                }
              });
            }else{
            */

            let stsSoc: String = self.checkBookingExceedSoc();
            // console.log('stsSoc => ' + stsSoc);
            let stsSoclocal: String = self.checkBookingExceedSoclocal();
            // console.log('stsSoclocal => ' + stsSoc);
            let stsSocts: String = self.checkBookingExceedSocts();
            // console.log('stsSocts => ' + stsSoc);

            let stsCoc: String = self.checkBookingExceedCoc();
            // console.log('stsCoc => ' + stsCoc);
            let stsCoclocal: String = self.checkBookingExceedCoclocal();
            // console.log('stsCoclocal => ' + stsCoclocal);
            let stsCocts: String = self.checkBookingExceedCocts();
            // console.log('stsCocts => ' + stsCocts);

            let stsSlot: String = self.checkBookingExceedSlot();

            console.log("stsStopBooking => " + self.globalALL);

            if (self.checkBookingExceed()) {
              // console.log('#1');
              self.dialogPlugin.show(
                "save",
                "Saving data",
                "Booking exceeds allocation, do you want to continue? ",
                "yesno",
                { yes: "this.saveEvent()", no: "this.loading = false;" }
              );
            } else if (stsSoc != "0") {
              // console.log('#2');
              if (stsSoc == "1") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Allotted figures for SOC is missing. Please set figures in allocation. ",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }
              if (stsSoc == "2") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Booking exceeds allocation, do you want to continue? ",
                  "yesno",
                  { yes: "this.saveEvent()", no: "this.loading = false;" }
                );
              }
            } else if (stsSoclocal != "0") {
              // console.log('#2');
              if (stsSoclocal == "1") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Allotted figures for SOC Local is missing. Please set figures in allocation. ",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }
              if (stsSoclocal == "2") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Booking exceeds allocation, do you want to continue? ",
                  "yesno",
                  { yes: "this.saveEvent()", no: "this.loading = false;" }
                );
              }
            } else if (stsSocts != "0") {
              // console.log('#2');
              if (stsSocts == "1") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Allotted figures for SOC T/S is missing. Please set figures in allocation. ",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }
              if (stsSocts == "2") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Booking exceeds allocation, do you want to continue? ",
                  "yesno",
                  { yes: "this.saveEvent()", no: "this.loading = false;" }
                );
              }
            } else if (stsCoc != "0") {
              // console.log('#3');
              if (stsCoc == "1") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Allotted figures for COC is missing. Please set figures in allocation.",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }
              if (stsCoc == "2") {
                //if(this.model.bContainerOwnership == 'COCTS'){
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Booking exceeds allocation.",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
                //}else{
                //  this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
                //}
              }
            } else if (stsCoclocal != "0") {
              // console.log('#3');
              if (stsCoclocal == "1") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Allotted figures for COC Local is missing. Please set figures in allocation.",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }
              if (stsCoclocal == "2") {
                /*
                if(this.model.bPot1Code != '' && this.model.bContainerOwnership == 'COC'){
                  this.inquireConnectionVessel();
                }else{
                  this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
                }
                */
                // 16/4/2024: GOS-668 fix encountered issue upon saving BKGs.
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Booking exceeds allocation.",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }
            } else if (stsCocts != "0") {
              // console.log('#3');
              if (stsCocts == "1") {
                this.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Allotted figures for COC T/S is missing. Please set figures in allocation.",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }
              if (stsCocts == "2") {
                //this.inquireConnectionVessel();
                //this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
              }
            } else if (stsSlot != "0") {
              // console.log('#4');
              if (stsSlot == "1") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Allotted figures for SS-" +
                    self.model.bSlotOperatorCode +
                    " is missing. Please set figures in allocation. ",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }
              if (stsSlot == "2") {
                self.dialogPlugin.show(
                  "save",
                  "Saving data",
                  "Booking exceeds allocation, do you want to continue? ",
                  "yesno",
                  { yes: "this.saveEvent()", no: "this.loading = false;" }
                );
              }
            } else {
              // console.log('#5');
              /*
              if(this.model.bPot1Code != '' && this.model.bContainerOwnership == 'COC'){
                this.inquireConnectionVessel();
              }else if(this.model.bContainerOwnership == 'COCTS'){
                this.inquireConnectionVessel();
              }else{
                this.saveEvent();
              }
              */

             //gos-779
             // if (self.undefineExVessel != undefined) {
                self.saveEvent();
              /*} else {
                self.dialogPlugin.show(
                  "information",
                  "Information",
                  "Ex Vessel is undefined.",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
              }*/
            }
            //}
          } else {
            window.scrollTo(0, 0);
          }
        } //end else stop booking
      }, 1000);
    }
  }

  inquireConnectionVessel() {
    if (this.defaultCountry == "sg") {
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
            "/Transshipment/inquireConnectionVessel",
          this.model
        )
        .subscribe((resp) => {
          if (resp.status == 200) {
            if (resp.json()["content"]["current"] == undefined) {
              this.dialogPlugin.show(
                "information",
                "Information",
                "System is unable to find any available 2nd leg vessel suitable for this booking!<br/> Please create <b><u>transshipment</u></b> request to <b><u>POT: " +
                  this.model.bPot1Name +
                  "</u></b>",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return;
            }
            let msg: string = "";
            this.tsData = resp.json()["content"]["current"];
            msg +=
              "<table border='0' width='100%'><tr><td colspan='6'>Earliest connection available is:</td></tr>";
            msg +=
              "<tr><td><strong>" +
              resp.json()["content"]["current"]["vesselName"] +
              "</strong></td><td><strong>" +
              resp.json()["content"]["current"]["voyage"] +
              "</strong></td><td><strong>ETA: </strong></td><td><strong>" +
              resp.json()["content"]["current"]["eta"] +
              "</strong></td><td><strong>Est POD Arrival:</strong></td><td><strong>" +
              resp.json()["content"]["current"]["arrival"] +
              "</strong></td></tr>";
            msg += "<tr><td colspann='6'>&nbsp;</td></tr>";
            //msg+="<strong>" +  + " " + resp.json()["content"]["current"]["voyage"] + "</strong> ETA: " + resp.json()["content"]["current"]["eta"] + " Arrival: "++"<br /><br />";
            msg += "<tr><td colspan='6'>Next available connection: </td></tr>";

            for (let i = 0; i < resp.json()["content"]["next"].length; i++) {
              msg +=
                "<tr><td>" +
                resp.json()["content"]["next"][i]["vesselName"] +
                "</td><td>" +
                resp.json()["content"]["next"][i]["voyage"] +
                "</td><td>ETA: </td><td>" +
                resp.json()["content"]["next"][i]["eta"] +
                "</td><td>Est POD Arrival:</td><td>" +
                resp.json()["content"]["next"][i]["arrival"] +
                "</td></tr>";
              //msg+=resp.json()["content"]["next"][i]["vesselName"] + " " + resp.json()["content"]["next"][i]["voyage"] + " ETA: " + resp.json()["content"]["next"][i]["eta"] + " Arrival: "+resp.json()["content"]["next"][i]["arrival"]+ "<br />";
            }

            msg += "</table>";
            this.dialogPlugin.show(
              "information",
              "Connection Information",
              msg,
              "yesno",
              { yes: "this.setNextVesselVoyage()", no: "" }
            );
          }
        });
    } else {
      this.saveEvent();
    }
  }

  setNextVesselVoyage() {
    // console.log("** set next vessel voyage **");
    // console.log(this.tsData);

    this.cbNextVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselId={query}"
    );

    this.cbNextVessel.setValue(this.tsData["vesselId"]);

    if (this.checkBookingExceed()) {
      // console.log("#1");
      var self = this;
      setTimeout(() => {
        self.dialogPlugin.show(
          "save",
          "Saving data",
          "Booking exceeds allocation, do you want to continue? ",
          "yesno",
          { yes: "this.saveEvent()", no: "this.loading = false;" }
        );
      }, 50);
    } else {
      // console.log("#2");
      this.saveEvent();
    }
  }

  checkBookingExceed(): Boolean {
    // console.log("** check booking exceeded **");
    var self = this;
    let result: Boolean = false;
    var strPath: String = "VS";

    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParentExceeded(
        parentList,
        element.allotmentCode,
        element.available,
        self.model
      );

      if (!chk) {
        if (strPath == String(element.parent)) {
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            if (
              str.trim() == String(self.model.bPodCode).trim() ||
              str.trim() == String(self.model.bPot1Code).trim()
            ) {
              strPath = element.allotmentCode + ";" + strPath;
              if (element.available < 0) {
                result = true;
              }
            }
          }

          if (
            "EMPTY" == String(element.allotmentCode) &&
            "EMPTY" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
            if (element.available < 0) {
              result = true;
            }
          }

          if (
            "LADEN" == String(element.allotmentCode) &&
            "FULL" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
            if (element.available < 0) {
              result = true;
            }
          }

          /*
          if ("SOC" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership) || "SOCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("SOC LOCAL" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC LOCAL created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("SOC T/S" == String(element.allotmentCode) && ("SOCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC T/S created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }
          */

          /*
          if ("COC" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership) || "COCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("COC LOCAL" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC LOCAL  created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("COC T/S" == String(element.allotmentCode) && ("COCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC T/S created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }
          */

          /*
          if (String(element.allotmentCode).startsWith("SS-")) {
            let str = String(element.allotmentCode).replace("SS-", '');

            if(str.trim() == String(self.model.bSlotOperatorCode).trim()) {
              // console.log("** allocation SS-" + self.model.bSlotOperatorCode + "  created");
              strPath = element.allotmentCode + ";" + strPath;
              if(element.available < 0){
                result = true;
              }
            }

          }
          */
        }
      }
    });
    return result;
  }

  checkBookingExceedSoc(): String {
    // console.log("** check booking exceeded soc **");
    var self = this;
    //let result:Boolean = false;
    let status: String = "0";
    var strPath: String = "VS";

    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParentExceeded(
        parentList,
        element.allotmentCode,
        element.available,
        self.model
      );
      // console.log("chk ==> " + chk)
      if (!chk) {
        // console.log('strPath => ' + strPath)
        // console.log('element.Parent => ' + element.Parent)
        if (strPath == String(element.parent)) {
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            if (
              str.trim() == String(self.model.bPodCode).trim() ||
              str.trim() == String(self.model.bPot1Code).trim()
            ) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if (
            "EMPTY" == String(element.allotmentCode) &&
            "EMPTY" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "LADEN" == String(element.allotmentCode) &&
            "FULL" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "SOC" == String(element.allotmentCode) &&
            ("SOC" == String(self.model.bContainerOwnership) ||
              "SOCTS" == String(self.model.bContainerOwnership))
          ) {
            // console.log("** allocation SOC created");
            strPath = element.allotmentCode + ";" + strPath;
            if (element.available < 0 && element.alloted == 0) {
              status = "1";
            }
            if (element.available < 0 && element.alloted > 0) {
              status = "2";
            }
          }
        }
      }
    });
    return status;
  }

  checkBookingExceedSoclocal(): String {
    // console.log("** check booking exceeded soc local **");
    var self = this;
    //let result:Boolean = false;
    let status: String = "0";
    var strPath: String = "VS";

    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParentExceeded(
        parentList,
        element.allotmentCode,
        element.available,
        self.model
      );
      // console.log("chk ==> " + chk)
      if (!chk) {
        // console.log('strPath => ' + strPath)
        // console.log('element.Parent => ' + element.Parent)
        if (strPath == String(element.parent)) {
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            if (
              str.trim() == String(self.model.bPodCode).trim() ||
              str.trim() == String(self.model.bPot1Code).trim()
            ) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if (
            "EMPTY" == String(element.allotmentCode) &&
            "EMPTY" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "LADEN" == String(element.allotmentCode) &&
            "FULL" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "SOC" == String(element.allotmentCode) &&
            ("SOC" == String(self.model.bContainerOwnership) ||
              "SOCTS" == String(self.model.bContainerOwnership))
          ) {
            // console.log("** allocation SOC created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "SOC LOCAL" == String(element.allotmentCode) &&
            "SOC" == String(self.model.bContainerOwnership)
          ) {
            // console.log("** allocation SOC LOCAL created");
            strPath = element.allotmentCode + ";" + strPath;
            if (element.available < 0 && element.alloted == 0) {
              status = "1";
            }
            if (element.available < 0 && element.alloted > 0) {
              status = "2";
            }
          }
        }
      }
    });
    return status;
  }

  checkBookingExceedSocts(): String {
    // console.log("** check booking exceeded soc ts **");
    var self = this;
    //let result:Boolean = false;
    let status: String = "0";
    var strPath: String = "VS";

    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParentExceeded(
        parentList,
        element.allotmentCode,
        element.available,
        self.model
      );
      // console.log("chk ==> " + chk)
      if (!chk) {
        // console.log('strPath => ' + strPath)
        // console.log('element.Parent => ' + element.Parent)
        if (strPath == String(element.parent)) {
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            if (
              str.trim() == String(self.model.bPodCode).trim() ||
              str.trim() == String(self.model.bPot1Code).trim()
            ) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if (
            "EMPTY" == String(element.allotmentCode) &&
            "EMPTY" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "LADEN" == String(element.allotmentCode) &&
            "FULL" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "SOC" == String(element.allotmentCode) &&
            ("SOC" == String(self.model.bContainerOwnership) ||
              "SOCTS" == String(self.model.bContainerOwnership))
          ) {
            // console.log("** allocation SOC created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "SOC T/S" == String(element.allotmentCode) &&
            "SOCTS" == String(self.model.bContainerOwnership)
          ) {
            // console.log("** allocation SOC T/S created");
            strPath = element.allotmentCode + ";" + strPath;
            if (element.available < 0 && element.alloted == 0) {
              status = "1";
            }
            if (element.available < 0 && element.alloted > 0) {
              status = "2";
            }
          }
        }
      }
    });
    return status;
  }

  checkBookingExceedCoc(): String {
    // console.log("** check booking exceeded coc **");
    var self = this;
    let status: String = "0";
    var strPath: String = "VS";

    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParentExceeded(
        parentList,
        element.allotmentCode,
        element.available,
        self.model
      );

      if (!chk) {
        if (strPath == String(element.parent)) {
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            if (
              str.trim() == String(self.model.bPodCode).trim() ||
              str.trim() == String(self.model.bPot1Code).trim()
            ) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if (
            "EMPTY" == String(element.allotmentCode) &&
            "EMPTY" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "LADEN" == String(element.allotmentCode) &&
            "FULL" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "COC" == String(element.allotmentCode) &&
            ("COC" == String(self.model.bContainerOwnership) ||
              "COCTS" == String(self.model.bContainerOwnership))
          ) {
            // console.log("** allocation COC created");
            strPath = element.allotmentCode + ";" + strPath;
            if (element.available < 0 && element.alloted == 0) {
              status = "1";
            }
            if (element.available < 0 && element.alloted > 0) {
              status = "2";
            }
          }
        }
      }
    });
    // console.log("status ==> " + status);
    return status;
  }

  checkBookingExceedCoclocal(): String {
    // console.log("** check booking exceeded coc local **");
    var self = this;
    let status: String = "0";
    var strPath: String = "VS";

    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParentExceeded(
        parentList,
        element.allotmentCode,
        element.available,
        self.model
      );

      if (!chk) {
        if (strPath == String(element.parent)) {
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            if (
              str.trim() == String(self.model.bPodCode).trim() ||
              str.trim() == String(self.model.bPot1Code).trim()
            ) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if (
            "EMPTY" == String(element.allotmentCode) &&
            "EMPTY" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "LADEN" == String(element.allotmentCode) &&
            "FULL" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "COC" == String(element.allotmentCode) &&
            ("COC" == String(self.model.bContainerOwnership) ||
              "COCTS" == String(self.model.bContainerOwnership))
          ) {
            // console.log("** allocation COC created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "COC LOCAL" == String(element.allotmentCode) &&
            "COC" == String(self.model.bContainerOwnership)
          ) {
            // console.log("** allocation COC LOCAL  created");
            strPath = element.allotmentCode + ";" + strPath;
            if (element.available < 0 && element.alloted == 0) {
              status = "1";
            }
            if (element.available < 0 && element.alloted > 0) {
              status = "2";
            }
          }
        }
      }
    });
    // console.log("status ==> " + status);
    return status;
  }

  checkBookingExceedCocts(): String {
    // console.log("** check booking exceeded coc ts **");
    var self = this;
    let status: String = "0";
    var strPath: String = "VS";

    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParentExceeded(
        parentList,
        element.allotmentCode,
        element.available,
        self.model
      );

      if (!chk) {
        if (strPath == String(element.parent)) {
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            if (
              str.trim() == String(self.model.bPodCode).trim() ||
              str.trim() == String(self.model.bPot1Code).trim()
            ) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if (
            "EMPTY" == String(element.allotmentCode) &&
            "EMPTY" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "LADEN" == String(element.allotmentCode) &&
            "FULL" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "COC" == String(element.allotmentCode) &&
            ("COC" == String(self.model.bContainerOwnership) ||
              "COCTS" == String(self.model.bContainerOwnership))
          ) {
            // console.log("** allocation COC created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "COC T/S" == String(element.allotmentCode) &&
            "COCTS" == String(self.model.bContainerOwnership)
          ) {
            // console.log("** allocation COC T/S created");
            strPath = element.allotmentCode + ";" + strPath;
            if (element.available < 0 && element.alloted == 0) {
              status = "1";
            }
            if (element.available < 0 && element.alloted > 0) {
              status = "2";
            }
          }
        }
      }
    });

    // console.log("status ==> " + status)
    return status;
  }

  checkBookingExceedSlot(): String {
    // console.log("** check booking exceeded slot **");
    var self = this;
    //let result:Boolean = false;
    let status: String = "0";
    var strPath: String = "VS";

    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParentExceeded(
        parentList,
        element.allotmentCode,
        element.available,
        self.model
      );

      if (!chk) {
        if (strPath == String(element.parent)) {
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            if (
              str.trim() == String(self.model.bPodCode).trim() ||
              str.trim() == String(self.model.bPot1Code).trim()
            ) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if (
            "EMPTY" == String(element.allotmentCode) &&
            "EMPTY" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (
            "LADEN" == String(element.allotmentCode) &&
            "FULL" == String(self.model.bContainerStatus)
          ) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (String(element.allotmentCode).startsWith("SS-")) {
            let str = String(element.allotmentCode).replace("SS-", "");

            if (str.trim() == String(self.model.bSlotOperatorCode).trim()) {
              // console.log("** allocation SS-" + self.model.bSlotOperatorCode + "  created");
              strPath = element.allotmentCode + ";" + strPath;
              if (element.available < 0 && element.alloted == 0) {
                // this.dialogPlugin.show('save','Saving data','Allocation for Operator Slot ' + this.model.bSlotOperatorCode +' is exceeded. ', 'okonly', { ok: 'this.loading = false;'});
                status = "1";
                //result = true;
              }

              if (element.available < 0 && element.alloted > 0) {
                //this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
                status = "2";
                //result = true;
              }
            }
          }
        }
      }
    });
    return status;
  }

  checkFeederVoyage(): Boolean {
    let result: Boolean = false;
    if (
      this.model.bFeederVesselCode != "" &&
      this.model.bFeederVesselVoyage == ""
    ) {
      return true;
    }
    return result;
  }

  checkExVoyage(): Boolean {
    let result: Boolean = false;
    if (
      this.model.bExMotherVesselCode != "" &&
      this.model.bExMotherVesselVoyage == ""
    ) {
      return true;
    }
    return result;
  }

  checkNextVoyage(): Boolean {
    let result: Boolean = false;
    console.log(
      "Value Next Voayage before checking checkNextVoyage " +
        this.model.bNextMotherVesselVoyage
    );
    if (
      this.model.bNextMotherVesselCode != "" &&
      this.model.bNextMotherVesselVoyage == ""
    ) {
      console.log("Masuk return true " + this.model.bNextMotherVesselVoyage);
      return true;
    }
    return result;
  }

  checkReeferDegree(): Boolean {
    console.log("** check reefer degree **");
    console.log(this.bDegree);
    // console.log("this.model.bContainerOwnership => " + this.model.bContainerOwnership);

    let result: Boolean = false;

    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      if (this.bDegree != undefined) {
        if (String(this.bDegree).length > 5) {
          this.model["error-ReeferDegreeValidator"] =
            "Max temperature is 5 chars.";
          result = true;
        } /*else if(this.bDegreeTemp == ""){
      this.model["error-ReeferDegreeValidator"] = "Please insert reefer temperature scale.";
      result = true;
    }*/ else {
          /*if (
            this.model.bContainerOwnership == "COC" ||
            this.model.bContainerOwnership == "COCTS"
          ) {*/
          for (let entry of Array.from(this.bookingDetails.entries())) {
            // console.log('size ' + entry[1].size);

            if (
              entry[0] == "REF" &&
              entry[1].size > 0 &&
              this.model.bContainerStatus != "EMPTY"
            ) {
              // console.log('come here');
              if (this.bDegree != null) {
                var degreeStd = this.bDegreeTemp === "F" ? 86 : 30;
                var degreeFroz = this.bDegreeTemp === "F" ? 140 : 60;
                if (this.bDegree < 0) {
                  degreeStd = this.bDegreeTemp === "F" ? 22 : 30;
                  degreeFroz = this.bDegreeTemp === "F" ? 76 : 60;
                }

                var degreeTypeError =
                  this.bDegreeTemp === "C" ? "Celsius" : "Fahrenheit";

                if (
                  String(this.bDegree) == "null" ||
                  String(this.bDegree) == "" ||
                  String(this.bDegree) == "NaN"
                ) {
                  console.log("come here3");
                  result = true;
                } else if (this.bDegree < -degreeFroz) {
                  this.model[
                    "error-ReeferDegreeValidator"
                  ] = `Reefer temperature should not be lower than ${-degreeFroz} degree ${degreeTypeError}`;
                  result = true;
                } else if (this.bDegree > degreeFroz) {
                  this.model[
                    "error-ReeferDegreeValidator"
                  ] = `Reefer temperature should not be higher than ${degreeFroz} degree ${degreeTypeError}`;
                  result = true;
                } else if (this.bDegreeTemp == "") {
                  this.model["error-ReeferDegreeValidator"] =
                    "Please insert reefer temperature scale.";
                  result = true;
                }
              } else {
                console.log("come here 4");
                result = true;
              }
            }
          }
          //}
        }
      } else {
        result = true;
      }
    }

    console.log("result reefer degree => " + result);
    return result;
  }

  checkReeferDegreeSO(): Boolean {
    let result: Boolean = false;

    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      if (this.bDegreeSO != undefined) {
        if (String(this.bDegreeSO).length > 5) {
          console.log("masuk 1 so");
          this.model["error-ReeferDegreeSOValidator"] = `Max ${
            this.bookingExtra.bSetOption === "C" ? "Pre-cooling" : "Pre-set"
          } temperature is 5 chars.`;
          result = true;
        } /* else if(this.bDegreeTempSO == ""){
      this.model["error-ReeferDegreeSOValidator"] = `Please insert ${this.bookingExtra.bSetOption === "C" ? "Pre-cooling" : "Pre-set"} temperature scale.`;
      result = true;
    }*/ else {
          console.log("masuk 2 so");
          /*if (
            this.model.bContainerOwnership == "COC" ||
            this.model.bContainerOwnership == "COCTS"
          ) {*/
          console.log("masuk 3 so");
          for (let entry of Array.from(this.bookingDetails.entries())) {
            // console.log('size ' + entry[1].size);

            if (
              entry[0] == "REF" &&
              entry[1].size > 0 &&
              this.model.bContainerStatus != "EMPTY"
            ) {
              console.log("masuk 4 so");
              // console.log('come here');
              if (this.bDegreeSO != null) {
                var degreeStd = this.bDegreeTempSO === "F" ? 86 : 30;
                var degreeFroz = this.bDegreeTempSO === "F" ? 140 : 60;
                if (this.bDegreeSO < 0) {
                  degreeStd = this.bDegreeTempSO === "F" ? 22 : 30;
                  degreeFroz = this.bDegreeTempSO === "F" ? 76 : 60;
                }

                var setOptionMessage =
                  this.bookingExtra.bSetOption === "C"
                    ? "Pre-cooling"
                    : "Pre-set";
                var degreeTypeError =
                  this.bDegreeTempSO === "C" ? "Celsius" : "Fahrenheit";

                if (
                  String(this.bDegreeSO) == "null" ||
                  String(this.bDegreeSO) == "" ||
                  String(this.bDegreeSO) == "NaN"
                ) {
                  console.log("masuk 5 so");
                  result = true;
                  // console.log('come here3');
                } else if (this.bDegreeSO < -degreeFroz) {
                  this.model[
                    "error-ReeferDegreeSOValidator"
                  ] = `${setOptionMessage} temperature should not be lower than ${-degreeFroz} degree ${degreeTypeError}`;
                  result = true;
                } else if (this.bDegreeSO > degreeFroz) {
                  this.model[
                    "error-ReeferDegreeSOValidator"
                  ] = `${setOptionMessage} temperature should not be higher than ${degreeFroz} degree ${degreeTypeError}`;
                  result = true;
                } else if (this.bDegreeTempSO == "") {
                  this.model[
                    "error-ReeferDegreeSOValidator"
                  ] = `Please insert ${
                    this.bookingExtra.bSetOption === "C"
                      ? "Pre-cooling"
                      : "Pre-set"
                  } temperature scale.`;
                  result = true;
                }
              } else {
                console.log("masuk 6 so");
                result = true;
              }
            }
          }
          /*}*/
        }
      } else {
        result = true;
      }
    }

    console.log("masuk 7 so ", result);
    return result;
  }

  checkVentNumber(): Boolean {
    let result: Boolean = false;

    // if (
    //   this.model.bContainerOwnership == "COC" ||
    //   this.model.bContainerOwnership == "COCTS"
    // ) {
      for (let entry of Array.from(this.bookingDetails.entries())) {
        if (
          entry[0] == "REF" &&
          entry[1].size > 0 &&
          this.model.bContainerStatus != "EMPTY"
        ) {
          if (this.bookingExtra.bVent === "O") {
            let total = this.bookingExtra.bVentOption === 'C' ? 180 : 100;
            if (String(this.bookingExtra.bVentNumber).length > 5) {
              this.model["error-VentNumberValidator"] =
                "Max ventilation value is 5 chars.";
              result = true;
            } else {
              if (this.bookingExtra.bVentNumber != null) {
                if (
                  String(this.bookingExtra.bVentNumber) == "null" ||
                  String(this.bookingExtra.bVentNumber) == "" ||
                  String(this.bookingExtra.bVentNumber) == "NaN"
                ) {
                  result = true;
                } else if (this.bookingExtra.bVentNumber < 1) {
                  result = true;
                  this.model["error-VentNumberValidator"] =
                    "Vent value must be greater than 0";
                } else if (this.bookingExtra.bVentNumber > total) {
                  result = true;
                  this.model["error-VentNumberValidator"] =
                    "Vent value must be lower than or equal " + total;
                }
              } else {
                result = true;
                this.isError = false;
              }

              if (this.bookingExtra.bVentOption != null) {
                if (
                  this.bookingExtra.bVentOption == "null" ||
                  this.bookingExtra.bVentOption == "" ||
                  this.bookingExtra.bVentOption == "NaN"
                ) {
                  result = true;
                  this.model["error-VentNumberValidator"] =
                    "Please insert a valid vent unit";
                }
              } else {
                result = true;
                this.model["error-VentNumberValidator"] =
                  "Please insert Vent unit";
              }
            }

            /*if(this.bookingExtra.bVentNumber < 1){
              result = true;
              this.model["error-VentNumberValidator"] =
                "Vent value must greater than 0";
            }

            if(this.bookingExtra.bVentNumber > 100){
              result = true;
              this.model["error-VentNumberValidator"] =
                "Vent value must lower or equal than 100";
            }*/
          }
        }
      }
    // }

    return result;
  }

  checkReeferCollectionDate(): Boolean {
    let result: Boolean = false;

    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      for (let entry of Array.from(this.bookingDetails.entries())) {
        if (
          entry[0] == "REF" /*||
          (entry[0] == "DRY" &&
            entry[1].size > 0 &&
            this.model.bContainerStatus != "EMPTY")*/
        ) {
          if (this.bookingExtra.bReeferCollectionDate != null) {
            console.log(
              "bReeferCollectionDate : ",
              this.bookingExtra.bReeferCollectionDate
            );
            if (
              this.bookingExtra.bReeferCollectionDate == "null" ||
              this.bookingExtra.bReeferCollectionDate == "" ||
              this.bookingExtra.bReeferCollectionDate == "NaN"
            ) {
              result = true;
              console.log("masuk sini oy 4");
            }
          } else {
            console.log("masuk sini oy 5");
            result = true;
          }
        }
      }
    }

    console.log("hasilnya oy : ", result);
    return result;
  }

  checkHumidity() {
    let result: Boolean = false;

    /*if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {*/
    for (let entry of Array.from(this.bookingDetails.entries())) {
      if (
        entry[0] == "REF" ||
        (entry[0] == "DRY" &&
          entry[1].size > 0 &&
          this.model.bContainerStatus != "EMPTY")
      ) {
        if (this.bookingExtra.bHumidity !== null) {
          if (this.bookingExtra.bHumidity.length > 0) {
            console.log(
              "humidity nih bos : ",
              this.bookingExtra.bHumidity,
              this.formValidation.regNegative.test(
                String(this.bookingExtra.bHumidity)
              )
            );
            if (
              !this.formValidation.regNumOnly.test(
                String(this.bookingExtra.bHumidity)
              )
            ) {
              result = true;
            }
          }
        }
      }
    }
    //}

    console.log("humidity : ", this.bookingExtra.bHumidity, result);

    return result;
  }

  checkRotation(): Boolean {
    //// console.log("** check rotation **");
    let result: Boolean = true;
    let rotationList: String[] = this.rotation.split(" - ");
    if (this.model.bPot1Code != "") {
      this.model["error-rotationValidator"] =
        "Port of Transhipment 1 is not valid with rotation.";

      rotationList.forEach((element) => {
        const lastCharacPotCode = this.model.bPot1TerminalCode.slice(-3);
        if (String(element).trim() == String(this.model.bPot1Code).trim() || String(element).trim() == String(this.model.bPot1Code).trim() + ` (${lastCharacPotCode})`) {
          this.model["error-rotationValidator"] = "";
          result = false;
        }
      });
    } else {
      if (this.model.bPodCode != "") {
        this.model["error-rotationValidator"] =
          "Discharge Port is not valid with rotation.";

        rotationList.forEach((element) => {
          if (String(element).trim() == String(this.model.bPodCode).trim() || String(element).trim() == String(this.model.bPodCode).trim() + ` (${this.model.bPodTerminalCode.slice(-3) })`) {
            this.model["error-rotationValidator"] = "";
            result = false;
          }
        });
      } else {
        this.model["error-rotationValidator"] = "";
        result = false;
      }
    }
    return result;
  }

  checkUnitContainer(): Boolean {
    console.log("** check unit container **");
    let result: Boolean = true;

    for (let entry of Array.from(this.bookingDetails.entries())) {
      if (entry[1].size > 0) {
        result = false;
      }
    }
    return result;
  }

  checkUC(): Boolean {
    console.log("** check UC **");
    let result: Boolean = false;

    console.log(this.bookingDetails);

    for (let entry of Array.from(this.bookingDetails.entries())) {
      for (let entryDetail of Array.from(entry[1].entries())) {
        if (
          entryDetail[1].bContainerType == "UC" &&
          entryDetail[1].bUch == 0 &&
          entryDetail[1].bUcl == 0 &&
          entryDetail[1].bUcw == 0
        ) {
          result = true;
        }
      }
    }
    return result;
  }

  checkDifUnitContainer(): Boolean {
    console.log("** check difUnitContainer **");
    let result: Boolean = true;

    console.log(this.model.bContainerOwnership);
    console.log(
      "this.model.bookingContainerInfo.length = " +
        this.model.bookingContainerInfo.length
    );

    if (
      this.model.bContainerOwnership == "COC" &&
      this.model.bookingContainerInfo.length > 0
    ) {
      let mp = new Map();
      console.log("** bookingContainerInfo **");
      this.model.bookingContainerInfo.forEach((e) => {
        console.log(e);
        if (mp.get(e.bContType) == undefined) {
          mp.set(e.bContType, 1);
        } else {
          mp.set(e.bContType, mp.get(e.bContType) + 1);
        }
      });

      let mp2 = new Map();
      console.log("** bookingDetails **");
      for (let entry of Array.from(this.bookingDetails.entries())) {
        for (let det of Array.from(entry[1].entries())) {
          console.log(det[1]);
          if (
            det[1].bContainerType == "REF" ||
            det[1].bContainerType == "DRY"
          ) {
            if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("R20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("R40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("R45", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("RH20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("RH40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("RH45", det[1].bQuantity);
            }
          } else if (det[1].bContainerType == "OPT") {
            if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("OT20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("OT40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("OT45", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("OTH20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("OTH40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("OTH45", det[1].bQuantity);
            }
          } else if (det[1].bContainerType == "FLR") {
            if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("FR20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("FR40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("FR45", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("FRH20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("FRH40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("FRH45", det[1].bQuantity);
            }
          } else if (det[1].bContainerType == "PLT") {
            if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("PL20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("PL40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("PL45", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("PLH20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("PLH40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("PLH45", det[1].bQuantity);
            }
          } else if (det[1].bContainerType == "TNK") {
            if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("TK20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("TK40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("TK45", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("TKH20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("TKH40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("TKH45", det[1].bQuantity);
            }
          } else {
            if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("D20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("D40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "86"
            ) {
              mp2.set("D45", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "20" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("HC20", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "40" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("HC40", det[1].bQuantity);
            } else if (
              det[1].bContainerSize == "45" &&
              det[1].bContainerHeight == "96"
            ) {
              mp2.set("HC45", det[1].bQuantity);
            }
          }
        }
      }

      if (
        mp.get("D20") != mp2.get("D20") ||
        mp.get("D40") != mp2.get("D40") ||
        mp.get("D45") != mp2.get("D45") ||
        mp.get("HC20") != mp2.get("HC20") ||
        mp.get("HC40") != mp2.get("HC40") ||
        mp.get("HC45") != mp2.get("HC45") ||
        mp.get("R20") != mp2.get("R20") ||
        mp.get("R40") != mp2.get("R40") ||
        mp.get("R45") != mp2.get("R45") ||
        mp.get("RH20") != mp2.get("RH20") ||
        mp.get("RH40") != mp2.get("RH40") ||
        mp.get("RH45") != mp2.get("RH45") ||
        mp.get("OT20") != mp2.get("OT20") ||
        mp.get("OT40") != mp2.get("OT40") ||
        mp.get("OT45") != mp2.get("OT45") ||
        mp.get("OTH20") != mp2.get("OTH20") ||
        mp.get("OTH40") != mp2.get("OTH40") ||
        mp.get("OTH45") != mp2.get("OTH45") ||
        mp.get("FR20") != mp2.get("FR20") ||
        mp.get("FR40") != mp2.get("FR40") ||
        mp.get("FR45") != mp2.get("FR45") ||
        mp.get("FRH20") != mp2.get("FRH20") ||
        mp.get("FRH40") != mp2.get("FRH40") ||
        mp.get("FRH45") != mp2.get("FRH45") ||
        mp.get("PL20") != mp2.get("PL20") ||
        mp.get("PL40") != mp2.get("PL40") ||
        mp.get("PL45") != mp2.get("PL45") ||
        mp.get("PLH20") != mp2.get("PLH20") ||
        mp.get("PLH40") != mp2.get("PLH40") ||
        mp.get("PLH45") != mp2.get("PLH45") ||
        mp.get("TK20") != mp2.get("TK20") ||
        mp.get("TK40") != mp2.get("TK40") ||
        mp.get("TK45") != mp2.get("TK45") ||
        mp.get("TKH20") != mp2.get("TKH20") ||
        mp.get("TKH40") != mp2.get("TKH40") ||
        mp.get("TKH45") != mp2.get("TKH45")
      ) {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }
    return result;
  }

  checkCoctsExceed(): Boolean {
    // console.log("** check cocts exceed **");

    let result: Boolean = false;
    let availableCOC: Number = 0;
    let availableCOCTS: number = 0;
    let alloted: number = 0;

    if (this.model.bContainerOwnership == "COCTS") {
      //this.grid.listStore.store.forEach(element => {
      this.rowData.forEach((element) => {
        if (String(element.allotmentCode) == "COC") {
          availableCOC = Number(element.available);
        }
        if (String(element.allotmentCode) == "COC T/S") {
          availableCOCTS = Number.parseInt(element.available);
          alloted = Number.parseInt(element.alloted);
        }
      });

      /*
      if(availableCOC < 0){
        if(availableCOCTS > availableCOC){
          return true;
        }else if(availableCOCTS < 0){
          return true;
        }
      }else{
        if(availableCOCTS < 0){
          return true;
        }
      }
      */
      if (availableCOCTS < 0 && alloted > 0) {
        return true;
      }
    }
    return result;
  }

  checkCocLocalExceed(): Boolean {
    // console.log("** check coc local exceed **");

    let result: Boolean = true;
    let existCOCTS: Boolean = false;
    let existCOCLOCAL: Boolean = false;
    let availableCOC: number = 0;
    let availableCOCTS: number = 0;
    let availableCOCLocal: number = 0;
    let alloted: number = 0;

    // console.log(this.model.bContainerOwnership)

    if (this.model.bContainerOwnership == "COC") {
      //this.grid.listStore.store.forEach(element => {
      this.rowData.forEach((element) => {
        // console.log(element);

        if (String(element.allotmentCode) == "COC") {
          availableCOC = Number.parseInt(element.available);
        }

        if (String(element.allotmentCode) == "COC T/S") {
          availableCOCTS = Number.parseInt(element.available);
          existCOCTS = true;
        }

        if (String(element.allotmentCode) == "COC LOCAL") {
          availableCOCLocal = Number.parseInt(element.available);
          alloted = Number.parseInt(element.alloted);

          existCOCLOCAL = true;
        }
      });

      if (existCOCLOCAL && existCOCTS) {
        // console.log("availableCOC ==> " + availableCOC);
        // console.log("alloted ==> " + alloted);
        if (availableCOC >= 0) {
          // console.log("#1")
          return false;
        } else {
          if (alloted <= 0) {
            // console.log("#3")
            return false;
          }
        }
      } else {
        // console.log("#4")
        return false;
      }
    } else {
      // console.log("#5")
      return false;
    }

    return result;
  }

  checkCocLocalExceedForLabel(): Boolean {
    // console.log("** check coc local exceed for label **");

    let result: Boolean = true;
    let availableCOC: Number = 0;
    let availableCOCTS: Number = 0;
    let availableCOCLocal: Number = 0;

    // console.log(this.model.bContainerOwnership)

    if (this.model.bContainerOwnership == "COC") {
      //this.grid.listStore.store.forEach(element => {
      this.rowData.forEach((element) => {
        // console.log(element);

        if (String(element.allotmentCode) == "COC") {
          availableCOC = Number(element.available);
        }

        if (String(element.allotmentCode) == "COC T/S") {
          availableCOCTS = Number(element.available);
        }

        if (String(element.allotmentCode) == "COC LOCAL") {
          availableCOCLocal = Number(element.available);
        }
      });

      if (availableCOC >= 0 && availableCOCTS > 0 && availableCOCLocal < 0) {
        return false;
      }
    }
    return result;
  }

  checkBookingPartyValidator(): Boolean {
    // console.log("** check booking party validator **");
    // console.log(this.isSocBookingParty);

    let result: Boolean = false;

    if (
      this.isSocBookingParty == true &&
      ("SOC" == String(this.model.bContainerOwnership) ||
        "SOCTS" == String(this.model.bContainerOwnership))
    ) {
      result = false;
    } else if (
      this.isCocBookingParty == true &&
      ("COC" == String(this.model.bContainerOwnership) ||
        "COCTS" == String(this.model.bContainerOwnership))
    ) {
      result = false;
    } else if (
      this.isSlotBookingParty == true &&
      "SLOT" == String(this.model.bContainerOwnership)
    ) {
      result = false;
    } else {
      result = true;
    }

    return result;
  }
  checkAllocationValidator(): Boolean {
    // console.log("** check allocation validator **");
    var self = this;
    let result: Boolean = false;

    // console.log("size grid allocation = " + this.grid.listStore.store.length);
    //if(this.grid.listStore.store.length == 0){
    if (this.rowData.length == 0) {
      // console.log("#1");
      this.model["error-allocationValidator"] =
        "Allocation has not been set for this vessel, unable to create booking.";
      result = true;

      //check structure allocation

      //}else if(this.checkAllocationTest("1",this.grid.listStore.store) == true){
    } else if (this.checkAllocationTest("1", this.rowData) == true) {
      // console.log("#2");
      result = true;
    } else {
      // console.log("#3");
      //var pattern = this.getPattern();
      //// console.log("pattern : " + pattern);

      var defaultBookedTemp = "";
      //this.grid.listStore.store.forEach(element => {
      this.rowData.forEach((element) => {
        if (element.allotmentCode != "VS") {
          defaultBookedTemp = defaultBookedTemp + element.booked + ";";
        }
      });

      this.model["error-allocationValidator"] = "";
      var msg: String = "Allocation of ";
      var map = new Map();
      var lastPath: String = "";
      var strPath: String = "VS";
      //$(this.grid.listStore.store).each(function(i,element) {
      $(this.rowData).each(function (i, element) {
        let parentList: String[] = String(element.parent).trim().split(";");
        let chk: Boolean = self.checkParent(
          parentList,
          element.allotmentCode,
          self.model
        );

        if (chk) {
          if (strPath == String(element.parent)) {
            if (String(element.allotmentCode).startsWith("PD-")) {
              let str = String(element.allotmentCode).replace("PD-", "");
              if (
                str.trim() == String(self.model.bPodCode).trim() ||
                str.trim() == String(self.model.bPot1Code).trim()
              ) {
                // console.log("** allocation SS-" + self.model.bPodCode + " created");
                strPath = element.allotmentCode + ";" + strPath;
                result = false;
              } else {
                if (String(element.allotmentCode).startsWith("PD-")) {
                  // console.log("default booked : " + self.defaultBooked2);
                  // console.log("current booked : " + defaultBookedTemp);
                  if (defaultBookedTemp != self.defaultBooked2) {
                  } else {
                    if (
                      String(self.model.bPodCode) != "" &&
                      String(self.model.bPot1Code) == ""
                    ) {
                      // console.log("#1")
                      if (map.get("PD") == null) {
                        map.set(
                          "PD",
                          self.model.bPodName + " (" + self.model.bPodCode + ")"
                        );
                      }
                      result = true;
                    } else if (
                      String(self.model.bPodCode) != "" &&
                      String(self.model.bPot1Code) != ""
                    ) {
                      // console.log("#2")
                      if (map.get("PD") == null) {
                        map.set(
                          "PD",
                          self.model.bPot1Name +
                            " (" +
                            self.model.bPot1Code +
                            ")"
                        );
                      }
                      result = true;
                    }
                  }
                }
              }
            }

            if (
              "EMPTY" == String(element.allotmentCode) &&
              "EMPTY" == String(self.model.bContainerStatus)
            ) {
              // console.log("** allocation EMPTY created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            } else {
              if ("EMPTY" == String(element.allotmentCode)) {
                if (defaultBookedTemp != self.defaultBooked2) {
                } else {
                  if (map.get("CS") == null) {
                    map.set(
                      "CS",
                      "Container Status (" + self.model.bContainerStatus + ")"
                    );
                  }
                  result = true;
                }
              }
            }

            if (
              "LADEN" == String(element.allotmentCode) &&
              "FULL" == String(self.model.bContainerStatus)
            ) {
              // console.log("** allocation LADEN created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            } else {
              if ("LADEN" == String(element.allotmentCode)) {
                if (defaultBookedTemp != self.defaultBooked2) {
                } else {
                  if (map.get("CS") == null) {
                    map.set(
                      "CS",
                      "Container Status (" + self.model.bContainerStatus + ")"
                    );
                  }
                  result = true;
                }
              }
            }

            if (
              "SOC" == String(element.allotmentCode) &&
              ("SOC" == String(self.model.bContainerOwnership) ||
                "SOCTS" == String(self.model.bContainerOwnership))
            ) {
              // console.log("** allocation SOC created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            } else {
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if ("SOC" == String(element.allotmentCode)) {
                if (defaultBookedTemp != self.defaultBooked2) {
                } else {
                  if (map.get("CO") == null) {
                    map.set(
                      "CO",
                      "Container Ownership (" +
                        self.model.bContainerOwnership +
                        ")"
                    );
                  }
                  result = true;
                }
              }
            }

            if (
              "SOC LOCAL" == String(element.allotmentCode) &&
              "SOC" == String(self.model.bContainerOwnership)
            ) {
              // console.log("** allocation SOC LOCAL created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            } else {
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if ("SOC LOCAL" == String(element.allotmentCode)) {
                if (defaultBookedTemp != self.defaultBooked2) {
                } else {
                  if (map.get("CO") == null) {
                    map.set(
                      "CO",
                      "Container Ownership (" +
                        self.model.bContainerOwnership +
                        ")"
                    );
                  }
                  result = true;
                }
              }
            }

            if (
              "SOC T/S" == String(element.allotmentCode) &&
              "SOCTS" == String(self.model.bContainerOwnership)
            ) {
              // console.log("** allocation SOC T/S created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            } else {
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if ("SOC T/S" == String(element.allotmentCode)) {
                if (defaultBookedTemp != self.defaultBooked2) {
                } else {
                  if (map.get("CO") == null) {
                    map.set(
                      "CO",
                      "Container Ownership (" +
                        self.model.bContainerOwnership +
                        ")"
                    );
                  }
                  result = true;
                }
              }
            }

            if (
              "COC" == String(element.allotmentCode) &&
              ("COC" == String(self.model.bContainerOwnership) ||
                "COCTS" == String(self.model.bContainerOwnership))
            ) {
              // console.log("** allocation COC created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            } else {
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if ("COC" == String(element.allotmentCode)) {
                if (defaultBookedTemp != self.defaultBooked2) {
                } else {
                  if (map.get("CO") == null) {
                    map.set(
                      "CO",
                      "Container Ownership (" +
                        self.model.bContainerOwnership +
                        ")"
                    );
                  }
                  result = true;
                }
              }
            }

            if (
              "COC LOCAL" == String(element.allotmentCode) &&
              "COC" == String(self.model.bContainerOwnership)
            ) {
              // console.log("** allocation COC LOCAL  created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            } else {
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if ("COC LOCAL" == String(element.allotmentCode)) {
                if (defaultBookedTemp != self.defaultBooked2) {
                } else {
                  if (map.get("CO") == null) {
                    map.set(
                      "CO",
                      "Container Ownership (" +
                        self.model.bContainerOwnership +
                        ")"
                    );
                  }
                  result = true;
                }
              }
            }

            if (
              "COC T/S" == String(element.allotmentCode) &&
              "COCTS" == String(self.model.bContainerOwnership)
            ) {
              // console.log("** allocation COC T/S created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            } else {
              if ("COC T/S" == String(element.allotmentCode)) {
                if (defaultBookedTemp != self.defaultBooked2) {
                } else {
                  if (map.get("CO") == null) {
                    map.set(
                      "CO",
                      "Container Ownership (" +
                        self.model.bContainerOwnership +
                        ")"
                    );
                  }
                  result = true;
                }
              }
            }

            if (String(element.allotmentCode).startsWith("SS-")) {
              let str = String(element.allotmentCode).replace("SS-", "");

              if (str.trim() == String(self.model.bSlotOperatorCode).trim()) {
                // console.log("** allocation SS-" + self.model.bSlotOperatorCode + "  created");
                strPath = element.allotmentCode + ";" + strPath;
                result = false;
              } else {
                if (String(element.allotmentCode).startsWith("SS-")) {
                  if (String(self.model.bSlotOperatorCode) != "") {
                    if (defaultBookedTemp != self.defaultBooked2) {
                    } else {
                      if (map.get("SS") == null) {
                        map.set(
                          "SS",
                          "Slot Operator (" + self.model.bSlotOperatorCode + ")"
                        );
                      }
                      result = true;
                    }
                  }
                }
              }
            }

            // console.log('PATH : ' + strPath);
            // console.log(element.allotmentCode + " - " + element.allotmentLevel + " - parent : " + String(element.parent).trim() + " - check Parent : " + chk);
          }
        } else {
          //// console.log(element.allotmentCode + " - " + element.allotmentLevel + " - parent : " + String(element.parent).trim() + " - check Parent : " + chk);
        }
      });
    }

    // console.log('result : ' + result);
    if (result) {
      // console.log('tes');
      // console.log(map)
      if (map != undefined) {
        map.forEach(function (item, key, mapObj) {
          // console.log(key + " - " + item);
          msg = msg + item + ",";
        });

        msg = msg.substring(0, msg.length - 1);
        msg =
          msg + " has not been set for this vessel, unable to create booking.";

        // console.log("msg ==> ")
        // console.log(msg)
        this.model["error-allocationValidator"] = msg;
      }
    }

    return result;
  }

  checkPodAllocation(): Boolean {
    // console.log("** check pod allocation **");
    var self = this;
    let result: Boolean = false;
    let hit: Number = 0;

    if (this.model.bPot1Code == "" && this.model.bPodCode != "") {
      //$(this.grid.listStore.store).each(function(i,element) {
      $(this.rowData).each(function (i, element) {
        let parentList: String[] = String(element.parent).trim().split(";");
        let chk: Boolean = self.checkParent(
          parentList,
          element.allotmentCode,
          self.model
        );

        if (chk) {
          // console.log(element.allotmentCode + " - " + element.allotmentLevel + " - parent : " + String(element.parent).trim() + " - check Parent : " + chk);

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", "");
            // console.log(str + " - " + self.model.bPodCode);

            if (str.trim() == String(self.model.bPodCode).trim()) {
              //result = true;
              hit = 1;
              // console.log("PD SAME ");
              return false;
            } else {
              hit = 0;
            }
          } else {
            //result = false;
            hit = 1;
          }
        } else {
          //result = false;
        }
      });

      if (hit == 0) {
        this.model["error-podAllocationValidator"] =
          "No allocation created for " + this.model.bPodName;
        result = true;
      }
      // console.log("** RESULT check pod allocation ** : " + result);
    }

    return result;
  }

  checkSlotOperatorCode(): Boolean {
    let result: Boolean = false;

    if (this.model.bContainerOwnership === "SLOT") {
      //// pending
      if (
        this.model.bOutwardSlotOperatorCode === "" &&
        this.model.bOfficeCode != "SGSIN"
      ) {
        this.model["error-SlotOperatorCodeValidator"] = "Missing CNTR Code.";
        result = true;
      } else {
        this.model["error-SlotOperatorCodeValidator"] = "";
        result = false;
      }
    }
    return result;
  }

  checkOperatorCode(): Boolean {
    // console.log("** check operator code **");

    let result: Boolean = false;

    if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      if (this.model.bSocCntrOperatorCode == "") {
        this.model["error-operatorCodeValidator"] =
          "Select a valid SOC Operator Code.";
        result = true;
      } else {
        this.model["error-operatorCodeValidator"] = "";
        result = false;
      }
    } else if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      // console.log("DEBUG OPERATOR CODE");
      // console.log(this.model.bContainerOwnership + " --- " + this.model.bCocCntrOperatorCode);
      // console.log(this.model['error-operatorCodeValidator']);

      if (this.model.bCocCntrOperatorCode == "") {
        this.model["error-operatorCodeValidator"] =
          "Select a valid COC Operator Code.";
        result = true;
      } else {
        this.model["error-operatorCodeValidator"] = "";
        result = false;
      }
    } else if (this.model.bContainerOwnership == "SLOT") {
      // console.log(this.model.bSlotOperatorCode);

      if (this.model.bSlotOperatorCode == "") {
        this.model["error-operatorCodeValidator"] =
          "Select a valid SLOT Operator Code.";
        result = true;
      } else {
        // check di allocation
        // console.log("check allocation");

        let chk: Boolean = false;
        //this.grid.listStore.store.forEach(element => {
        this.rowData.forEach((element) => {
          let parentList: String[] = String(element.parent).split(";");
          if (this.checkParent(parentList, element.allotmentCode, this.model)) {
            if (String(element.allotmentCode).startsWith("SS-")) {
              let str = String(element.allotmentCode).replace("SS-", "");
              // console.log(str + " - " + this.model.bSlotOperatorCode);
              if (str.trim() == String(this.model.bSlotOperatorCode).trim()) {
                chk = true;
              }
            }
          }
        });

        // console.log('chk : ' + chk);
        if (!chk) {
          // console.log("#1");
          this.model["error-operatorCodeValidator"] =
            "Allocation is not set for op code " +
            this.model.bSlotOperatorCode +
            ".";
          result = true;
        } else {
          // console.log("#2");
          this.model["error-operatorCodeValidator"] = "";
          result = false;
        }
      }
    }
    return result;
  }

  checkParentExceeded(
    parentList: String[],
    allotmentCode: String,
    available: Number,
    masterBookings: Booking
  ) {
    let result: Boolean = false;

    if ("VS" != allotmentCode) {
      $(parentList).each(function (i, parent) {
        if (parent != "") {
          if ("VS" == parent) {
            if (available < 0) {
              return true;
            }
          } else if (
            parent.startsWith("PD") &&
            (parent.replace("PD-", "") == masterBookings.bPodCode ||
              parent.replace("PD-", "") == masterBookings.bPot1Code)
          ) {
            if (available < 0) {
              return true;
            }
          } else if (
            "EMPTY" == parent &&
            "EMPTY" == masterBookings.bContainerStatus
          ) {
            if (available < 0) {
              return true;
            }
          } else if (
            "LADEN" == parent &&
            "FULL" == masterBookings.bContainerStatus
          ) {
            if (available < 0) {
              return true;
            }
          } else if (
            "SOC" == parent &&
            ("SOC" == masterBookings.bContainerOwnership ||
              "SOCTS" == masterBookings.bContainerOwnership)
          ) {
            if (available < 0) {
              return true;
            }
          } else if (
            "SOC LOCAL" == parent &&
            "SOC" == masterBookings.bContainerOwnership
          ) {
            if (available < 0) {
              return true;
            }
          } else if (
            "SOC T/S" == parent &&
            "SOCTS" == masterBookings.bContainerOwnership
          ) {
            if (available < 0) {
              return false;
            }
          } else if (
            "COC" == parent &&
            ("COC" == masterBookings.bContainerOwnership ||
              "COCTS" == masterBookings.bContainerOwnership)
          ) {
            // console.log("available #2 : " + available)
            if (available < 0) {
              return true;
            }
          } else if (
            "COC LOCAL" == parent &&
            "COC" == masterBookings.bContainerOwnership
          ) {
            if (available < 0) {
              return true;
            }
          } else if (
            "COC T/S" == parent &&
            "COCTS" == masterBookings.bContainerOwnership
          ) {
            if (available < 0) {
              return true;
            }
          } else if (
            parent.startsWith("SS") &&
            parent.replace("SS-", "") == masterBookings.bSlotOperatorCode &&
            "SLOT" == masterBookings.bContainerOwnership
          ) {
            if (available < 0) {
              return true;
            }
          } else {
            return false;
          }
        }
      });
    } else {
      result = false;
    }
    //// console.log("result : " + result);
    return result;
  }

  checkParent(
    parentList: String[],
    allotmentCode: String,
    masterBookings: Booking
  ) {
    let result: Boolean = false;

    if ("VS" != allotmentCode) {
      let hit: Number = -1;

      $(parentList).each(function (i, parent) {
        //// console.log("#parent : " + parent);

        if (parent != "") {
          if (hit == 0) {
            return false;
          }

          if ("VS" == parent) {
            hit = 1;
          } else if (
            parent.startsWith("PD") &&
            (parent.replace("PD-", "") == masterBookings.bPodCode ||
              parent.replace("PD-", "") == masterBookings.bPot1Code)
          ) {
            //// console.log("check PD- " + parent + " - " + masterBookings.bPodCode);
            hit = 1;
          } else if (
            "EMPTY" == parent &&
            "EMPTY" == masterBookings.bContainerStatus
          ) {
            hit = 1;
          } else if (
            "LADEN" == parent &&
            "FULL" == masterBookings.bContainerStatus
          ) {
            hit = 1;
          } else if (
            "SOC" == parent &&
            ("SOC" == masterBookings.bContainerOwnership ||
              "SOCTS" == masterBookings.bContainerOwnership)
          ) {
            hit = 1;
          } else if (
            "SOC LOCAL" == parent &&
            "SOC" == masterBookings.bContainerOwnership
          ) {
            hit = 1;
          } else if (
            "SOC T/S" == parent &&
            "SOCTS" == masterBookings.bContainerOwnership
          ) {
            hit = 1;
          } else if (
            "COC" == parent &&
            ("COC" == masterBookings.bContainerOwnership ||
              "COCTS" == masterBookings.bContainerOwnership)
          ) {
            hit = 1;
          } else if (
            "COC LOCAL" == parent &&
            "COC" == masterBookings.bContainerOwnership
          ) {
            hit = 1;
          } else if (
            "COC T/S" == parent &&
            "COCTS" == masterBookings.bContainerOwnership
          ) {
            hit = 1;
          } else if (
            parent.startsWith("SS") &&
            parent.replace("SS-", "") == masterBookings.bSlotOperatorCode &&
            "SLOT" == masterBookings.bContainerOwnership
          ) {
            hit = 1;
          } else {
            hit = 0;
          }
        }
      });

      if (hit == 1) {
        result = true;
      }
    } else {
      result = true;
    }
    //// console.log("result : " + result);
    return result;
  }

  handleDelete() {
    // console.log('** delete event **');
    // console.log('** delete event **');
    /*
    var self = this;
    setTimeout(function() {
      self.dialogPlugin.show('save','Deleting data','Do you want to delete the record? ', 'yesno', { yes: 'this.deleteProcess()', no: 'this.loading = false;'});
    }, 50);
    */

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getLockBooking/" +
          this.model.bOfficeCode +
          "/" +
          this.model.bPrefix +
          "/" +
          this.model.bNo
      )
      .subscribe((resp) => {
        console.log(resp.json()["content"]);
        let det: any[] = resp.json()["content"];
        const message = resp.json().message;
        if (message) {
          this.dialogPlugin.show(
            "information",
            "Information",
            message,
            "okonly",
            { ok: "" }
          );
        }

        if (det["bUserId"] != undefined) {
          this.model.bIsUnlocked = "N";
          this.model["bLockBy"] = det["bUserId"];
        } else {
          this.model.bIsUnlocked = "Y";
          this.model["bLockBy"] = "";
        }

        if (
          this.model.bIsUnlocked == "Y" ||
          (this.model.bIsUnlocked == "N" &&
            this.model["bLockBy"] == this.cookieService.getName().toUpperCase())
        ) {
          this.frmDialogMessage = "Do you want to delete the record?";
          this.genericUtil.showDialog(
            "bookingDeleteDialogYesNo",
            "Do you want to delete the record?",
            425,
            425
          );
        } else {
          this.disableToolbarButtons =
            "retrieve,new,modify,save,copy,cancel,print,delete";
          this.dialogPlugin.show(
            "information",
            "Information",
            "Booking is locked by " + this.model["bLockBy"] + ".",
            "okonly",
            { ok: "this.genericUtil.hideLoader();" }
          );
        }
      });
  }

  onDialogBookingYesDelete() {
    this.model.bReason = this.txtReason.getValue();
    console.log("validator");
    this.setValidatorReasonBeforeDelete();
    let isPassedDel: boolean = this.onValidate(this.model);
    if (!isPassedDel) {
      this.isErrorDel = true;
      this.setDetail();
      this.deleteProcess();
    }
    /*
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/BL/delete/' +
      this.model.blOfficeCode.trim() + '/' + this.model.blNo.trim() +
      '/' + this.cookieService.getName()).subscribe((resp) => {
      if (resp.json()['status'] === 'OK') {
        console.log('delete masuk sini ');
        this.genericUtil.closeDialog('blDeleteDialogYesNo');
          this.frmDialogMessage = 'BL Successfully deleted.';
          this.genericUtil.showDialog('blDeleteDialogOk', 'Information', 300, 150);
      } else {
        console.log('delete masuk sana ');
        this.genericUtil.closeDialog('blDeleteDialogYesNo');
          this.frmDialogMessage = resp.json()['message'].toString();
          this.genericUtil.showDialog('blDeleteDialogOk', 'Information', 500, 200);
      }
    });
    */
  }

  onDialogBookingNoDelete() {
    this.txtReason.setValue("");
    this.genericUtil.closeDialog("bookingDeleteDialogYesNo");
  }

  deleteProcess() {
    this.genericUtil.showLoader();
    this.model.bStatus = "D";
    this.model.bDeletedUserid = this.cookieService.getName();
    this.model.bDeletedDate = this.genericUtil.getPSIDate();

    this.model.bModifiedUserid = this.cookieService.getName();
    this.model.bModifiedDate = this.genericUtil.getPSIDate();

    //console.log(this.model.bReason);

    this.genericUtil.closeDialog("bookingDeleteDialogYesNo");

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/BL/checkBLForDelete/" +
          this.model.bOfficeCode.toUpperCase() +
          "/SSL" +
          this.model.bNo.toUpperCase()
      )
      .subscribe((response) => {
        const dt = response.json();
        if (dt["status"] === "OK") {
          this.genericService
            .POST(
              this.configService.config.BASE_API.toString() +
                "/MasterBookings/delete",
              this.model
            )
            .subscribe(
              (resp) => {
                let data = resp.json();
                this.genericUtil.hideLoader();

                //GSO-1048 Enable <Cancel> <Back><Close>& <Send to Depot>, the rest button DISABLE.
                if(this.isSingaporeSite){
                  this.disableToolbarButtons =  this.model.bContainerOwnership === "COC" ? "retrieve,new,modify,save,copy,print,delete" : "retrieve,new,modify,save,copy,print,delete,sendDepot";
                }else{
                  this.disableToolbarButtons = "retrieve,new,modify,save,copy,cancel,print,delete";
                }
                this.model.bReason = "";
                this.isCheckedDepot = false;
                this.dialogPlugin.show(
                  "information",
                  "Information",
                  "Booking deleted successfully.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
              },
              (error) => {
                this.loading = false;
                // console.log(error.message);
                this.info = error.message;
              }
            );
        } else {
          this.genericUtil.hideLoader();
          // this.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
          this.txtReason.setValue("");
          this.dialogPlugin.show(
            "information",
            "Information",
            "Unable to delete Booking because the BL is still active.",
            "okonly",
            { ok: "this.loading=false;" }
          );
        }
      });
  }

  stopBookingValidation() {
    var locPort: String = "";
    var grabData = [];
    var portList = [];
    var terminalList = [];
    var cntOwnList = [];
    var cntStatList = [];
    var slotOPList = "ALL";
    var comeback = "";
    //var globalALL = 0;
    this.globalALL = 0;

    if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    )
      slotOPList = this.model.bSocCntrOperatorCode.toString();
    else if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    )
      slotOPList = this.model.bCocCntrOperatorCode.toString();
    else slotOPList = this.model.bSlotOperatorCode.toString();

    if (slotOPList == "") slotOPList = "ALL";
    console.log("slot Op List" + slotOPList);
    if (this.model.bPot1Code != "") {
      locPort = this.model.bPot1Code;
    } else {
      locPort = this.model.bPodCode;
    }

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/getStopBookingDataWithPortOfCall/" +
          this.model.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.bOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.bOceanVesselBound +
          "/" +
          this.model.bOfficeCode +
          "/" +
          locPort +
          "/" +
          this.model.bContainerOwnership +
          "/" +
          this.model.bContainerStatus +
          "/" +
          slotOPList +
          "/" +
          this.haveTerminal +
          "/" +
          this.originLocation
      )
      .subscribe((resp) => {
        //this.genericService.GET("http://localhost:10000/MasterSailingSchedules/getStopBookingDataWithPortOfCall/" + this.model.bOceanVesselId + "/" + this.model.bOceanVesselVoyage + "/" + this.model.bOceanVesselBound + "/" + this.model.bOfficeCode+'/'+locPort+'/'+this.model.bContainerOwnership+'/'+this.model.bContainerStatus).subscribe((resp) => {

        if (resp.status == 200) {
          if (Object.keys(resp.json()["content"]).length > 0) {
            this.stopDetection = 1;
            console.log("mmmasuk lenght");
            resp.json()["content"].forEach((spal) => {
              if (
                spal.portofcall == "ALL" &&
                spal.bcntowner == "ALL" &&
                spal.bcntstatus == "BOTH" &&
                spal.slotoperator == ""
              ) {
                console.log("mmmasuk ALL");
                this.globalALL = 1;

                //this.globalALL = 'ALL'
              }
              this.stopByAll = spal.stopby;

              portList.push(spal.portofcall);
              // terminalList.push(spal.terminalcode);
              cntOwnList.push(spal.bcntowner);
              cntStatList.push(spal.bcntstatus);
              //slotOPList.push(spal.slotoperator)
            });
          } else {
            //end object key lenght
            this.globalALL = 0;
            this.stopDetection = 0;
          }

          console.log(portList);
          console.log(comeback);
          console.log(cntOwnList);
          console.log(cntStatList);
          //console.log(slotOPList)
        } else {
          var self = this;
          setTimeout(function () {
            self.dialogPlugin.show(
              "information",
              "Information",
              "Error Occured, please contact administrator",
              "okonly",
              { ok: "this.loading=false" }
            );
          }, 50);
        }
      });
  }
  saveEvent() {
    const officeCode = this.cookieService.getDefaultLocationCode().substring(0, 2);
    if (
      (officeCode && officeCode === 'CN' && !this.isModify) ||
      (officeCode && officeCode === 'CN' && !this.model['bNo'])
     ) {
      this.model.isCreateNewBL = "1"
      const SUDU = "SUDU";
      let bContainerOwnership = '';
      const POL = this.model.bPolBooking;
      const POD = this.model.bPodCode ? this.model.bPodCode.substring(this.model.bPodCode.length - 3) : '';
      switch (this.model.bContainerOwnership) {
        case 'SOC':
        case 'SOCTS':
          bContainerOwnership = 'S';
          break;
        case 'COC':
        case 'COCTS':
          bContainerOwnership = 'C';
          break;
        default:
          bContainerOwnership = 'P';
      }
      this.bookingNumberPrefixValueDefaute = SUDU;
      this.bookingNumberValueDefaute = POL + POD + bContainerOwnership;
      if (this.model['bPsaReferenceNo']) {
        this.shipperPortnetReterence = this.model['bPsaReferenceNo'];
      }
      this.genericUtil.showDialog(
        "newBookingHandling",
        "Saving Booking Data",
        600,
        225
      );
    } else {

      this.model.isCreateNewBL = "";
      if(officeCode && officeCode === 'CN' 
        && (this.isModify || this.isNew)   && this.model['bNo'] ){
          if (this.isNew && this.model.bStatus=='U'){
            this.model.bStatus='N'
           
          }
          if(this.model.bStatus=='N'){
            this.model.isCreateNewBL = "1";
          }
      }

      this.showDialogConfirmSave();
    }
  }

  newBookingHandling(){

    if(!this.bookingNumberValue){
      this.errorMessageBookingNumber = 'Please input Booking Number';
      //return
    }else{
      if (!this.regexBookingNumber.test(this.bookingNumberValue)) {
        this.errorMessageBookingNumber = 'Should follow by 2 chars (ranging from AA to ZZ) and follow by 4 digits';
        //return
      }else{
        this.errorMessageBookingNumber = null;
      }
    }

    if(!this.shipperPortnetReterence){
      this.errorMessagePortnetReference = 'Please input Shipper / Portnet Reference';
      //return
    }else{
      if(!this.bookingNumberValue){
        this.shipperPortnetReterence = null;
        this.errorMessagePortnetReference = 'Please input Shipper / Portnet Reference';
      }else{
        this.errorMessagePortnetReference = null;
      }
    }

    console.log("this.errorMessageBookingNumber = " + this.errorMessageBookingNumber);
    console.log("this.errorMessagePortnetReference = " + this.errorMessagePortnetReference);
    if(this.errorMessageBookingNumber !== null || this.errorMessagePortnetReference !== null){
      return;
    }

    if(!this.errorMessageBookingNumber && !this.errorMessagePortnetReference){
      this.model.bNo = this.bookingNumberValueDefaute.replace('SUDU', '') + this.bookingNumberValue.toUpperCase();
      this.model.bPsaReferenceNo = this.shipperPortnetReterence;
      this.showDialogConfirmSave();
      this.resetNewBookingHandling();
      this.genericUtil.closeDialog("newBookingHandling");
    }
  }

  closeNewBookingHandling(){
    this.resetNewBookingHandling();
    if(this.isNew === true){
      this.model.bNo = "";
      this.model.bPsaReferenceNo = "";
    }
    this.genericUtil.closeDialog("newBookingHandling");
  }

  resetNewBookingHandling(){
    this.bookingNumberValue = "";
    this.shipperPortnetReterence = null;
    this.errorMessageBookingNumber = null;
    this.errorMessagePortnetReference = null;
  }

  valiCharNumOnlyWOSpace(event){
    if (event && !this.regexCharNumOnlyWOSpace.test(event)) {
      this.errorMessageBookingNumber = 'Only accept alphanumeric';
    }else{
      this.errorMessageBookingNumber = null;
    }
    if (event && !this.regexBookingNumber.test(event)) {
      this.errorMessageBookingNumber = 'Should follow by 2 chars (ranging from AA to ZZ) and follow by 4 digits';
    } else {
      this.errorMessageBookingNumber = null;
    }
  }

  enterCharNumOnlyWOSpace(event){
    this.newBookingHandling();
  }

  valiPortnetReference(event){
    if (event.target.value && !this.formValidation.regPortnetReference.test(event.target.value)) {
      this.errorMessagePortnetReference = 'Alphanumeric, (-) and (/) allowed';
    }else{
      this.errorMessagePortnetReference = null;
    }
  }

  focusOutBookingNumber(){
    this.shipperPortnetReterence = this.bookingNumberPrefixValueDefaute + this.bookingNumberValueDefaute + this.bookingNumberValue;
  }

  showDialogConfirmSave() {
    // console.log('** save event **');
    //validation stop booking first
    var stopScenario = 0;
    var self = this;

    // self.stopBookingValidation();

    console.log("masuk save");

    setTimeout(function () {
      self.dialogPlugin.show(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveProcess()", no: "this.loading = false;this.noConfirm();" }
      );
    }, 50);
  }

  noConfirm(){
    console.log("** noConfirm **");
    // console.log(this.isNew);
    // console.log(this.isModify);
    // console.log(this.isCopy);
    // console.log(this.isCHINASiteEDI_NewBkg);
    /*
    if( (this.isNew === true || this.isCopy == true)  
       && this.isCHINASiteEDI_NewBkg==false && !this.model.bOfficeCode.startsWith("CN")){
      this.model.bNo = "";
      this.model.bPsaReferenceNo = "";
    }
    */

    if (this.isCopy == true){
      this.isCopy=false;
    }
  }

  saveProcess() {
    this.genericUtil.showLoader();
    /*
    if(this.model.etdPOL != '' && this.model.etdPOL != undefined){
      this.model.etdMaster = this.model.ETD;
      this.model.ETD = this.model.etdPOL;
    }
    */
   if(this.model.bPot1Code == 'SGSIN' && this.model.bNextMotherVesselCode && this.model.bNextMotherVesselVoyage && this.model.bPendingRequest != 'N'){
     this.model.bPendingRequest = 'Y';
   } else {
     this.model.bPendingRequest = 'N';
    };

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/PFCGenericAPI/freightingFromBooking",
        this.model
      )
      .subscribe((refreightResp) => {
        // console.log("** refreight when save booking **")
        //// console.log(refreightResp.json());
        if (refreightResp.json()["status"] == "OK") {
          this.model.bIsFinished = "Y";
          let refreightList: any[] = refreightResp.json()["content"];
          let no: number = 1;
          this.model.bookingFreightCharges = [];
          refreightList.forEach((element) => {
            let bfr = new BookingFreightCharge();
            bfr.bOfficeCode = this.model.bOfficeCode;
            bfr.bPrefix = this.model.bPrefix;
            bfr.bNo = this.model.bNo;
            bfr.bFrtSeqNo = no++;
            bfr.bContainerType = element.containerType;
            bfr.bChargeCode = element.chargeCode;
            bfr.bPerCode = element.perCode;
            bfr.bCurrencyCode = element.currencyCode;
            bfr.bFreightType = element.pc;
            bfr.bPayerCode = element.payer;
            bfr.bIsFaiGroup = element.faiGroup;
            bfr.bRecordId =
              this.model.bFreightType != null &&
              this.model.bFreightType != undefined &&
              this.model.bFreightType != ""
                ? element.recordId + "|" + this.model.bFreightType
                : element.recordId;

            console.log("element.perCode ==> " + element.perCode);
            if (
              element.perCode == "D20" ||
              element.perCode == "TK20" ||
              element.perCode == "R20" ||
              element.perCode == "OT20" ||
              element.perCode == "PL20" ||
              element.perCode == "FR20" ||
              element.perCode == "UC20"
            ) {
              bfr.bContainerSize = "20";
              bfr.bContainerHeight = "86";
            }

            if (
              element.perCode == "HC20" ||
              element.perCode == "TKH20" ||
              element.perCode == "RH20" ||
              element.perCode == "OTH20" ||
              element.perCode == "PLH20" ||
              element.perCode == "FRH20" ||
              element.perCode == "UCH20"
            ) {
              bfr.bContainerSize = "20";
              bfr.bContainerHeight = "96";
            }

            if (
              element.perCode == "D40" ||
              element.perCode == "TK40" ||
              element.perCode == "R40" ||
              element.perCode == "OT40" ||
              element.perCode == "PL40" ||
              element.perCode == "FR40" ||
              element.perCode == "UC40"
            ) {
              bfr.bContainerSize = "40";
              bfr.bContainerHeight = "86";
            }

            if (
              element.perCode == "HC40" ||
              element.perCode == "TKH40" ||
              element.perCode == "RH40" ||
              element.perCode == "OTH40" ||
              element.perCode == "PLH40" ||
              element.perCode == "FRH40" ||
              element.perCode == "UCH40"
            ) {
              bfr.bContainerSize = "40";
              bfr.bContainerHeight = "96";
            }

            if (
              element.perCode == "D45" ||
              element.perCode == "TK45" ||
              element.perCode == "R45" ||
              element.perCode == "OT45" ||
              element.perCode == "PL45" ||
              element.perCode == "FR45" ||
              element.perCode == "UC45"
            ) {
              bfr.bContainerSize = "45";
              bfr.bContainerHeight = "86";
            }

            if (
              element.perCode == "HC45" ||
              element.perCode == "TKH45" ||
              element.perCode == "RH45" ||
              element.perCode == "OTH45" ||
              element.perCode == "PLH45" ||
              element.perCode == "FRH45" ||
              element.perCode == "UCH45"
            ) {
              bfr.bContainerSize = "45";
              bfr.bContainerHeight = "96";
            }

            bfr.bRevenue = element.quantity;
            bfr.bRate = element.rate;
            bfr.bAmount = element.chargeAmount;

            bfr.popCode = element.pop;

            //// console.log(element);
            this.model.bookingFreightCharges.push(bfr);
          });
          //this.model.bookingFreightCharges = refreightResp.json()['content'];
        } else {
          this.model.bIsFinished = "N";
          this.model.bookingFreightCharges = [];
        }

        // GSO-504
        if (this.model.bIsTransmittedToPsa == "Y") {
          this.model.bIsTransmittedToPsa = "N";
          this.model.bTransmittedToPsaDate = "";
          this.model.bTransmittedToPsaUserid = "";
        }

      

        console.log("** before save booking **");
        console.log(this.model);
        console.log("** before save booking extra**");
        console.log(this.model.bookingExtra);
        console.log(this.model);
        this.model.bPolBooking = this.originalBookingRef;
        this.genericUtil.showLoader();


        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/MasterBookings/save",
            this.model
          )
          .pipe(finalize(() => this.genericUtil.hideLoader()))
          .subscribe(
            (resp) => {
              console.log("this.isCHINASiteEDI_NewBkg = " + this.isCHINASiteEDI_NewBkg);

              let data = resp.json();
              //// console.log(data.bNo);
              //// console.log(this.model);

            if(data.status == 'nok'){
              if(data.message!=undefined && data.message!=''){
                console.log("#error0");
                this.dialogPlugin.show(
                  "error",
                  "Error",
                  data.message,
                  "okonly",
                  { ok: "this.loading=false" }
                );
              }

              if(data.msg!=undefined && data.msg!='' ){
                console.log("#error1");
                this.dialogPlugin.show(
                  "error",
                  "Error",
                  data.msg,
                  "okonly",
                  { ok: "this.loading=false" }
                );
              }

              if(data.invalidBLNoMsg!=undefined && data.invalidBLNoMsg[0]){
                console.log("#error2");
                let messageError = data.invalidBLNoMsg.join(' ');

                this.dialogPlugin.show(
                  "error",
                  "Error",
                  messageError,
                  "okonly",
                  { ok: "this.loading=false" }
                );

              }

            
              if(data.invalidBookingNoMsg!=undefined && data.invalidBookingNoMsg[0]){
                let messageError = data.invalidBookingNoMsg.join(' ');
                //this.model['bNo'] = null;
                // if(this.isChinaSite == true){
                //     if(this.isNew === true){
                      this.model.bNo = "";
                      this.model.bPsaReferenceNo = "";
                    // }
                // }
                this.dialogPlugin.show(
                  "error",
                  "Error",
                  messageError,
                  "okonly",
                  { ok: "this.loading=false" }
                );
              }

              if(data.invalidShipperMsg!=undefined && data.invalidShipperMsg[0]){
                let messageError = data.invalidShipperMsg.join(' ');
                //this.model.bPsaReferenceNo = "";
                this.dialogPlugin.show(
                  "error",
                  "Error",
                  messageError,
                  "okonly",
                  { ok: "this.loading=false" }
                );
              }
              
              return;
            }

              this.model.bNo = data.bNo;
              this.model.bPsaReferenceNo = data.bPsaReferenceNo;
              this.model.bIsUnlocked = data.bIsUnlocked;
              this.model.bTransmitToSds = data.bTransmitToSds;
              
              console.log("#this.isCopy" + this.isCopy);
              if ( this.isCHINASiteEDI_NewBkg==true && this.isCopy==false){
                
                let shaBooking_model : sha_booking_edi;
                shaBooking_model=new sha_booking_edi();
                shaBooking_model.ediId=this.model.shaEdiId.toString();
                shaBooking_model.bNo='SSL' + this.model.bNo.toString();

                this.genericService
                .POST(
                  this.configService.config.BASE_API.toString() + "/sha/acceptBookingContainer",
                  //"http://localhost:14000/sha/rejectBookingContainer",
                  shaBooking_model
                )
                .subscribe(
                  (resp) => {
                    if (resp.status == 200 ) { //return 500
                      //clear all

                      console.log("Booking(s) accepted successfully");

                      // this.dialogPlugin.show(
                      //   "information",
                      //   "Information",
                      //   "Booking(s) accepted.",
                      //   "okonly",
                      //   { ok: "this.loading=false;" }
                      // );
                    } else {

                      this.dialogPlugin.show(
                        "warning",
                        "Warning",
                        "No Booking(s) accepted now.",
                        "okonly",
                        { ok: "this.loading=false;" }
                      );
                      return;
                    }
                  },
                  (error) => {
                    this.loading = false;
                    // console.log(error.message);
                    this.info = error.message;
                    return;
                  }
                );
              }

              let msg =
                "Save Successfully with booking number " + this.model.bNo;
              if (this.model.bookingFreightCharges.length == 0) {
                msg =
                  "Save Successfully, pricing role not found, without freight charges (" +
                  this.model.bNo +
                  ")";
              }
              console.log("copyy bukjan " + this.isCopy);
              
              if (this.isCopy) {
                this.dialogPlugin.show(
                  "information",
                  "Information",
                  msg,
                  "okonly",
                  { ok: "this.loading=false;this.afterSaveCopy();this.genericUtil.hideLoader();" }
                );
              } else {
                if (data.bIsUnlocked == "N") {
                  this.checkLockAfterSave = true;
                } else {
                  this.checkLockAfterSave = false;
                }

                this.dialogPlugin.show(
                  "information",
                  "Information",
                  msg,
                  "okonly",
                  { ok: "this.loading=false;this.afterSave();this.genericUtil.hideLoader();" }
                );
              }
            },
            (error) => {
              this.loading = false;
              // console.log(error.message);
              this.dialogPlugin.show(
                "error",
                "Error",
                'An error has occurred',
                "okonly",
                { ok: "this.loading=false" }
              );
            }
          );


      });
  }
  saveExtra() {
    console.log("lagi mogok dulu :)");
    // this.bookingExtra.bPrefix = this.model.bPrefix.toString();
    // this.bookingExtra.bOfficeCode = this.model.bOfficeCode.toString();
    // this.bookingExtra.bNo = this.model.bNo.toString();
    // this.bookingExtra.bPrefix = this.model.bPrefix.toString();
    // this.bookingExtra.bOfficeCode = this.model.bOfficeCode.toString();
    // this.bookingExtra.bReeferDegree = this.bookingExtra.bReeferDegree.toString();
    // this.bookingExtra.bNatureTemp = this.bookingExtra.bNatureTemp.toString();
    // this.bookingExtra.bNo = this.model.bNo.toString();
    // this.bookingExtra.bReeferCollectionDate =
    //   this.bookingExtra.bReeferCollectionDate.toString();
    // this.bookingExtra.bDegreeSo = this.bookingExtra.bDegreeSO.toString();
    // this.bookingExtra.bSetOption = this.bookingExtra.bSetOption.toString();
    // this.bookingExtra.bIsDry = this.bookingExtra.bIsDry.toString();
    // this.bookingExtra.bVent = this.bookingExtra.bVent.toString();
    // this.bookingExtra.bVentNumber = this.bookingExtra.bVentNumber;
    // this.bookingExtra.bVentOption = this.bookingExtra.bVentOption.toString();
    // this.bookingExtra.bHumidity = this.bookingExtra.bHumidity.toString();

    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBookings/saveExtra",
    //     [this.bookingExtra]
    //   )
    //   .subscribe(
    //     (resp) => {},
    //     (error) => {
    //       this.loading = false;
    //       // console.log(error.message);
    //       this.info = error.message;
    //     }
    //   );
  }

  changeMisc(event) {
    if (event.target.checked) {
      this.bookingExtra.bRemarkIsPrinted = "Y";
    } else {
      this.bookingExtra.bRemarkIsPrinted = "N";
    }

    console.log("misc: " + this.bookingExtra.bRemarkIsPrinted);
  }

  afterSave() {
    console.log("** AFTER SAVE **");
    this.doUnlock(
      this.model.bOfficeCode,
      this.model.bPrefix,
      this.model.bNo,
      this.cookieService.getName()
    );
    // this.saveExtra();
    this.haveTerminal = "N";
    this.originLocation = "NNN";
    this.isEngKongContainer = this.bookingMisc.bReleaseAt == 'ENG KONG CTR - SIN';
    if (this.checkLockAfterSave) {
      this.appStatusLabel = "-- LOCKED --";
      this.disableToolbarButtons = "retrieve,new,modify,save,copy,print,delete,sendDepot";

      this.isInit = true;
      this.lockEdit = true;
      this.lock = true;
      this.isModify = false;
    } else {
      this.info = "save successfully";
      //rubah state yg modify
      //this.disableToolbarButtons = 'retrieve,save,copy';
      this.lock = true;
      this.lockEdit = true;
      this.isModify = false;
      this.isNew=false;
    }
    this.isAfterSaveCopy = false;

    var self = this;
    console.log("POL = " + this.model.bPolCode);
    if (this.rotation.includes(this.model.bPolCode.toString())) {
      console.log("Masuk A");
      // this.cbLoadPort.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
      // );

      this.cbLoadPort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.bOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.bOceanVesselBound +
          "/{query}"
      );

      let uri =
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByCode/" +
          this.model.bPolCode.toString();

      this.genericService.GET(uri).subscribe((resp) => {
          if (resp.ok) {
            this.model.bPolBooking = resp.json()["locationBookingRef"];
            this.originalBookingRef = resp.json()["locationBookingRef"];
            //this.model.bPolName = resp.json()["locationName"];
            this.cbLoadPort.setForceValue(resp.json()["locationName"]);
            const masterLocations = resp.json().masterLocationTerminals;
            const polTerminal = masterLocations.find(element => element.locationCode === this.model.bPolTerminalCode);

            this.cbLoadPortTerminal.setForceValue(polTerminal.locationName);
          }
      });

      // this.cbLoadPort.setValue(
      //   this.model.bPolCode.toString(),
      //   function (callbackPOL) {
      //     console.log("---callbacak pol after get rotation fired---");
      //     self.model.bPolBooking = callbackPOL.locationBookingRef;
      //     self.cbLoadPort.setUrl(
      //       self.configService.config.BASE_API.toString() +
      //         "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails3/" +
      //         self.cookieService.getDefaultLocationCode() +
      //         "/" +
      //         self.model.bOceanVesselId +
      //         "/" +
      //         self.sCharUtil.htmlEncode(
      //           String(self.model.bOceanVesselVoyage).trim()
      //         ) +
      //         "/" +
      //         self.model.bOceanVesselBound +
      //         "/{query}"
      //     );
      //   }
      // );
    } else {
      console.log("Masuk B");
      //if(self.cookieService.getDefaultLocationCode().startsWith("TH")){
      if (this.cleanRotation.includes(this.model.bPolCode.toString())) {
        // this.cbLoadPort.setUrl(
        //   this.configService.config.BASE_API.toString() +
        //     "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
        // );

        this.cbLoadPort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
            this.cookieService.getDefaultLocationCode() +
            "/" +
            this.model.bOceanVesselId +
            "/" +
            this.sCharUtil.htmlEncode(
              String(this.model.bOceanVesselVoyage).trim()
            ) +
            "/" +
            this.model.bOceanVesselBound +
            "/{query}"
        );

        let uri =
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByCode/" +
          this.model.bPolCode.toString();

        this.genericService.GET(uri).subscribe((resp) => {
            if (resp.ok) {
              this.model.bPolBooking = resp.json()["locationBookingRef"];
              this.originalBookingRef = resp.json()["locationBookingRef"];
              //this.model.bPolName = resp.json()["locationName"];
              this.cbLoadPort.setForceValue(resp.json()["locationName"]);
              this.cbLoadPortTerminal.setForceValue(resp.json()["terminalName"]);
              console.log(resp.json());
            }
        });

        // this.cbLoadPort.setValue(
        //   this.model.bPolCode.toString(),
        //   function (callbackPOL) {
        //     console.log("---callbacak pol after get rotation fired---");
        //     self.model.bPolBooking = callbackPOL.locationBookingRef;
        //     self.cbLoadPort.setUrl(
        //       self.configService.config.BASE_API.toString() +
        //         "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails3/" +
        //         self.cookieService.getDefaultLocationCode() +
        //         "/" +
        //         self.model.bOceanVesselId +
        //         "/" +
        //         self.sCharUtil.htmlEncode(
        //           String(self.model.bOceanVesselVoyage).trim()
        //         ) +
        //         "/" +
        //         self.model.bOceanVesselBound +
        //         "/{query}"
        //     );
        //   }
        // );
      } else {
        console.log("Masuk C");
        this.cbLoadPort.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
            this.cookieService.getDefaultLocationCode() +
            "/" +
            this.model.bOceanVesselId +
            "/" +
            this.sCharUtil.htmlEncode(
              String(this.model.bOceanVesselVoyage).trim()
            ) +
            "/" +
            this.model.bOceanVesselBound +
            "/{query}"
        );
        //this.cbLoadPort.setValue("");
        this.cbLoadPort.setForceValue("");
        // this.cbLoadPortTerminal.setForceValue("");
        this.model.bPolCode = "";
      }
      /*} else {
        this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/{query}');
        this.cbLoadPort.setValue('');
        this.model.bPolCode = '';
      }*/
      console.log("Masuk D");
      //this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId  + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/{query}');
      //this.cbLoadPort.setValue('');
      //this.model.bPolCode = '';
    }

    // GSO-1034 Modification detected (Click <Modify>, <Save or Save & Copy>), depot transmission checkbox will be reset <N>
    this.isCheckedDepot = false;

    // 1048 Retrieve> button need enabled after saving action
    // GOS-805 Incorrect buttons enabled after prompter dismissal of successful booking creation
    setTimeout(() => {
      this.disableToolbarButtons = "modify,save,copy,delete";
      this.handleCheckStatusDepot();
    }, 0);

    if(this.isCHINASiteEDI_NewBkg==true){

      if (localStorage.getItem("Shanghai-booking-process-data-grid") != null &&
          (self.ChinaBookingselectedList.length<1 || self.ChinaBookingselectedList== null)
      ){

        this.isCHINASiteEDI_NewBkg=false;
        self.router.navigate(["/main/edi-process-edi-booking"]);

      }else{
        this.ngAfterViewInit();
      }
    }

  }

  afterSaveCopy() {
    console.log("** AFTER SAVE COPY **");

    this.doUnlock(
      this.model.bOfficeCode,
      this.model.bPrefix,
      this.model.bNo,
      this.cookieService.getName()
    );
    this.saveExtra();
    /*.;..
    this.info = 'save successfully';
    this.disableToolbarButtons = 'retrieve,new,modify,print,delete';
    this.lock = false;
    this.lockTNK = false;
    this.lockEdit = true;
    */

    if (this.checkLockAfterSave) {
      this.appStatusLabel = "-- LOCKED --";
      this.disableToolbarButtons = "retrieve,new,modify,save,copy,print,delete,sendDepot";

      this.isInit = true;
      this.lockEdit = true;
      this.lock = true;
      this.isModify = false;
    } else {
      this.info = "save successfully";
      this.lock = false;
      this.lockTNK = false;
      this.lockEdit = true;
      // GSO-1063
      this.checkDisableSlotLc();

      this.isNew=true;
      
    }

    //GCHO-84
    if(this.isCopy==true){
      this.model.bEdiBookingCommodity = "";

      if(this.model.bOfficeCode.startsWith("CN")){
        this.model['bCreatedDate'] = '';
        this.model['bCreatedUserid'] = '';
      }
    }

    if(this.cookieService.getDefaultLocationCode() == "SGSIN" && this.isCopy){
      this.lockEdit = false;
      this.isCopySuccess = true;
    }

    this.isCopy = false;
    this.currentContainerType = "STD";
    this.lockSTD = false;
    this.d20 = 0;
    this.d40 = 0;
    this.d45 = 0;
    this.h20 = 0;
    this.h40 = 0;
    this.h45 = 0;
    this.model.bNo = "";
    this.model.bPsaReferenceNo = "";
    this.model.bookingDetails = [];
    this.isAfterSaveCopy = true;

    //reset the loading port to trigger the bloadingport prefix

    let self = this;
    // this.cbLoadPort.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
    // );

    this.cbLoadPort.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.bOceanVesselId +
        "/" +
        this.sCharUtil.htmlEncode(
          String(this.model.bOceanVesselVoyage).trim()
        ) +
        "/" +
        this.model.bOceanVesselBound +
        "/{query}"
    );

    let uri =
      this.configService.config.BASE_API.toString() +
      "/MasterLocations/findByCode/" +
      this.model.bPolCode.toString();

    this.genericService.GET(uri).subscribe((resp) => {
      if (resp.ok) {
          this.model.bPolBooking = resp.json()["locationBookingRef"];
          this.originalBookingRef = resp.json()["locationBookingRef"];
          //this.model.bPolName = resp.json()["locationName"];
          this.cbLoadPort.setForceValue(resp.json()["locationName"]);
          this.cbLoadPortTerminal.setForceValue(resp.json()["terminalName"]);
      }
    });

    // this.cbLoadPort.setValue(
    //   this.model.bPolCode.toString(),
    //   function (callbackPOL) {
    //     self.model.bPolCode = callbackPOL.locationCode;
    //     self.model.bPolBooking = callbackPOL.locationBookingRef;
    //     //self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}');
    //     self.cbLoadPort.setUrl(
    //       self.configService.config.BASE_API.toString() +
    //         "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails3/" +
    //         self.cookieService.getDefaultLocationCode() +
    //         "/" +
    //         self.model.bOceanVesselId +
    //         "/" +
    //         self.sCharUtil.htmlEncode(
    //           String(self.model.bOceanVesselVoyage).trim()
    //         ) +
    //         "/" +
    //         self.model.bOceanVesselBound +
    //         "/{query}"
    //     );
    //   }
    // );

    console.log("this.model.bPot1Code = " + this.model.bPot1Code);
    if (this.model.bPot1Code  != "" && this.model.bPot1Code  != undefined) {
      this.cbDischargePort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}"
      );
    } else {
      this.cbDischargePort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
          this.cookieService.getDefaultLocationCode()+"/"+
          this.model.bOceanVesselId+"/"+
          this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim())+"/"+
          this.model.bOceanVesselBound+"/{query}"
      );
    }

    // if (this.model.bPot1Code == "") {
      // this.cbDischargePort.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails3/" +
      //     this.cookieService.getDefaultLocationCode() +
      //     "/" +
      //     this.model.bOceanVesselId +
      //     "/" +
      //     this.sCharUtil.htmlEncode(
      //       String(this.model.bOceanVesselVoyage).trim()
      //     ) +
      //     "/" +
      //     this.model.bOceanVesselBound +
      //     "/{query}"
      // );
      // this.cbDischargePort.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
      //     this.cookieService.getDefaultLocationCode()+"/"+
      //     this.model.bOceanVesselId+"/"+
      //     this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim())+"/"+
      //     this.model.bOceanVesselBound+"/{query}"
      // );
    // }

    console.log("this.cbDischargePort.url = " + this.cbDischargePort.url);

    this.bookingDetails.clear();
    let list = new Map<String, BookingDetail>();
    this.bookingDetails.set(this.currentContainerType, list);

    //** Added by glg 14 August 2018 as per miki request

    if (!this.isSingaporeSite) {
      this.model.bRemarks = "";
      this.txtRemarks.setValue("");
      this.killSlot = 0;

      //gos-292
      this.model.bTelephone = "";
      this.model.bContactPerson = "";

      this.model.bContainerOwnership = "SOC";

      // Container ownership
      this.model.bSocOperatorCode = "SA";
      this.model.bCocOperatorCode = "";

      this.cbSlotOPR.clearSelect();
      this.cbSlotOPR.listStore.store = [];
      this.cbSlotOPR.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findSlotOperator/" +
        this.model.bBookingPartyCode
      );
      this.cbSlotOPR.loadSelect();

      this.cbSocOPR.clearSelect();
      this.cbSocOPR.listStore.store = [];
      this.cbSocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findSocOperator/" +
        this.model.bBookingPartyCode
      );
      this.cbSocOPR.loadSelect();

      this.cbCocOPR.clearSelect();
      this.cbCocOPR.listStore.store = [];
      this.cbCocOPR.url = "";
      this.cbCocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findCocOperator/" +
        this.model.bBookingPartyCode
      );
      this.cbCocOPR.loadSelect();

      this.cbSlotCntrOPR.clearSelect();
      this.cbSlotCntrOPR.listStore.store = [];
      this.cbSlotCntrOPR.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findSlotOperator/" +
        this.model.bBookingPartyCode
      );
      this.cbSlotCntrOPR.loadSelect();
      this.model.bOutwardSlotOperatorCode = "";


      // Booking note - 2
      this.model.bDetention = "N";
      this.model.bDemurrage = "N";
      this.model.bDetentionRequest = 0;
      this.model.bDemurrageRequest = 0;

      // Mother vessel info
      this.cbExVessel.setValue("");
      this.model.bExMotherVesselVoyage = "";
    } else {
      // if (this.isCopy) {
        this.model['bCreatedDate'] = '';
        this.model['bCreatedUserid'] = '';
      // }
    }

    this.bookingContainerInfo = new BookingContainerInfo();

    this.gridFreightCharges.onClear();
    this.gridFreightPerSummary.onClear();
    this.gridFreightCurrencySummary.onClear();
    this.refreightEffectiveFrom = "";
    this.refreightExpiryOn = "";
    this.refreightModifiedBy = "";
    this.refreightModifiedDate = "";
    this.refreightAccountPIC = "";
    this.refreightApprovalBy = "";
    this.refreightApprovedDate = "";
    this.gridFreightPerSummary.onClear();
    this.gridFreightCurrencySummary.onClear();
    this.gridContainerInfo.onClear();

    this.bookingMisc = new BookingMisc();
    this.cbHaulier.setValue("");
    this.cbReceiptTypeExtra.setValue("");

    if(this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'){
      this.initiallyReleaseAt();
      this.calculationReleaseAtDate();
    }else{
      this.cbReleaseAt.setValue("");
    }

    // this.dateReleaseAt.setValue("");
    this.cbReturnToPor.setValue("");
    this.dateReturnToPor.setValue("");
    this.dateReturnToPorGateInClosing.setValue("");
    this.dateReturnToPorVgmClosing.setValue("");
    this.cbReturnToPol.setValue("");
    this.dateReturnToPol.setValue("");
    this.dateReturnToPolGateInClosing.setValue("");
    this.dateReturnToPolVgmClosing.setValue("");
    this.cbStuffAt.setValue("");
    this.dateStuffAtPor.setValue("");
    this.dateStuffAtPorGateInClosing.setValue("");
    this.dateStuffAtPorVgmClosing.setValue("");
    this.txtTerminalRemarks.setValue("");

    this.OH = "N";
    this.OW = "N";
    this.OL = "N";
    this.heightMeasure = 0;
    this.leftMeasure = 0;
    this.rightMeasure = 0;
    this.frontMeasure = 0;
    this.endMeasure = 0;
    this.UCH = 0;
    this.UCL = 0;
    this.UCW = 0;
    this.d20 = 0;
    this.d40 = 0;
    this.d45 = 0;
    this.h20 = 0;
    this.h40 = 0;
    this.h45 = 0;
    // this.killSlot = 0;
    this.doorOpen = "N";
    this.bDegree = null;
    this.bDegreeTemp = "C";
    // this.model.bDetentionRequest = 0;
    // this.model.bDemurrageRequest = 0;

    this.cbFeederVessel.setValue("");
    this.cbFeederVoyage.setValue("");
    this.cbSupplier.setValue("");
    // this.cbExVessel.setValue("");
    this.cbNextVessel.setValue("");
    this.cbNextVoyage.setValue("");
    this.cbPackageType.setValue("");

    // 668 reset confirm checkbox
    this.model.vesselVoyageConfirmStatus = false;

    // this.cbSocOPR.clearSelect();
    // this.cbSocOPR.listStore.store = [];

    // this.cbSlotOPR.clearSelect();
    // this.cbSlotOPR.listStore.store = [];

    // this.cbSlotCntrOPR.clearSelect();
    // this.cbSlotCntrOPR.listStore.store = [];

    console.log(
      "AFTER COPY SAVE " +
        this.fpAcctCodeCOC +
        " " +
        this.fpAcctCodeSOC +
        " " +
        this.fpAcctCodeSLOT
    );

    // this.model.bContainerOwnership = "SOC";
    // this.model.bSocOperatorCode = "SA";

    this.model.bContainerStatus = "FULL";
    this.haveTerminal = "N";
    this.originLocation = "NNN";

    //GOS 110 START
    this.fpAcctCode = "<<";
    // if (this.nvoccBookingParty) {
    if (this.nvoccFreightParty) {
      console.log("hore");
      this.fpAcctCode += " NVOCC ";
    }
    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      this.fpAcctCode += " COC: " + this.fpAcctCodeCOC;
    }
    if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      this.fpAcctCode += " SOC: " + this.fpAcctCodeSOC;
    }
    if (this.model.bContainerOwnership == "SLOT") {
      this.fpAcctCode += " SLOT: " + this.fpAcctCodeSLOT;
    }
    this.fpAcctCode += " >>";

    //END GOS 110

    this.lock = false;
    this.lockTNK = false;
    this.lockSOC = false;
    this.lockCOC = false;
    this.lockSLOT = false;

    this.lockSOCOPR = false;
    this.lockCOCOPR = true;
    this.lockSLOTOPR = true;
    this.lockSLOTCNTROPR = true;

    this.model.bIsFinished = "N";
    this.model.bIsTransmittedToPsa = "N";
    this.model.bTransmitToSds = "N";
    this.model.bReleaseOrderPrinted = "N";
    this.model.bFreightType = "P";
    this.model.bShipmentType = "N";
    this.model.bDgClass = "N";
    this.model.bIhc = "N";
    this.model.bWeightClass = "M";
    this.model.bIsTransmittedToPsa = "N";
    this.model.bStatus = "N";
    this.model.bIsFinished = "N";
    this.model.bReleaseOrderPrinted = "N";
    // this.model.bDetention = "N";
    // this.model.bDemurrage = "N";
    this.bookingExtra.bIsDry = "N";
    this.bookingExtra.bVent = "N";
    this.model.bTsBooking = "N";
    this.model.bIsReserve = "N";
    // this.model.bSocOperatorCode = "SA";
    // this.model.bCocOperatorCode = "";
    // this.model.bOutwardSlotOperatorCode = "";
    // this.model.bExMotherVesselVoyage = "";

    this.model["error-operatorCodeValidator"] = "";
    this.model["error-SlotOperatorCodeValidator"] = "";

    // GSO-1063
    this.checkDisableSlotLc();

    // this.cbCocOPR.clearSelect();
    // this.cbCocOPR.listStore.store = [];
    // this.cbCocOPR.url = "";
    // this.cbCocOPR.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findCocOperator/" +
    //     this.model.bBookingPartyCode
    // );
    // this.cbCocOPR.loadSelect();

    // this.cbCocOPR.clearSelect();
    // this.cbCocOPR.listStore.store = [];
    // this.cbCocOPR.url = "";
    // this.cbCocOPR.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //   "/MasterCustomers/findCocOperator/" +
    //   this.model.bBookingPartyCode
    // );
    // this.cbCocOPR.loadSelect();

    // this.cbSlotOPR.clearSelect();
    // this.cbSlotOPR.listStore.store = [];
    // this.cbSlotOPR.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findSlotOperator/" +
    //     this.model.bBookingPartyCode
    // );
    // this.cbSlotOPR.loadSelect();

    // this.cbSlotCntrOPR.clearSelect();
    // this.cbSlotCntrOPR.listStore.store = [];
    // this.cbSlotCntrOPR.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findSlotOperator/" +
    //     this.model.bBookingPartyCode
    // );
    // this.cbSlotCntrOPR.loadSelect();

    this.model.bModifiedDate = "";
    this.model.bModifiedUserid = "";

    this.clearReeferDetailsField();

    if ((this.model.bContainerOwnership == "SOC"  || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){

      // if(){

         console.log('suk masuk');

         //console.log(this.cbPOT1.getValue());
         console.log(this.model.bCreatedUserid)
         console.log(this.model.bPot1Code);
         console.log(this.model.bPot2Code);
         console.log(this.model.bPot3Code);


         $("#tsBkg").prop("checked", true);
         //gos 281
         this.model.bTsBooking = 'Y';
      // }

     }else{
       $("#tsBkg").prop("checked", false);
       console.log('masuk suk')
        //gos 281
        this.model.bTsBooking = 'N';
     }

     console.log("GOS-387");
     if (this.viewSOCStatus){
       this.lockCOC = true;
       this.model.bContainerOwnership = "SOC";
     }

     if(this.viewCOCStatus){
       this.lockSLOT = true;
       this.model.bContainerOwnership = "COC";
     }


     // GOS-484

    if(this.model.bOfficeCode === 'SGSIN') {
      if (this.model.bContainerOwnership === 'COC' || this.model.bContainerOwnership === 'COCTS') {
        this.lockSOOPR2 = false;
        this.lockCOCOPR = false;
      } else if (this.model.bContainerOwnership === 'SOC' || this.model.bContainerOwnership === 'SOCTS') {
        this.lockSOCOPR = false;
        this.lockSOOPR1 = false;
      } else {
        // slot goes here
        this.lockSLOTOPR = false;
        this.lockSLOTCNTROPR = false;
      }
    }

    // GSO-1034 Modification detected (Click <Modify>, <Save or Save & Copy>), depot transmission checkbox will be reset <N>
    if(this.model.bTransmitToSds === "N"){
      this.isCheckedDepot = false;
      this.model.bDepotTransmitDate = "";
      this.model['bSlotLC'] = 'N';
    }else{
      this.checkDepotTransmissionStatus();
    }
  }

  getRotation(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/getPortOfRotationForBookingMaintenance/" +
          officeCode +
          "/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound
      )
      .subscribe((resp) => {
        if (resp.json()["content"].length > 0) {
          this.rotation = resp.json()["content"][0]["rotation"];
          this.cleanRotation = resp.json()["content"][0]["cleanRotation"];
          this.canalRotation = resp.json()["content"][0]["hidenPortList"];

          var self = this;
          if (
            this.model.bPolCode != null &&
            this.model.bPolCode != "" &&
            this.model.bPolCode != undefined
          ) {
            console.log("#1");
            if (this.rotation.includes(this.model.bPolCode.toString())) {
              console.log("#1.1");
              console.log(this.model);
              this.cbLoadPort.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetailsCallPurpose/" +
                  // "/MasterLocations/findByComboBoxAllowedPOL/" +
                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/" +
                  vesselId +
                  "/" +
                  this.sCharUtil.htmlEncode(String(voyage).trim()) +
                  "/" +
                  bound +
                  "/{query}"
              );

              let uri =
                  this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByCode/" +
                  this.model.bPolCode.toString();

              this.genericService.GET(uri).subscribe((resp) => {
                  if (resp.ok) {
                    this.model.bPolBooking = resp.json()["locationBookingRef"];
                    this.originalBookingRef = resp.json()["locationBookingRef"];
                      //this.model.bPolName = resp.json()["locationName"];
                    this.cbLoadPort.setForceValue(resp.json()["locationName"]);
                    const masterLocations = resp.json().masterLocationTerminals;
                    // fix log: check null array masterLocations
                    if (masterLocations) {
                      const polTerminal = masterLocations.find(element => element.locationCode === this.model.bPolTerminalCode);
                      this.cbLoadPortTerminal.setForceValue(polTerminal.locationName);
                    }
                  }
              });

              //this.cbLoadPort.setValue(this.model.bPolCode.toString());
            } else {
              console.log("#1.2");
              //if(officeCode.startsWith("TH")){
              if (this.cleanRotation.includes(this.model.bPolCode.toString())) {

                this.cbLoadPort.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                    this.cookieService.getDefaultLocationCode() +
                    "/" +
                    this.model.bOceanVesselId +
                    "/" +
                    this.sCharUtil.htmlEncode(
                      String(this.model.bOceanVesselVoyage).trim()
                    ) +
                    "/" +
                    this.model.bOceanVesselBound +
                    "/{query}"
                );

                let uri =
                  this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByCode/" +
                  this.model.bPolCode.toString();

                this.genericService.GET(uri).subscribe((resp) => {
                    if (resp.ok) {
                        this.model.bPolBooking = resp.json()["locationBookingRef"];
                        this.originalBookingRef = resp.json()["locationBookingRef"];
                        //this.model.bPolName = resp.json()["locationName"];
                        this.cbLoadPort.setForceValue(resp.json()["locationName"]);
                        const masterLocations = resp.json().masterLocationTerminals;
                        const polTerminal = masterLocations.find(element => element.locationCode === this.model.bPolTerminalCode);
                        this.cbLoadPortTerminal.setForceValue(polTerminal.locationName);
                    }
                });

              } else {

                //canal passing
                var isHiddenPort:Boolean = false;
                var hiddenPortPurpose = "";
                this.canalRotation.forEach((eL)=>{
                  if(this.model.bPolCode.toString() == eL.portCode){
                    isHiddenPort = true;
                    hiddenPortPurpose = eL.portPurpose;
                  }
                });


                if (isHiddenPort) {
                  this.cbLoadPort.setUrl(
                    this.configService.config.BASE_API.toString() +
                      // "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetailsCallPurpose/" +
                      // "/MasterLocations/findByComboBoxAllowedPOL/" +
                      "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                      this.cookieService.getDefaultLocationCode() +
                      "/" +
                      vesselId +
                      "/" +
                      this.sCharUtil.htmlEncode(String(voyage).trim()) +
                      "/" +
                      bound +
                      "/{query}"
                  );

                  let uri =
                    this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByCode/" +
                    this.model.bPolCode.toString();

                  this.genericService.GET(uri).subscribe((resp) => {
                      if (resp.ok) {
                          this.model.bPolBooking = resp.json()["locationBookingRef"];
                          this.originalBookingRef = resp.json()["locationBookingRef"];
                          //this.model.bPolName = resp.json()["locationName"];
                          this.cbLoadPort.setForceValue(resp.json()["locationName"] + " (" + hiddenPortPurpose + ")");
                        const masterLocations = resp.json().masterLocationTerminals;
                        // fix log: check null array masterLocations
                        if (masterLocations) {
                          const polTerminal = masterLocations.find(element => element.locationCode === this.model.bPolTerminalCode);
                          this.cbLoadPortTerminal.setForceValue(polTerminal.locationName);
                        }
                      }
                  });

                  this.isPOLCanalPassing = true;

                  //this.cbLoadPort.setValue(this.model.bPolCode.toString());
                }else{
                  console.log("From Retrieve : "+this.fromRetrieve);
                  console.log("not cannal passing");
                  this.cbLoadPort.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                      this.cookieService.getDefaultLocationCode() +
                      "/" +
                      vesselId +
                      "/" +
                      this.sCharUtil.htmlEncode(String(voyage).trim()) +
                      "/" +
                      bound +
                      "/{query}"
                  );

                  if(this.fromRetrieve){
                    console.log("retrieve");
                    let uri =
                        this.configService.config.BASE_API.toString() +
                        "/MasterLocations/findByCode/" +
                        this.model.bPolCode.toString();

                      this.genericService.GET(uri).subscribe((resp) => {
                          if (resp.ok) {
                              this.model.bPolBooking = resp.json()["locationBookingRef"];
                              this.originalBookingRef = resp.json()["locationBookingRef"];
                              //this.model.bPolName = resp.json()["locationName"];
                              this.cbLoadPort.setForceValue(resp.json()["locationName"]);
                              const masterLocations = resp.json().masterLocationTerminals;
                              const polTerminal = masterLocations.find(element => element.locationCode === this.model.bPolTerminalCode);
                              this.cbLoadPortTerminal.setForceValue(polTerminal.locationName);
                          }
                      });

                  }else{
                    console.log("new");

                    //this.cbLoadPort.setValue("");
                    this.cbLoadPort.setForceValue("");
                    this.cbLoadPortTerminal.setForceValue("");
                    this.model.bPolCode = "";
                  }
                }
              }
            }
          } else {
            console.log("##2");
            console.log("From Retrieve : "+this.fromRetrieve);
            this.cbLoadPort.setUrl(
              this.configService.config.BASE_API.toString() +
                "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                this.cookieService.getDefaultLocationCode() +
                "/" +
                vesselId +
                "/" +
                this.sCharUtil.htmlEncode(String(voyage).trim()) +
                "/" +
                bound +
                "/{query}"
            );
            if(this.fromRetrieve){
              console.log("retrieve");
              let uri =
                  this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByCode/" +
                  this.model.bPolCode.toString();

                this.genericService.GET(uri).subscribe((resp) => {
                    if (resp.ok) {
                      this.model.bPolBooking = resp.json()["locationBookingRef"];
                      this.originalBookingRef = resp.json()["locationBookingRef"];
                        //this.model.bPolName = resp.json()["locationName"];
                      this.cbLoadPort.setForceValue(resp.json()["locationName"]);
                      this.cbLoadPortTerminal.setForceValue(resp.json()["terminalName"]);
                      const masterLocations = resp.json().masterLocationTerminals;
                      const polTerminal = masterLocations.find(element => element.locationCode === this.model.bPolTerminalCode);
                      this.cbLoadPortTerminal.setForceValue(polTerminal.locationName);
                      console.log(resp.json());
                    }
                });

            }else{
              console.log("new");
              //this.cbLoadPort.setValue("");
              this.cbLoadPort.setForceValue("");
              this.cbLoadPortTerminal.setForceValue("");
              this.model.bPolCode = "";
            }
          }

          //POD not visible under rotation set POT1 = SGSIN
          if (this.isCHINASiteEDI_NewBkg==true){
            if( this.model.bPodCode !=""  && this.rotation.includes(String(this.model.bPodCode).trim())==false
               && this.rotation.includes("SGSIN")){
                this.model.bPot1Code = "SGSIN";
                this.model.bPot1Name = "SINGAPORE";
              }
              
            if (this.model.bPot1Code !== "") {
              this.cbPOT1.setForceValue(this.model.bPot1Name.toString());
              this.cbPOT1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/" +
                  this.model.bOceanVesselId +
                  "/" +
                  this.sCharUtil.htmlEncode(
                    String(this.model.bOceanVesselVoyage).trim()
                  ) +
                  "/" +
                  this.model.bOceanVesselBound +
                  "/{query}"
              );
        
              if (this.model.bPot1Code  != "" && this.model.bPot1Code  != undefined) {
                this.cbDischargePort.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
              } else {
                this.cbDischargePort.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                    this.cookieService.getDefaultLocationCode()+"/"+
                    this.model.bOceanVesselId+"/"+
                    this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim())+"/"+
                    this.model.bOceanVesselBound+"/{query}"
                );
              }
              this.lockNextVessel = false;
            } else {
              if (this.model.bPot1Code  != "" && this.model.bPot1Code  != undefined) {
                this.cbDischargePort.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControl/locationCode={query}"
                );
              } else {
                this.cbDischargePort.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
                    this.cookieService.getDefaultLocationCode()+"/"+
                    this.model.bOceanVesselId+"/"+
                    this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim())+"/"+
                    this.model.bOceanVesselBound+"/{query}"
                );
              }
            }
          }
          
        }
      });
  }


  getWeightProfiles(
    officeCode: String,
    vesselId: String,
    voyage: String,
    bound: String
  ) {
    this.gridWeightProfiles.search =
      officeCode +
      "/" +
      vesselId +
      "/" +
      this.sCharUtil.htmlEncode(String(voyage).trim()) +
      "/" +
      bound;
    this.gridWeightProfiles.loadData();
    //// console.log("ke hit");
  }

  getContainerProfiles(
    officeCode: String,
    vesselId: String,
    voyage: String,
    bound: String
  ) {
    this.gridContainerProfiles.search =
      officeCode +
      "/" +
      vesselId +
      "/" +
      this.sCharUtil.htmlEncode(String(voyage).trim()) +
      "/" +
      bound;
    this.gridContainerProfiles.loadData();
  }

  getAllocationAndEstimateArrivalDate(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String,
    flagString: String,
    callbackOnSuccess: () => void = undefined
  ) {
    console.log("** getAllocationAndEstimateArrivalDates **");
    console.log(vesselId + " - " + voyage + " - " + bound + " - " + officeCode);
    this.model["allocationValidator"] = false;

    if(flagString == ''){
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/MasterBookings/getSailSchedule/" +
              vesselId +
              "/" +
              this.sCharUtil.htmlEncode(String(voyage).trim()) +
              "/" +
              bound +
              "/" +
              officeCode
          )
          .subscribe(
            (resp) => {
              let data = resp.json();

              this.model.ETA = String(data["ETA"]).trim().replace(/\//gi, "-");
              this.model.ETD = String(data["ETD"]).replace(/\//g, "-");
              //this.model.ETA = String(data["ETAPOL"]).trim().replace(/\//gi, "-");
              //this.model.ETD = String(data["ETDPOL"]).trim().replace(/\//gi, "-");
              this.model.ATA = String(data["ATA"]).replace(/\//g, "-");
              this.model.ATD = String(data["ATD"]).replace(/\//g, "-");
              //this.grid.setBody(this.model);
              //this.grid.loadData();

              // this.calculateReleaseDateWhenTypeDry();
              this.loadDataAlloc();

              if (callbackOnSuccess) {
                callbackOnSuccess();
              }
            },
            (error) => {
              // console.log(error.message);
            }
          );
    }else{

      this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getSailSchedule/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound +
          "/" +
          officeCode +"/" + this.model.bPolCode
      )
      .subscribe(
        (resp) => {
          let data = resp.json();

          //this.model.ETA = String(data["ETA"]).trim().replace(/\//gi, "-");
          //this.model.ETD = String(data["ETD"]).replace(/\//g, "-");
          this.model.ETA = String(data["ETAPOL"]).trim().replace(/\//gi, "-");
          this.model.ETD = String(data["ETDPOL"]).trim().replace(/\//gi, "-");
          this.model.ATA = String(data["ATA"]).replace(/\//g, "-");
          this.model.ATD = String(data["ATD"]).replace(/\//g, "-");
          //this.grid.setBody(this.model);
          //this.grid.loadData();
          // this.calculateReleaseDateWhenTypeDry();
          this.loadDataAlloc();

          if (callbackOnSuccess) {
            callbackOnSuccess();
          }
        },
        (error) => {
          // console.log(error.message);
        }
      );






    }
  }

  calculationReleaseAtDate(){
    let dateETA = new Date(String(this.model.ETA));
    if(
      this.currentContainerType === "STD" ||  //NORMAL
      this.currentContainerType === "OPT" ||  //OPEN TOP
      this.currentContainerType === "FLR" ||  //FLATRACK
      this.currentContainerType === "PLT" ||  //PLATFORM
      this.currentContainerType === "DRY"     //REEFER DRY
    ){
      if(this.bookingExtra.bDetentionPol === "S" || this.bookingExtra.bDetentionPol === "N"){
        dateETA.setDate(dateETA.getDate() + 1 - 7);
      }else{
        dateETA.setDate(dateETA.getDate() + 1 - Number(this.bookingExtra.bDetentionRequestPol));
      }
      let datecalculation = this.datePipe.transform(new Date(dateETA), "yyyy-MM-dd");
      // Alternative for *dateReleaseAt.setValue()*
      this.setDatePluginValue(this.settingDateReleaseAt.id, datecalculation.toString());
      this.bookingMisc['bReleaseAtTime'] = "";

      if (this.currentContainerType === "DRY") {
        // Alternative for *dateReeferCollection.setValue()*
        this.setDatePluginValue(this.settingReeferCollection.id, datecalculation.toString());
      }
    }
  }

  clearReleaseAtDate(){
    this.dateReleaseAt.reset();
    this.bookingMisc['bReleaseAtTime'] = "";
  }

  clearReeferCollectionDate(){
    this.dateReeferCollection.reset();
    this.bookingExtra.bReeferCollectionDate = "";
  }

  async changeEventDateReleaseAt(event) {
    const dateReleaseAt = await this.getDatePluginValue(this.settingDateReleaseAt.id);
    const conditionForREF = this.currentContainerType === "REF" && (this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'); // GSO-1029
    if(conditionForREF || this.isReeferDryTypeInSing){
      if(dateReleaseAt != await this.getDatePluginValue(this.settingReeferCollection.id)){
        // Alternative for *dateReeferCollection.setValue()*
        this.setDatePluginValue(this.settingReeferCollection.id, dateReleaseAt);
      }
    }
  }

  async changeEventReeferCollection(event){
    const dateReeferCollection = await this.getDatePluginValue(this.settingReeferCollection.id);
    const conditionForREF = this.currentContainerType === "REF" && (this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'); // GSO-1029
    if(conditionForREF || this.isReeferDryTypeInSing){
      if(dateReeferCollection != await this.getDatePluginValue(this.settingDateReleaseAt.id)){
        // Alternative for *dateReleaseAt.setValue()*
        this.setDatePluginValue(this.settingDateReleaseAt.id, dateReeferCollection);
      }
    }
  }

  changeDetentionReqPol(event){
    // GOS-843 ONLY SIN site adopt <Release Date> auto calculation formula.
    if(this.isSingaporeSite){
      this.calculationReleaseAtDate();
    }
  }

  refreightProcess() {
    // console.log("** refreight process **");
    // console.log(this.model);
    this.setDetail();

    /*
    if(this.model.etdPOL != '' && this.model.etdPOL != undefined){
      this.model.etdMaster = this.model.ETD;
      this.model.ETD = this.model.etdPOL;
    }
    */
    this.genericUtil.showLoader();
    this.gridFreightCharges.listStore.store = [];
    this.gridFreightCharges.loadData();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/PFCGenericAPI/freightingFromBooking",
        this.model
      )
      .subscribe((resp) => {
        this.refreightEffectiveFrom = "";
        this.refreightExpiryOn = "";
        this.refreightModifiedBy = "";
        this.refreightModifiedDate = "";
        this.refreightAccountPIC = "";
        this.refreightApprovalBy = "";
        this.refreightApprovedDate = "";

        if (resp.json()["status"] == "OK") {
          this.dialogPlugin.show(
            "information",
            "Information",
            resp.json()["message"],
            "okonly",
            {
              ok:
                "this.genericUtil.hideLoader();this.afterRefreight(" +
                JSON.stringify(resp.json()) +
                ")",
            }
          );
        } else {
          this.dialogPlugin.show(
            "information",
            "Information",
            resp.json()["message"],
            "okonly",
            {
              ok: " this.genericUtil.hideLoader();this.gridFreightPerSummary.onClear();this.gridFreightCurrencySummary.onClear();",
            }
          );
          //this.refreightMessage = '';
          //this.refreightMessage = resp.json()['message'];
          //this.showDialog('#compareDialog');
        }
      });
  }
  refreightProcessSilent() {
    // console.log("** refreight process **");
    // console.log(this.model);
    this.setDetail();

    this.gridFreightCharges.listStore.store = [];
    this.gridFreightCharges.loadData();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/PFCGenericAPI/freightingFromBooking",
        this.model
      )
      .subscribe((resp) => {
        this.refreightEffectiveFrom = "";
        this.refreightExpiryOn = "";
        this.refreightModifiedBy = "";
        this.refreightModifiedDate = "";
        this.refreightAccountPIC = "";
        this.refreightApprovalBy = "";
        this.refreightApprovedDate = "";

        let dt = resp.json();
        if (dt.status == "OK") {
          console.log("handle directly");
          this.gridFreightCharges.listStore.store = dt.content;
          this.gridFreightCharges.loadData();
          console.log(dt.content);

          this.gridFreightPerSummary.listStore.store = dt.content2;
          this.gridFreightPerSummary.loadData();

          this.gridFreightCurrencySummary.listStore.store = dt.content3;
          this.gridFreightCurrencySummary.loadData();

          this.refreightEffectiveFrom = dt.summary["effectiveFrom"];
          this.refreightExpiryOn = dt.summary["effectiveTo"];
          this.refreightModifiedBy = dt.summary["userModified"];
          this.refreightModifiedDate = dt.summary["dateModified"];
          this.refreightAccountPIC = dt.summary["userPIC"];
          this.refreightApprovalBy = dt.summary["userApproved"];
          this.refreightApprovedDate = dt.summary["dateApproved"];

          //this.afterRefreight(JSON.stringify(resp.json()));
        }

        /*
      if(resp.json()['status'] == 'OK'){
        this.dialogPlugin.show('information','Information',resp.json()['message'], 'okonly', { ok: 'this.afterRefreight(' + JSON.stringify(resp.json()) + ')'});
      }else{
        this.dialogPlugin.show('information','Information',resp.json()['message'], 'okonly', { ok: ''});
        //this.refreightMessage = '';
        //this.refreightMessage = resp.json()['message'];
        //this.showDialog('#compareDialog');
      }*/
      });
  }

  afterRefreight(data: any) {
    console.log("after refreight");
    console.log(data);
    this.gridFreightCharges.onClear();


    if (data["content"] !== undefined) {
      console.log("masuk grid freight charge");
      console.log(this.gridFreightCharges.url);
      for (let i = 0; i < data["content"].length; i++) {
        console.log("debug frieght charge");
        console.log(data["content"][i]);
        data["content"][i]["recordId"] =
          this.model.bFreightType != null &&
          this.model.bFreightType != undefined &&
          this.model.bFreightType != ""
            ? data["content"][i]["recordId"] + "|" + this.model.bFreightType
            : data["content"][i]["recordId"];
        this.gridFreightCharges.listStore.store.push(data["content"][i]);
      }
      this.gridFreightCharges.loadData();

      this.refreightEffectiveFrom = data.summary["effectiveFrom"];
      this.refreightExpiryOn = data.summary["effectiveTo"];
      this.refreightModifiedBy = data.summary["userModified"];
      this.refreightModifiedDate = data.summary["dateModified"];
      this.refreightAccountPIC = data.summary["userPIC"];
      this.refreightApprovalBy = data.summary["userApproved"];
      this.refreightApprovedDate = data.summary["dateApproved"];
    }

    this.gridFreightPerSummary.onClear();
    if (data["content2"] !== undefined) {
      for (let i = 0; i < data["content2"].length; i++) {
        this.gridFreightPerSummary.listStore.store.push(data["content2"][i]);
      }
      this.gridFreightPerSummary.loadData();
    }

    this.gridFreightCurrencySummary.onClear();
    if (data["content3"] !== undefined) {
      for (let i = 0; i < data["content3"].length; i++) {
        this.gridFreightCurrencySummary.listStore.store.push(
          data["content3"][i]
        );
      }
      this.gridFreightCurrencySummary.loadData();
    }

    /*
    if(data.summary['effectiveFrom'] != null && data.summary['effectiveFrom'] !== '' && data.summary['effectiveFrom'] !== undefined ){
      this.refreightEffectiveFrom =  data['summary']['effectiveFrom'];
    }
    if(data['summary']['effectiveTo'] != null && data['summary']['effectiveTo'] !== '' && data['summary']['effectiveTo'] !== undefined ){
      this.refreightExpiryOn =  data['summary']['effectiveTo'];
    }
    if(data['summary']['userModified'] != null && data['summary']['userModified'] !== '' && data['summary']['userModified'] !== undefined ){
      this.refreightModifiedBy =  data['summary']['userModified'];
    }
    if(data['summary']['dateModified'] != null && data['summary']['dateModified'] !== '' && data['summary']['dateModified'] !== undefined ){
      this.refreightModifiedDate =  data['summary']['dateModified'];
    }
    if(data['summary']['userPIC'] != null && data['summary']['userPIC'] !== '' && data['summary']['userPIC'] !== undefined ){
      this.refreightAccountPIC =  data['summary']['userPIC'];
    }
    if(data['summary']['userPIC'] != null && data['summary']['userPIC'] !== '' && data['summary']['userPIC'] !== undefined ){
      this.refreightAccountPIC =  data['summary']['userPIC'];
    }


    if(data['summary']['userApproved'] != null && data['summary']['userApproved'] !== '' && data['summary']['userApproved'] !== undefined ){
      this.refreightApprovalBy =  data['summary']['userApproved'];
    }
    if(data['summary']['dateApproved'] != null && data['summary']['dateApproved'] !== '' && data['summary']['dateApproved'] !== undefined ){
      this.refreightApprovedDate =  data['summary']['dateApproved'];
    }
    */
  }

  setData(data: any) {
    console.log("** setData **");
    // console.log(data);
    var self = this;

    this.model = new Booking();
    this.model.bOfficeCode = String(data.bOfficeCode).trim().toUpperCase();
    this.model.bPrefix = String(data.bPrefix).trim().toUpperCase();
    this.model.bNo = String(data.bNo).trim().toUpperCase();
    this.model.bOfficeCodeTs = String(data.bOfficeCodeTs).trim().toUpperCase();
    this.model.bPrefixTs = String(data.bPrefixTs).trim().toUpperCase();
    this.model.bNoTs = String(data.bNoTs).trim().toUpperCase();
    this.model.bOceanVesselBound = String(data.bOceanVesselBound)
      .trim()
      .toUpperCase();
    this.model.bTsBooking = data.bTsBooking; // disini valuenya bener = Y
    this.model.bIsSlotCommission = data.bIsSlotCommission;
    this.model.bIsReserve = data.bIsReserve;
    this.model.bCreatedUserid = data.bCreatedUserid;
    this.model.bCreatedDate = data.bCreatedDate;
    this.model.bModifiedUserid = data.bModifiedUserid;
    this.model.bModifiedDate = data.bModifiedDate;
    this.model.bContainerStatus = data.bContainerStatus;
    this.model.bIsFinished = data.bIsFinished;
    this.model.bReleaseOrderPrinted = data.bReleaseOrderPrinted;
    this.model.bIsTransmittedToPsa = data.bIsTransmittedToPsa;
    this.model.bTransmitToSds = data.bTransmitToSds;
    this.model.bContainerOwnership = data.bContainerOwnership;
    this.model.bIsUnlocked = data.bIsUnlocked;
    this.model.bStatus = data.bStatus;

    // GSO-1063 Add SLOT LC Checkbox: SLOT LC check box can only allowed to checked when Container Ownership=SLOT
    this.model.bSlotLC = data.bSlotLC;
    this.checkDisableSlotLc();

    if(data.bTransmitToSds === "Y"){
      this.isCheckedDepot = true;
    }else{
      this.isCheckedDepot = false;
    }

    // 01. Set vessel
    this.model.bOceanVesselId = data.bOceanVesselId;
    this.model.bOceanVesselCode = data.bOceanVesselCode;
    this.model.bOceanVesselName = data.bOceanVesselName;
    this.model.bOceanVesselBound = data.bOceanVesselBound;
    this.model.bOceanVesselVoyage = data.bOceanVesselVoyage
    this.cbVessel.setForceValue(data.bOceanVesselName);
    this.model.confirmVoyage = data.confirmVoyage;
    this.model.bPot1Code = data.bPot1Code;
    //   this.cbVoyage.disableCombo = false;

    // GOS-668
    this.model.bPendingRequest = data.bPendingRequest;
    this.model.bNextMotherVesselVoyage = data.bNextMotherVesselVoyage;
    this.model.bNextMotherVesselBound = data.bNextMotherVesselBound;
    this.model.bNextMotherVesselCode = data.bNextMotherVesselCode;
    this.model.bNextMotherVesselId = data.bNextMotherVesselId;
    this.model.etaNextVessel = data.bNextMotherVesselETA;
    this.model.etdNextVessel = data.bNextMotherVesselETD;
    this.cbNextVessel.setForceValue(data.etaNextVessel ? data.etaNextVessel : "");
    this.cbNextVoyage.setForceValue(data.bNextMotherVesselVoyage ? data.bNextMotherVesselVoyage : "");
    this.model.bNextVesselVoyagePriority = data.masterBookingExtra.bNextVesselVoyagePriority;
    this.isBookingRequest = (this.model.bPendingRequest == 'N' ||  this.model.bPendingRequest == 'Y' ) && this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage;
    // this.model.vesselVoyageConfirmStatus = this.model.bPendingRequest == 'N' && this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage;
    this.model.vesselVoyageConfirmStatus = data.bTsStatus === 'C';

    // this.lockEdit = this.model.vesselVoyageConfirmStatus ? true : false; // Check disable next vessel & voyage
    //gos-268
    this.model.etdPOL = data.etdPOL;
    this.model.ETA = "";
    this.model.ETD = "";
    this.model.ATA = "";
    this.model.ATD = "";

    this.serviceCode = "";

    this.searchFpIsValid = "";
    this.searchFpIsFreightParty = "";

    this.searchShIsValid = "";
    this.searchShIsShipper = "";

    this.searchBkIsValid = "";
    this.searchBkIsBookingParty = "";
    this.contactFromBooking = "";
    this.telephoneFromBooking = "";
    this.attrCustChanged = false;

    this.model.bPodCode = data.bPodCode;
    this.model.bPot1Name = data.bPot1Name;
    this.isAfterSaveCopy = false;

    //GSO-1030
    this.model.bDepotTransmitDate = data.bDepotTransmitDate;

     //GOS-874 & GOS-869
    this.model.bEdiBookingCommodity = data.bEdiBookingCommodity;

    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
        data.bOfficeCode +
        "/" +
        data.bOceanVesselId +
        "/" +
        data.bOceanVesselBound +
        "/voyage={query}"
    );

    // this.cbNextVessel.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/findConnectingVesselAndVoyageForTSBooking/" +
    //     data.bOfficeCode +
    //     "/" +
    //     data.bOceanVesselId +
    //     "/" +
    //     data.bOceanVesselVoyage +
    //     "/" +
    //     data.bOceanVesselBound +
    //     "/" +
    //     data.bOceanVesselVoyage +
    //     "/voyage={query}"
    // );


    this.cbVoyage.setValue(data.bOceanVesselVoyage, function (callbackVoyage) {
      self.model.bOceanVesselVoyage = callbackVoyage.voyage;
      self.cbVoyage.setUrl(
        self.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          data.bOfficeCode +
          "/" +
          data.bOceanVesselId +
          "/" +
          data.bOceanVesselBound +
          "/voyage_like={query}"
      );

      self.serviceCode = callbackVoyage.serviceCode;
      self.getRotation(
        self.model.bOceanVesselId,
        self.model.bOceanVesselVoyage,
        self.model.bOceanVesselBound,
        self.model.bOfficeCode
      );
      self.getAllocationAndEstimateArrivalDate(
        self.model.bOceanVesselId,
        self.model.bOceanVesselVoyage,
        self.model.bOceanVesselBound,
        self.model.bOfficeCode,
        'Y'
      );

      //self.getWeightProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);
      self.gridWeightProfiles.url = "";
      self.gridWeightProfiles.listStore.store = data.gridWeightProfiles;
      self.gridWeightProfiles.loadData();

      //self.getContainerProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);
      self.gridContainerProfiles.url = "";
      self.gridContainerProfiles.listStore.store = data.gridContainerProfiles;
      self.gridContainerProfiles.loadData();

      self.model.bPot1Code = data.bPot1Code;
      self.model.bPot1Name = data.bPot1Name;

      if (self.model.bPot1Code !== "") {
        self.cbPOT1.setForceValue(self.model.bPot1Name.toString());
        self.cbPOT1.setUrl(
          self.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/" +
            self.cookieService.getDefaultLocationCode() +
            "/" +
            self.model.bOceanVesselId +
            "/" +
            self.sCharUtil.htmlEncode(
              String(self.model.bOceanVesselVoyage).trim()
            ) +
            "/" +
            self.model.bOceanVesselBound +
            "/{query}"
        );

        if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
          self.cbDischargePort.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
        } else {
          self.cbDischargePort.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
              self.cookieService.getDefaultLocationCode()+"/"+
              self.model.bOceanVesselId+"/"+
              self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
              self.model.bOceanVesselBound+"/{query}"
          );
        }


        self.lockNextVessel = false;
      } else {
        if (self.model.bPot1Code  != "" && self.model.bPot1Code  != undefined) {
           self.cbDischargePort.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
        } else {
          self.cbDischargePort.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/"+
              self.cookieService.getDefaultLocationCode()+"/"+
              self.model.bOceanVesselId+"/"+
              self.sCharUtil.htmlEncode(String(self.model.bOceanVesselVoyage).trim())+"/"+
              self.model.bOceanVesselBound+"/{query}"
          );
        }
      }

      self.model.bPodCode = data.bPodCode;
      self.model.bPodBooking = data.bPodBooking;
      console.log("data.bPodName ==> " + data.bPodName);
      self.cbDischargePort.setForceValue(data.bPodName);
    });

    this.model.bBookingPartyCode = data.bBookingPartyCode;
    this.cbBookingParty.setForceValue(data.bBookingPartyName);
    this.nvoccBookingParty = data.bIsNvoccBookingParty === "Y" ? true : false;
    this.isSocBookingParty = data.bIsSocBookingParty === "Y" ? true : false;
    this.isCocBookingParty = data.bIsCocBookingParty === "Y" ? true : false;
    this.isSlotBookingParty = data.bIsSlotBookingParty === "Y" ? true : false;

    if (data.bBookingPartyCode !== null &&
        data.bBookingPartyCode !== "" &&
        data.bBookingPartyCode !== undefined
    ) {
      this.custIdForCbBooking = data.bBookingPartyCode;
    } else {
      this.custIdForCbBooking = "*";
    }

    let uriBooking =
              this.configService.config.BASE_API.toString() +
              "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/"+this.custIdForCbBooking+"/customerName="+
              this.sCharUtil.htmlEncode(String(data.bBookingPartyName.toString()).trim());

    var self = this;
    this.genericService.GET(uriBooking).subscribe((resp) => {
        if (resp.ok) {
          var datas = resp.json()['content'];
          if (datas.length != 0) {
            datas.forEach(function (data) {
              self.searchBkIsValid = data['isValid'];
              self.searchBkIsBookingParty = data['isBookingParty'];
            });
          }
        }
    });

    if (
      this.model.bContainerOwnership === "COC" ||
      this.model.bContainerOwnership === "COCTS"
    ) {
      this.cbCocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCocOperator/" +
          data.bBookingPartyCode
      );
      this.cbCocOPR.loadSelect();
      this.cbCocOPR.setValue(data.bCocCntrOperatorCode);
    } else if (
      this.model.bContainerOwnership === "SOC" ||
      this.model.bContainerOwnership === "SOCTS"
    ) {
      this.cbSocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSocOperator/" +
          data.bBookingPartyCode
      );
      this.cbSocOPR.loadSelect();
      this.model.bSocCntrOperatorCode = data.bSocCntrOperatorCode;
      this.retrieveSocContainerOperatorCode = data.bSocCntrOperatorCode;
      this.cbSocOPR.setValue(data.bSocCntrOperatorCode);

      this.cbSlotOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          data.bBookingPartyCode
      );
      this.cbSlotOPR.loadSelect();

      this.cbSlotCntrOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          data.bBookingPartyCode
      );
      this.cbSlotCntrOPR.loadSelect();
    } else {
      this.cbSlotOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          data.bBookingPartyCode
      );
      this.cbSlotOPR.loadSelect();
      this.cbSlotOPR.setValue(data.bSlotOperatorCode);
      this.cbSlotCntrOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          data.bBookingPartyCode
      );
      this.cbSlotCntrOPR.loadSelect();
      this.cbSlotCntrOPR.setValue(data.bOutwardSlotOperatorCode);

      this.cbSocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSocOperator/" +
          data.bBookingPartyCode
      );
      this.cbSocOPR.loadSelect();
    }

    this.model.bShipperCode = data.bShipperCode;
    this.cbShipper.setForceValue(data.bShipperName);
    this.nvoccShipper = data.bIsNvoccShipper === "Y" ? true : false;


    if (data.bShipperCode !== null &&
        data.bShipperCode !== "" &&
        data.bShipperCode !== undefined
    ) {
      this.custIdForCbShipper = data.bShipperCode;
    } else {
      this.custIdForCbShipper = "*";
    }

    let uriShipper =
              this.configService.config.BASE_API.toString() +
              "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerName="+
              this.sCharUtil.htmlEncode(String(data.bShipperName.toString()).trim());

    var self = this;
    this.genericService.GET(uriShipper).subscribe((resp) => {
        if (resp.ok) {
          var datas = resp.json()['content'];
          if (datas.length != 0) {
            datas.forEach(function (data) {
              self.searchShIsValid = data['isValid'];
              self.searchShIsShipper = data['isShipper'];
            });
          }
        }
    });

    this.model.bFreightPartyCode = data.bFreightPartyCode;
    this.cbFreightParty.setForceValue(data.bFreightPartyName);

    if (data.bFreightPartyCode !== null &&
        data.bFreightPartyCode !== "" &&
        data.bFreightPartyCode !== undefined
      ) {
        this.custIdForCbFreightParty = data.bFreightPartyCode;
    } else {
      this.custIdForCbFreightParty = "*";
    }

    let uriFP =
              this.configService.config.BASE_API.toString() +
              "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerName="+
              this.sCharUtil.htmlEncode(String(data.bFreightPartyName.toString()).trim());

    var self = this;
    this.genericService.GET(uriFP).subscribe((resp) => {
        if (resp.ok) {
          var datas = resp.json()['content'];
          if (datas.length != 0) {
            datas.forEach(function (data) {
              self.searchFpIsValid = data['isValid'];
              self.searchFpIsFreightParty = data['isFreightParty'];
            });
          }
        }
    });

    this.nvoccFreightParty = data.bIsNvoccFreightParty === "Y" ? true : false;

    this.model.bPorCode = data.bPorCode;
    this.cbPOR.setForceValue(data.bPorName);

    this.model.bPsaReferenceNo = data.bPsaReferenceNo;

    this.model.bPolCode = data.bPolCode;
    this.model.bPolBooking = data.bPolBooking;

    console.log("this is");
    console.log(this.model);
    console.log(this.model.bPolCode);
    this.cbLoadPort.setForceValue(data.blPOLName);

    this.cbLoadPortTerminal.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterTerminals/findTerminalByLocation/" +
      data.bPolTerminalCode +
      "/{query}"
    );
    this.cbLoadPortTerminal.setValue(data.bPolTerminalCode);
    this.cbLoadPortTerminal.loadSelect();
    this.model.bPolTerminalCode = data.bPolTerminalCode;
    this.model.bPolTerminalName = data.bPolTerminalName;

    this.model.bPot1TerminalCode = data.bPot1TerminalCode;
    this.model.bPot1TerminalName = data.bPot1TerminalName;
    this.cbPOT1Terminal.setForceValue(data.bPot1TerminalName);
    this.model.bPodTerminalCode = data.bPodTerminalCode;
    this.model.bPodTerminalName = data.bPodTerminalName;
    this.cbDischargePortTerminal.setForceValue(data.bPodTerminalName);

    this.model.bPodlCode = data.bPodlCode;
    this.cbPODL.setForceValue(data.bPodlName);

    this.model.bFdestCode = data.bFdestCode;
    this.cbFinalDest.setForceValue(data.bFdestName);

    this.model.bSpecialDetailCode = data.bSpecialDetailCode;
    this.cbSpecialDet.setForceValue(data.bSpecialDetailName);

    this.model.bPot1Code = data.bPot1Code;
    //this.cbPOT2.setForceValue(data.bPot2Name);

    this.model.bPot2Code = data.bPot2Code;
    this.cbPOT2.setForceValue(data.bPot2Name);

    this.model.bPot3Code = data.bPot3Code;
    this.cbPOT3.setForceValue(data.bPot3Name);

    this.model.bCommodityCode = data.bCommodityCode;
    this.cbCommodity.setForceValue(data.bCommodityName);

    this.model.bReceiptType = data.bReceiptType;
    this.cbReceiptType.setForceValue(data.bReceiptType);

    this.model.bDeliveryType = data.bDeliveryType;
    this.cbDeliveryType.setForceValue(data.bDeliveryType);

    this.model.bFreightType = data.bFreightType;
    this.model.bIhc = data.bIhc;
    this.model.bContainerOwnership = data.bContainerOwnership;
    if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      this.lockSOC = false;
      this.lockCOC = true;
      this.lockSLOT = false;
      this.lockSOCOPR = false;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = false;
      this.lockSLOTCNTROPR = false;
    } else if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      this.lockSOC = true;
      this.lockCOC = false;
      this.lockSLOT = true;
      this.lockSOCOPR = true;
      this.lockCOCOPR = false;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
    } else if (this.model.bContainerOwnership == "SLOT") {
      this.lockSOC = false;
      this.lockCOC = true;
      this.lockSLOT = false;
      this.lockSOCOPR = false;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = false;
      this.lockSLOTCNTROPR = false;
    }

    this.model.bShipmentType = data.bShipmentType;
    this.model.bDgClass = data.bDgClass;
    this.model.bWeightClass = data.bWeightClass;
    this.model.bWeight = data.bWeight;
    this.model.bRemarks = data.bRemarks;
    this.bRmarksFormat = data.bRemarks.toString().replaceAll('↵', '\r\n');
    this.txtRemarks.setValue(this.model.bRemarks.toString());
    this.model.bContactPerson = data.bContactPerson;
    this.contactFromBooking = data.bContactPerson;
    this.model.bTelephone = data.bTelephone;
    this.telephoneFromBooking = data.bTelephone;
    this.model.bSocCntrOperatorCode = data.bSocCntrOperatorCode;
    this.model.bSocOperatorCode = data.bSocOperatorCode;
    this.model.bCocCntrOperatorCode = data.bCocCntrOperatorCode;
    this.model.bCocOperatorCode = data.bCocOperatorCode;
    this.model.bOutwardSlotOperatorCode = data.bOutwardSlotOperatorCode;
    this.model.bSlotOperatorCode = data.bSlotOperatorCode;
    this.retrieveSocContainerOperatorCode = data.bSocCntrOperatorCode;
    this.retrieveCocContainerOperatorCode = data.bCocCntrOperatorCode;
    // this.retrieveSlotContainerOperatorCode = data.bSlotCntrOperatorCode;


    //Booking Note 2
    this.cbFeederVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*"
    );
    this.cbFeederVessel.setForceValue(data.bFeederVesselName);
    this.cbFeederVoyage.setForceValue(data.bFeederVesselVoyage);
    this.model.bFeederVesselId = data.bFeederVesselId;
    this.model.bFeederVesselVoyage = data.bFeederVesselVoyage;
    //this.cbFeederVessel.setValue(data.bFeederVesselCode,function (callbackVesselFeeder) {

    //self.cbFeederVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*');

    //self.model.bFeederVesselId = callbackVesselFeeder.vesselId;
    //self.model.bFeederVesselCode = callbackVesselFeeder.vesselCode;

    //self.cbFeederVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ data.bFeederVesselId+"/"+ data.bFeederVesselBound+"/{query}/" + data.bOfficeCode);
    //self.cbFeederVoyage.setValue(data.bFeederVesselVoyage);
    //});

    this.cbSupplier.setForceValue(data.bSupplierName);
    this.model.bSupplierCode = data.bSupplierCode;
    /*this.cbSupplier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlSupplier/customerId={query}');
    this.cbSupplier.setValue(data.bSupplierCode,(callbacksupplier) => {
      self.cbSupplier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlSupplier/customerName={query}');
    });*/

    if (
      data.bExMotherVesselId != null &&
      data.bExMotherVesselId != "" &&
      data.bExMotherVesselId != undefined
    ) {
      this.model.bExMotherVesselId = data.bExMotherVesselId;
      this.model.bExMotherVesselCode = data.bExMotherVesselCode;
      this.model.bExMotherVesselVoyage = data.bExMotherVesselVoyage;
      this.model.bExMotherVesselName = data.bExMotherVesselName;
      this.cbExVessel.setForceValue(data.bExMotherVesselName);
    }

    if (
      data.bNextMotherVesselId != null &&
      data.bNextMotherVesselId != "" &&
      data.bNextMotherVesselId != undefined
    ) {
      this.model.bNextMotherVesselId = data.bNextMotherVesselId;
      this.model.bNextMotherVesselCode = data.bNextMotherVesselCode;
      this.model.bNextMotherVesselVoyage = data.bNextMotherVesselVoyage;
      this.cbNextVessel.setForceValue(data.bNextMotherVesselName);
      this.cbNextVoyage.setForceValue(data.bNextMotherVesselVoyage);
      this.cbNextVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.bOceanVesselId +
          "/" +
          this.model.bOceanVesselBound +
          "/{query}/" +
          this.model.bPot1Code
      );
    }

    console.log("** bNextMotherVesselVoyage ==> ");
    console.log(this.model);

     //gos 256
    /*
     if ((this.model.bContainerOwnership == "SOC"  || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){


        $("#tsBkg").prop("checked", true);
        //gos 281
        this.model.bTsBooking = 'Y';

    }else{
      $("#tsBkg").prop("checked", false);
      console.log('masuk suk')
       //gos 281
       this.model.bTsBooking = 'N';
    }
    */

    if (String(data.bRequestReleaseDate) != "") {
      this.model.bRequestReleaseDate = String(data.bRequestReleaseDate) + ":00";
      //this.dtRequestReleaseDate.dateP.nativeElement.value= this.model.bRequestReleaseDate;
    }

    if (String(data.bExpectedReturnDate) != "") {
      this.model.bExpectedReturnDate = String(data.bExpectedReturnDate) + ":00";
      //this.dtExpReturnDate.dateP.nativeElement.value= this.model.bExpectedReturnDate;
    }

    if (String(data.bReeferCollectionDate) != "") {
      this.bookingExtra.bReeferCollectionDate =
        String(data.bReeferCollectionDate) + ":00";
      //this.dtReeferCollection.dateP.nativeElement.value= this.bookingExtra.bReeferCollectionDate;
      // Alternative for *dateReeferCollection.setValue()*
      if(data.bReeferCollectionDate != data.masterBookingMisc.bReleaseAtDate){
        this.setDatePluginValue(this.settingReeferCollection.id,
          this.bookingExtra.bReeferCollectionDate.toString()
        );
      }
    }

    this.model.bHaulierContact = data.bHaulierContact;
    //this.cbPackedAt.setValue(data.bPackedAtCode);
    this.model.bHaulierContactNo = data.bHaulierContactNo;

    this.model.bWt = data.bWt;
    this.model.bMeasure = data.bMeasure;
    this.model.bPackageNo = data.bPackageNo;
    this.cbPackageType.setValue(data.bPackageTypeCode);

    // this.bDegreeTemp = String(data.bReeferDegree).substring(
    //   String(data.bReeferDegree).length + 1,
    //   String(data.bReeferDegree).length - 1
    // );
    // this.bDegree = Number.parseInt(
    //   String(data.bReeferDegree).replace(this.bDegreeTemp.toString(), "")
    // );
    // this.bookingExtra.bReeferDegree = String(this.bDegree) + this.bDegreeTemp;
    this.bookingExtra.bIsDry = data.bIsDry;
    this.bookingExtra.bVent = data.bVent;
    this.model.bDetention = data.bDetention;
    if (this.model.bDetention == "R") {
      this.lockDetention = false;
    } else {
      this.lockDetention = true;
    }
    this.model.bDetentionRequest = data.bDetentionRequest;
    this.model.bDemurrage = data.bDemurrage;
    if (this.model.bDemurrage == "R") {
      this.lockDemurrage = false;
    } else {
      this.lockDemurrage = true;
    }
    this.model.bDemurrageRequest = data.bDemurrageRequest;

    if (data.masterBookingDetails != null) {
      this.bookingDetails.clear();

      this.currentContainerType = "";

      data.masterBookingDetails.forEach((element) => {
        this.killSlot = element.bKillslot;
        this.doorOpen = element.bDoorOpen;
        this.UCH = element.bUch;
        this.UCL = element.bUcl;
        this.UCW = element.bUcw;

        this.OH = element.bOverHeight;
        if (this.OH == "Y") {
          this.lockOH = false;
        } else {
          this.lockOH = true;
        }
        this.OL = element.bOverLength;
        if (this.OL == "Y") {
          this.lockOL = false;
        } else {
          this.lockOL = true;
        }
        this.OW = element.bOverWidth;
        if (this.OW == "Y") {
          this.lockOW = false;
        } else {
          this.lockOW = true;
        }

        this.heightMeasure = element.bOverHeightCm;
        this.leftMeasure = element.bOverWidthLeftCm;
        this.rightMeasure = element.bOverWidthRightCm;
        this.frontMeasure = element.bOverLengthFrontCm;
        this.endMeasure = element.bOverLengthBackCm;

        if (this.bookingDetails.get(element.bContainerType) == null) {
          let list = new Map<String, BookingDetail>();
          list.set(element.bContainerSize + element.bContainerHeight, element);
          this.bookingDetails.set(element.bContainerType, list);
        } else {
          this.bookingDetails
            .get(element.bContainerType)
            .set(element.bContainerSize + element.bContainerHeight, element);
        }

        if (this.model.bContainerOwnership == "SLOT") {
          // console.log("Size : " + this.bookingDetails.size);

          /*
          if(this.bookingDetails.size > 1){
            if(this.bookingDetails.get("STD") != null){
              this.currentContainerType = 'STD';
            }else if(this.bookingDetails.get("REF") != null){
              this.currentContainerType = 'REF';
            }else if(this.bookingDetails.get("TNK") != null){
              this.currentContainerType = 'TNK';
            }else if(this.bookingDetails.get("DRY") != null){
              this.currentContainerType = 'DRY';
            }
          }else{
            this.currentContainerType = element.bContainerType;
          }
          */
          this.currentContainerType = element.bContainerType;
        } else {
          this.currentContainerType = element.bContainerType;
        }

        this.lockOOG = true;
        if (
          this.currentContainerType == "OPT" ||
          this.currentContainerType == "PLT" ||
          this.currentContainerType == "FLR"
        ) {
          this.lockOOG = false;
          this.lockSTD = true;
        }

        if (this.currentContainerType == "REF") {
          this.lockReeferTemp = false;
          this.lockNature = false;
          this.lockHumidity = false;
          this.lockSTD = true;
        }

        if (this.currentContainerType == "DRY") {
          this.lockHumidity = true;
          this.lockSTD = true;
        }
        this.dateReeferCollection.setDisable(this.lockReeferTemp);
      });

      for (let det of Array.from(
        this.bookingDetails.get(this.currentContainerType)
      )) {
        switch (det[0]) {
          case "2086":
            this.d20 = det[1].bQuantity;
            this.D20Saved = this.d20;
            break;
          case "4086":
            this.d40 = det[1].bQuantity;
            this.D40Saved = this.d40;
            break;
          case "4586":
            this.d45 = det[1].bQuantity;
            this.D45Saved = this.d45;
            break;
          case "2096":
            this.h20 = det[1].bQuantity;
            this.H20Saved = this.h20;
            break;
          case "4096":
            this.h40 = det[1].bQuantity;
            this.H40Saved = this.h40;
            break;
          case "4596":
            this.h45 = det[1].bQuantity;
            this.H45Saved = this.h45 as number;
            break;
          default:
            break;
        }
      }
    }

    // Container info
    console.log("** SET DATA **");
    console.log(data.masterBookingContainerInformation);

    if (
      data.masterBookingContainerInformations != null &&
      data.masterBookingContainerInformations.length > 0
    ) {
      this.gridContainerInfo.url = "";
      this.gridContainerInfo.onClear();
      for (let i = 0; i < data.masterBookingContainerInformations.length; i++) {
        let otherDetailsClean: String = "";
        if (
          data.masterBookingContainerInformations[i].bOtherDetails.length <=
            1 ||
          data.masterBookingContainerInformations[i].bOtherDetails === "↵"
        ) {
          otherDetailsClean = "";
        } else {
          for (
            let j = 0;
            j <
            data.masterBookingContainerInformations[i].bOtherDetails.split("↵")
              .length;
            j++
          ) {
            if (j === 0) {
              otherDetailsClean +=
                data.masterBookingContainerInformations[i].bOtherDetails.split(
                  "↵"
                )[j] + "<br/>";
            } else if (j === 1) {
              otherDetailsClean +=
                data.masterBookingContainerInformations[i].bOtherDetails.split(
                  "↵"
                )[j];
            }
          }
        }
        data.masterBookingContainerInformations[i].bOtherDetailsClean =
          otherDetailsClean;
        this.model.bookingContainerInfo.push(
          data.masterBookingContainerInformations[i]
        );
        this.gridContainerInfo.listStore.store.push(
          data.masterBookingContainerInformations[i]
        );
      }
      this.gridContainerInfo.loadData();
    }

    //misc
    if (data.masterBookingMisc != null) {
      console.log("before kitchen cat");
      console.log(data.masterBookingMisc);
      this.bookingMiscCheckboxPrint = data.masterBookingMisc.bPrint === "Y" ? true : false;
      this.bookingMisc = data.masterBookingMisc;

      //set combo
      // 1. Hauiler if exist
      if (
        this.bookingMisc.bHaulierId != null &&
        this.bookingMisc.bHaulierId != "" &&
        this.bookingMisc.bHaulierId != undefined
      ) {
        /*
        this.cbHaulier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlHaulier/customerId={query}');
        this.cbHaulier.setValue(this.bookingMisc.bHaulierId.toString());
        */
        this.cbHaulier.setForceValue(this.bookingMisc.bHaulierName.toString());
      }

      // 2. Release date
      if (
        this.bookingMisc.bReleaseAtDate != null &&
        this.bookingMisc.bReleaseAtDate != "" &&
        this.bookingMisc.bReleaseAtDate != undefined
      ) {
        console.log(this.bookingMisc.bReleaseAtDate.toString());

        // Alternative for *dateReleaseAt.setValue()*
        this.setDatePluginValue(this.settingDateReleaseAt.id, this.bookingMisc.bReleaseAtDate.toString());
      }

      // 3. Return to (por)
      if (
        this.bookingMisc.bReturnToPorGateInDate != null &&
        this.bookingMisc.bReturnToPorGateInDate != "" &&
        this.bookingMisc.bReturnToPorGateInDate != undefined
      ) {
        this.dateReturnToPor.setValue(
          this.bookingMisc.bReturnToPorGateInDate.toString()
        );
      }
      if (
        this.bookingMisc.bReturnToPorClosingDate != null &&
        this.bookingMisc.bReturnToPorClosingDate != "" &&
        this.bookingMisc.bReturnToPorClosingDate != undefined
      ) {
        this.dateReturnToPorGateInClosing.setValue(
          this.bookingMisc.bReturnToPorClosingDate.toString()
        );
      }
      if (
        this.bookingMisc.bReturnToPorVgmDate != null &&
        this.bookingMisc.bReturnToPorVgmDate != "" &&
        this.bookingMisc.bReturnToPorVgmDate != undefined
      ) {
        this.dateReturnToPorVgmClosing.setValue(
          this.bookingMisc.bReturnToPorVgmDate.toString()
        );
      }

      // 4. Return to (pol)
      if (
        this.bookingMisc.bReturnToPolGateInDate != null &&
        this.bookingMisc.bReturnToPolGateInDate != "" &&
        this.bookingMisc.bReturnToPolGateInDate != undefined
      ) {
        this.dateReturnToPol.setValue(
          this.bookingMisc.bReturnToPolGateInDate.toString()
        );
      }
      if (
        this.bookingMisc.bReturnToPolClosingDate != null &&
        this.bookingMisc.bReturnToPolClosingDate != "" &&
        this.bookingMisc.bReturnToPolClosingDate != undefined
      ) {
        this.dateReturnToPolGateInClosing.setValue(
          this.bookingMisc.bReturnToPolClosingDate.toString()
        );
      }
      if (
        this.bookingMisc.bReturnToPolVgmDate != null &&
        this.bookingMisc.bReturnToPolVgmDate != "" &&
        this.bookingMisc.bReturnToPolVgmDate != undefined
      ) {
        this.dateReturnToPolVgmClosing.setValue(
          this.bookingMisc.bReturnToPolVgmDate.toString()
        );
      }

      // 5. Stuff at
      if (
        this.bookingMisc.bStuffAtPorGateInDate != null &&
        this.bookingMisc.bStuffAtPorGateInDate != "" &&
        this.bookingMisc.bStuffAtPorGateInDate != undefined
      ) {
        this.dateStuffAtPor.setValue(
          this.bookingMisc.bStuffAtPorGateInDate.toString()
        );
      }
      if (
        this.bookingMisc.bStuffAtPorClosingDate != null &&
        this.bookingMisc.bStuffAtPorClosingDate != "" &&
        this.bookingMisc.bStuffAtPorClosingDate != undefined
      ) {
        this.dateStuffAtPorGateInClosing.setValue(
          this.bookingMisc.bStuffAtPorClosingDate.toString()
        );
      }
      if (
        this.bookingMisc.bStuffAtPorVgmDate != null &&
        this.bookingMisc.bStuffAtPorVgmDate != "" &&
        this.bookingMisc.bStuffAtPorVgmDate != undefined
      ) {
        this.dateStuffAtPorVgmClosing.setValue(
          this.bookingMisc.bStuffAtPorVgmDate.toString()
        );
      }

      if (
        this.bookingMisc.bReleaseAt != null &&
        this.bookingMisc.bReleaseAt != "" &&
        this.bookingMisc.bReleaseAt != undefined
      ) {
        /*
        this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
        this.cbReleaseAt.setValue(this.bookingMisc.bReleaseAt.toString());
        */
        this.cbReleaseAt.setForceValue(
          this.bookingMisc.bReleaseAtName.toString()
        );
      }
      if (
        this.bookingMisc.bReturnToPor != null &&
        this.bookingMisc.bReturnToPor != "" &&
        this.bookingMisc.bReturnToPor != undefined
      ) {
        /*
        this.cbReturnToPor.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
        this.cbReturnToPor.setValue(this.bookingMisc.bReturnToPor.toString());
         */
        this.cbReturnToPor.setForceValue(
          this.bookingMisc.bReturnToPorName.toString()
        );
      }
      if (
        this.bookingMisc.bReturnToPol != null &&
        this.bookingMisc.bReturnToPol != "" &&
        this.bookingMisc.bReturnToPol != undefined
      ) {
        /*
        this.cbReturnToPol.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
        this.cbReturnToPol.setValue(this.bookingMisc.bReturnToPol.toString());
        */
        this.cbReturnToPol.setForceValue(
          this.bookingMisc.bReturnToPolName.toString()
        );
      }
      if (
        this.bookingMisc.bStuffAtPor != null &&
        this.bookingMisc.bStuffAtPor != "" &&
        this.bookingMisc.bStuffAtPor != undefined
      ) {
        /*
        this.cbStuffAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
        this.cbStuffAt.setValue(this.bookingMisc.bStuffAtPor.toString());
        */
        this.cbStuffAt.setForceValue(
          this.bookingMisc.bStuffAtPorName.toString()
        );
      }

      if (
        this.bookingMisc.bReceiptTypeExtra != null &&
        this.bookingMisc.bReceiptTypeExtra != "" &&
        this.bookingMisc.bReceiptTypeExtra != undefined
      ) {
        console.log("Debug get summary");
        console.log(this.getDetailSummary());
        if (this.getDetailSummary() == "") {
          this.cbReceiptTypeExtra.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
          );
          this.cbReceiptTypeExtra.setValue(
            this.bookingMisc.bReceiptTypeExtra.toString()
          );
        } else {
          this.populateReceiptTypeExtra();
          this.cbReceiptTypeExtra.setForceValue(
            this.bookingMisc.bReceiptTypeExtra.toString()
          );
        }
      }

      if (
        this.bookingMisc.bAdditionalRemarks != null &&
        this.bookingMisc.bAdditionalRemarks != "" &&
        this.bookingMisc.bAdditionalRemarks != undefined
      ) {
        this.txtTerminalRemarks.setValue(
          this.bookingMisc.bAdditionalRemarks.toString()
        );
      }

      if (this.currentContainerType == "UC") {
        this.lockUC = true;
      } else {
        this.lockUC = false;
      }

      if (this.containerSlots.get(this.currentContainerType)) {
        this.group = "1";
      }

      if (this.containerSlots2.get(this.currentContainerType)) {
        this.group = "2";
      }
      this.model.depoId = data.masterBookingMisc.depoId;
    }

    // acct code
    console.log("mari kita tengok");
    console.log(data.bAcctCodeCOC);
    console.log(data.bAcctCodeSOC);
    console.log(data.bAcctCodeSLOT);
    console.log(this.nvoccFreightParty);
    this.fpAcctCodeCOC = data.bAcctCodeCOC; //jira gos 110
    this.fpAcctCodeSOC = data.bAcctCodeSOC; //jira gos 110
    this.fpAcctCodeSLOT = data.bAcctCodeSLOT; //jira gos 110

    this.fpAcctCode = "<<";
    if (this.nvoccFreightParty) {
      //gos 110 ubah dari booking party ke freight party
      console.log("hore");
      this.fpAcctCode += " NVOCC ";
    }
    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      this.fpAcctCode += " COC: " + data.bAcctCodeCOC;
    }
    if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      this.fpAcctCode += " SOC: " + data.bAcctCodeSOC;
    }
    if (this.model.bContainerOwnership == "SLOT") {
      this.fpAcctCode += " SLOT: " + data.bAcctCodeSLOT;
    }
    this.fpAcctCode += " >>";

    // * Booking Extra
    console.log("ada ga ya ? : ", data.masterBookingExtra);
    if (Object.keys(data.masterBookingExtra).length > 0) {
      this.bookingExtra = data.masterBookingExtra;

      this.bDegreeTemp = String(
        data.masterBookingExtra.bReeferDegree
      ).substring(
        String(data.masterBookingExtra.bReeferDegree).length + 1,
        String(data.masterBookingExtra.bReeferDegree).length - 1
      );

      this.bDegree = Number.parseFloat(
        String(data.masterBookingExtra.bReeferDegree).replace(
          this.bDegreeTemp.toString(),
          ""
        )
      );

      this.bDegreeTempSO = String(data.masterBookingExtra.bDegreeSo).substring(
        String(data.masterBookingExtra.bDegreeSo).length + 1,
        String(data.masterBookingExtra.bDegreeSo).length - 1
      );

      this.bDegreeSO = Number.parseFloat(
        String(data.masterBookingExtra.bDegreeSo).replace(
          this.bDegreeTempSO.toString(),
          ""
        )
      );

      // Alternative for *dateReeferCollection.setValue()*
      // this.setDatePluginValue(this.settingReeferCollection.id,
      //   data.masterBookingExtra.bReeferCollectionDate
      // );

      if (data.masterBookingExtra.bVent === "O") {
        this.lockVentNumber = false;
      }

      if (data.masterBookingExtra.bSetOption == "") {
        data.masterBookingExtra.bSetOption = "N";
      }

      console.log("kekunci ga ?", this.lockVentNumber);

      switch (data.masterBookingExtra.bNatureTemp) {
        case "F":
          this.bNatureTemp = "FROZEN";
          break;
        case "C":
          this.bNatureTemp = "CHILLED";
          break;
        default:
          this.bNatureTemp = "N/A";
      }

      console.log(
        "this.bDegreeSO : ",
        this.bDegreeTempSO,
        data.masterBookingExtra.bDegreeSo,
        data.masterBookingExtra
      );
    }

    // GSO-1042 disable button when Transmit succeeded
    // GSO-1034 Depot EDI Integration - COC Local Bkg Modification
    this.handleCheckStatusDepot();

  }

  setShanghaiData(data: any) {
    console.log("** setData **");
    // console.log(data);
    var self = this;

    //this.model = new Booking();
    this.model.bPrefix ="SUDU";
    this.model.bNo = String(data[0]["bNo"]).trim().toUpperCase().replace("SSL","");
    this.model.bOfficeCodeTs = "";
    this.model.bPrefixTs = "";
    this.model.bNoTs = "";
    this.model.bOceanVesselBound = "O";

    this.model.bTsBooking = "N"; // disini valuenya bener = Y
    this.model.bIsSlotCommission = "";
    this.model.bIsReserve = "N";
    // this.model.bCreatedUserid = String(data[0]["receivedUserId"]).trim();
    // this.model.bCreatedDate = String(data[0]["dateTimeReceived"]).trim();
    // this.model.bModifiedUserid = String(data[0]["receivedUserId"]).trim();
    // this.model.bModifiedDate = String(data[0]["dateTimeReceived"]).trim();

    // this.model.bCreatedUserid = this.cookieService.getName();
    // this.model.bCreatedDate = this.genericUtil.getPSIDate();
    // this.model.bModifiedUserid = this.cookieService.getName();
    // this.model.bModifiedDate = this.genericUtil.getPSIDate();

    this.model.bContainerStatus = String(data[0]["containerStatus"]).trim();
    this.model.bIsFinished = "N";
    this.model.bReleaseOrderPrinted = "N";
    this.model.bIsTransmittedToPsa = "N";
    this.model.bTransmitToSds = "N";
    this.model.bContainerOwnership = String(data[0]["containerOwnership"]).trim();
    this.model.bIsUnlocked = "Y";
    this.model.bStatus = "N";

    // GSO-1063 Add SLOT LC Checkbox: SLOT LC check box can only allowed to checked when Container Ownership=SLOT
    if (this.model.bContainerOwnership=="SLOT")
    this.model.bSlotLC = "Y";
  else this.model.bSlotLC = "N";

    this.checkDisableSlotLc();

    if(data.bTransmitToSds === "Y"){
      this.isCheckedDepot = true;
    }else{
      this.isCheckedDepot = false;
    }

    // 01. Set vessel
    this.model.bOceanVesselId = String(data[0]["vesselID"]).trim();
    this.model.bOceanVesselCode = String(data[0]["VesselCode"]).trim();
    this.model.bOceanVesselName = String(data[0]["vesselName"]).trim();
    this.model.bOceanVesselBound = "O";
    this.model.bOceanVesselVoyage = String(data[0]["voyageNo"]).trim();
    this.cbVessel.setForceValue(String(data[0]["vesselName"]).trim());
    // this.model.confirmVoyage = data.confirmVoyage;
    this.model.bPot1Code = String(data[0]["potCode"]).trim();
    //   this.cbVoyage.disableCombo = false;
    this.model.bEdiBookingCommodity = String(data[0]["description"]).trim();

    // GOS-668
    this.model.bPendingRequest = "N";
    this.model.bNextMotherVesselVoyage = "";
    this.model.bNextMotherVesselBound = "";
    this.model.bNextMotherVesselCode = "";
    this.model.bNextMotherVesselId = "";
    this.model.etaNextVessel = "";
    this.model.etdNextVessel = "";
    this.cbNextVessel.setForceValue("");
    this.cbNextVoyage.setForceValue("");
    this.model.bNextVesselVoyagePriority = "N";
    this.isBookingRequest = (this.model.bPendingRequest == 'N' ||  this.model.bPendingRequest == 'Y' ) && this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage;
    // this.model.vesselVoyageConfirmStatus = this.model.bPendingRequest == 'N' && this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage;
    this.model.vesselVoyageConfirmStatus = false;

    // this.lockEdit = this.model.vesselVoyageConfirmStatus ? true : false; // Check disable next vessel & voyage
    //gos-268
    //http://localhost:14000/MasterBookings/getETDPOL/VS10549/064W/O/CNSHA/CNSHA
    //this.model.etdPOL = this.getEtdPol(this.model.bOceanVesselId,this.model.bOceanVesselVoyage,this.model.bOceanVesselBound, this.model.bOfficeCode);
    this.genericService
    .GET(
      this.configService.config.BASE_API.toString() +
        "/MasterBookings/getETDPOL/" +
        this.model.bOceanVesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim()) +
        "/" +
        this.model.bOceanVesselBound +
        "/" +
        this.model.bOfficeCode +
        "/" +
        this.model.bOfficeCode
    )
    .subscribe(
      (resp) => {
        if (Object.keys(resp.json()["content"]).length > 0) {
        let data_etdPOL = resp.json()["content"];
        this.model.etdPOL=data_etdPOL[0]["etdPOL"];
        }
      });

    this.model.ETA = "";
    this.model.ETD = "";
    this.model.ATA = "";
    this.model.ATD = "";

    this.serviceCode = "";

    this.searchFpIsValid = "";
    this.searchFpIsFreightParty = "";

    this.searchShIsValid = "";
    this.searchShIsShipper = "";

    this.searchBkIsValid = "";
    this.searchBkIsBookingParty = "";
    this.contactFromBooking = "";
    this.telephoneFromBooking = "";
    this.attrCustChanged = false;

    this.model.bPodCode = String(data[0]["podCode"]).trim();
    this.model.bPodName = String(data[0]["podName"]).trim();
    this.isAfterSaveCopy = false;

    //GSO-1030
    this.model.bDepotTransmitDate = "";

    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
        this.model.bOfficeCode +
        "/" +
        this.model.bOceanVesselId +
        "/" +
        this.model.bOceanVesselBound +
        "/voyage={query}"
    );

     this.cbVoyage.setValue(String(data[0]["voyageNo"]).trim());

    // this.cbVoyage.setValue(String(data[0]["voyageNo"]).trim(),
    // function (callbackVoyage) {
    //   self.cbVoyage.setUrl(
    //     self.configService.config.BASE_API.toString() +
    //       "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
    //       this.model.bOfficeCode +
    //       "/" +
    //       this.model.bOceanVesselId +
    //       "/" +
    //       this.model.bOceanVesselBound +
    //       "/voyage_like={query}"
    //   );

    //   self.model.bOceanVesselVoyage = callbackVoyage.voyage;
    //   self.serviceCode = callbackVoyage.serviceCode;

    // });
    this.model.bOceanVesselVoyage =String(data[0]["voyageNo"]).trim();
   //self.serviceCode = "";

    this.model.bPolCode = String(data[0]["polCode"]);

    this.model.bPot1Code = String(data[0]["potCode"]);
    this.model.bPot1Name = String(data[0]["potName"]);

    this.model.bPodCode = String(data[0]["podCode"]);
    this.model.bPodBooking = String(data[0]["podBooking"]);
    console.log("data.bPodName ==> " + String(data[0]["podName"]));
    this.cbDischargePort.setForceValue(String(data[0]["podName"]));

   this.getRotation(
    this.model.bOceanVesselId,
    this.model.bOceanVesselVoyage,
    this.model.bOceanVesselBound,
    this.model.bOfficeCode
    );

    this.getAllocationAndEstimateArrivalDate(
      this.model.bOceanVesselId,
      this.model.bOceanVesselVoyage,
      this.model.bOceanVesselBound,
      this.model.bOfficeCode,
      'Y'
    );

    // this.genericService
    // .GET(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterBookings/getSailSchedule/" +
    //     this.model.bOceanVesselId +
    //     "/" +
    //     this.sCharUtil.htmlEncode(String(this.model.bOceanVesselVoyage).trim()) +
    //     "/" +
    //     this.model.bOceanVesselBound +
    //     "/" +
    //     this.model.bOfficeCode +"/" + this.model.bPolCode
    // )
    // .subscribe(
    //   (resp) => {
    //     let data = resp.json();

    //     //this.model.ETA = String(data["ETA"]).trim().replace(/\//gi, "-");
    //     //this.model.ETD = String(data["ETD"]).replace(/\//g, "-");
    //     this.model.ETA = String(data["ETAPOL"]).trim().replace(/\//gi, "-");
    //     this.model.ETD = String(data["ETDPOL"]).trim().replace(/\//gi, "-");
    //     this.model.ATA = String(data["ATA"]).replace(/\//g, "-");
    //     this.model.ATD = String(data["ATD"]).replace(/\//g, "-");

    //     let shaAllocation = new Booking();
    //     shaAllocation.ETA=this.model.ETA;
    //     shaAllocation.ATD=this.model.ETD;
    //     shaAllocation.ATA=this.model.ATA;
    //     shaAllocation.ATD=this.model.ATD;
    //     shaAllocation.bOfficeCode=this.model.bOfficeCode;
    //     shaAllocation.bPrefix=this.model.bPrefix;
    //     shaAllocation.bOceanVesselId=this.model.bOceanVesselId;
    //     shaAllocation.bOceanVesselCode=this.model.bOceanVesselCode;
    //     shaAllocation.bOceanVesselBound=this.model.bOceanVesselName;
    //     shaAllocation.bOceanVesselVoyage=this.model.bOceanVesselVoyage;
    //     shaAllocation.bOceanVesselBound=this.model.bOceanVesselBound;

    //     //Start this.loadDataAlloc();
    //     this.genericService
    //     .POST(
    //       this.configService.config.BASE_API.toString() +
    //         "/MasterBookings/calculateAllocation2",
    //         shaAllocation
    //     )
    //     .subscribe((resp) => {
    //       let dataAlloc: any[] = resp.json()["content"];

    //       this.rowData = [];
    //       let no: number = 1;
    //       dataAlloc.forEach((element) => {
    //         element.no = no++;
    //         this.rowData.push(element);
    //       });

    //       if (this.rowData.length > 0) {
    //         this.defaultBooked = this.rowData[0]["booked"];
    //         this.defaultBooked2 = "";
    //         this.rowData.forEach((element) => {
    //           if (element.allotmentCode != "VS") {
    //             this.defaultBooked2 = this.defaultBooked2 + element.booked + ";";
    //           }
    //         });
    //       }

    //       if (this.trigerInit) {
    //         this.genericUtil.hideLoader();

    //         this.trigerInit = false;
    //         this.isError = false;
    //       }
    //     });

    //     //end this.loadDataAlloc();
    //   },
    //   (error) => {
    //     // console.log(error.message);
    //   }
    // );


    this.getWeightProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);
    // self.gridWeightProfiles.url = "";
    // self.gridWeightProfiles.listStore.store = data.gridWeightProfiles;
    // self.gridWeightProfiles.loadData();

    this.getContainerProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);
    // self.gridContainerProfiles.url = "";
    // self.gridContainerProfiles.listStore.store = data.gridContainerProfiles;
    // self.gridContainerProfiles.loadData();

    

   
    //let rotationList: String[] = this.rotation.split(" - ");
    // if (this.model.bPodCode != "") {

    //   rotationList.forEach((element) => {
    //     //|| String(element).trim() == String(this.model.bPodCode).trim() + ` (${this.model.bPodTerminalCode.slice(-3) })
    //     if (String(element).trim() == String(this.model.bPodCode).trim()) {
        
    //     }
    //   });
    // } 

    this.model.bBookingPartyCode = String(data[0]["bookingPartyId"]);
    this.cbBookingParty.setForceValue(String(data[0]["bookingPartyName"]));

    this.nvoccBookingParty = false;

    if (String(data[0]["containerOwnership"])=="SOC" || String(data[0]["containerOwnership"])=="SOCTS")
    this.isSocBookingParty = true; else this.isSocBookingParty=false;

    if (String(data[0]["containerOwnership"])=="COC" || String(data[0]["containerOwnership"])=="COCTS")
    this.isCocBookingParty =  true; else this.isCocBookingParty = false;

    if (String(data[0]["containerOwnership"])=="SLOT" )
    this.isSlotBookingParty = true; else  this.isSlotBookingParty=false;

    if (String(data[0]["bookingPartyId"]) !== null &&
        String(data[0]["bookingPartyId"]) !== "" &&
        String(data[0]["bookingPartyId"]) !== undefined
    ) {
      this.custIdForCbBooking = String(data[0]["bookingPartyId"]);
    } else {
      this.custIdForCbBooking = "*";
    }

    let uriBooking =
              this.configService.config.BASE_API.toString() +
              "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/"+this.custIdForCbBooking+"/customerName="+
              this.sCharUtil.htmlEncode(String(data[0]["bookingPartyName"]).trim());

    var self = this;
    this.genericService.GET(uriBooking).subscribe((resp) => {
        if (resp.ok) {
          var datas = resp.json()['content'];
          if (datas.length != 0) {
            datas.forEach(function (data) {
              self.searchBkIsValid = data['isValid'];
              self.searchBkIsBookingParty = data['isBookingParty'];
            });
          }
        }
    });

    if (
      this.model.bContainerOwnership === "COC" ||
      this.model.bContainerOwnership === "COCTS"
    ) {
      this.cbCocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCocOperator/" +
          String(data[0]["bookingPartyId"])
      );

      //this.cbCocOPR.setValue("SA");
      this.cbCocOPR.loadSelect(function (clcocoper) {
        if (
          (self.model.bContainerOwnership == "COC" ||
            self.model.bContainerOwnership == "COCTS") &&
            self.cbCocOPR.listStore.store.length > 0
        ) {
            self.model.bCocCntrOperatorCode = self.cbCocOPR.listStore.store[0]["code"].toString();
            self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[0]["code"]);
        }
      });

    } else if (
      this.model.bContainerOwnership === "SOC" ||
      this.model.bContainerOwnership === "SOCTS"
    ) {
      this.cbSocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSocOperator/" +
          String(data[0]["bookingPartyId"])
      );
      this.cbSocOPR.loadSelect();
      this.model.bSocCntrOperatorCode = ""; //SOC container OP
      this.retrieveSocContainerOperatorCode = ""; //SOC container OP
      this.cbSocOPR.setValue(""); //SOC container OP

      this.cbSlotOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          String(data[0]["bookingPartyId"])
      );
      this.cbSlotOPR.loadSelect();

      this.cbSlotCntrOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          String(data[0]["bookingPartyId"])
      );
      this.cbSlotCntrOPR.loadSelect();
    } else {
      this.cbSlotOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          String(data[0]["bookingPartyId"])
      );
      this.cbSlotOPR.loadSelect();
      this.cbSlotOPR.setValue(""); //SlotOperatorCode,bSlotOperatorCode
      this.cbSlotCntrOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSlotOperator/" +
          String(data[0]["bookingPartyId"])
      );
      this.cbSlotCntrOPR.loadSelect();
      this.cbSlotCntrOPR.setValue("");//bOutwardSlotOperatorCode

      this.cbSocOPR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findSocOperator/" +
          String(data[0]["bookingPartyId"])
      );
      this.cbSocOPR.loadSelect();
    }

    this.model.bShipperCode = String(data[0]["shipperId"]);
    this.cbShipper.setForceValue(String(data[0]["shipperName"]));
    this.nvoccShipper =  false;


    if (String(data[0]["shipperId"]) !== null &&
       String(data[0]["shipperId"]) !== "" &&
        data.bShipperCode !== undefined
    ) {
      this.custIdForCbShipper = String(data[0]["shipperId"]);
    } else {
      this.custIdForCbShipper = "*";
    }

    let uriShipper =
              this.configService.config.BASE_API.toString() +
              "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/"+this.custIdForCbShipper+"/customerName="+
              this.sCharUtil.htmlEncode(String(data[0]["shipperName"]).trim());

    var self = this;
    this.genericService.GET(uriShipper).subscribe((resp) => {
        if (resp.ok) {
          var datas = resp.json()['content'];
          if (datas.length != 0) {
            datas.forEach(function (data_shipper) {
              self.searchShIsValid = data_shipper['isValid'];
              self.searchShIsShipper = data_shipper['isShipper'];
            });
          }
        }
    });

    this.model.bFreightPartyCode = String(data[0]["freightPartyId"]);
    this.cbFreightParty.setForceValue(String(data[0]["freightPartyName"]));

    this.model.bFreightType= String(data[0]["freightType"]);

    if (String(data[0]["freightPartyId"]) !== null &&
        String(data[0]["freightPartyId"]) !== "" &&
        String(data[0]["freightPartyId"]) !== undefined
      ) {
        this.custIdForCbFreightParty = String(data[0]["freightPartyId"]);
    } else {
      this.custIdForCbFreightParty = "*";
    }

    let uriFP =
              this.configService.config.BASE_API.toString() +
              "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/"+this.custIdForCbFreightParty+"/customerName="+
              this.sCharUtil.htmlEncode(String(data[0]["freightPartyName"]).trim());

    var self = this;
    this.genericService.GET(uriFP).subscribe((resp) => {
        if (resp.ok) {
          var datas = resp.json()['content'];
          if (datas.length != 0) {
            datas.forEach(function (data_FreightParty) {
              self.searchFpIsValid = data_FreightParty['isValid'];
              self.searchFpIsFreightParty = data_FreightParty['isFreightParty'];
            });
          }
        }
    });

    this.nvoccFreightParty =  false;

    this.model.bPorCode = String(data[0]["porCode"]);
    this.cbPOR.setForceValue(String(data[0]["porName"]));

    this.model.bPsaReferenceNo = String(data[0]["psaReferenceNo"]);

    this.model.bPolCode = String(data[0]["polCode"]);
    this.model.bPolBooking = String(data[0]["polBooking"]);

    console.log("this is");
    console.log(this.model);
    console.log(this.model.bPolCode);
    this.cbLoadPort.setForceValue(String(data[0]["polName"]));

    // this.cbLoadPortTerminal.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //   "/MasterTerminals/findTerminalByLocation/" +
    //   data.bPolTerminalCode +
    //   "/{query}"
    // );
    // this.cbLoadPortTerminal.setValue(data.bPolTerminalCode);
    // this.cbLoadPortTerminal.loadSelect();
    // this.model.bPolTerminalCode = data.bPolTerminalCode;
    // this.model.bPolTerminalName = data.bPolTerminalName;

    // this.model.bPot1TerminalCode = data.bPot1TerminalCode;
    // this.model.bPot1TerminalName = data.bPot1TerminalName;
    // this.cbPOT1Terminal.setForceValue(data.bPot1TerminalName);
    // this.model.bPodTerminalCode = data.bPodTerminalCode;
    // this.model.bPodTerminalName = data.bPodTerminalName;
    // this.cbDischargePortTerminal.setForceValue(data.bPodTerminalName);

    this.model.bPodlCode = String(data[0]["podlCode"]);
    this.cbPODL.setForceValue(String(data[0]["placeOfDeliveryName"]));

    this.model.bFdestCode = String(data[0]["fdestCode"]);
    this.cbFinalDest.setForceValue(String(data[0]["finalDestinationName"]));

    this.model.bSpecialDetailCode = "";
    this.cbSpecialDet.setForceValue("");

    this.model.bPot1Code = String(data[0]["potCode"]);
    //this.cbPOT2.setForceValue(data.bPot2Name);

    this.model.bPot2Code = "";
    this.cbPOT2.setForceValue("");

    this.model.bPot3Code = "";
    this.cbPOT3.setForceValue("");

    this.model.bCommodityCode = "";
    this.cbCommodity.setForceValue("");

    // this.model.bReceiptType = String(data[0]["receiptType"]);
    // this.cbReceiptType.setForceValue(String(data[0]["receiptType"]));

    // this.model.bDeliveryType = String(data[0]["deliveryType"]);
    // this.cbDeliveryType.setForceValue(String(data[0]["deliveryType"]));

    this.model.bReceiptType = "CY";
    this.cbReceiptType.setForceValue("CY");

    this.model.bDeliveryType = "CY";
    this.cbDeliveryType.setForceValue("CY");

    this.model.bFreightType = String(data[0]["freightType"]);
    this.model.bIhc = "N"; //flag who to handle the haulier
    this.model.bContainerOwnership = String(data[0]["containerOwnership"]);
    if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      this.lockSOC = false;
      this.lockCOC = true;
      this.lockSLOT = false;
      this.lockSOCOPR = false;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = false;
      this.lockSLOTCNTROPR = false;
      // this.model.bSocCntrOperatorCode = data.bSocCntrOperatorCode;
      this.model.bSocOperatorCode = "SA";
    } else if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      this.lockSOC = true;
      this.lockCOC = false;
      this.lockSLOT = true;
      this.lockSOCOPR = true;
      this.lockCOCOPR = false;
      this.lockSLOTOPR = true;
      this.lockSLOTCNTROPR = true;
      // this.model.bCocCntrOperatorCode = "SA";
      this.model.bCocOperatorCode = "SA";
    } else if (this.model.bContainerOwnership == "SLOT") {
      this.lockSOC = false;
      this.lockCOC = true;
      this.lockSLOT = false;
      this.lockSOCOPR = false;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = false;
      this.lockSLOTCNTROPR = false;
      // this.model.bOutwardSlotOperatorCode = data.bOutwardSlotOperatorCode;
      // this.model.bSlotOperatorCode = data.bSlotOperatorCode;
    }

    this.model.bShipmentType = "N";
   // this.model.bDgClass = String(data[0]["dgClass"]);
    if(String(data[0]["unno"])!=''){
      this.model.bDgClass ='C2';
    }else {
      this.model.bDgClass ='N'
    }
    this.model.bWeightClass = "M";  // set to medium(M), or set to custom(C) theb write the exact weight to the weight column
    this.model.bWeight = 0;
    //this.model.bRemarks = String(data[0]["remarks"]);
    //this.bRmarksFormat = data[0]["remarks"].toString().replaceAll('↵', '\r\n');
    this.txtRemarks.setValue(this.model.bRemarks.toString());
    if(String(data[0]["contactPerson"])!=""){
    this.model.bContactPerson = String(data[0]["contactPerson"]);
    this.contactFromBooking =String(data[0]["contactPerson"]);
    this.model.bTelephone = String(data[0]["telNo"]);
    this.telephoneFromBooking = String(data[0]["telNo"]);
    }else{
    this.model.bContactPerson ="EDI";
    this.contactFromBooking ="EDI";
    }
   
    // this.retrieveSocContainerOperatorCode = data.bSocCntrOperatorCode;
    // this.retrieveCocContainerOperatorCode = data.bCocCntrOperatorCode;
    //XXX this.retrieveSlotContainerOperatorCode = data.bSlotCntrOperatorCode;


    // //Booking Note 2
    // this.cbFeederVessel.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*"
    // );
    // this.cbFeederVessel.setForceValue(data.bFeederVesselName);
    // this.cbFeederVoyage.setForceValue(data.bFeederVesselVoyage);
    // this.model.bFeederVesselId = data.bFeederVesselId;
    // this.model.bFeederVesselVoyage = data.bFeederVesselVoyage;
    // //this.cbFeederVessel.setValue(data.bFeederVesselCode,function (callbackVesselFeeder) {

    // //self.cbFeederVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*');

    // //self.model.bFeederVesselId = callbackVesselFeeder.vesselId;
    // //self.model.bFeederVesselCode = callbackVesselFeeder.vesselCode;

    // //self.cbFeederVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ data.bFeederVesselId+"/"+ data.bFeederVesselBound+"/{query}/" + data.bOfficeCode);
    // //self.cbFeederVoyage.setValue(data.bFeederVesselVoyage);
    // //});

    // this.cbSupplier.setForceValue(data.bSupplierName);
    // this.model.bSupplierCode = data.bSupplierCode;
    // /*this.cbSupplier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlSupplier/customerId={query}');
    // this.cbSupplier.setValue(data.bSupplierCode,(callbacksupplier) => {
    //   self.cbSupplier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlSupplier/customerName={query}');
    // });*/

    // if (
    //   data.bExMotherVesselId != null &&
    //   data.bExMotherVesselId != "" &&
    //   data.bExMotherVesselId != undefined
    // ) {
    //   this.model.bExMotherVesselId = data.bExMotherVesselId;
    //   this.model.bExMotherVesselCode = data.bExMotherVesselCode;
    //   this.model.bExMotherVesselVoyage = data.bExMotherVesselVoyage;
    //   this.model.bExMotherVesselName = data.bExMotherVesselName;
    //   this.cbExVessel.setForceValue(data.bExMotherVesselName);
    // }

    // if (
    //   data.bNextMotherVesselId != null &&
    //   data.bNextMotherVesselId != "" &&
    //   data.bNextMotherVesselId != undefined
    // ) {
    //   this.model.bNextMotherVesselId = data.bNextMotherVesselId;
    //   this.model.bNextMotherVesselCode = data.bNextMotherVesselCode;
    //   this.model.bNextMotherVesselVoyage = data.bNextMotherVesselVoyage;
    //   this.cbNextVessel.setForceValue(data.bNextMotherVesselName);
    //   this.cbNextVoyage.setForceValue(data.bNextMotherVesselVoyage);
    //   this.cbNextVoyage.setUrl(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterSailingSchedules/distinctVoyage/" +
    //       this.model.bOceanVesselId +
    //       "/" +
    //       this.model.bOceanVesselBound +
    //       "/{query}/" +
    //       this.model.bPot1Code
    //   );
    // }

    // console.log("** bNextMotherVesselVoyage ==> ");
    // console.log(this.model);

     //gos 256
    /*
     if ((this.model.bContainerOwnership == "SOC"  || this.model.bContainerOwnership == "SLOT") && this.model.bPodlCode == "SGSIN" && this.model.bFdestCode == "" && this.siteOffice != 'SGSIN' && this.model.bPot1Code == ""  && this.model.bPot2Code == "" && this.model.bPot3Code == ""){


        $("#tsBkg").prop("checked", true);
        //gos 281
        this.model.bTsBooking = 'Y';

    }else{
      $("#tsBkg").prop("checked", false);
      console.log('masuk suk')
       //gos 281
       this.model.bTsBooking = 'N';
    }
    */

    // if (String(data.bRequestReleaseDate) != "") {
    //   this.model.bRequestReleaseDate = String(data.bRequestReleaseDate) + ":00";
    //   //this.dtRequestReleaseDate.dateP.nativeElement.value= this.model.bRequestReleaseDate;
    // }

    // if (String(data.bExpectedReturnDate) != "") {
    //   this.model.bExpectedReturnDate = String(data.bExpectedReturnDate) + ":00";
    //   //this.dtExpReturnDate.dateP.nativeElement.value= this.model.bExpectedReturnDate;
    // }

    // if (String(data.bReeferCollectionDate) != "") {
    //   this.bookingExtra.bReeferCollectionDate =
    //     String(data.bReeferCollectionDate) + ":00";
    //   //this.dtReeferCollection.dateP.nativeElement.value= this.bookingExtra.bReeferCollectionDate;
    //   // Alternative for *dateReeferCollection.setValue()*
    //   if(data.bReeferCollectionDate != data.masterBookingMisc.bReleaseAtDate){
    //     this.setDatePluginValue(this.settingReeferCollection.id,
    //       this.bookingExtra.bReeferCollectionDate.toString()
    //     );
    //   }
    // }

    // this.model.bHaulierContact = data.bHaulierContact;
    // //this.cbPackedAt.setValue(data.bPackedAtCode);
    // this.model.bHaulierContactNo = data.bHaulierContactNo;

    // this.model.bWt =  data[0]["grossWeight"];
    // this.model.bMeasure = data[0]["grossMeasure"];
    // this.model.bPackageNo =data[0]["outerQty"];
    // this.cbPackageType.setValue(String(data[0]["innerPkgTypeCode"]));

    // this.bDegreeTemp = String(data.bReeferDegree).substring(
    //   String(data.bReeferDegree).length + 1,
    //   String(data.bReeferDegree).length - 1
    // );
    // this.bDegree = Number.parseInt(
    //   String(data.bReeferDegree).replace(this.bDegreeTemp.toString(), "")
    // );
    // this.bookingExtra.bReeferDegree = String(this.bDegree) + this.bDegreeTemp;
    // this.bookingExtra.bIsDry = data.bIsDry;
    // this.bookingExtra.bVent = data.bVent;

    if(this.model.bContainerOwnership == "COC"){
      this.model.bDetention = "S";
      this.model.bDemurrage = "S";
      this.bookingExtra.bDetentionPol = "S";
      this.bookingExtra.bDemurragePol = "S";
    }else{
      this.model.bDetention = "N";
      this.model.bDemurrage = "N";
      this.bookingExtra.bDetentionPol = "N";
      this.bookingExtra.bDemurragePol = "N";
    }

    this.model.bDetentionRequest = 0;
    this.model.bDemurrageRequest = 0;
    this.bookingExtra.bDetentionRequestPol = 0;
    this.bookingExtra.bDemurrageRequestPol = 0;

    if (this.model.bDetention == "R") {
      this.lockDetention = false;
    } else {
      this.lockDetention = true;
    }

    if (this.model.bDemurrage == "R") {
      this.lockDemurrage = false;
    } else {
      this.lockDemurrage = true;
    }

    if (data.length > 0) {
      this.bookingDetails.clear();

      this.currentContainerType = "";


      //data.masterBookingDetails.forEach((element) => {
      data.forEach((element) => {
        this.killSlot = 0;
        this.doorOpen = "N";
        this.UCH = 0;
        this.UCL = 0;
        this.UCW = 0;

        if(element.oogOh>0 ){this.OH="Y"} else {this.OH="N"}
        // if (this.OH == "Y") {
        //   this.lockOH = false;
        // } else {
        //   this.lockOH = true;
        // }

        if(element.oogOlf>0 || element.oogOlb>0){this.OL="Y"} else {this.OL="N"}
        //this.OL = element.bOverLength;
        // if (this.OL == "Y") {
        //   this.lockOL = false;
        // } else {
        //   this.lockOL = true;
        // }
        if(element.oogOwl>0 || element.oogOwr>0){this.OW="Y"} else {this.OW="N"}
       // this.OW = element.bOverWidth;
        // if (this.OW == "Y") {
        //   this.lockOW = false;
        // } else {
        //   this.lockOW = true;
        // }

        this.heightMeasure = element.oogOh;
        this.leftMeasure = element.oogOwl;//bOverWidthLeftCm
        this.rightMeasure = element.oogOwr;//bOverWidthRightCm
        this.frontMeasure = element.oogOlf;//bOverLengthFrontCm
        this.endMeasure = element.oogOlb;//bOverLengthBackCm

        let BkgDtl = new BookingDetail();
        BkgDtl.bOfficeCode = this.model.bOfficeCode;
        BkgDtl.bPrefix = this.model.bPrefix;
        BkgDtl.bNo = this.model.bNo;
        //BkgDtl.bContainerType = this.currentContainerType;
        BkgDtl.bContainerType =element.cType;
        BkgDtl.bContainerSize = element.containerSize;
        BkgDtl.bContainerHeight = element.containerHeight;
        BkgDtl.bQuantity = element.containerQty;
        BkgDtl.bKillSlot=this.killSlot;
        BkgDtl.bUch=this.UCH;
        BkgDtl.bUcl=this.UCL;
        BkgDtl.bUcw=this.UCW ;
        BkgDtl.bOverHeight=this.OH;
        BkgDtl.bOverHeightCm=element.oogOh;
        BkgDtl.bOverWidth=this.OW;
        BkgDtl.bOverWidthLeftCm=element.oogOwl;
        BkgDtl.bOverWidthRightCm=element.oogOwr;
        BkgDtl.bOverLength=this.OL;
        BkgDtl.bOverLengthFrontCm=element.oogOlf;
        BkgDtl.bOverLengthBackCm=element.oogOlb;
        BkgDtl.bDoorOpen=this.doorOpen;

        if (this.bookingDetails.get(element.cType) == null) {
          let list = new Map<String, BookingDetail>();
          list.set(element.containerSize + element.containerHeight, BkgDtl);
          this.bookingDetails.set(element.cType, list);
        } else {
          this.bookingDetails
            .get(element.cType)
            .set(element.containerSize + element.containerHeight, BkgDtl);
        }

        if (this.model.bContainerOwnership == "SLOT") {
          // console.log("Size : " + this.bookingDetails.size);

          /*
          if(this.bookingDetails.size > 1){
            if(this.bookingDetails.get("STD") != null){
              this.currentContainerType = 'STD';
            }else if(this.bookingDetails.get("REF") != null){
              this.currentContainerType = 'REF';
            }else if(this.bookingDetails.get("TNK") != null){
              this.currentContainerType = 'TNK';
            }else if(this.bookingDetails.get("DRY") != null){
              this.currentContainerType = 'DRY';
            }
          }else{
            this.currentContainerType = element.bContainerType;
          }
          */
          this.currentContainerType = element.cType;
        } else {
          this.currentContainerType = element.cType;
        }

        this.lockOOG = true;
        if (
          this.currentContainerType == "OPT" ||
          this.currentContainerType == "PLT" ||
          this.currentContainerType == "FLR"
        ) {
          this.lockOOG = false;
          this.lockSTD = true;
        }

        if (this.currentContainerType == "REF") {
          this.lockReeferTemp = false;
          this.lockNature = false;
          this.lockHumidity = false;
          this.lockSTD = true;
        }

        if (this.currentContainerType == "DRY") {
          this.lockHumidity = true;
          this.lockSTD = true;
        }
        this.dateReeferCollection.setDisable(this.lockReeferTemp);
      });

      for (let det of Array.from(
        this.bookingDetails.get(this.currentContainerType)
      )) {
        switch (det[0]) {
          case "2086":
            this.d20 = det[1].bQuantity;
            this.D20Saved = this.d20;
            break;
          case "4086":
            this.d40 = det[1].bQuantity;
            this.D40Saved = this.d40;
            break;
          case "4586":
            this.d45 = det[1].bQuantity;
            this.D45Saved = this.d45;
            break;
          case "2096":
            this.h20 = det[1].bQuantity;
            this.H20Saved = this.h20;
            break;
          case "4096":
            this.h40 = det[1].bQuantity;
            this.H40Saved = this.h40;
            break;
          case "4596":
            this.h45 = det[1].bQuantity;
            this.H45Saved = this.h45 as number;
            break;
          default:
            break;
        }
      }
    }

    // Container info
    console.log("** SET DATA **");
    // console.log(data.masterBookingContainerInformation);

    // if (
    //   data.masterBookingContainerInformations != null &&
    //   data.masterBookingContainerInformations.length > 0
    // ) {
    //   this.gridContainerInfo.url = "";
    //   this.gridContainerInfo.onClear();
    //   for (let i = 0; i < data.masterBookingContainerInformations.length; i++) {
    //     let otherDetailsClean: String = "";
    //     if (
    //       data.masterBookingContainerInformations[i].bOtherDetails.length <=
    //         1 ||
    //       data.masterBookingContainerInformations[i].bOtherDetails === "↵"
    //     ) {
    //       otherDetailsClean = "";
    //     } else {
    //       for (
    //         let j = 0;
    //         j <
    //         data.masterBookingContainerInformations[i].bOtherDetails.split("↵")
    //           .length;
    //         j++
    //       ) {
    //         if (j === 0) {
    //           otherDetailsClean +=
    //             data.masterBookingContainerInformations[i].bOtherDetails.split(
    //               "↵"
    //             )[j] + "<br/>";
    //         } else if (j === 1) {
    //           otherDetailsClean +=
    //             data.masterBookingContainerInformations[i].bOtherDetails.split(
    //               "↵"
    //             )[j];
    //         }
    //       }
    //     }
    //     data.masterBookingContainerInformations[i].bOtherDetailsClean =
    //       otherDetailsClean;
    //     this.model.bookingContainerInfo.push(
    //       data.masterBookingContainerInformations[i]
    //     );
    //     this.gridContainerInfo.listStore.store.push(
    //       data.masterBookingContainerInformations[i]
    //     );
    //   }
    //   this.gridContainerInfo.loadData();
    // }

    // //misc
    // if (data.masterBookingMisc != null) {
    //   console.log("before kitchen cat");
    //   console.log(data.masterBookingMisc);
    //   this.bookingMiscCheckboxPrint = data.masterBookingMisc.bPrint === "Y" ? true : false;
    //   this.bookingMisc = data.masterBookingMisc;

    //   //set combo
    //   // 1. Hauiler if exist
    //   if (
    //     this.bookingMisc.bHaulierId != null &&
    //     this.bookingMisc.bHaulierId != "" &&
    //     this.bookingMisc.bHaulierId != undefined
    //   ) {
    //     /*
    //     this.cbHaulier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlHaulier/customerId={query}');
    //     this.cbHaulier.setValue(this.bookingMisc.bHaulierId.toString());
    //     */
    //     this.cbHaulier.setForceValue(this.bookingMisc.bHaulierName.toString());
    //   }

    //   // 2. Release date
    //   if (
    //     this.bookingMisc.bReleaseAtDate != null &&
    //     this.bookingMisc.bReleaseAtDate != "" &&
    //     this.bookingMisc.bReleaseAtDate != undefined
    //   ) {
    //     console.log(this.bookingMisc.bReleaseAtDate.toString());

    //     // Alternative for *dateReleaseAt.setValue()*
    //     this.setDatePluginValue(this.settingDateReleaseAt.id, this.bookingMisc.bReleaseAtDate.toString());
    //   }

    //   // 3. Return to (por)
    //   if (
    //     this.bookingMisc.bReturnToPorGateInDate != null &&
    //     this.bookingMisc.bReturnToPorGateInDate != "" &&
    //     this.bookingMisc.bReturnToPorGateInDate != undefined
    //   ) {
    //     this.dateReturnToPor.setValue(
    //       this.bookingMisc.bReturnToPorGateInDate.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bReturnToPorClosingDate != null &&
    //     this.bookingMisc.bReturnToPorClosingDate != "" &&
    //     this.bookingMisc.bReturnToPorClosingDate != undefined
    //   ) {
    //     this.dateReturnToPorGateInClosing.setValue(
    //       this.bookingMisc.bReturnToPorClosingDate.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bReturnToPorVgmDate != null &&
    //     this.bookingMisc.bReturnToPorVgmDate != "" &&
    //     this.bookingMisc.bReturnToPorVgmDate != undefined
    //   ) {
    //     this.dateReturnToPorVgmClosing.setValue(
    //       this.bookingMisc.bReturnToPorVgmDate.toString()
    //     );
    //   }

    //   // 4. Return to (pol)
    //   if (
    //     this.bookingMisc.bReturnToPolGateInDate != null &&
    //     this.bookingMisc.bReturnToPolGateInDate != "" &&
    //     this.bookingMisc.bReturnToPolGateInDate != undefined
    //   ) {
    //     this.dateReturnToPol.setValue(
    //       this.bookingMisc.bReturnToPolGateInDate.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bReturnToPolClosingDate != null &&
    //     this.bookingMisc.bReturnToPolClosingDate != "" &&
    //     this.bookingMisc.bReturnToPolClosingDate != undefined
    //   ) {
    //     this.dateReturnToPolGateInClosing.setValue(
    //       this.bookingMisc.bReturnToPolClosingDate.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bReturnToPolVgmDate != null &&
    //     this.bookingMisc.bReturnToPolVgmDate != "" &&
    //     this.bookingMisc.bReturnToPolVgmDate != undefined
    //   ) {
    //     this.dateReturnToPolVgmClosing.setValue(
    //       this.bookingMisc.bReturnToPolVgmDate.toString()
    //     );
    //   }

    //   // 5. Stuff at
    //   if (
    //     this.bookingMisc.bStuffAtPorGateInDate != null &&
    //     this.bookingMisc.bStuffAtPorGateInDate != "" &&
    //     this.bookingMisc.bStuffAtPorGateInDate != undefined
    //   ) {
    //     this.dateStuffAtPor.setValue(
    //       this.bookingMisc.bStuffAtPorGateInDate.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bStuffAtPorClosingDate != null &&
    //     this.bookingMisc.bStuffAtPorClosingDate != "" &&
    //     this.bookingMisc.bStuffAtPorClosingDate != undefined
    //   ) {
    //     this.dateStuffAtPorGateInClosing.setValue(
    //       this.bookingMisc.bStuffAtPorClosingDate.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bStuffAtPorVgmDate != null &&
    //     this.bookingMisc.bStuffAtPorVgmDate != "" &&
    //     this.bookingMisc.bStuffAtPorVgmDate != undefined
    //   ) {
    //     this.dateStuffAtPorVgmClosing.setValue(
    //       this.bookingMisc.bStuffAtPorVgmDate.toString()
    //     );
    //   }

    //   if (
    //     this.bookingMisc.bReleaseAt != null &&
    //     this.bookingMisc.bReleaseAt != "" &&
    //     this.bookingMisc.bReleaseAt != undefined
    //   ) {
    //     /*
    //     this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
    //     this.cbReleaseAt.setValue(this.bookingMisc.bReleaseAt.toString());
    //     */
    //     this.cbReleaseAt.setForceValue(
    //       this.bookingMisc.bReleaseAtName.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bReturnToPor != null &&
    //     this.bookingMisc.bReturnToPor != "" &&
    //     this.bookingMisc.bReturnToPor != undefined
    //   ) {
    //     /*
    //     this.cbReturnToPor.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
    //     this.cbReturnToPor.setValue(this.bookingMisc.bReturnToPor.toString());
    //      */
    //     this.cbReturnToPor.setForceValue(
    //       this.bookingMisc.bReturnToPorName.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bReturnToPol != null &&
    //     this.bookingMisc.bReturnToPol != "" &&
    //     this.bookingMisc.bReturnToPol != undefined
    //   ) {
    //     /*
    //     this.cbReturnToPol.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
    //     this.cbReturnToPol.setValue(this.bookingMisc.bReturnToPol.toString());
    //     */
    //     this.cbReturnToPol.setForceValue(
    //       this.bookingMisc.bReturnToPolName.toString()
    //     );
    //   }
    //   if (
    //     this.bookingMisc.bStuffAtPor != null &&
    //     this.bookingMisc.bStuffAtPor != "" &&
    //     this.bookingMisc.bStuffAtPor != undefined
    //   ) {
    //     /*
    //     this.cbStuffAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
    //     this.cbStuffAt.setValue(this.bookingMisc.bStuffAtPor.toString());
    //     */
    //     this.cbStuffAt.setForceValue(
    //       this.bookingMisc.bStuffAtPorName.toString()
    //     );
    //   }

    //   if (
    //     this.bookingMisc.bReceiptTypeExtra != null &&
    //     this.bookingMisc.bReceiptTypeExtra != "" &&
    //     this.bookingMisc.bReceiptTypeExtra != undefined
    //   ) {
    //     console.log("Debug get summary");
    //     console.log(this.getDetailSummary());
    //     if (this.getDetailSummary() == "") {
    //       this.cbReceiptTypeExtra.setUrl(
    //         this.configService.config.BASE_API.toString() +
    //           "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
    //       );
    //       this.cbReceiptTypeExtra.setValue(
    //         this.bookingMisc.bReceiptTypeExtra.toString()
    //       );
    //     } else {
    //       this.populateReceiptTypeExtra();
    //       this.cbReceiptTypeExtra.setForceValue(
    //         this.bookingMisc.bReceiptTypeExtra.toString()
    //       );
    //     }
    //   }

    //   if (
    //     this.bookingMisc.bAdditionalRemarks != null &&
    //     this.bookingMisc.bAdditionalRemarks != "" &&
    //     this.bookingMisc.bAdditionalRemarks != undefined
    //   ) {
    //     this.txtTerminalRemarks.setValue(
    //       this.bookingMisc.bAdditionalRemarks.toString()
    //     );
    //   }

    //   if (this.currentContainerType == "UC") {
    //     this.lockUC = true;
    //   } else {
    //     this.lockUC = false;
    //   }

    //   if (this.containerSlots.get(this.currentContainerType)) {
    //     this.group = "1";
    //   }

    //   if (this.containerSlots2.get(this.currentContainerType)) {
    //     this.group = "2";
    //   }
    //   this.model.depoId = data.masterBookingMisc.depoId;
    // }

    // acct code
    console.log("mari kita tengok");
    // console.log(data.bAcctCodeCOC);
    // console.log(data.bAcctCodeSOC);
    // console.log(data.bAcctCodeSLOT);
    console.log(this.nvoccFreightParty);
    this.fpAcctCodeCOC = "";//data.bAcctCodeCOC; //jira gos 110
    this.fpAcctCodeSOC = "";//data.bAcctCodeSOC; //jira gos 110
    this.fpAcctCodeSLOT = "";// data.bAcctCodeSLOT; //jira gos 110

    this.fpAcctCode = "<<";
    if (this.nvoccFreightParty) {
      //gos 110 ubah dari booking party ke freight party
      console.log("hore");
      this.fpAcctCode += " NVOCC ";
    }
    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      this.fpAcctCode += " COC: " + data.bAcctCodeCOC;
    }
    if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      this.fpAcctCode += " SOC: " + data.bAcctCodeSOC;
    }
    if (this.model.bContainerOwnership == "SLOT") {
      this.fpAcctCode += " SLOT: " + data.bAcctCodeSLOT;
    }
    this.fpAcctCode += " >>";



    // * Booking Extra
    //console.log("ada ga ya ? : ", data.masterBookingExtra);
    // if (Object.keys(data.masterBookingExtra).length > 0) {


      // this.bookingExtra = data.masterBookingExtra;
      this.bookingExtra.bNo=this.model.bNo;
      this.bookingExtra.bPrefix=this.model.bPrefix;
      this.bookingExtra.bOfficeCode=this.model.bOfficeCode;

      // this.bDegreeTemp = String(
      //   data.masterBookingExtra.bReeferDegree
      // ).substring(
      //   String(data.masterBookingExtra.bReeferDegree).length + 1,
      //   String(data.masterBookingExtra.bReeferDegree).length - 1
      // );

      // this.bDegree = Number.parseFloat(
      //   String(data.masterBookingExtra.bReeferDegree).replace(
      //     this.bDegreeTemp.toString(),
      //     ""
      //   )
      // );

      // this.bDegreeTempSO = String(data.masterBookingExtra.bDegreeSo).substring(
      //   String(data.masterBookingExtra.bDegreeSo).length + 1,
      //   String(data.masterBookingExtra.bDegreeSo).length - 1
      // );

      // this.bDegreeSO = Number.parseFloat(
      //   String(data.masterBookingExtra.bDegreeSo).replace(
      //     this.bDegreeTempSO.toString(),
      //     ""
      //   )
      // );

      // // Alternative for *dateReeferCollection.setValue()*
      // // this.setDatePluginValue(this.settingReeferCollection.id,
      // //   data.masterBookingExtra.bReeferCollectionDate
      // // );

      // if (data.masterBookingExtra.bVent === "O") {
      //   this.lockVentNumber = false;
      // }

      // if (data.masterBookingExtra.bSetOption == "") {
      //   data.masterBookingExtra.bSetOption = "N";
      // }

      // console.log("kekunci ga ?", this.lockVentNumber);

      // switch (data.masterBookingExtra.bNatureTemp) {
      //   case "F":
      //     this.bNatureTemp = "FROZEN";
      //     break;
      //   case "C":
      //     this.bNatureTemp = "CHILLED";
      //     break;
      //   default:
      //     this.bNatureTemp = "N/A";
      // }

      // console.log(
      //   "this.bDegreeSO : ",
      //   this.bDegreeTempSO,
      //   data.masterBookingExtra.bDegreeSo,
      //   data.masterBookingExtra
      // );
    //}

    // GSO-1042 disable button when Transmit succeeded
    // GSO-1034 Depot EDI Integration - COC Local Bkg Modification
    this.handleCheckStatusDepot();

  }

  getEtdPol(vesselId: String,  voyage: String,  bound: String, officeCode: String): String{
    let etdPOL: String;
    etdPOL="";
    this.genericService
    .GET(
      this.configService.config.BASE_API.toString() +
        "/MasterBookings/getETDPOL/" +
        vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(voyage).trim()) +
        "/" +
        bound +
        "/" +
        officeCode +
        "/" +
        officeCode
    )
    .subscribe(
      (resp) => {

        if (Object.keys(resp.json()["content"]).length > 0) {
        let data = resp.json()["content"];
        etdPOL=data[0]["etdPOL"];
        }

      },
      (error) => {
        // console.log(error.message);
      }
    );

    return etdPOL;

  }

  checkDisableSlotLc(){
    console.log(this.model.bContainerOwnership);
    console.log(this.lock);

    if(this.model.bContainerOwnership === "SLOT" && !this.lock){
      this.isDisablebSlotLC = false;
    }else{
      this.isDisablebSlotLC = true;
    }

  }

  getPattern(): String {
    var result: String = "";
    var self = this;
    //$(this.grid.listStore.store).each(function(i,element) {
    $(this.rowData).each(function (i, element) {
      let parentList: String[] = String(element.parent).trim().split(";");
      let chk: Boolean = self.checkParent(
        parentList,
        element.allotmentCode,
        self.model
      );
      if (chk) {
        var s = ";" + element.allotmentCode + ";" + element.parent;
        s = s.replace(/;VS/g, "");

        if (result.length <= s.length) {
          result = s;
        }
      }
    });

    return result;
  }

  //Used to check bound M whether existed in the sailing schedule or not
  checkBoundM() {
    this.checkIdenticalVesVoy(
      "bNextMotherVesselVoyage",
      this.model.bExMotherVesselVoyage,
      "Ex Voyage & Next Voyage can't have identical value."
    )
      .then(() => {
        if (
          this.model.bExMotherVesselVoyage != null &&
          this.model.bExMotherVesselVoyage != "" &&
          this.model.bExMotherVesselVoyage != undefined &&
          this.model.bExMotherVesselId != "" &&
          this.model.bExMotherVesselId != undefined &&
          this.model.bExMotherVesselId != null
        ) {
          this.clearValidatorError();
          this.isError = false;

          let hitUrl =
            this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/checkDuplicateBoundMFromBooking/" +
            this.model.bOfficeCode +
            "/" +
            this.model.bExMotherVesselId +
            "/" +
            this.sCharUtil.htmlEncode(
              this.model.bExMotherVesselVoyage.toUpperCase()
            ) +
            "/M";

          if (
            !this.model.bExMotherVesselVoyage.match(
              this.formValidation.regVesVoy
            )
          ) {
            this.isError = true;
            return (this.model["error-bExMotherVesselVoyage"] =
              "Only allowed special characters .-/");
          } else {
            this.genericService.GET(hitUrl).subscribe((resp) => {
              // console.log(resp.json()["status"])
              if (resp.json()["status"].toUpperCase() == "OK") {
                //create bound M
                let msg: string = "";
                msg +=
                  "Schedule for vessel <b>" +
                  this.model.bExMotherVesselName +
                  " " +
                  this.model.bExMotherVesselVoyage.toUpperCase() +
                  "</b> is not yet registered. <br />";
                msg += "System will now register this schedule?";

                this.dialogPlugin.show(
                  "information",
                  "Information",
                  msg,
                  "yesno",
                  {
                    yes: "this.registerBoundM();",
                    no: "",
                  }
                );
              }
            });
          }
        }
      })
      .catch(() => {
        this.model.bExMotherVesselVoyage = "";
      });
  }

  registerBoundM() {
    let dateBoundM = this.genericUtil.getPSIDate();
    let boundMSailingSchedule: MSailingSchedule = new MSailingSchedule();
    boundMSailingSchedule.officeCode = this.model.bOfficeCode.toString();
    boundMSailingSchedule.vesselId = this.model.bExMotherVesselId.toString();
    boundMSailingSchedule.vesselCode =
      this.model.bExMotherVesselCode.toString();
    boundMSailingSchedule.voyage = this.model.bExMotherVesselVoyage
      .toString()
      .toUpperCase();
    boundMSailingSchedule.bound = "M";
    boundMSailingSchedule.nameOfMaster = "";
    boundMSailingSchedule.nationality = "";
    boundMSailingSchedule.blDate = dateBoundM;
    boundMSailingSchedule.exchangeRateDate = dateBoundM;
    boundMSailingSchedule.nonSsl = "";
    boundMSailingSchedule.localETA = dateBoundM;
    boundMSailingSchedule.localETD = dateBoundM;
    boundMSailingSchedule.gdvNumber = "";
    boundMSailingSchedule.leg = "";
    boundMSailingSchedule.callingSeq = "";
    boundMSailingSchedule.psaVoyage = "";
    boundMSailingSchedule.agent = "";
    boundMSailingSchedule.dryDocking = "";
    boundMSailingSchedule.psaTransmitFlag = "";
    boundMSailingSchedule.lockedBlDate = "N";
    boundMSailingSchedule.lockedExchangeRateDate = "N";
    boundMSailingSchedule.isValid = "Y";
    //boundMSailingSchedule.createdUserid=this.cookieService.getName();
    //boundMSailingSchedule.createdDate=dateBoundM;
    boundMSailingSchedule.createdUserid = "";
    boundMSailingSchedule.createdDate = "";
    boundMSailingSchedule.modifiedUserid = "";
    boundMSailingSchedule.modifiedDate = "";
    boundMSailingSchedule.serviceId = "SV99999";
    boundMSailingSchedule.serviceCode = "OTH";

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/saveBoundM",
        boundMSailingSchedule
      )
      .subscribe((resp) => {
        if (resp.status == 200 && resp.json()["message"] == "OK") {
          this.dialogPlugin.show(
            "information",
            "Information",
            "Schedule has been registered successfully.",
            "okonly",
            { ok: "this.loading=false;" }
          );
        }
      });
  }

  //Used to go back into vessel listing
  handleBack() {
    // console.log("** handle back **")

    //clean up unnecessar details
    localStorage.removeItem("new-booking-from-flag");
    localStorage.removeItem("new-booking-from-vessel-listing");

    if (this.prev == "booking-flag") {
      this.backToBookingFlag = true;
      // console.log("#1")
      if (this.strParams != "") {
        // console.log(this.strParams)
        localStorage.setItem("close-booking-to-flag", this.strParams);
        this.router.navigateByUrl("/main/transaction-booking-flag");
      } else {
        this.gridContainerInfo.onClear();
        this.bookingContainerInfo = new BookingContainerInfo();
        this.model.bookingContainerInfo = [];

        this.location.back();
      }
    } else {
      this.gridContainerInfo.onClear();
      this.bookingContainerInfo = new BookingContainerInfo();
      this.model.bookingContainerInfo = [];
      this.location.back();
    }
  }

  showDialog(id) {
    $(id).modal("setting", "closable", false).modal("show");
  }

  closeDialog(id) {
    $(id).modal("hide");
  }

  onCompare() {
    this.setDetail();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/PFCGenericAPI/compareRefreightBookingPFC/" +
          this.refreightRefNo,
        this.model
      )
      .subscribe((resp) => {
        if (resp.status == 200) {
          this.dialogPlugin.show(
            "information",
            "Information",
            resp.json()["message"],
            "okonly",
            { ok: "" }
          );
        }
      });
  }

  onCancelCompare() {
    this.closeDialog("#compareDialog");
  }

  countChildNew(parentLevel: String, store: any) {
    var total = 0;
    store.forEach((fe) => {
      if (
        fe.allotmentLevel.startsWith(parentLevel) &&
        fe.allotmentLevel.split(".").length == parentLevel.split(".").length + 1
      ) {
        total++;
      }
    });

    return total;
  }

  getChildListNew(parentLevel: String, store: any) {
    var result = [];
    store.forEach((fe) => {
      if (
        fe.allotmentLevel.startsWith(parentLevel) &&
        fe.allotmentLevel.split(".").length == parentLevel.split(".").length + 1
      ) {
        result.push(fe);
      }
    });

    return result;
  }

  checkAllocationTest(parent, dataStore: any) {
    var result: boolean = true;
    var allocationChild = [];
    var countSOC = 0;
    var countCOC = 0;
    var countSlot = 0;
    var isError = "";
    var tmpPod: String = "";
    var tmpPodName: String = "";

    // console.log("this.model.bContainerOwnership => " + this.model.bContainerOwnership);
    // console.log("this.model.bContainerStatus => " + this.model.bContainerStatus);

    //if(this.countChildNew(parent, dataStore) > 0){
    allocationChild = this.getChildListNew(parent, dataStore);
    allocationChild.forEach((fe) => {
      // console.log(fe);

      if (this.model.bPodCode != "" && this.model.bPot1Code != "") {
        tmpPod = this.model.bPot1Code;
        tmpPodName = this.model.bPot1Name;
      } else {
        tmpPod = this.model.bPodCode;
        tmpPodName = this.model.bPodName;
      }

      if (
        fe.allotmentCode.startsWith("PD") &&
        fe.allotmentCode.replace("PD-", "") == tmpPod
      ) {
        // console.log("** check PD **")
        countSOC = 0;
        countCOC = 0;
        countSlot = 0;

        isError = "";
        isError = "PD";

        var childPD = [];
        childPD = this.getChildListNew(fe.allotmentLevel, dataStore);
        childPD.forEach((fe) => {
          if (
            fe.allotmentCode == "LADEN" &&
            this.model.bContainerStatus == "FULL"
          ) {
            countSOC = 0;
            countCOC = 0;
            countSlot = 0;

            isError = "";
            isError = "LADEN";

            var childLaden = [];
            childLaden = this.getChildListNew(fe.allotmentLevel, dataStore);
            childLaden.forEach((fe) => {
              // console.log(fe.allotmentCode)

              if (this.model.bContainerOwnership.substring(0, 3) == "SOC") {
                if (fe.allotmentCode.substring(0, 3) == "SOC") {
                  countSOC++;
                }
              }

              if (this.model.bContainerOwnership.substring(0, 3) == "COC") {
                if (fe.allotmentCode.substring(0, 3) == "COC") {
                  countCOC++;
                }
              }

              if (this.model.bContainerOwnership == "SLOT") {
                if (fe.allotmentCode.startsWith("SS")) {
                  countSlot++;
                }
              }
            });
          }

          if (
            fe.allotmentCode == "EMPTY" &&
            this.model.bContainerStatus == "EMPTY"
          ) {
            countSOC = 0;
            countCOC = 0;
            countSlot = 0;

            isError = "";
            isError = "EMPTY";

            var childEmpty = [];
            childEmpty = this.getChildListNew(fe.allotmentLevel, dataStore);
            childEmpty.forEach((fe) => {
              if (this.model.bContainerOwnership.substring(0, 3) == "SOC") {
                if (fe.allotmentCode.substring(0, 3) == "SOC") {
                  countSOC++;
                }
              }

              if (this.model.bContainerOwnership.substring(0, 3) == "COC") {
                if (fe.allotmentCode.substring(0, 3) == "COC") {
                  countCOC++;
                }
              }

              if (this.model.bContainerOwnership == "SLOT") {
                if (fe.allotmentCode.startsWith("SS")) {
                  countSlot++;
                }
              }
            });
          }

          if (this.model.bContainerOwnership.substring(0, 3) == "SOC") {
            if (fe.allotmentCode.substring(0, 3) == "SOC") {
              countSOC++;
            }
          }

          if (this.model.bContainerOwnership.substring(0, 3) == "COC") {
            if (fe.allotmentCode.substring(0, 3) == "COC") {
              countCOC++;
            }
          }

          if (this.model.bContainerOwnership == "SLOT") {
            if (fe.allotmentCode.startsWith("SS")) {
              countSlot++;
            }
          }
        });
      } else {
      }

      if (
        fe.allotmentCode == "LADEN" &&
        this.model.bContainerStatus == "FULL"
      ) {
        countSOC = 0;
        countCOC = 0;
        countSlot = 0;

        isError = "";
        isError = "LADEN";

        var childLaden = [];
        childLaden = this.getChildListNew(fe.allotmentLevel, dataStore);
        childLaden.forEach((fe) => {
          // console.log(fe.allotmentCode)

          if (
            fe.allotmentCode.startsWith("PD") &&
            fe.allotmentCode.replace("PD-", "") == tmpPod
          ) {
            // console.log("** check PD **")
            countSOC = 0;
            countCOC = 0;
            countSlot = 0;

            isError = "";
            isError = "PD";

            var childPD = [];
            childPD = this.getChildListNew(fe.allotmentLevel, dataStore);
            childPD.forEach((fe) => {
              if (this.model.bContainerOwnership.substring(0, 3) == "SOC") {
                if (fe.allotmentCode.substring(0, 3) == "SOC") {
                  countSOC++;
                }
              }

              if (this.model.bContainerOwnership.substring(0, 3) == "COC") {
                if (fe.allotmentCode.substring(0, 3) == "COC") {
                  countCOC++;
                }
              }

              if (this.model.bContainerOwnership == "SLOT") {
                if (fe.allotmentCode.startsWith("SS")) {
                  countSlot++;
                }
              }
            });
          }

          if (this.model.bContainerOwnership.substring(0, 3) == "SOC") {
            if (fe.allotmentCode.substring(0, 3) == "SOC") {
              countSOC++;
            }
          }

          if (this.model.bContainerOwnership.substring(0, 3) == "COC") {
            if (fe.allotmentCode.substring(0, 3) == "COC") {
              countCOC++;
            }
          }

          if (this.model.bContainerOwnership == "SLOT") {
            if (fe.allotmentCode.startsWith("SS")) {
              countSlot++;
            }
          }
        });
      }

      if (
        fe.allotmentCode == "EMPTY" &&
        this.model.bContainerStatus == "EMPTY"
      ) {
        countSOC = 0;
        countCOC = 0;
        countSlot = 0;

        isError = "";
        isError = "EMPTY";

        var childEmpty = [];
        childEmpty = this.getChildListNew(fe.allotmentLevel, dataStore);
        childEmpty.forEach((fe) => {
          if (
            fe.allotmentCode.startsWith("PD") &&
            fe.allotmentCode.replace("PD-", "") == tmpPod
          ) {
            // console.log("** check PD **")
            countSOC = 0;
            countCOC = 0;
            countSlot = 0;

            isError = "";
            isError = "PD";

            var childPD = [];
            childPD = this.getChildListNew(fe.allotmentLevel, dataStore);
            childPD.forEach((fe) => {
              if (this.model.bContainerOwnership.substring(0, 3) == "SOC") {
                if (fe.allotmentCode.substring(0, 3) == "SOC") {
                  countSOC++;
                }
              }

              if (this.model.bContainerOwnership.substring(0, 3) == "COC") {
                if (fe.allotmentCode.substring(0, 3) == "COC") {
                  countCOC++;
                }
              }

              if (this.model.bContainerOwnership == "SLOT") {
                if (fe.allotmentCode.startsWith("SS")) {
                  countSlot++;
                }
              }
            });
          }

          if (this.model.bContainerOwnership.substring(0, 3) == "SOC") {
            if (fe.allotmentCode.substring(0, 3) == "SOC") {
              countSOC++;
            }
          }

          if (this.model.bContainerOwnership.substring(0, 3) == "COC") {
            if (fe.allotmentCode.substring(0, 3) == "COC") {
              countCOC++;
            }
          }

          if (this.model.bContainerOwnership == "SLOT") {
            if (fe.allotmentCode.startsWith("SS")) {
              countSlot++;
            }
          }
        });
      }

      if (
        fe.allotmentCode.substring(0, 3) == "SOC" &&
        this.model.bContainerOwnership.substring(0, 3) == "SOC"
      ) {
        isError = "";
        countSOC = 0;
        countSOC++;
      }

      if (
        fe.allotmentCode.substring(0, 3) == "COC" &&
        this.model.bContainerOwnership.substring(0, 3) == "COC"
      ) {
        isError = "";
        countCOC = 0;
        countCOC++;
      }

      if (
        fe.allotmentCode.startsWith("SS") &&
        this.model.bContainerOwnership == "SLOT"
      ) {
        isError = "";
        countSlot = 0;
        countSlot++;
      }
    });
    //}

    // console.log("soc "+countSOC+" coc "+countCOC+" slot "+countSlot);
    if (isError == "PD") {
      this.model["error-allocationValidator"] =
        "Allocation of Container Ownership (COC, SOC & SLOT) for " +
        tmpPodName +
        " has not been set for this vessel, unable to create booking.";
    }
    if (isError == "LADEN") {
      this.model["error-allocationValidator"] =
        "Allocation of Container Ownership (COC, SOC & SLOT) for LADEN has not been set for this vessel, unable to create booking.";
    }
    if (isError == "EMPTY") {
      this.model["error-allocationValidator"] =
        "Allocation of Container Ownership (COC, SOC & SLOT) for EMPTY has not been set for this vessel, unable to create booking.";
    }

    if (isError == "") {
      result = false;
    }

    if (countSOC > 0 || countCOC > 0 || countSlot > 0) {
      result = false;
    }

    return result;
  }

  lpad(s, width, char) {
    return s.length >= width
      ? s
      : (new Array(width).join(char) + s).slice(-width);
  }

  /** Container Information Handler **/
  gridEventContainerInfo(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        let strEvent: String = event;
        if (strEvent != "") {
          let arr: String[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.lockContainerInfo = false;
              this.bContainerInfoMode = "update";

              this.bookingContainerInfo = new BookingContainerInfo();

              this.bookingContainerInfo.bOfficeCode =
                this.gridContainerInfo.getSelectedValues()[0].bOfficeCode;
              this.bookingContainerInfo.bPrefix =
                this.gridContainerInfo.getSelectedValues()[0].bPrefix;
              this.bookingContainerInfo.bNo =
                this.gridContainerInfo.getSelectedValues()[0].bNo;
              this.bookingContainerInfo.bSeqNo =
                this.gridContainerInfo.getSelectedValues()[0].bSeqNo;
              this.bookingContainerInfo.bPageNo =
                this.gridContainerInfo.getSelectedValues()[0].bPageNo;
              this.bookingContainerInfo.bVent =
                this.gridContainerInfo.getSelectedValues()[0].bVent;
              this.bookingContainerInfo.bNoOfPkg =
                this.gridContainerInfo.getSelectedValues()[0].bNoOfPkg;
              this.bookingContainerInfo.bPkgGroup =
                this.gridContainerInfo.getSelectedValues()[0].bPkgGroup;
              this.bookingContainerInfo.bReleaseDate =
                this.gridContainerInfo.getSelectedValues()[0].bReleaseDate;
              this.bookingContainerInfo.bReleaseTime =
                this.gridContainerInfo.getSelectedValues()[0].bReleaseTime;
              this.bookingContainerInfo.bPackageType =
                this.gridContainerInfo.getSelectedValues()[0].bPackageType;
              this.bookingContainerInfo.bPSAClass =
                this.gridContainerInfo.getSelectedValues()[0].bPSAClass;
              this.bookingContainerInfo.bContOperator =
                this.gridContainerInfo.getSelectedValues()[0].bContOperator;
              this.bookingContainerInfo.bIsPartLot =
                this.gridContainerInfo.getSelectedValues()[0].bIsPartLot;
              this.bookingContainerInfo.bTrueShippingName =
                this.gridContainerInfo.getSelectedValues()[0].bTrueShippingName;
              this.bookingContainerInfo.bContType =
                this.gridContainerInfo.getSelectedValues()[0].bContType;
              this.bookingContainerInfo.bChemicalName =
                this.gridContainerInfo.getSelectedValues()[0].bChemicalName;
              this.bookingContainerInfo.bGrossWeight =
                this.gridContainerInfo.getSelectedValues()[0].bGrossWeight;
              this.bookingContainerInfo.bGrossMeas =
                this.gridContainerInfo.getSelectedValues()[0].bGrossMeas;
              this.bookingContainerInfo.bNettWeight =
                this.gridContainerInfo.getSelectedValues()[0].bNettWeight;
              this.bookingContainerInfo.bLabel =
                this.gridContainerInfo.getSelectedValues()[0].bLabel;
              this.bookingContainerInfo.bIMO =
                this.gridContainerInfo.getSelectedValues()[0].bIMO;
              this.bookingContainerInfo.bUNNO =
                this.gridContainerInfo.getSelectedValues()[0].bUNNO;
              this.bookingContainerInfo.bFlashPoint =
                this.gridContainerInfo.getSelectedValues()[0].bFlashPoint;
              this.bookingContainerInfo.bDGRef =
                this.gridContainerInfo.getSelectedValues()[0].bDGRef;
              this.bookingContainerInfo.bFlashPointScale =
                this.gridContainerInfo.getSelectedValues()[0].bFlashPointScale;
              this.bookingContainerInfo.bEMS =
                this.gridContainerInfo.getSelectedValues()[0].bEMS;
              this.bookingContainerInfo.bMFAG =
                this.gridContainerInfo.getSelectedValues()[0].bMFAG;
              this.bookingContainerInfo.bContainerNo =
                this.gridContainerInfo.getSelectedValues()[0].bContainerNo;
              this.bookingContainerInfo.bCellLocation =
                this.gridContainerInfo.getSelectedValues()[0].bCellLocation;
              this.bookingContainerInfo.bMark =
                this.gridContainerInfo.getSelectedValues()[0].bMark;
              this.bookingContainerInfo.bReceiptType =
                this.gridContainerInfo.getSelectedValues()[0].bReceiptType;
              this.bookingContainerInfo.bVGMEnabled =
                this.gridContainerInfo.getSelectedValues()[0].bVGMEnabled;
              this.bookingContainerInfo.bVGMType =
                this.gridContainerInfo.getSelectedValues()[0].bVGMType;
              this.bookingContainerInfo.bVGMEnabledFlag =
                this.gridContainerInfo.getSelectedValues()[0].bVGMEnabledFlag;

              this.bookingContainerInfo.bVGMWeight =
                this.gridContainerInfo.getSelectedValues()[0].bVGMWeight;
              this.bookingContainerInfo.bOtherDetails =
                this.gridContainerInfo.getSelectedValues()[0].bOtherDetails;
              this.bookingContainerInfo.bOtherDetailsClean =
                this.gridContainerInfo.getSelectedValues()[0].bOtherDetailsClean;
              this.txtContainerOtherDetails.setValue(
                this.bookingContainerInfo.bOtherDetails.toString()
              );

              this.cbVGMMethod.setValue(
                this.bookingContainerInfo.bVGMType.toString()
              );
              //ini dia
              this.cbBContainerPackageType.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterPackageTypes/findByComboBoxControl/packageTypeCode={query}"
              );
              this.cbBContainerPackageType.setValue(
                this.bookingContainerInfo.bPackageType.toString()
                  // .toString()
                  // .replace("/", "[-47]")
              );

              this.cbBContainerReceiptType.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
              );
              this.cbBContainerReceiptType.setValue(
                this.gridContainerInfo
                  .getSelectedValues()[0]
                  .bReceiptType.toString()
              );

              this.cbBContainerFlashPointScale.setValue(
                this.gridContainerInfo.getSelectedValues()[0].bFlashPointScale
              );
              this.bookingContainerInfo.bDGRef =
                this.gridContainerInfo.getSelectedValues()[0].bDGRef;
              this.lockBtnContainerNew = true;
              this.lockBtnContainerSaveUpdate = false;
              this.lockBtnContainerDelete = false;
              this.lockBtnContainerCancel = false;

              if (this.currentContainerType === "UC") {
                this.typeSizeComboDisabled = true;
              } else {
                this.typeSizeComboDisabled = false;
                this.initDropDown();
              }

              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }
  infoGridContainerInfo(event) {}

  onContainerInfo__New() {
    this.bContainerInfoMode = "insert";
    this.lockBtnContainerNew = true;
    this.lockBtnContainerSaveUpdate = false;
    this.lockBtnContainerDelete = true;
    this.lockBtnContainerCancel = false;
    this.lockContainerInfo = false;

    this.bookingContainerInfo = new BookingContainerInfo();

    this.cbBContainerReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
    );
    this.cbBContainerReceiptType.setValue(
      this.bookingMisc.bReceiptTypeExtra.toString()
    );

    this.bookingContainerInfo.bOfficeCode =
      this.model.bOfficeCode.toUpperCase();
    this.bookingContainerInfo.bPrefix = this.model.bPrefix.toUpperCase();
    this.bookingContainerInfo.bNo = this.model.bNo.toUpperCase();
    this.bookingContainerInfo.bSeqNo =
      this.gridContainerInfo.listStore.store.length + 1;
    this.cbVGMMethod.setValue("");
    //this.txtTerminalRemarks.setValue("");
    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      this.bookingContainerInfo.bContOperator = this.model.bCocCntrOperatorCode;
    } else if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      this.bookingContainerInfo.bContOperator = this.model.bSocCntrOperatorCode;
    } else {
      this.bookingContainerInfo.bContOperator = this.model.bSlotOperatorCode;
    }
    if (this.currentContainerType === "UC") {
      this.typeSizeComboDisabled = true;
    } else {
      this.typeSizeComboDisabled = false;
      this.initDropDown();
    }
    this.cbBContainerFlashPointScale.setValue("");
  }

  onContainerInfo__SaveUpdate() {
    this.isErrorContainerInfo = false;
    // this.setValidatorContainerInsert();
    // this.isErrorContainerInfo = this.onValidate(this.bookingContainerInfo);

    this.capitalizeInput();

    /*
    if (this.gridContainerInfo.listStore.store.length > 0){
      for (let i=0; i<this.gridContainerInfo.listStore.store.length; i++) {
        if (this.gridContainerInfo.listStore.store[i].bContainerNo.trim() == this.bookingContainerInfo.bContainerNo.trim()) {
          this.isErrorContainerInfo = true;
          this.bookingContainerInfo['error-bContainerNo'] = 'Duplicate container number ' + this.bookingContainerInfo.bContainerNo;
        }
      }
    }
    */

    if (
      this.bookingContainerInfo.bContainerNo != null &&
      this.bookingContainerInfo.bContainerNo !== "" &&
      this.bookingContainerInfo.bContainerNo !== undefined
    ) {
      if (
        this.bookingContainerInfo.bContType == null ||
        this.bookingContainerInfo.bContType === "" ||
        this.bookingContainerInfo.bContType === undefined
      ) {
        this.isErrorContainerInfo = true;
        this.bookingContainerInfo["error-bContType"] =
          "Please input Cont Type.";

        if (this.currentContainerType === "UC") {
          this.typeSizeComboDisabled = true;
        } else {
          this.typeSizeComboDisabled = false;
        }
      } else {
        this.isErrorContainerInfo = false;
        this.typeSizeComboDisabled = false;
        this.bookingContainerInfo["error-bContType"] = "";
      }
    }

    if (this.bContainerInfoMode === "insert") {
      this.bookingContainerInfo.bOtherDetails = this.txtContainerOtherDetails
        .getValue()
        .toString();
      let otherDetailsClean: String = "";
      if (
        this.txtContainerOtherDetails.getValue().toString().length <= 1 ||
        this.txtContainerOtherDetails.getValue().toString() === "↵"
      ) {
        otherDetailsClean = "";
      } else {
        for (
          let i = 0;
          i < this.bookingContainerInfo.bOtherDetails.split("↵").length;
          i++
        ) {
          if (i === 0) {
            otherDetailsClean +=
              this.bookingContainerInfo.bOtherDetails.split("↵")[i] + "<br/>";
          } else if (i === 1) {
            otherDetailsClean +=
              this.bookingContainerInfo.bOtherDetails.split("↵")[i];
          }
        }
      }
      this.bookingContainerInfo.bOtherDetailsClean =
        otherDetailsClean.toString();

      /*
      if (!this.isErrorContainerInfo) {
        if (this.bookingContainerInfo.bGrossMeas > this.bookingContainerInfo.bGrossWeight) {
          this.isErrorContainerInfo = true;
          this.bookingContainerInfo['error-checkWtMeasValidator'] = 'Gross Meas cannot be bigger than Gross Weight';
        } else {
          this.isErrorContainerInfo = false;
          this.bookingContainerInfo['error-checkWtMeasValidator'] = '';
        }
      }
      */

      if (!this.isErrorContainerInfo) {
        this.lockBtnContainerNew = false;
        this.lockBtnContainerSaveUpdate = true;
        this.lockBtnContainerDelete = true;
        this.lockBtnContainerCancel = true;
        this.gridContainerInfo.url = "";
        this.gridContainerInfo.listStore.store.push(this.bookingContainerInfo);
        this.gridContainerInfo.loadData();
        this.lockContainerInfo = true;
        this.typeSizeComboDisabled = true;
        this.onContainerInfo__Reset();
        this.onContainerInfo__Cancel();
      }
    } else {
      // on update goes here
      let otherDetailsClean: String = "";
      if (
        this.txtContainerOtherDetails.getValue().toString().length <= 1 ||
        this.txtContainerOtherDetails.getValue().toString() === "↵"
      ) {
        otherDetailsClean = "";
      } else {
        for (
          let i = 0;
          i < this.bookingContainerInfo.bOtherDetails.split("↵").length;
          i++
        ) {
          if (i === 0) {
            otherDetailsClean +=
              this.bookingContainerInfo.bOtherDetails.split("↵")[i] + "<br/>";
          } else if (i === 1) {
            otherDetailsClean +=
              this.bookingContainerInfo.bOtherDetails.split("↵")[i];
          }
        }
      }
      this.bookingContainerInfo.bOtherDetailsClean =
        otherDetailsClean.toString();

      if (!this.isErrorContainerInfo) {
        this.gridContainerInfo.listStore.updateData(
          this.gridContainerInfo.getSelectedValues()[0].bSeqNo - 1,
          this.bookingContainerInfo
        );
        this.gridContainerInfo.clearSelectedValues();
        this.gridContainerInfo.loadData();

        this.lockBtnContainerNew = false;
        this.lockBtnContainerSaveUpdate = true;
        this.lockBtnContainerDelete = true;
        this.lockBtnContainerCancel = true;
        this.lockContainerInfo = true;
        this.typeSizeComboDisabled = true;
        this.onContainerInfo__Reset();
      }
    }
  }

  onContainerInfo__Delete() {
    this.gridContainerInfo.url = "";
    const dt = this.gridContainerInfo.getSelectedValues()[0];
    this.gridContainerInfo.listStore.removeData(dt);
    this.gridContainerInfo.loadData();
    this.onContainerInfo__Cancel();
    this.genericUtil.showDialog(
      "bkgContainerDeleteInfo",
      "Information",
      350,
      150
    );
  }

  onContainerInfo__Cancel() {
    this.bContainerInfoMode = "insert";
    this.lockBtnContainerNew = false;
    this.lockBtnContainerSaveUpdate = true;
    this.lockBtnContainerDelete = true;
    this.lockBtnContainerCancel = true;
    this.lockContainerInfo = true;
    this.onContainerInfo__Reset();
    this.txtContainerOtherDetails.setValue("");
    $("#typeSize").dropdown("clear");
    //$('#flashPointScale').dropdown('clear');

    this.typeSizeComboDisabled = true;
  }

  onContainerInfo__Reset() {
    this.isErrorContainerInfo = false;
    this.bookingContainerInfo = new BookingContainerInfo();
    this.cbBContainerPackageType.setValue("");
    this.cbBContainerReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
    );
    let bReceiptTypeExtra = this.bookingMisc.bReceiptTypeExtra ? this.bookingMisc.bReceiptTypeExtra.toString() : "";
    this.cbBContainerReceiptType.setValue(
      bReceiptTypeExtra
    );
    this.cbVGMMethod.setValue("");

    if (
      this.model.bContainerOwnership == "COC" ||
      this.model.bContainerOwnership == "COCTS"
    ) {
      this.bookingContainerInfo.bContOperator = this.model.bCocCntrOperatorCode;
    } else if (
      this.model.bContainerOwnership == "SOC" ||
      this.model.bContainerOwnership == "SOCTS"
    ) {
      this.bookingContainerInfo.bContOperator = this.model.bSocCntrOperatorCode;
    } else {
      this.bookingContainerInfo.bContOperator = this.model.bSlotOperatorCode;
    }

    this.cbBContainerFlashPointScale.setValue("");
    this.txtContainerOtherDetails.setValue("");
  }

  changeEventBContainerReceiptType(event) {
    if (
      event.receiptTypeCode != null &&
      event.receiptTypeCode !== "" &&
      event.receiptTypeCode !== undefined
    ) {
      this.bookingContainerInfo.bReceiptType = event.receiptTypeCode;
    } else {
      this.bookingContainerInfo.bReceiptType = "";
    }
    this.cbBContainerReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}"
    );
  }

  changeEventVGMMethod(event) {
    console.log("debugggg vgm");
    console.log(event);
    if (
      event.vgmMethodCode != null &&
      event.vgmMethodCode !== "" &&
      event.vgmMethodCode !== undefined
    ) {
      this.bookingContainerInfo.bVGMType = event.vgmMethodCode;
    } else {
      this.bookingContainerInfo.bVGMType = "";
    }
  }
  setValidatorContainerInsert() {
    this.validatorRules = {
      bContainerNo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Container No.",
          },
        ],
      },
    };
  }

  capitalizeInput() {
    this.bookingContainerInfo.bOfficeCode =
      this.bookingContainerInfo.bOfficeCode.toUpperCase();
    this.bookingContainerInfo.bPrefix =
      this.bookingContainerInfo.bPrefix.toUpperCase();
    this.bookingContainerInfo.bNo = this.bookingContainerInfo.bNo.toUpperCase();
    this.bookingContainerInfo.bPageNo =
      this.bookingContainerInfo.bPageNo.toUpperCase();
    //this.bookingContainerInfo.bVent = this.bookingContainerInfo.bVent.toUpperCase();
    this.bookingContainerInfo.bPkgGroup =
      this.bookingContainerInfo.bPkgGroup.toUpperCase();
    this.bookingContainerInfo.bReleaseDate =
      this.bookingContainerInfo.bReleaseDate.toUpperCase();
    this.bookingContainerInfo.bReleaseTime =
      this.bookingContainerInfo.bReleaseTime.toUpperCase();
    this.bookingContainerInfo.bPackageType =
      this.bookingContainerInfo.bPackageType.toUpperCase();
    this.bookingContainerInfo.bPackageTypeName =
      this.bookingContainerInfo.bPackageTypeName.toUpperCase();
    this.bookingContainerInfo.bPSAClass =
      this.bookingContainerInfo.bPSAClass.toUpperCase();
    this.bookingContainerInfo.bContOperator =
      this.bookingContainerInfo.bContOperator.toUpperCase();
    this.bookingContainerInfo.bTrueShippingName =
      this.bookingContainerInfo.bTrueShippingName.toUpperCase();

    this.bookingContainerInfo.bContType =
      this.bookingContainerInfo.bContType.toUpperCase();
    this.bookingContainerInfo.bChemicalName =
      this.bookingContainerInfo.bChemicalName.toUpperCase();
    this.bookingContainerInfo.bTrueShippingName =
      this.bookingContainerInfo.bTrueShippingName.toUpperCase();
    this.bookingContainerInfo.bContType =
      this.bookingContainerInfo.bContType.toUpperCase();
    this.bookingContainerInfo.bLabel =
      this.bookingContainerInfo.bLabel.toUpperCase();
    this.bookingContainerInfo.bIMO =
      this.bookingContainerInfo.bIMO.toUpperCase();
    this.bookingContainerInfo.bUNNO =
      this.bookingContainerInfo.bUNNO.toUpperCase();
    this.bookingContainerInfo.bEMS =
      this.bookingContainerInfo.bEMS.toUpperCase();
    this.bookingContainerInfo.bFlashPointScale =
      this.bookingContainerInfo.bFlashPointScale.toUpperCase();
    this.bookingContainerInfo.bFlashPoint =
      this.bookingContainerInfo.bFlashPoint;
    this.bookingContainerInfo.bDGRef =
      this.bookingContainerInfo.bDGRef.toUpperCase();

    this.bookingContainerInfo.bMFAG =
      this.bookingContainerInfo.bMFAG.toUpperCase();
    this.bookingContainerInfo.bContainerNo =
      this.bookingContainerInfo.bContainerNo.toUpperCase();
    this.bookingContainerInfo.bCellLocation =
      this.bookingContainerInfo.bCellLocation.toUpperCase();
    this.bookingContainerInfo.bMark =
      this.bookingContainerInfo.bMark.toUpperCase();
    this.bookingContainerInfo.bReceiptType =
      this.bookingContainerInfo.bReceiptType.toUpperCase();
    this.bookingContainerInfo.bVGMEnabledFlag =
      this.bookingContainerInfo.bVGMEnabledFlag.toUpperCase();
    this.bookingContainerInfo.bVGMType =
      this.bookingContainerInfo.bVGMType.toUpperCase();
    this.bookingContainerInfo.bOtherDetails = this.txtContainerOtherDetails
      .getValue()
      .toUpperCase();
    // this.txtContainerOtherDetails.setValue(this.bookingContainerInfo.bOtherDetails.toString());
  }

  changeEventChBookingContainerInfo(event) {
    if (event.target.checked) {
      this.bookingContainerInfo.bVGMEnabled = true;
      this.bookingContainerInfo.bVGMEnabledFlag = "Y";
    } else {
      this.bookingContainerInfo.bVGMEnabled = false;
      this.bookingContainerInfo.bVGMEnabledFlag = "N";
      this.cbVGMMethod.setValue("");
      this.bookingContainerInfo.bVGMType = "";
      this.bookingContainerInfo.bVGMWeight = 0;
    }
  }

  changeEventCbBContainerPackageType(event) {
    if (
      event.packageTypeId != null &&
      event.packageTypeId !== "" &&
      event.packageTypeId !== undefined
    ) {
      this.bookingContainerInfo.bPackageType = event.packageTypeCode;
      this.bookingContainerInfo.bPackageTypeName = event.packageTypeName;
    } else {
      this.bookingContainerInfo.bPackageType = "";
      this.bookingContainerInfo.bPackageTypeName = "";
    }
    this.cbBContainerPackageType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
    );
  }

  initDropDown() {
    this.loadContainerDropDown(this.currentContainerType);
  }

  eventDropDown(type: string, value: string) {
    if (value != "") {
      if (type == "ctype") {
        this.typeSizeComboLoading = true;
        this.loadContainerDropDown(value);
      } else if (type == "csize") {
        this.bookingContainerInfo.bContType = value;
      }
    }
  }

  loadContainerDropDown(value: string) {
    var hitURL =
      this.hitUrl +
      "/MasterPerCodes/getPerCodeByCtype/tags/" +
      value.toUpperCase() +
      "/";
    $("#typeSize").dropdown("clear");
    $("#typeSize").find(".menu").empty();
    if (!this.typeSizeComboDisabled) {
      this.typeSizeComboDisabled = true;
    }
    this.genericService.GET(hitURL).subscribe((resp) => {
      if (resp.ok) {
        var htmlData = "";
        var data = [];
        data = resp.json()["results"];
        data.forEach((fe) => {
          htmlData =
            htmlData +
            "<div class='item' data-value='" +
            fe.value +
            "' style='font-size:11px'>" +
            fe.name +
            "</div>";
        });
        $("#typeSize").find(".menu").append(htmlData);
        this.typeSizeComboLoading = false;
        this.typeSizeComboDisabled = false;
        var self = this;

        $("#typeSize").dropdown({
          action: "activate",
          onChange: function (value, text, $selectedItem) {
            self.eventDropDown("csize", value);
          },
        });

        if (this.bookingContainerInfo.bContType != "") {
          $("#typeSize").dropdown(
            "set selected",
            this.bookingContainerInfo.bContType
          );
        }
      }
    });
  }

  changeEventCbReceiptTypeExtra(event) {
    console.log("Extra");
    console.log(event);
    if (event.receiptTypeCode != null && event.receiptTypeCode !== undefined) {
      this.bookingMisc.bReceiptTypeExtra = event.receiptTypeCode;

      // another handle to pull down sub terminal information
      this.bookingMisc.bReturnToPor = event.porTerminalCode;
      this.cbReturnToPor.setUrl(
        this.configService.config.BASE_API.toString() +
          "/LocalMasterDepos/findByComboBoxControl/" +
          this.cookieService.getDefaultLocationCode() +
          "/depoCode={query}"
      );
      this.cbReturnToPor.setValue(this.bookingMisc.bReturnToPor.toString());
      this.bookingMisc.bReturnToPorClosingDate = event.porTerminalClosingDate;
      this.dateReturnToPorGateInClosing.setValue(
        this.bookingMisc.bReturnToPorClosingDate
      );
      this.bookingMisc.bReturnToPorClosingTime = event.porTerminalClosingTime;
      $("#timeReturnToPorClosing").val(event.porTerminalClosingTime);
      this.bookingMisc.bReturnToPorVgmDate = event.porVgmClosingDate;
      this.dateReturnToPorVgmClosing.setValue(
        this.bookingMisc.bReturnToPorVgmDate
      );
      this.bookingMisc.bReturnToPorVgmTime = event.porVgmClosingTime;
      $("#timeReturnToPorVgm").val(event.porVgmClosingTime);

      this.bookingMisc.bReturnToPol = event.polTerminalCode;
      this.cbReturnToPol.setUrl(
        this.configService.config.BASE_API.toString() +
          "/LocalMasterDepos/findByComboBoxControl/" +
          this.cookieService.getDefaultLocationCode() +
          "/depoCode={query}"
      );
      this.cbReturnToPol.setValue(this.bookingMisc.bReturnToPol.toString());
      this.bookingMisc.bReturnToPolClosingDate = event.polTerminalClosingDate;
      this.dateReturnToPolGateInClosing.setValue(
        this.bookingMisc.bReturnToPolClosingDate
      );
      this.bookingMisc.bReturnToPolClosingTime = event.polTerminalClosingTime;
      $("#timeReturnToPolClosing").val(event.polTerminalClosingTime);
      this.bookingMisc.bReturnToPolVgmDate = event.polVgmClosingDate;
      this.dateReturnToPolVgmClosing.setValue(
        this.bookingMisc.bReturnToPolVgmDate
      );
      this.bookingMisc.bReturnToPolVgmTime = event.polVgmClosingTime;
      $("#timeReturnToPolVgm").val(event.polVgmClosingTime);

      this.bookingMisc.bStuffAtPor = event.porStuffAtTerminalCode;
      this.cbStuffAt.setUrl(
        this.configService.config.BASE_API.toString() +
          "/LocalMasterDepos/findByComboBoxControl/" +
          this.cookieService.getDefaultLocationCode() +
          "/depoCode={query}"
      );
      this.cbStuffAt.setValue(this.bookingMisc.bStuffAtPor.toString());
      this.bookingMisc.bStuffAtPorClosingDate =
        event.porStuffAtTerminalClosingDate;
      this.dateStuffAtPorGateInClosing.setValue(
        this.bookingMisc.bStuffAtPorClosingDate
      );
      this.bookingMisc.bStuffAtPorClosingTime =
        event.porStuffAtTerminalClosingTime;
      $("#timeStuffAtPorClosing").val(event.porStuffAtTerminalClosingTime);
      this.bookingMisc.bStuffAtPorVgmDate = event.porStuffAtVgmClosingDate;
      this.dateStuffAtPorVgmClosing.setValue(
        this.bookingMisc.bStuffAtPorVgmDate
      );
      this.bookingMisc.bStuffAtPorVgmTime = event.porStuffAtVgmClosingTime;
      $("#timeStuffAtPorVgm").val(event.porStuffAtVgmClosingTime);

      this.cbBContainerReceiptType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
      );
      this.cbBContainerReceiptType.setValue(
        this.bookingMisc.bReceiptTypeExtra.toString()
      );
    } else {
      this.bookingMisc.bReceiptTypeExtra = "";
    }
    //this.cbReceiptTypeExtra.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}');
  }

  changeEventReleaseAt(event) {
    // console.log(event);
    if (
      event.depoCode != null &&
      event.depoCode != "" &&
      event.depoCode != undefined
    ) {
      this.bookingMisc.bReleaseAt = event.depoCode;
    } else {
      this.bookingMisc.bReleaseAt = "";
    }
    this.cbReleaseAt.setUrl(
      this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findByComboBoxControl/" +
        this.cookieService.getDefaultLocationCode() +
        "/depoName={query}"
    );
  }
  changeEventReturnToPor(event) {
    // console.log(event);
    if (
      event.depoCode != null &&
      event.depoCode != "" &&
      event.depoCode != undefined
    ) {
      this.bookingMisc.bReturnToPor = event.depoCode;
    } else {
      this.bookingMisc.bReturnToPor = "";
    }
    this.cbReturnToPor.setUrl(
      this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findByComboBoxControl/" +
        this.cookieService.getDefaultLocationCode() +
        "/depoName={query}"
    );
  }
  changeEventReturnToPol(event) {
    // console.log(event);
    if (
      event.depoCode != null &&
      event.depoCode != "" &&
      event.depoCode != undefined
    ) {
      this.bookingMisc.bReturnToPol = event.depoCode;
    } else {
      this.bookingMisc.bReturnToPol = "";
    }
    this.cbReturnToPol.setUrl(
      this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findByComboBoxControl/" +
        this.cookieService.getDefaultLocationCode() +
        "/depoName={query}"
    );
  }
  changeEventStuffAt(event) {
    // console.log(event);
    if (
      event.depoCode != null &&
      event.depoCode != "" &&
      event.depoCode != undefined
    ) {
      this.bookingMisc.bStuffAtPor = event.depoCode;
    } else {
      this.bookingMisc.bStuffAtPor = "";
    }
    this.cbStuffAt.setUrl(
      this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findByComboBoxControl/" +
        this.cookieService.getDefaultLocationCode() +
        "/depoName={query}"
    );
  }

  populateReceiptTypeExtra() {
    if (
      this.model.bPorCode != null &&
      this.model.bPorCode !== "" &&
      this.model.bPorCode !== undefined
    ) {
      if (
        this.model.bPolCode != null &&
        this.model.bPolCode !== "" &&
        this.model.bPolCode !== undefined
      ) {
        console.log("Get detail summary " + this.getDetailSummary());
        if (this.getDetailSummary() === "") {
          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/LocalMasterTerminalClosingTime/findReceiptTypeByPortPair/" +
                this.cookieService.getDefaultLocationCode() +
                "/" +
                this.model.bOceanVesselId +
                "/" +
                this.sCharUtil.htmlEncode(
                  String(this.model.bOceanVesselVoyage).trim()
                ) +
                "/" +
                this.model.bOceanVesselBound +
                "/" +
                this.model.bPorCode +
                "/" +
                this.model.bPolCode
            )
            .subscribe((resp) => {
              let data: any[] = resp.json()["content"];
              if (data.length === 1) {
                this.cbReceiptTypeExtra.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/LocalMasterTerminalClosingTime/findReceiptTypeByPortPair/" +
                    this.cookieService.getDefaultLocationCode() +
                    "/" +
                    this.model.bOceanVesselId +
                    "/" +
                    this.sCharUtil.htmlEncode(
                      String(this.model.bOceanVesselVoyage).trim()
                    ) +
                    "/" +
                    this.model.bOceanVesselBound +
                    "/" +
                    this.model.bPorCode +
                    "/" +
                    this.model.bPolCode
                );
                this.cbReceiptTypeExtra.setValue(data[0]["receiptTypeCode"]);
                if (data[0]["receiptTypeCode"] === "") {
                  this.changeEventCbReceiptTypeExtra(data[0]);
                } else {
                  this.cbReceiptTypeExtra.setValue(data[0]["receiptTypeCode"]);
                }
              } else {
                this.cbReceiptTypeExtra.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/LocalMasterTerminalClosingTime/findReceiptTypeByPortPair/" +
                    this.cookieService.getDefaultLocationCode() +
                    "/" +
                    this.model.bOceanVesselId +
                    "/" +
                    this.sCharUtil.htmlEncode(
                      String(this.model.bOceanVesselVoyage)
                    ) +
                    "/" +
                    this.model.bOceanVesselBound +
                    "/" +
                    this.model.bPorCode +
                    "/" +
                    this.model.bPolCode
                );
              }
            });
        } else {
          this.cbReceiptTypeExtra.setUrl(
            this.configService.config.BASE_API.toString() +
              "/LocalMasterTerminalClosingTime/findReceiptTypeByPortPair/" +
              this.cookieService.getDefaultLocationCode() +
              "/" +
              this.model.bOceanVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.bOceanVesselVoyage).trim()
              ) +
              "/" +
              this.model.bOceanVesselBound +
              "/" +
              this.model.bPorCode +
              "/" +
              this.model.bPolCode
          );
        }
      } else {
        this.cbReceiptTypeExtra.setUrl("");
      }
    }
  }

  getDetailSummary(): String {
    let result: String = "";
    result +=
      (this.bookingMisc.bHaulierId !== undefined
        ? this.bookingMisc.bHaulierId.trim()
        : "") +
      (this.bookingMisc.bContactPerson !== undefined
        ? this.bookingMisc.bContactPerson.trim()
        : "") +
      (this.bookingMisc.bReceiptTypeExtra !== undefined
        ? this.bookingMisc.bReceiptTypeExtra.trim()
        : "") +
      (this.bookingMisc.bReleaseAt !== undefined
        ? this.bookingMisc.bReleaseAt.trim()
        : "") +
      (this.bookingMisc.bReleaseAtDate !== undefined
        ? this.bookingMisc.bReleaseAtDate.trim()
        : "") +
      (this.bookingMisc.bReleaseAtTime !== undefined
        ? this.bookingMisc.bReleaseAtTime.trim()
        : "");
    result +=
      (this.bookingMisc.bReturnToPol !== undefined
        ? this.bookingMisc.bReturnToPol.trim()
        : "") +
      (this.bookingMisc.bReturnToPolClosingDate !== undefined
        ? this.bookingMisc.bReturnToPolClosingDate.trim()
        : "") +
      (this.bookingMisc.bReturnToPolClosingTime !== undefined
        ? this.bookingMisc.bReturnToPolClosingTime.trim()
        : "") +
      (this.bookingMisc.bReturnToPolGateInDate !== undefined
        ? this.bookingMisc.bReturnToPolGateInDate.trim()
        : "") +
      (this.bookingMisc.bReturnToPolGateInTime !== undefined
        ? this.bookingMisc.bReturnToPolGateInTime.trim()
        : "") +
      (this.bookingMisc.bReturnToPolVgmDate !== undefined
        ? this.bookingMisc.bReturnToPolVgmDate.trim()
        : "") +
      (this.bookingMisc.bReturnToPolVgmTime !== undefined
        ? this.bookingMisc.bReturnToPolVgmTime.trim()
        : "");
    result +=
      (this.bookingMisc.bReturnToPor !== undefined
        ? this.bookingMisc.bReturnToPor.trim()
        : "") +
      (this.bookingMisc.bReturnToPorClosingDate !== undefined
        ? this.bookingMisc.bReturnToPorClosingDate.trim()
        : "") +
      (this.bookingMisc.bReturnToPorClosingTime !== undefined
        ? this.bookingMisc.bReturnToPorClosingTime.trim()
        : "") +
      (this.bookingMisc.bReturnToPorGateInDate !== undefined
        ? this.bookingMisc.bReturnToPorGateInDate.trim()
        : "") +
      (this.bookingMisc.bReturnToPorGateInTime !== undefined
        ? this.bookingMisc.bReturnToPorGateInTime.trim()
        : "") +
      (this.bookingMisc.bReturnToPorVgmDate !== undefined
        ? this.bookingMisc.bReturnToPorVgmDate.trim()
        : "") +
      (this.bookingMisc.bReturnToPorVgmTime !== undefined
        ? this.bookingMisc.bReturnToPorVgmTime.trim()
        : "");
    result +=
      (this.bookingMisc.bStuffAtPor !== undefined
        ? this.bookingMisc.bStuffAtPor.trim()
        : "") +
      (this.bookingMisc.bStuffAtPorClosingDate !== undefined
        ? this.bookingMisc.bStuffAtPorClosingDate.trim()
        : "") +
      (this.bookingMisc.bStuffAtPorClosingTime !== undefined
        ? this.bookingMisc.bStuffAtPorClosingTime.trim()
        : "") +
      (this.bookingMisc.bStuffAtPorGateInDate !== undefined
        ? this.bookingMisc.bStuffAtPorGateInDate.trim()
        : "") +
      (this.bookingMisc.bStuffAtPorGateInTime !== undefined
        ? this.bookingMisc.bStuffAtPorGateInTime.trim()
        : "") +
      (this.bookingMisc.bStuffAtPorVgmDate !== undefined
        ? this.bookingMisc.bStuffAtPorVgmDate.trim()
        : "") +
      (this.bookingMisc.bStuffAtPorVgmTime !== undefined
        ? this.bookingMisc.bStuffAtPorVgmTime.trim()
        : "");

    return result;
  }

  printBookingConfirmation() {
    this.genericUtil.showLoader();
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var officeCode = this.cookieService.getDefaultLocationCode();
    //var jasperURL = 'http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/THBKK/BOOKING/Booking_Confirmation.pdf?bOfficeCode='+this.cookieService.getDefaultLocationCode()+'&bPrefix='+this.model.bPrefix+'&bNo='+this.model.bNo+'&userId='+this.cookieService.getName();
    //var jasperURL = 'http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/'+officeCode.toUpperCase()+'/BOOKING/Booking_Confirmation.pdf?bOfficeCode='+officeCode.toUpperCase()+'&bPrefix='+this.model.bPrefix+'&bNo='+this.model.bNo+'&userId='+this.cookieService.getName();
    var jasperURL =
      this.configService.config.jasperUrl +
      "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
      officeCode.toUpperCase() +
      "/BOOKING/Booking_Confirmation.pdf?bOfficeCode=" +
      officeCode.toUpperCase() +
      "&bPrefix=" +
      this.model.bPrefix +
      "&bNo=" +
      this.model.bNo +
      "&userId=" +
      this.cookieService.getName();
    var fileName =
      this.cookieService.getDefaultLocationCode() +
      "-" +
      this.model.bPrefix +
      this.model.bNo;

    if (officeCode.startsWith("ID")) {
      jasperURL =
        this.configService.config.jasperUrl +
        "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
        officeCode.toUpperCase() +
        "/BOOKING/Booking_Confirmation.pdf?bOfficeCode=" +
        officeCode.toUpperCase() +
        "&bPrefix=" +
        this.model.bPrefix +
        "&bNo=" +
        this.model.bNo +
        "&userId=" +
        this.cookieService.getName() +
        "&openStack=" +
        this.openStackDT +
        "&closingtime=" +
        this.closingTimeDT;
    }

    var randomNumber = this.genericUtil
      .getPSIDate()
      .replace(/-/g, "")
      .replace(/:/g, "")
      .replace(" ", "");

    var postData = {};
    postData["fileName"] = fileName + "-" + randomNumber + ".pdf";
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl, postData).subscribe(
      (resp) => {
        if (resp.ok) {
          var result = resp.json();

          if (result["message"] == "success") {
            this.pdfContainer.setPDFURL(
              this.configService.config.pdfUrl + result["filePath"]
            );
            this.pdfContainer.showPDF();
          }

          this.loading = false;
          this.genericUtil.hideLoader();
        }
      },
      (error) => {
        this.genericUtil.hideLoader();
      }
    );
  }

  onDialogBlDeleteOk() {
    this.genericUtil.closeDialog("bkgContainerDeleteInfo");
    this.onContainerInfo__Cancel();
  }

  loadDataAlloc() {
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/calculateAllocation2",
        this.model
      )
      .subscribe((resp) => {
        let dataAlloc: any[] = resp.json()["content"];

        this.rowData = [];
        let no: number = 1;
        dataAlloc.forEach((element) => {
          element.no = no++;
          this.rowData.push(element);
        });

        if (this.rowData.length > 0) {
          this.defaultBooked = this.rowData[0]["booked"];
          this.defaultBooked2 = "";
          this.rowData.forEach((element) => {
            if (element.allotmentCode != "VS") {
              this.defaultBooked2 = this.defaultBooked2 + element.booked + ";";
            }
          });
        }

        if (this.trigerInit) {
          this.genericUtil.hideLoader();

          if(this.cookieService.getDefaultLocationCode() == "SGSIN" && this.isCopySuccess){
            this.disableToolbarButtons = "retrieve,new,modify,print,delete,sendDepot";
          }else if(this.isCHINASiteEDI_NewBkg==true){
            this.disableToolbarButtons = "retrieve,new,modify,copy,print,delete,sendDepot";
          }
          else{
            this.disableToolbarButtons = "modify,save,copy,cancel,print,delete,sendDepot";
          }


          this.trigerInit = false;
          this.isError = false;
        }
      });
  }

  /*
  onSelectFlashPointScale(val: String){
    this.bookingContainerInfo.bFlashPointScale = val;
    val val val
  }
  */

  changeEventBContainerFlashPointScale(event) {
    console.log("triggered flash point");
    if (
      event.flashPointScaleCode != null &&
      event.flashPointScaleCode !== undefined
    ) {
      console.log("masuk if");
      console.log(event);
      this.bookingContainerInfo.bFlashPointScale = event.flashPointScaleCode;
    }
  }
  onDialogConfirmChangeOperatorCodeYes() {
    this.gridContainerInfo.onClear();
    this.model.bookingContainerInfo = [];
    this.genericUtil.closeDialog("bkgConfirmChangeOperatorCode");
  }
  onDialogConfirmChangeOperatorCodeCancel() {
    this.model.bCocCntrOperatorCode = "";
    this.cbCocOPR.setValue("");
    this.genericUtil.closeDialog("bkgConfirmChangeOperatorCode");
  }

  onDialogConfirmChangeOperatorCodeYesSOC() {
    this.gridContainerInfo.onClear();
    this.model.bookingContainerInfo = [];
    this.genericUtil.closeDialog("bkgConfirmChangeOperatorCodeSOC");
  }
  onDialogConfirmChangeOperatorCodeCancelSOC() {
    this.model.bSocCntrOperatorCode = "";
    this.cbSocOPR.setValue("");
    this.genericUtil.closeDialog("bkgConfirmChangeOperatorCodeSOC");
  }

  onDialogConfirmUCChangeYes() {
    this.gridContainerInfo.onClear();
    this.model.bookingContainerInfo = [];
    this.genericUtil.closeDialog("bkgConfirmUCChange");
  }
  onDialogConfirmUCChangeCancel() {
    this.currentContainerType = this.previousContainerType;
    this.lockUC = false;
    this.bookingDetails.clear();
    let list = new Map<String, BookingDetail>();
    this.bookingDetails.set(
      this.currentContainerType,
      this.setContainerValue(list)
    );
    this.genericUtil.closeDialog("bkgConfirmUCChange");
  }

  eventPDFPlugin(event) {}

  doUnlockByUser(officeCode: String, user: String) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/setUnlockBookingByUser/" +
          officeCode +
          "/" +
          user
      )
      .subscribe((resp) => {});
  }

  doUnlock(officeCode: String, prefix: String, bNo: String, user: String) {
    if (bNo != "") {
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterBookings/setUnlockBooking/" +
            officeCode +
            "/" +
            prefix +
            "/" +
            bNo +
            "/" +
            user
        )
        .subscribe((resp) => {});
    }
  }

  doLock(officeCode: String, prefix: String, bNo: String, user: String) {
    if (bNo != "") {
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterBookings/setLockBooking/" +
            officeCode +
            "/" +
            prefix +
            "/" +
            bNo +
            "/" +
            user
        )
        .subscribe((resp) => {});
    }
  }

  getLock(officeCode: String, prefix: String, bNo: String, user: String) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getLockBooking/" +
          officeCode +
          "/" +
          prefix +
          "/" +
          bNo
      )
      .subscribe((resp) => {
        console.log(resp.json()["content"]);
        let det: any[] = resp.json()["content"];
        if (det["bUserId"] != undefined) {
          this.model.bIsUnlocked = "N";
          this.model["bLockBy"] = det["bUserId"];
        } else {
          this.model.bIsUnlocked = "Y";
          this.model["bLockBy"] = "";
        }
        console.log(this.model);
      });
  }

  printButtonEvent(type) {
    if (type == "print") {
      this.openStackDT = this.openStackDT + "@" + $("#openStackTime").val();
      this.closingTimeDT =
        this.closingTimeDT + "@" + $("#clossingTimeTime").val();

      this.closeDialogs("printDialog");
      this.printBookingConfirmation();
    } else if (type == "close") {
      this.closeDialogs("printDialog");
    }
  }

  printBCFormReset() {
    this.openStack.reset();
    this.clossingTime.reset();

    $("#openStackTime").val("");
    $("#clossingTimeTime").val("");
  }

  showDialogs(id: string, options: any) {
    $("#" + id)
      .dialog(options)
      .dialog("open");
  }

  closeDialogs(id: string) {
    $("#" + id).dialog("close");
    $("#" + id).dialog("destroy");
  }

  changeEventDateFrom(event) {}

  changeOpenStack(event) {
    console.log(event);
    if (!event.toString().startsWith("datepick")) {
      this.openStack.reset();
      this.openStackDT = "";
    } else {
      this.openStackDT = this.openStack.getValue();
    }
  }

  changeClossingTime(event) {
    if (!event.toString().startsWith("datepick")) {
      this.clossingTime.reset();
      this.closingTimeDT = "";
    } else {
      this.closingTimeDT = this.clossingTime.getValue();
    }
  }

  checkIdenticalVesVoy(val1: any, val2: any = "", errMessage: string) {
    return new Promise<void>((resolve, reject) => {
      if (val1 !== "" && val2 !== "") {
        if (this.model[val1] === val2) {
          this.dialogPlugin.show(
            "information",
            "Information",
            errMessage,
            "okonly",
            { ok: "" }
          );

          reject("failed");
        }

        resolve();
      }

      resolve();
    });
  }

  checkIsError(field: string) {
    if (this.model[field] == "" || this.model[field] == null) {
      return false;
    }

    return true;
  }

  checkIsLock() {
    if (
      (this.isModify == true &&
        this.lockCombine == true &&
        this.lockReeferTemp == false) ||
      (this.isModify == true && this.lockSplit == true)
    ) {
      return true;
    }

    return false;
  }

  lockWhenDry() {
    //console.log("KESINI");
    if (this.currentContainerType == "DRY") {
      return true;
    }

    return false;
  }

  setDisableReeferCollection() {
    if (this.currentContainerType == "DRY") {
      this.dateReeferCollection.setDisable(false);

      return false;
    }
    return false;
  }

  grayReefer() {
    //console.log("KESINI");
    if (this.currentContainerType == "REF") {
      return true;
    }

    return false;
  }

  validateAfterSaveNew() {
    console.log("this.custIdForCbFreightParty :" + this.custIdForCbFreightParty);
    console.log("this.model.bFreightPartyCode :" + this.model.bFreightPartyCode);

    console.log("this.custIdForCbBooking :" + this.custIdForCbBooking);
    console.log("this.model.bBookingPartyCode :" + this.model.bBookingPartyCode);

    console.log("this.custIdForCbShipper :" + this.custIdForCbShipper);
    console.log("this.model.bShipperCode :" + this.model.bShipperCode);
    // var haveChanged = false;
    // var dtBooking = new Booking();
    // dtBooking = this.model;

    // if (this.attrCustChanged) {
    //   dtBooking.bContactPerson = this.contactFromBooking;
    //   dtBooking.bTelephone = this.telephoneFromBooking;
    // }

    // var currentBookingString = this.getBookingString(dtBooking);
    // var currentBookingMiscString = this.getBookingMiscString(this.model.bookingMisc);
    // var currentBookingExtraString = this.getBookingExtraString(this.model.bookingExtra);
    // var currentBookingDetailString = "";
    // var currentBookingFreightChargeString = "";
    // var currentBookingContainerInfoString = "";

    // if (this.model.bookingDetails.length > 0) {
    //   this.model.bookingDetails.forEach(value => {
    //     currentBookingDetailString += this.getBookingDetailString(value);
    //   });
    // }

    // if (this.model.bookingFreightCharges.length > 0) {
    //   this.model.bookingFreightCharges.forEach(value => {
    //     currentBookingFreightChargeString += this.getBookingFreightChargeString(value);
    //   });
    // }

    // if (this.model.bookingContainerInfo.length > 0) {
    //   this.model.bookingContainerInfo.forEach(value => {
    //     currentBookingContainerInfoString += this.getBookingContainerInfoString(value);
    //   });
    // }

    // if (currentBookingString != this.retrieveBookingString) {
    //   console.log("*** currentBookingString ***");
    //   console.log(currentBookingString);
    //   console.log("*** retrieveBookingString ***");
    //   console.log(this.retrieveBookingString);
    //   haveChanged = true;
    // }

    // if (currentBookingMiscString != this.retrieveBookingMiscString) {
    //   console.log("*** currentBookingMiscString ***");
    //   console.log(currentBookingMiscString);
    //   console.log("*** retrieveBookingMiscString ***");
    //   console.log(this.retrieveBookingMiscString);
    //   haveChanged = true;
    // }

    // if (currentBookingExtraString != this.retrieveBookingExtraString) {
    //   console.log("*** currentBookingExtraString ***");
    //   console.log(currentBookingExtraString);
    //   console.log("*** retrieveBookingExtraString ***");
    //   console.log(this.retrieveBookingExtraString);
    //   haveChanged = true;
    // }

    // if (currentBookingDetailString != this.retrieveBookingDetailString) {
    //   console.log("*** currentBookingDetailString ***");
    //   console.log(currentBookingDetailString);
    //   console.log("*** retrieveBookingDetailString ***");
    //   console.log(this.retrieveBookingDetailString);
    //   haveChanged = true;
    // }

    // if (currentBookingFreightChargeString != this.retrieveBookingFreightChargeString) {
    //   console.log("*** currentBookingFreightChargeString ***");
    //   console.log(currentBookingFreightChargeString);
    //   console.log("*** retrieveBookingFreightChargeString ***");
    //   console.log(this.retrieveBookingFreightChargeString);
    //   haveChanged = true;
    // }

    // if (currentBookingContainerInfoString != this.retrieveBookingContainerInfoString) {
    //   console.log("*** currentBookingContainerInfoString ***");
    //   console.log(currentBookingContainerInfoString);
    //   console.log("*** retrieveBookingContainerInfoString ***");
    //   console.log(this.retrieveBookingContainerInfoString);
    //   haveChanged = true;
    // }

    // console.log("haveChanged : "+haveChanged);

    // if (haveChanged) {
      console.log("this.searchFpIsFreightParty : "+this.searchFpIsFreightParty);
      console.log("this.searchFpIsValid : "+this.searchFpIsValid);
      if (this.searchFpIsFreightParty == 'N') {
        this.model["error-bFreightPartyCode"] = "Customer record is no longer set as a Freight Party, unable to proceed with creation";
        this.isError = true;
      } else if (this.searchFpIsValid == 'N') {
        this.model["error-bFreightPartyCode"] = "Freight Party is invalid, unable to proceed with creation";
        this.isError = true;
      }

      if (this.searchBkIsBookingParty == 'N') {
        this.model["error-bBookingPartyCode"] = "Customer record is no longer set as a Booking Party, unable to proceed with creation";
        this.isError = true;
      } else if (this.searchBkIsValid == 'N') {
        this.model["error-bBookingPartyCode"] = "Booking Party is invalid, unable to proceed with creation";
        this.isError = true;
      }

      if (this.searchShIsShipper == 'N') {
        this.model["error-bShipperCode"] = "Customer record is no longer set as a Shipper, unable to proceed with creation";
        this.isError = true;
      } else if (this.searchShIsValid == 'N') {
        this.model["error-bShipperCode"] = "Shipper is invalid, unable to proceed with creation";
        this.isError = true;
      }
    // }

    // if (this.custIdForCbFreightParty != this.model.bFreightPartyCode) {
    //   if (this.searchFpIsFreightParty == 'N') {
    //     this.model["error-bFreightPartyCode"] = "Customer record is no longer set as a Freight Party, unable to proceed with creation";
    //     this.isError = true;
    //   } else if (this.searchFpIsValid == 'N') {
    //     this.model["error-bFreightPartyCode"] = "Freight Party is invalid, unable to proceed with creation";
    //     this.isError = true;
    //   }
    // }

    // if (this.custIdForCbBooking != this.model.bBookingPartyCode) {
    //   if (this.searchBkIsBookingParty == 'N') {
    //     this.model["error-bBookingPartyCode"] = "Customer record is no longer set as a Booking Party, unable to proceed with creation";
    //     this.isError = true;
    //   } else if (this.searchBkIsValid == 'N') {
    //     this.model["error-bBookingPartyCode"] = "Booking Party is invalid, unable to proceed with creation";
    //     this.isError = true;
    //   }
    // }

    // if (this.custIdForCbShipper != this.model.bShipperCode) {
    //   if (this.searchShIsShipper == 'N') {
    //     this.model["error-bShipperCode"] = "Customer record is no longer set as a Shipper, unable to proceed with creation";
    //     this.isError = true;
    //   } else if (this.searchShIsValid == 'N') {
    //     this.model["error-bShipperCode"] = "Shipper is invalid, unable to proceed with creation";
    //     this.isError = true;
    //   }
    // }
  }

  getBookingString(dt: Booking): string {
    //return dt.pfcOfficeCode + "/" +
    return (
      // dt.ATA + "/" +
      // dt.ATD + "/" +
      // dt.ETA + "/" +
      // dt.ETD + "/" +

      dt.bOfficeCode + "/" +
      dt.bPrefix + "/" +
      dt.bNo + "/" +
      dt.bOfficeCodeTs + "/" +
      dt.bPrefixTs + "/" +
      dt.bNoTs + "/" +
      dt.bOceanVesselId + "/" +
      dt.bOceanVesselCode + "/" +
      dt.bOceanVesselName + "/" +
      dt.bOceanVesselVoyage + "/" +
      dt.bOceanVesselBound + "/" +
      // dt.bCreatedDate + "/" +
      // dt.bCreatedUserid + "/" +
      // dt.bModifiedDate + "/" +
      // dt.bModifiedUserid + "/" +
      // dt.bDeletedDate + "/" +
      // dt.bDeletedUserid + "/" +
      dt.bReleaseOrderPrinted + "/" +
      dt.bReleaseOrderPrintedUserid + "/" +
      dt.bReleaseOrderPrintedDate + "/" +
      // dt.bStatus + "/" +
      dt.bIsFinished + "/" +
      dt.bTsBooking + "/" +
      dt.bIsReserve + "/" +
      dt.bIsSlotCommission + "/" +
      dt.bIsTransmittedToPsa + "/" +
      dt.bTransmittedToPsaDate + "/" +
      dt.bTransmittedToPsaUserid + "/" +
      dt.bPrincipalCode + "/" +
      dt.bTransmitToSds + "/" +
      dt.bIsUnlocked + "/" +
      dt.bReason + "/" +

      // booking note 1
      dt.bBookingPartyCode + "/" +
      // dt.bBookingPartyName + "/" +
      dt.bShipperCode + "/" +
      // dt.bShipperName + "/" +
      dt.bFreightPartyCode + "/" +
      // dt.bFreightPartyName + "/" +
      dt.bPorCode + "/" +
      dt.bPolCode + "/" +
      dt.bPolName + "/" +
      dt.bPolBooking + "/" +
      dt.bPodCode + "/" +
      dt.bPodName + "/" +
      dt.bPodBooking + "/" +
      dt.bPodlCode + "/" +
      dt.bFdestCode + "/" +
      dt.bPot1Code + "/" +
      dt.bPot1Name + "/" +
      dt.bPot2Code + "/" +
      dt.bPot3Code + "/" +
      dt.bCommodityCode + "/" +
      dt.bCommodityId + "/" +
      dt.bSpecialDetailCode + "/" +
      dt.bShipmentType + "/" +
      dt.bContainerOwnership + "/" +
      dt.bContainerStatus + "/" +
      dt.bDgClass + "/" + //ini
      dt.bFreightType + "/" +
      dt.bIhc + "/" +
      dt.bContactPerson + "/" +
      dt.bTelephone + "/" +
      dt.bRemarks + "/" +
      dt.bWeightClass + "/" + //ini
      dt.bWeight + "/" +
      ((dt.bCocOperatorCode.length > 0) ? dt.bCocOperatorCode.trim() : dt.bCocOperatorCode) + "/" +
      ((dt.bCocCntrOperatorCode.length > 0) ? dt.bCocCntrOperatorCode.trim() : dt.bCocCntrOperatorCode) + "/" +
      ((dt.bSocOperatorCode.length > 0 ) ? dt.bSocOperatorCode.trim() : dt.bSocOperatorCode) + "/" +
      ((dt.bSocCntrOperatorCode.length > 0) ? dt.bSocCntrOperatorCode.trim() : dt.bSocCntrOperatorCode) + "/" + //ini
      ((dt.bSlotOperatorCode.length > 0)? dt.bSlotOperatorCode.trim() : dt.bSlotOperatorCode) + "/" +
      ((dt.bOutwardSlotOperatorCode.length > 0) ? dt.bOutwardSlotOperatorCode.trim() : dt.bOutwardSlotOperatorCode) + "/" +
      dt.bReceiptType + "/" +
      dt.bDeliveryType + "/" +
      dt.bPsaReferenceNo + "/" +

      // booking note 2
      dt.bFeederVesselId + "/" +
      dt.bFeederVesselCode + "/" +
      dt.bFeederVesselVoyage + "/" +
      // dt.bFeederVesselBound + "/" + //ini
      dt.bSupplierCode + "/" +
      dt.bSupplierName + "/" +
      dt.bExMotherVesselId + "/" +
      dt.bExMotherVesselCode + "/" +
      dt.bExMotherVesselVoyage + "/" +
      // dt.bExMotherVesselBound + "/" +
      dt.bExMotherVesselName + "/" +
      dt.bNextMotherVesselId + "/" +
      dt.bNextMotherVesselCode + "/" +
      dt.bNextMotherVesselVoyage + "/" +
      // dt.bNextMotherVesselBound + "/" +
      dt.bReeferDegree + "/" +
      dt.bReeferCollectionDate + "/" +
      dt.bVent+ "/" +
      dt.bIsDry + "/" +
      dt.bDetention + "/" +
      dt.bDetentionRequest+ "/" +
      dt.bDemurrage + "/" +
      dt.bDemurrageRequest+ "/" +
      dt.bDetentionPol + "/" +
      dt.bDetentionRequestPol+ "/" +
      dt.bDemurragePol + "/" +
      dt.bDemurrageRequestPol+ "/" +
      dt.bHaulierCode + "/" +
      dt.bHaulierName + "/" +
      dt.bReturnToCode + "/" +
      dt.bPackedAtCode + "/" +
      dt.bReleaseAtCode + "/" +
      dt.bRequestReleaseDate + "/" +
      dt.bFirstReleaseDate + "/" +
      dt.bExpectedReturnDate + "/" +
      dt.bLastReleaseDate + "/" +
      dt.bHaulierContact + "/" +
      dt.bHaulierContactNo + "/" +
      dt.bWt+ "/" +
      dt.bMeasure+ "/" +
      dt.bPackageNo + "/" +
      dt.bPackageTypeCode + "/" +

      // terminal info
      dt.bPorTerminalCode + "/" +
      dt.bPolTerminalCode + "/" +
      dt.bPodTerminalCode + "/" +
      dt.bPodlTerminalCode + "/" +
      dt.bPot1TerminalCode + "/" +
      dt.bPot2TerminalCode + "/" +
      dt.bPot3TerminalCode + "/" +
      dt.bFdestTerminalCode + "/" +

      dt.etdPOL + "/" +
      dt.etdMaster
    );
  }

  getBookingContainerInfoString(dt: BookingContainerInfo): string {
    return (dt.bOfficeCode + "/" +
      dt.bPrefix + "/" +
      dt.bNo + "/" +
      dt.bSeqNo + "/" +
      dt.bPageNo + "/" +
      dt.bVent + "/" +
      dt.bVentNumber + "/" +
      dt.bVentOption + "/" +
      dt.bNoOfPkg + "/" +
      dt.bPkgGroup + "/" +
      dt.bReleaseDate + "/" +
      dt.bReleaseTime + "/" +
      dt.bPackageType + "/" +
      dt.bPackageTypeName + "/" +
      dt.bPSAClass + "/" +
      dt.bContOperator + "/" +
      dt.bIsPartLot + "/" +
      dt.bTrueShippingName + "/" +
      dt.bContType + "/" +
      dt.bChemicalName + "/" +
      dt.bGrossWeight + "/" +
      dt.bGrossMeas + "/" +
      dt.bNettWeight + "/" +
      dt.bLabel + "/" +
      dt.bIMO + "/" +
      dt.bUNNO + "/" +
      dt.bFlashPoint + "/" +
      dt.bFlashPointScale + "/" +
      dt.bEMS + "/" +
      dt.bMFAG + "/" +
      dt.bContainerNo + "/" +
      dt.bCellLocation + "/" +
      dt.bMark + "/" +
      dt.bReceiptType + "/" +
      dt.bVGMEnabled + "/" +
      dt.bVGMEnabledFlag + "/" +
      dt.bVGMType + "/" +
      dt.bVGMWeight + "/" +
      dt.bOtherDetails + "/" +
      dt.bOtherDetailsClean + "/" +
      dt.bDGRef
    );
  }

  getBookingDetailString(dt: BookingDetail): string {
    return (
      dt.bOfficeCode + "/" +
      dt.bPrefix + "/" +
      dt.bNo + "/" +
      dt.bContainerType + "/" +
      dt.bContainerSize + "/" +
      dt.bContainerHeight + "/" +
      dt.bQuantity + "/" +
      dt.bKillSlot + "/" +
      dt.bUch + "/" +
      dt.bUcl + "/" +
      dt.bUcw + "/" +
      dt.bOverHeight + "/" +
      dt.bOverHeightCm + "/" +
      dt.bOverWidth + "/" +
      dt.bOverWidthLeftCm + "/" +
      dt.bOverWidthRightCm + "/" +
      dt.bOverLength + "/" +
      dt.bOverLengthFrontCm + "/" +
      dt.bOverLengthBackCm + "/" +
      dt.bDoorOpen
    );
  }

  getBookingFreightChargeString(dt: BookingFreightCharge): string {
    return (
      dt.bOfficeCode + "/" +
      dt.bPrefix + "/" +
      dt.bNo + "/" +
      dt.bContainerType + "/" +
      dt.bContainerSize + "/" +
      dt.bContainerHeight + "/" +
      dt.bFrtSeqNo + "/" +
      dt.bChargeCode + "/" +
      dt.bRevenue + "/" +
      dt.bRate + "/" +
      dt.bPerCode + "/" +
      dt.bCurrencyCode + "/" +
      dt.bAmount + "/" +
      dt.bFreightType + "/" +
      dt.bPayerCode + "/" +
      dt.bIsFaiGroup + "/" +
      dt.createdDate + "/" +
      dt.createdUserid + "/" +
      // dt.modifiedDate + "/" +
      // dt.modifiedUserid + "/" +
      // dt.deletedDate + "/" +
      // dt.deletedUserid + "/" +

      dt.bRecordId + "/" +
      dt.bAccountPIC + "/" +
      dt.bApprovedUserId + "/" +
      dt.bApprovedDate + "/" +
      dt.popCode + "/" +
      dt.popName
    );
  }

  getBookingMiscString(dt: BookingMisc): string {
    return (
      dt.bOfficeCode + "/" +
      dt.bPrefix + "/" +
      dt.bNo + "/" +
      dt.bFreightRecordId + "/" +
      dt.bFreightApprovedUserId + "/" +
      dt.bFreightApprovedDate + "/" +
      dt.bFreightAccountPic + "/" +
      dt.bHaulierId + "/" +
      dt.bContactPerson + "/" +
      dt.bReleaseAt + "/" +
      dt.bReleaseAtDate + "/" +
      dt.bReleaseAtTime + "/" +
      dt.bReturnToPor + "/" +
      dt.bReturnToPorGateInDate + "/" +
      dt.bReturnToPorGateInTime + "/" +
      dt.bReturnToPorClosingDate + "/" +
      dt.bReturnToPorClosingTime + "/" +
      dt.bReturnToPorVgmDate + "/" +
      dt.bReturnToPorVgmTime + "/" +
      dt.bReturnToPol + "/" +
      dt.bReturnToPolGateInDate + "/" +
      dt.bReturnToPolGateInTime + "/" +
      dt.bReturnToPolClosingDate + "/" +
      dt.bReturnToPolClosingTime + "/" +
      dt.bReturnToPolVgmDate + "/" +
      dt.bReturnToPolVgmTime + "/" +
      dt.bStuffAtPor + "/" +
      dt.bStuffAtPorGateInDate + "/" +
      dt.bStuffAtPorGateInTime + "/" +
      dt.bStuffAtPorClosingDate + "/" +
      dt.bStuffAtPorClosingTime + "/" +
      dt.bStuffAtPorVgmDate + "/" +
      dt.bStuffAtPorVgmTime + "/" +
      dt.bAdditionalRemarks + "/" +
      dt.bReceiptTypeExtra + "/" +

      dt.bHaulierName + "/" +
      dt.bReleaseAtName + "/" +
      dt.bReturnToPorName + "/" +
      dt.bReturnToPolName + "/" +
      dt.bStuffAtPorName + "/" +
      dt.bStuffAtPolName
    );
  }

  getBookingExtraString(dt: BookingExtra): string {
    return (
      dt.bPrefix + "/" +
      dt.bOfficeCode + "/" +
      //dt.bReeferDegree.toUpperCase() + "/" +
      dt.bNatureTemp + "/" +
      dt.bNo + "/" +
      dt.bReeferCollectionDate + "/" +
      // dt.bRemarkIsPrinted + "/" +
      dt.bDetentionPol + "/" +
      dt.bDetentionRequestPol + "/" +
      dt.bDemurragePol + "/" +
      dt.bDemurrageRequestPol + "/" +
      dt.bDetentionDemurragePodIsPrinted + "/" +
      dt.bDetentionDemurragePolIsPrinted + "/" +
      dt.bExtra1 + "/" +
      // dt.bDegreeSo.toUpperCase() + "/" +
      dt.bSetOption + "/" +
      dt.bIsDry + "/" +
      dt.bVent + "/" +
      dt.bVentNumber + "/" +
      dt.bVentOption + "/" +
      dt.bHumidity
    );
  }

  setDataAfterRetrieve() {
    this.setDetail();
    setTimeout(() => {
      this.retrieveBookingString = this.getBookingString(this.model);
      this.retrieveBookingMiscString = this.getBookingMiscString(this.bookingMisc);
      this.retrieveBookingExtraString = this.getBookingExtraString(this.bookingExtra);
      this.retrieveBookingDetailString = "";
      this.retrieveBookingFreightChargeString = "";
      this.retrieveBookingContainerInfoString = "";

      if (this.model.bookingDetails.length > 0) {
        this.model.bookingDetails.forEach(value => {
          this.retrieveBookingDetailString += this.getBookingDetailString(value);
        });
      }

      if (this.model.bookingFreightCharges.length > 0) {
        this.model.bookingFreightCharges.forEach(value => {
          this.retrieveBookingFreightChargeString += this.getBookingFreightChargeString(value);
        });
      }

      if (this.model.bookingContainerInfo.length > 0) {
        this.model.bookingContainerInfo.forEach(value => {
          this.retrieveBookingContainerInfoString += this.getBookingContainerInfoString(value);
        });
      }
    }, 1000);

  }

   // * Field Validation On Paste
   onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field);
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      return (this.model[`error-${field}`] = "");
    }

    return false;
  }

  checkErrorblVesselIdblVoyage() {
    if (!this.model[`error-bOceanVesselCode`] && !this.model[`error-bOceanVesselVoyage`] && !this.model['error-bNo']) {
      this.isError = false;
    } else {
      this.isError = true;
    }
  }

  backspaceValue(event: any, regexName: RegExp, field: string) {
    if(field === 'bOceanVesselCode' && !event.target.value){
      this.cbVoyage.setValue("");
      this.model[`error-bOceanVesselVoyage`] = "";
      this.model.bOceanVesselVoyage = "";
    }
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field);
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field){
    const fileName = field === 'bOceanVesselCode' ? 'Vessel' : 'Voyage';
    if (pastedText && regexName.test(pastedText) == false) {
      this.isError = true;
      this.model[`error-${field}`] = `${fileName} 'Only accept alphanumeric and -./'`;
    } else {
      this.model[`error-${field}`] = "";
    }
    this.checkErrorblVesselIdblVoyage();
  }

  onlyNumber(event){
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }

  valiAlphanumericSomeSpecialCharacters(event) {
    if (this.regexAlphanumericSomeSpecialCharacters.test(event.key) == false) {
      event.preventDefault();
    }
  }

  pasteAlphanumericSomeSpecialCharacters(event: any, lengthChar: Number) {
    let re = new RegExp(this.regexAlphanumericSomeSpecialCharactersForPaste, "g");
    let pastedText = event.clipboardData.getData("text").replace(re, "");
    event.preventDefault();
    if (pastedText.length > lengthChar) pastedText = pastedText.slice(0, lengthChar);
    event.target.value = pastedText;
    return;
  }

  handleValidateExVoyageVessel() {
    if (!!this.model.bExMotherVesselId === true && !!this.model.bExMotherVesselVoyage === false) {
      this.dialogPlugin.show(
        "save",
        "Saving data",
        "Please input Ex-Voyage.",
        "okonly",
        { ok: "this.loading = false;return false" }
      );
      return true;
    }
    if (!!this.model.bExMotherVesselId === false && !!this.model.bExMotherVesselVoyage === true) {
      this.dialogPlugin.show(
        "save",
        "Saving data",
        "Please input Ex-Vessel.",
        "okonly",
        { ok: "this.loading = false;return false" }
      );
      return true;
    }
    return false;
  }

  onChangeConfirmVessel(event) {
    this.model.vesselVoyageConfirmStatus = !this.model.vesselVoyageConfirmStatus;
  }

  onChangeConfirmVoyage(event) {
    this.model.confirmVoyage = !this.model.confirmVoyage;
  }

  onChangePriority(event) {
    // GST-145 fix Incorrect Priority Booking status.
    // this.isPriority = !this.isPriority;
    this.model.bNextVesselVoyagePriority = event.target.checked ? 'Y' : 'N';
  }

  onValidateChangeUniContainer() {
    return new Promise(
      (resolve,reject)=>
      {
        if (this.model.bPot1Code == 'SGSIN' && !!this.model.bNextMotherVesselId && !!this.model.bNextMotherVesselVoyage) {
          this.dialogPlugin.show(
            "information",
            "Information",
            "Next Vessel and Voyage details will be removed, confirm to proceed?",
            "yesno",
            {
              yes: "this.removeNextVesselVoyage();",
              no: "this.loading = false;return false",
            }
          );
        }
        }
    );

  }

  removeNextVesselVoyage(){
    this.model.bNextMotherVesselId = "";
    this.model.bNextMotherVesselCode = "";
    this.model.bNextMotherVesselVoyage = "";
    this.model.bNextMotherVesselBound = "";
    this.model['etaNextVessel'] = "";
    this.model['etdNextVessel'] = "";
    this.cbNextVessel.setForceValue("");
    this.cbNextVoyage.setForceValue("");
  }

  handleSendDepot() {
    this.genericUtil.showLoader();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/sendCoparnToEngKongWithBNo/" +
        this.model.bOfficeCode.toUpperCase() +
        "/" +
        this.model.bOceanVesselId +
        "/" +
        this.model.bOceanVesselVoyage +
        "/" +
        this.model.bOceanVesselBound +
        "/" +
        this.model.bContainerOwnership +
        "/" +
        this.model.bNo
      )
      .pipe(
        finalize(() => this.genericUtil.hideLoader())
      )
      .subscribe((resp) => {
        let data = resp.json();
        if (data.status == 'ok') {
          this.isCheckedDepot = true;
          //GSO-1048 Allow Viewing of Deleted Booking
          if(this.model.bStatus === "D"){
            this.disableToolbarButtons = "retrieve,new,modify,save,copy,print,delete,sendDepot";
          }else{
            this.disableToolbarButtons = "retrieve,save,copy,sendDepot";
          }

          this.dialogPlugin.show(
            "information",
            "Information",
            "Transmit succeeded.",
            "okonly",
            { ok: "this.loading = false" }
          );
        }
        if (data.status == 'nok') {
          this.dialogPlugin.show(
            "information",
            "Information",
            "Transmission failed.",
            "okonly",
            { ok: "this.loading = false" }
          );
          return;
        }
        this.model.bDepotTransmitDate = data.bDepotTransmitDate;
        this.model.bTransmitToSds = "Y";

      });
  }

  handleCheckStatusDepot(isModify = false) {
    if (this.isSingaporeSite) {
      //GSO-1049 Disable <Send to Depot> button when <Release At> NOT Capturing <ENG KONG CONTAINER>
      //GSO-1034 Depot EDI Integration - COC Local Bkg Modification
      this.isEngKongContainer = this.bookingMisc.bReleaseAt == "ENG KONG CTR - SIN";
      setTimeout(() => {
        if(this.model.bContainerOwnership === "COC" && !isModify){
          const disableToolbarButtons = this.disableToolbarButtons.replace(',sendDepot','');
          this.disableToolbarButtons = disableToolbarButtons;
          if(this.model.bTransmitToSds === "N" && this.isEngKongContainer){
            const disableToolbarButtons = this.disableToolbarButtons.replace(',sendDepot','');
            this.disableToolbarButtons = disableToolbarButtons;
            if(this.model.bStatus === "D"){
              this.disableToolbarButtons = "retrieve,new,modify,save,copy,print,delete";
            }
          }else{
            const disableToolbarButtons = this.disableToolbarButtons.concat(",sendDepot");
            this.disableToolbarButtons = disableToolbarButtons;
            if(this.model.bStatus === "D"){
              this.disableToolbarButtons = "retrieve,new,modify,save,copy,print,delete,sendDepot";
            }
          }

        }else{
          const disableToolbarButtons = this.disableToolbarButtons.concat(",sendDepot");
          this.disableToolbarButtons = disableToolbarButtons;
        }
      }, 0);

    }
  }
  /*
  calculateReleaseDateWhenTypeDry() {
    if (this.isReeferDryTypeInSing) {
      let detentionFreeTime;
      const dateETA = new Date(String(this.model.ETA));
      if (this.bookingExtra.bDetentionPol === "S" || this.bookingExtra.bDetentionPol === "N") {
        detentionFreeTime = 7;
      } else {
        detentionFreeTime = 14;
      }
      // Adopt formula (ETA+1) - (POL Detention Free Time) on <Reefer Collection> field and tabulate the same date on Empty Release <Date> -> GSO-1033
      const newDate = new Date(dateETA.setDate((dateETA.getDate() + 1 - detentionFreeTime)));

      const formatDate = moment(newDate).format("YYYY-MM-DD");
      this.dateReleaseAt.setValue(formatDate);
      this.dateReeferCollection.setValue(formatDate);
    }
  }
  */

  // 1029 Initially valid for Release At
  initiallyReleaseAt(){
    if(this.isSingaporeSite && this.model['bContainerOwnership'] === 'COC'){
      this.cbReleaseAt.setUrl(
        this.configService.config.BASE_API.toString() +
          "/LocalMasterDepos/findByComboBoxControl/" +
          this.cookieService.getDefaultLocationCode() +
          "/depoId={query}"
      );
      this.cbReleaseAt.setValue("DEPSINSFLT");
      this.bookingMisc.bReleaseAt = "ENG KONG CTR - SIN";
    }else{
      this.cbReleaseAt.setForceValue("");
      this.bookingMisc.bReleaseAt = "";
    }
  }

  clearReaseAtValue(){
    this.cbReleaseAt.setForceValue("");
    this.bookingMisc.bReleaseAt = "";
  }

  private async getDatePluginValue(componentId): Promise<any> {
    /**
     * GSO-1029
     * Encounter a glitch when using "getDate" => always return current date if the date picker input is empty.
     * Solution: Use .datepicker().val() instead.
     */
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve($('#' + componentId + "-date-input").val());
      });
    });
  }

  private setDatePluginValue(componentId, value) {
    /**
     * GSO-1029
     * Encounter a glitch when using "setDate" => always set current date if the value is undefined/null/empty.
     * Solution: Reset the date picker field when value is undefined/null/empty.
     */
    if (!value) {
      $('#' + componentId + "-date-input").datepicker('reset');
      return;
    }

    $('#' + componentId + "-date-input").datepicker('setDate', value);
  }

    // GSO-1034 When Depot=Y, Bkg Status =N/U -- Modification detected (Click <Modify>, <Save or Save & Copy>), depot transmission checkbox will be reset <N>
    checkDepotTransmissionStatus(){
      if(this.isSingaporeSite){
        if(this.model.bTransmitToSds === "Y" && (this.model.bStatus === "N" || this.model.bStatus === "U")){
          this.isCheckedDepot = false;
        }
      }
    }

    pasteValidate(event: any, model: any, field: string, regex: string, comboPluginName: string, lengthChar: Number){
      let re = new RegExp(regex, "g");
      let pastedText = event.clipboardData.getData("text").replace(re, "");
      event.preventDefault();
      if (pastedText.length > lengthChar) pastedText = pastedText.slice(0, lengthChar);
      event.target.value = pastedText;
      model[field] = pastedText;
      this[comboPluginName].setForceValue(pastedText);
      if (pastedText.length > 0) model[`error-${field}`] = "";
    }

}

import { Component, OnInit, AfterViewInit, ViewChild, Input } from "@angular/core";
import { ComboPluginComponent,ConfigService, Validator, GridPluginComponent,ToolbarPluginComponent,CookieService, GenericService, GenericUtil } from "sibego-ui-library";
import { Router } from "@angular/router";
import { element } from "@angular/core/src/render3/instructions";
import { chinaBL } from "../shared/models/chinaBL";
import { finalize } from "rxjs/operators";
import * as moment from 'moment';

declare var $: any;

export class Param {
  parafileName: string = "";
  selectedBl:any[];
  constructor() {}
}

@Component({
  selector: "app-edi-process-bl-page",
  templateUrl: "./edi-process-bl-page.component.html",
  styleUrls: ["./edi-process-bl-page.component.css"]
})
export class EDIProcessBlPageComponent extends Validator implements OnInit, AfterViewInit {
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("grid2") grid2: GridPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;
  @ViewChild("cbFileName") cbFileName: ComboPluginComponent;
  dialog: any;

  // Footer information
  info = "";
  appStatusLabel = "";
  tabStatus = "tab1";
  tabStatus1 = "active";
  tabStatus2 = "";

  // Toolbar button settings
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  modeForm = "false";
  loading = false;
  lock=true;

  // Toolbar and grid settings
  settingToolbar1: any;
  settingToolbar2: any;
  settingGrid: any;
  settingGrid2: any;
  settingFileName: any;


  validatorRules = {};

  param = new Param();
  strParams: string="";
  model=new chinaBL();
  createList:any =[];
  blCreateConfirmAllPayload = null;
  unconfirmedList = [];
  isConfirm: Boolean = false;
  filename: string="";
  listToProceed=[];
  success: Boolean=false;
  parties:any =[];



  constructor(
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private genericService: GenericService,
    private genericUtil: GenericUtil,

  ) {
    super();

    // Toolbar 1 settings
    this.settingToolbar1 = {

      buttonsFront: [{ name: "Close", event: "close", icon: "power" }],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BL EDI-Listing"
    };

    // Toolbar 2 settings
    this.settingToolbar2 = {

      buttonsFront: [
        {name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Create", event: "create", icon: "plus" },
        { name: "Close", event: "close", icon: "power" }
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Create / Overwrite BL(s)"
     };

 // Configure the grid
 this.settingGrid = {
  url: this.configService.config.BASE_API.toString() +"/shaBL/retrieveBlList",
  page: 10,
  columns: [
    { header: "Seq", field: "no", width: 50 },
    { header: "File Name", field: "fileName", width: 250 },
    { header: "Vessel Name", field: "vesselName", width: 170 },
    { header: "Voyage No.", field: "voyageNo" },
    { header: "Total No. of BL", field: "totalNoOfBL" },
    { header: "No. of BL pending actions", field: "noOfPendingBL" },
    { header: "Date Received", field: "fileDate" },
    { header: "Time Received", field: "fileTime" }
  ],
  buttons: [{ name: "Edit", event: "edit", enabled: true }],
  enableSorting: true,
  enableSelectAll: true,
  enablePagination: true,
  sortingColumns: "bl_edi_china_datetime",
  sortingDirection: "DESC",
};


    // Grid 2 settings
    this.settingGrid2 = {
      url: this.configService.config.BASE_API.toString() +"/shaBL/retrieveBlsInEdi",
      //page: 10,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "Created", field: "isProcessed", width: 50 },
        { header: "Vessel Name", field: "vesselName", width: 170 },
        { header: "Voyage No.", field: "voyageNo" },
        { header: "BL No.", field: "blNo" },
        { header: "Shipper", field: "shipper" },
        { header: "Consignee", field: "consignee" },
        { header: "Notify Party", field: "notifyParty" },
        { header: "POR", field: "por" },
        { header: "POL", field: "pol" },
        { header: "POD", field: "pod" },
        { header: "PODL", field: "podl" },
        { header: "FDEST", field: "fdest" },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
     // enablePagination: true,
      sortingColumns: "bl_no",
      sortingDirection: "ASC",
    };

    this.settingFileName = {
      id: "cbFileName",
      url: this.configService.config.BASE_API.toString() +"/shaBL/retrieveBLsListComboBox/{query}",
      type: "search enter", // search | select | select input
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "filename",
      description: "",
      isMark: true,
      columns: [
        {  field: "filename", width: 850 },

      ],
    }


  }

  ngOnInit(): void {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit(): void {

    $(".test.checkbox").checkbox();
    $(".special.cards .image").dimmer({ on: "hover" });


/*     if (localStorage.getItem("create-bl-from-china-blprocess") != null &&
      localStorage.getItem("create-bl-from-china-blprocess") != undefined &&
      localStorage.getItem("create-bl-from-china-blprocess") != "undefined")
      {
        this.strParams = localStorage.getItem("create-bl-from-china-blprocess");
        if (this.strParams != null)
          {
          let par: any = JSON.parse(this.strParams);
          //this.model.ediId=par.bEDIid;
          this.model.ediFileName=par.fileName;
          this.cbFileName.setValue(this.model.ediFileName);

          localStorage.removeItem("create-bl-from-china-blprocess");
          this.tabStatus = "tab2";
          this.tabStatus1 = "";
          this.tabStatus2 = "active";
          this.grid2.onClear();
          this.handleRetrieve();

          }
      }
      else
      { */
        this.param.selectedBl =[];

        // Make sure the grid is initialized after the view is ready
        if (this.grid) {
          // Ensure the grid has fully loaded before calling onFirst
              setTimeout(() => {
                this.grid.search = "*/*";
                this.grid.onFirst();  // Load the grid data

              }, 500);
        } else
        {
          console.warn("Grid is not initialized yet.");
        }
        this.disableToolbarButtons = "create";
     // }

    var self = this;

}


  toolbarEvent(event: string): void {
    switch (event) {
      case "close":
        this.router.navigateByUrl("/main/home");
        break;
      default:

        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
        }

        break;

    }
  }

  toolbarEvent2(event: string): void {
    switch (event) {
      case "retrieve":
        this.grid2.clearSelectedValues();
        this.grid2.onClear();
        this.handleRetrieve();
        break;
      case "create":
        this.handleCreate();
        break;
      case "close":
        this.router.navigateByUrl("/main/home");
        break;
      default:
        console.warn(`Unhandled toolbar event 2: ${event}`);
        break;
    }
  }

  markDefaultRow(dataNo: number): void {
    const gridId = this.grid.id;
    $(`#${gridId}_dataTables_scrollBody`)
      .find("table tbody tr")
      .eq(dataNo)
      .css({ cursor: "pointer" });
  }

  gridEvent(event: string): void {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          console.log("Row selected");
          this.lock=false;

        }
        else
        {
          // No rows are selected
          console.log("No row selected");
          this.lock = true;
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          console.log("Row clicked");
          this.lock=false;
        }
        break;
      case "afterLoad":
        setTimeout(() => {
          var sTime='';
           // Ensure listStore and store are defined before iterating over them
          if (this.grid.listStore && this.grid.listStore.store) {
            /* this.grid.listStore.store.forEach((_element, idx) => {
              //element["fileTime"]=this.convertToDateString( element["fileTime"] );
              this.markDefaultRow(idx); */
              this.grid.listStore.store.forEach((element) => {

                sTime=element["fileTime"];
                element["fileTime"]= sTime + ':00';
            });
          } else {
            console.warn("listStore or store is not initialized yet for grid");
          }
        }, 1000);
        this.lock=true;


    }
  }

  gridEvent2(event: string): void {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid2.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "";
        }
        else {

          this.disableToolbarButtons = "create";
        }
        break;
      case "click":
        if (this.grid2.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "";
        }
        else {

          this.disableToolbarButtons = "create";
        }
        break;
      case "afterLoad":
        setTimeout(() => {
          // Check if listStore and store are defined before using them
          if (this.grid2.listStore && this.grid2.listStore.store) {
            this.grid2.listStore.store.forEach((_element, idx) => {
              this.markDefaultRow(idx);

                if (_element.isProcessed === "Y") {
                    _element.isProcessed = "Y"; // Keep "Y"
                  } else {
                    _element.isProcessed = ""; // Set to empty for "N"
                  }

               });
          }
        }, 1000);
        //this.cbFileName && this.cbFileName.setValue("");
        this.disableToolbarButtons = "create";
        break;
      default:
        this.disableToolbarButtons = "create";
        this.handleGridEvent(event);

        break;
    }
  }


  handleGridEvent(event: string): void {
    const eventArr = event.split("-");
    switch (eventArr[0]) {
      case "edit":
        console.log("Edit action triggered");
        break;
      default:
        console.log(`Unhandled event: ${event}`);
        break;
    }
  }

  initTab1(): void {
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.tabStatus2 = "";
    this.lock=true
    this.loading=true;
    this.grid.search = "*/*";
    this.grid.onFirst();


  }

  initTab2(): void {
    this.tabStatus = "tab2";
    this.tabStatus1 = "";
    this.tabStatus2 = "active";
  }

  discard(e) {
    this.loading = true;
    let deleteList = [];

    // Get selected rows
    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.fileName);
    });

    // Check if at least one row is selected
    if (deleteList.length > 0) {
      this.message(
        "delete",
        "Deletion Record",
        "Do you want to discard the selected BL EDI?",
        "yesno",
        { yes: "this.deleteEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
      // Optional: You can show a message indicating no row was selected
      this.message(
        "information",
        "No Selection",
        "Please select at least one record to delete.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    }
  }


  deleteEvent() {
    //// console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.fileName);
    });


    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"fileName":[]}
    paramsDelete.fileName = deleteList;

    this.genericService.PUT(`${this.configService.config.BASE_API}/shaBL/deleteBL`, paramsDelete)
    .subscribe(
      (resp) => {
        console.log('Full Response:', resp); // Log the entire response

        const message = resp._body; // Directly access the message in the response body

        this.grid.listStore.store = [];
        this.grid.loadData();

        this.loading = false;

        // Display a message based on the response

        if (message !== 'Success') {
          this.message(
            "information",
            "Information",
            message || "EDI process Failed.", // Fallback message if message is undefined
            "okonly",
            { ok: "this.loading=false;" }
          );
          return;
        }
        else{

        // Show success message
          this.message(
            "information",
            "Information",
            "BL EDI discarded successfully.",
            "okonly",
            { ok: "this.loading=false;this.afterDelete();" }
          );

        }
      },
      (error) => {
        // Handle error response
        this.loading = false;
        this.info = error.message;

        // Optionally show an error message
        this.message(
          "error",
          "Error",
          `Failed to delete record. Error: ${error.message}`,
          "okonly",
          { ok: "this.loading=false;" }
        );
      }
    );
}
  afterDelete() {

    this.grid.loadData();
    this.grid.clearSelectedValues();
    this.lock=true;
  }

  changeEventFileName(event : any) {

    console.log ("eventfilename:",event.filename )
  if (event.filename == null || event.filename == undefined || typeof event.filename === 'undefined') {
    this.param.parafileName = '';
  } else
  {
    this.param.parafileName = String(event.filename).trim();
  }


  //this.param.parafileName  = String(event.filename).trim();

  this.grid2.onHistory(this.param.parafileName );
  // re set the url to avoid errors
  this.cbFileName.setUrl(
    this.configService.config.BASE_API.toString() +
    '/shaBL/retrieveBLsListComboBox/{query}');

  }


  //to check again this part if need param or not
  /* changeEventFileName(event : any) {

     this.param["error-fileName"] = null;
     console.log("query",event.filename);
      if (event && event.target )
      {
        this.param.parafileName = "*";
      }
      else
      {
        this.param.parafileName = event.filename;
      }

      this.param.parafileName = String(event.filename).trim();

      if (!this.param.parafileName || this.param.parafileName === '') {
        this.param.parafileName = "*";
      }
      this.cbFileName.setUrl(
        this.configService.config.BASE_API.toString() +"/sha/retrieveBLsListComboBox/{query}");
   }
 */
/*    onComboKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const inputElement = event.target as HTMLInputElement;
      console.log("query =*");

      event.preventDefault();
      // Check if the combo box value is blank
      if (!inputElement.value || inputElement.value.trim() === '') {
        console.log("element", inputElement.value);
        this.param.parafileName = "*";
        console.log("filename:",this.param.parafileName );

        this.cbFileName.setUrl(
          this.configService.config.BASE_API.toString() +"/sha/retrieveBLsListComboBox/{query}");
        this.grid2.onHistory(this.param.parafileName);
      }
    }
  } */


   handleRetrieve() {

    console.log("parafilename:",this.param.parafileName)
    if (this.param.parafileName != "" && this.param.parafileName != "undefined") {
      this.grid2.search=this.param.parafileName;
      this.grid2.loadData();
      this.filename=this.param.parafileName;

      //this.param.parafileName="";

   }
   else
   {  this.message(
    "Information",
    "information",
    'Please select a file.',

    "okonly",
    { ok: "" }  );}
  }

  refresh() {

    if (this.filename != "" && this.filename != "undefined") {
      //this.grid2.search=this.param.parafileName;

      this.grid2.loadData();

   /*    if (this.grid2.listStore && this.grid2.listStore.store) {
        console.log (" Update grid data source with party information");
        this.grid2.listStore.store.forEach((element) => {
          const matchingParty = this.parties.find(party => party.blNo === element.blNo);
          if (matchingParty) {
            element["shipper"] = matchingParty.shipper;
            element["consignee"] = matchingParty.consignee;
            element["notifyParty"] = matchingParty.notifyParty;
          }
        });

        // Trigger a grid reload or refresh to reflect changes
        this.grid2.loadData();
      } */

      this.grid2.clearSelectedValues();

      //this.filename="";
   }
  }

  handleCreate(){

  this.unconfirmedList =[];
  let dt =[];
  this.listToProceed=[];
  this.parties =[];
  this.success=false;

   dt = this.grid2.getSelectedValues()[0];
    if (dt.length < 1) {

      return this.message(
        "Information",
        "information",
        'Please select at least 1 record to create.',
        "okonly",
        { ok: "" }
      );
    }
    else{ this.createList=[];

          this.grid2.getSelectedValues().forEach((element) => {
          this.createList.push({  blNo: element.blNo,
                                  vesselName:element.vesselName,
                                  vesselCode:element.vesselCode,
                                  voyageNo:element.voyageNo,
                                  isProcessed:element.isProcessed === "Y" ? "Y" : "N",
                                  shipper:element.shipper,
                                  consignee:element.consignee,
                                  notifyParty:element.notifyParty,
                                  por:element.por,
                                  pol:element.pol,
                                  pod:element.pod,
                                  podl:element.podl,
                                  fdest:element.fdest,
                                  //Status:element.Status,
                                  user:this.cookieService.getName(),
                                  fileName:element.fileName,
                                  officeCode:element.officeCode
                                });

          });
          this.grid2.getSelectedValues().forEach((element) => {
            this.parties.push({  blNo: element.blNo,
                                 shipper:element.shipper,
                                 consignee:element.consignee,
                                 notifyParty:element.notifyParty,
                              });

            });

        console.log("BL List to create:",this.createList );
          this.listToProceed=this.createList;
        this.genericUtil.showLoader();
        const disableToolbarButtons = this.disableToolbarButtons.concat(",create");
        this.disableToolbarButtons = disableToolbarButtons;

        this.genericService.POST(
          this.configService.config.BASE_API.toString() + "/shaBL/processBlsInEdi/",this.createList
        )
        .pipe(
          finalize(() => {
            const disableToolbarButtons = this.disableToolbarButtons.replace(',create', '');
            this.disableToolbarButtons = disableToolbarButtons;
            this.blCreateConfirmAllPayload = null;
            this.genericUtil.hideLoader();
          })
        )
        .subscribe(
          (resp) => {
            const response = resp.json();

            if (response.length > 0) {

              // this.unconfirmedList = response.filter(obj => obj.isProcessed === 'Y' || obj.Status === 'No BKG found' ).map(obj => ({ ...obj, isConfirm: 'N' }));
              //this.unconfirmedList = response.filter(obj => obj.isProcessed === 'Y' ).map(obj => ({ ...obj, isConfirm: 'N' }));
              const successItems = response.filter(item => item.success === 'success');
              const nonSuccessItems = response.filter(item => item.success !== 'success'); //return existing bl and no bkg BL list to show in prompter

              console.log ("successItems", successItems);
              console.log ("nonSuccessItems", nonSuccessItems);
              this.unconfirmedList = response.filter(item => item.success !== 'success').map(obj => ({ ...obj, isConfirm: 'N' }));
              // if (this.unconfirmedList.length > 0)
              if (successItems.length > 0 && nonSuccessItems.length > 0) //some success and bl list to confirm
                {
                // Handle unconfirmed list if success is not 'success'
                this.success=true;
                console.log("Unconfirmlist",this.unconfirmedList);
                this.handleUnconfirmedList();
                this.refresh();
                this.createList=[];

                }// else if (response[0]["success"] === 'success')
              else if (successItems.length > 0)
                { this.success=true;
                      this.message(
                      "Information",
                      "Information",
                      "BL processing completed.",
                      "okonly",
                      { ok: "this.createList=[];this.loading=false;this.refresh();" }
                    );
                }
              else if (nonSuccessItems.length > 0)
                { console.log("Unconfirmlist",this.unconfirmedList);
                  this.handleUnconfirmedList();
                  this.refresh();
                  this.createList=[];
                }

          } else {
              // Handle error scenario with a fallback message
              this.message(
                "Error",
                "Error",
                response.message || "An unexpected error occurred.",
                "okonly",
                { ok: "this.createList=[];this.loading=false;this.refresh();" }
              );

               //refresh grid

            }

          },
          (error) => {
            // Handle any errors that might occur in the POST request
            this.message(
              "Error",
              "Error",
              "Failed to process BLs. Please try again.",
              "okonly",
              { ok: "" }
            );
          }
        );


      this.grid2.clearSelectedValues();
      dt=[];
      }

    }


    afterProcess() {


      this.disableToolbarButtons = "create";

      //this.modeToolbar=false;

      this.grid2.clearSelectedValues();
      this.grid2.loadData();

    }

    /*  if (data.createList !=="")
        {
         // this.continueToMaintenance(data);
        }
        else
        {  return this.message(
          "Information",
          "information",
          'There is no data to display.',
          "okonly",
          { ok: "" }
        );

        } */


  /* continueToMaintenance(data: any[]) {
    this.genericUtil.showLoaderPage();
    var self = this;

    //setTimeout(function () {
      self.param.selectedBl = data;


      console.log("create-bl-from-china-blprocess");
      //console.log(self.rowData);
      console.log(self.param);

      if (localStorage.getItem("create-bl-from-china-blprocess") != null &&
      localStorage.getItem("create-bl-from-china-blprocess") != undefined &&
      localStorage.getItem("create-bl-from-china-blprocess") != "undefined" )
      {
        localStorage.removeItem("create-bl-from-china-blprocess");
      }

      localStorage.setItem("create-bl-from-china-blprocess",JSON.stringify(data));

      /* if (this.viewOnlyStatus)
      {
        localStorage.setItem("view-only-mode", "Y");
      } */

 /*     localStorage.setItem("create-bl-from-china-current-page", self.grid.getCurrentPage().toString());

      self.router.navigate(["/main/transaction-bl-maintenance-latest"]);
    //}, 100);
  } */
 //3327

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any): void {
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event: eve };
    $("#dialog").modal("show");
  }

  infoGrid(event: any): void {
    this.info = event;
  }
  infoGrid2(event: any): void {
    this.info = event;
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

   handleUnconfirmedList() {
    let heightDialog = 215;
    if (this.unconfirmedList.length > 3) {
      heightDialog = 450;
    }
    if (this.unconfirmedList.length == 3) {
      heightDialog = 350;
    }
    if (this.unconfirmedList.length == 2) {
      heightDialog = 300;
    }
    if (this.unconfirmedList.length == 1) {
      heightDialog = 250;
    }
    this.genericUtil.showDialog(
      `UnconfirmedList`,
      "Confirmation!",
      1000,
      heightDialog
    );
  }

  proceed() {
    console.log("** click proceed **");
    this.isConfirm = true;
    let proceedList =[];
    let notProcessedList =[];

  let promterFilteredList = this.unconfirmedList.filter(item => item.isConfirm === "Y" );
  //let promterFilteredList = this.unconfirmedList.filter(item => item.isConfirm === "Y" || item.Status==="No BKG found" ); // Filter unconfirmedList where isConfirm = "Y"

/*  let filterNonProcessedList = this.listToProceed.filter(item => item.isProcessed === "N" ); // Filter non-created list where isProcessed is "N"

  if(promterFilteredList.length !== 0 && filterNonProcessedList.length !== 0) // take out the duplicate Bls from selected list which are not prompted in prompter
    { filterNonProcessedList = filterNonProcessedList.filter(
      item => !promterFilteredList.some(promterItem => promterItem.BL_no === item.BL_no));

        console.log("Non-Processed List after deletion:", filterNonProcessedList);
    }
 */
  // Check if the filtered list has no data
  if (promterFilteredList.length !== 0)
  {
     proceedList = promterFilteredList.map(item =>
    {
      return {
        blNo: item.blNo,
        vesselName: item.vesselName,
        vesselCode: item.vesselCode,
        voyageNo: item.voyageNo,
        shipper: item.shipper,
        consignee: item.consignee,
        notifyParty: item.notifyParty,
        por: item.por,
        pol: item.pol,
        pod: item.pod,
        podl: item.podl,
        fdest: item.fdest,
        officeCode: this.cookieService.getDefaultLocationCode().toString().trim(),
        isProcessed: item.isProcessed,
        Status: item.Status,
        fileName: item.fileName,
        user: localStorage.getItem("logged").split("|")[0]

      };
    });
  }

 /* console.log("filterNonProcessedList:",filterNonProcessedList.length);
 console.log("create list:", this.listToProceed.length);
 if (filterNonProcessedList.length !== 0)
  { notProcessedList = filterNonProcessedList.map(item => {
    return {
              blNo: item.blNo,
              vesselName: item.vesselName,
              vesselCode: item.vesselCode,
              voyageNo: item.voyageNo,
              shipper: item.shipper,
              consignee: item.consignee,
              notifyParty: item.notifyParty,
              por: item.por,
              pol: item.pol,
              pod: item.pod,
              podl: item.podl,
              fdest: item.fdest,
              officeCode: this.cookieService.getDefaultLocationCode().toString().trim(),
              isProcessed: "N",  // Ensuring isProcessed = "N"
              fileName: item.fileName,
              user: localStorage.getItem("logged").split("|")[0]
            };
    });
 }
 */
  //  let combinedList = [...proceedList, ...notProcessedList]; //Combine
  let combinedList = [...proceedList];
    if (combinedList.length !== 0 ) //check if records exist in the list to pass back to process
    {
        let body = combinedList;

        let urlReqest = this.configService.config.BASE_API.toString() + "/shaBL/saveBlsSelectedByUser";
        this.genericService.POST(urlReqest, body).subscribe(
          (resp) => {
            this.isConfirm = false;

             if (resp._body==="Success") {

              this.genericUtil.closeDialog("UnconfirmedList");
              this.message(
                "Information",
                "Information",
                "BL processing completed.",
                "okonly",

                { ok: "" }

              );

              this.refresh();

            } else {
              this.message("error", "Error", resp.json().message, "okonly", {
                ok: "",
              });
            }
          },
          (error) => {
            this.message(
              "error",
              "Error",
              "An error has occurred. Please report to Administrator!",
              "okonly",
              { ok: "" }
            );
            this.isConfirm = false;
          }
        );

    } else {this.closeUnconfirmedList();}
    combinedList=[];
    // this.message(
    //   "Information",
    //   "Information",
    //   "BL processing completed.",
    //   "okonly",

    //   { ok: "" }

    // );

    this.refresh();

  }


  closeUnconfirmedList() {
    this.genericUtil.closeDialog("UnconfirmedList");
    console.log("success",this.success);
    if (this.success)
     { this.message(
        "Information",
        "Information",
        "BL processing completed.",
        "okonly",

        { ok: "" });
     }


    this.unconfirmedList = [];
    this.createList=[];
    this.listToProceed=[];
  }

  convertToDateString(fileTime: string): string {
    return moment(fileTime, 'HH:mm').format('HH:mm:ss');
  }

}
<form class="ui raised segment">
  <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <button class="ui button" style="display:none;"></button>
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="displayToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" ></toolbar-plugin>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="param['error-vesselNo'] == null || param['error-vesselNo'] == '' || param['error-vesselNo']==undefined">
          {{param['error-vesselNo']}}
        </li>
        <li [class.hidden]="param['error-voyageNo'] == null || param['error-voyageNo'] == '' || param['error-voyageNo']==undefined">
          {{param['error-voyageNo']}}
        </li>
      </ul>
    </div>
  
    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <div class="field">
          <div class="ui top attached tabular menu" style="background-color: beige">
            <a
              class="item"
              *ngIf="lockDashboard == false"
              [class.active]="tabStatus == 'tab1'"
              data-tab="blDashboardTab"
              (click)="tabDashboardClicked()"
            >Dashboard<i
              *ngIf="lockTab1 == true"
              class="lock icon"
              style="margin-left: 5px; color: red"
            ></i
            ></a>
            <span class="item" *ngIf="lockDashboard == true" style="color: darkgray"
            >Dashboard<i
              *ngIf="lockTab1 == true"
              class="lock icon"
              style="margin-left: 5px; color: red"
            ></i
            ></span>

            <a
              class="item"
              *ngIf="lockTransmissionLock == false"
              [class.active]="tabStatus == 'tab2'"
              data-tab="blTransmissionLockTab"
              (click)="tabTransmissionLoclClicked()"
            >Transmission Lock<i
              *ngIf="lockTab2 == true"
              class="lock icon"
              style="margin-left: 5px; color: red"
            ></i
            ></a>
            <span class="item" *ngIf="lockTransmissionLock == true" style="color: darkgray"
            >Transmission Lock<i
              *ngIf="lockTab2 == true"
              class="lock icon"
              style="margin-left: 5px; color: red"
            ></i
            ></span>

            <a
              class="item"
              *ngIf="lockTransmissionUnLoack == false"
              [class.active]="tabStatus == 'tab3'"
              data-tab="blTransmissionUnlockTab"
              (click)="tabTransmissionUnlockClicked()"
            >Transmission Unlock<i
              *ngIf="lockTab3 == true"
              class="lock icon"
              style="margin-left: 5px; color: red"
            ></i
            ></a>
            <span class="item" *ngIf="lockTransmissionUnLoack == true" style="color: darkgray"
            >Transmission Unlock<i
              *ngIf="lockTab3 == true"
              class="lock icon"
              style="margin-left: 5px; color: red"
            ></i
            ></span>
          </div>
        </div>
      <!-- Tab Dashboard Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
        id="blDashboardTab"
        data-tab="blDashboardTab"
      >
        <div class="ack-content">
          <i class="bell icon ack-icon"></i>
          <div class="ack-column">
            <span class="ack-text ack-number">{{ param['totalAck'] || 0 }}</span>
            <span class="ack-text">Ack Required</span>
          </div>
        </div>
        <div class="field" style="margin-bottom: 0px">
          <!-- 1st line -->
          <div class="fields">
            <div class="four wide field" [class.error]="param['error-from'] != null && param['error-from'] != ''" >
              <label>Transmitted Date From</label>
              <input style = "width : 98%" type="date" name="from"  placeholder="Date" [(ngModel)]="param['from']" >
            </div>
            <div class="four wide field" [class.error]="param['error-to'] != null && param['error-to'] != ''" >
              <label>Transmitted Date To</label>
              <input style = "width : 98%" type="date" name="to"  placeholder="Date" [(ngModel)]="param['to']" >
            </div>
            <div class="four wide field" [class.error]="param['error-vesselNo'] != null && param['error-vesselNo'] != ''" >
              <label>Vessel</label>
              <combo-plugin
              #cbVessel
              [settings]="settingVessel"
              (change)="changeEventVessel($event)"
              (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
              (keyup)="backspaceValue($event,formValidation.regVesVoy, 'vesselNo', false, 'and -./', 'Vessel')"
              (paste)="
                onPasteValidation(
                  $event,
                  'vesselNo',
                  formValidation.regVesVoy,
                  'and -./'
                )
              "
            ></combo-plugin>
              <!-- <input style = "width : 98%" type="text" name="vesselNo"  placeholder="Search" [(ngModel)]="param['vesselNo']" > -->
            </div>
            <div class="four wide field" [class.error]="param['error-voyageNo'] != null && param['error-voyageNo'] != ''" >
              <label>Voyage</label>
              <combo-plugin
              #cbVoyage
              [settings]="settingVoyage"
              (change)="changeEventVoyage($event)"
              (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
              (keyup)="backspaceValue($event,formValidation.regVesVoy, 'voyageNo', false, 'and -./', 'Voyage')"
              (paste)="
                onPasteValidation(
                  $event,
                  'voyageNo',
                  formValidation.regVesVoy,
                  'and -./'
                )
              "
            ></combo-plugin>
              <!-- <input style = "width : 98%" type="text" name="voyageNo"  placeholder="Search" [(ngModel)]="param['voyageNo']" > -->
            </div>
          </div>
          <!-- 2nd line -->
          <div class="fields">
              <div class="four wide field" [class.error]="param['error-pol'] != null && param['error-pol'] != ''" >
                <label>Load Port</label>
                <combo-plugin
                  #cbPOL
                  [settings]="settingPOL"
                  (change)="changeEventPOL($event)"
                  (keypress)="formValidation.CharNumOnly($event)"
                ></combo-plugin>
                <!-- <input style = "width : 98%" type="text" name="pol"  placeholder="Port Of Loading" [(ngModel)]="param['pol']" > -->
              </div>
              <div class="four wide field" [class.error]="param['error-pot'] != null && param['error-pot'] != ''" >
                <label>Transhipment Port</label>
                <combo-plugin
                  #cbPOT1
                  [settings]="settingPOT1"
                  (change)="changeEventPOT1($event)"
                  (keypress)="formValidation.CharNumOnly($event)"
                ></combo-plugin>
                <!-- <input style = "width : 98%" type="text" name="pot"  placeholder="Port of Transhipment" [(ngModel)]="param['pot']" > -->
              </div>
              <div class="eight wide field">
                <toolbar-plugin [settings]="settingToolbar"  (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
              </div>
          </div>
        </div>
        <div class="fields">
          <div class="sixteen wide field">
            <grid-plugin id="gridHeader" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
          </div>
        </div>  
      </div>

      <!-- Tab TransmissionLock Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus2 == 'active' && tabStatus == 'tab2'"
        id="blTransmissionLockTab"
        data-tab="blTransmissionLockTab"
      >
      <div class="field">
        <toolbar-plugin [settings]="transmissionLockToolbar"  (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

        <div class="field">

          <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%"  id="myfieldset">

            <legend style="font-weight:bold;color:dodgerblue;"></legend>

            <div class="four wide fields">
              <div class="four wide field" [class.error]="param['error-vesselNo'] != null && param['error-vesselNo'] != ''" >
                <label>Vessel</label>
                <combo-plugin
                #cbVesselTransLock
                [settings]="settingVesselTransLock"
                (change)="changeEventVesselTransLock($event)"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (keyup)="backspaceValue($event,formValidation.regVesVoy, 'vesselNo', false, 'and -./', 'Vessel')"
                (paste)="
                  onPasteValidation(
                    $event,
                    'vesselNo',
                    formValidation.regVesVoy,
                    'and -./'
                  )
                "
              ></combo-plugin>
              </div>

              <div class="four wide field" [class.error]="param['error-voyageNo'] != null && param['error-voyageNo'] != ''" >
                <label>Voyage</label>
                <combo-plugin
                #cbVoyageTransLock
                [settings]="settingVoyageTransLock"
                (change)="changeEventVoyageTransLock($event)"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (keyup)="backspaceValue($event,formValidation.regVesVoy, 'voyageNo', false, 'and -./', 'Voyage')"
                (paste)="
                  onPasteValidation(
                    $event,
                    'voyageNo',
                    formValidation.regVesVoy,
                    'and -./'
                  )
                "
              ></combo-plugin>
              </div>

              <div class="four wide field">
                <label>Bound</label>
                <input type="text" name="boundFrom" placeholder="Bound" [(ngModel)]="param['bound']" >
              </div>
            </div>
            <div class="switch-container">
              <label class="switch">
                <input type="checkbox" [checked]="isSwitchChecked" [disabled]="isSwitchDisabled" (change)="onSwitchChangeTransLock($event)">
                <span class="slider round"></span>
              </label>
              <span class="switch-label">Lock</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label style="color: red;">{{ labelText }}</label>
            </div>

          </fieldset>

        </div>
      </div>

      </div>

      <!-- Tab TransmissionUnlock Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus3 == 'active' && tabStatus == 'tab3'"
        id="blTransmissionUnlockTab"
        data-tab="blTransmissionUnlockTab"
      >
      <toolbar-plugin [settings]="transmissionUnlockToolbar"  (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

      <div class="field">
        <fieldset style="border: 1px solid lightgray;width: 100%">
          <legend style="font-weight:bold;color:dodgerblue;">Transmission Unlock Selection</legend>

          <div class="field">
            <div class="two fields">
    
                <div class="field" style="width:35%">   
                    <div class="field">     
                      <div class="ui radio checkbox" >
                        <input disabled type="checkbox"  name="isUnlock" tabindex="0" class="hidden" (change)="changeType('chVessel')" [checked]="isUnlock == 'chVessel'">
                        <label style="color: grey;">By Vessel/Voyage</label>
                      </div>
                    </div>
                      <br>
                      <div class="field" style="height:325px">
                        <fieldset style="border: 1px solid lightgray;height:325px">
      
                            <br>
                            <div class="field">                  
                             <br>
                              <div class="field">
    
                                <label style="color: grey;">Vessel</label>
                                <combo-plugin style="color: grey;"
                                  #cbVesselTransUnlock
                                  [settings]="settingVesselTransUnlock"
                                  (change)="changeEventVesselTransUnlock($event)"
                                  [disableCombo]="true"
                                  (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                                  (keyup)="backspaceValue($event,formValidation.regVesVoy, 'vesselNo', false, 'and -./', 'Vessel')"
                                  (paste)="
                                    onPasteValidation(
                                      $event,
                                      'vesselNo',
                                      formValidation.regVesVoy,
                                      'and -./'
                                    )
                                  "
                                ></combo-plugin>
                              </div>
                              <br>

                              <div class="field" >
                                <label style="color: grey;">Voyage</label>
                                  <combo-plugin
                                    #cbVoyageTransUnlock
                                    [settings]="settingVoyageTransUnlock"
                                    (change)="changeEventVoyageTransUnlock($event)"
                                    (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                                    (keyup)="backspaceValue($event,formValidation.regVesVoy, 'voyageNo', false, 'and -./', 'Voyage')"
                                    (paste)="
                                      onPasteValidation(
                                        $event,
                                        'voyageNo',
                                        formValidation.regVesVoy,
                                        'and -./'
                                      )
                                    "
                                  ></combo-plugin>
                              </div>
                              <br>
                              <div class="field">
                                <label style="color: grey;">Bound</label>
                                <input type="text" name="boundFrom" placeholder="Bound" [(ngModel)]="param['bound']" disabled>
                              </div>
                              <br>
                          </div>
                        </fieldset>
                    </div>
                </div>
     
              <div class="field" style="width:84%">
                <div class="field">
                    <div class="field">
                      <div class="ui radio checkbox" >
                        <input type="checkbox"  name="isUnlock" tabindex="0" class="hidden" (change)="changeType('chBL')" [checked]="isUnlock == 'chBL'">
                        <label>By BL Reference</label>
                      </div>
                    </div>
                    <br>
                    <div class="field">
                      <fieldset style="border: 1px solid lightgray;width:98%;">  
                          <button class="tiny ui positive button" [disabled]="cButtonLock" (click) = "addNewRow();" >+</button>
                          <button class="tiny ui negative button" [disabled]="dButtonLock" (click) = "removeRow();">-</button>	    
                          
                          <input type="hidden" name="gridCount" value="0">   
                          <grid-plugin id="gridTransUnLock" #gridTransUnLock [settings]="settingGridTransUnLock" (gridEvent)="gridEventTransUnlock($event)" (infoGrid)="infoGrid($event)"></grid-plugin>                     
                      </fieldset>
                    </div>
                </div>
              </div>
            </div>
          </div>
              
        </fieldset>
      </div>
      </div>
    </form>
  </form>

  <div id="blToFlagStatus"  style="display:none;font-family:Lucida Console, monospace;">
    <form  class="ui form">
      <!-- form start -->
      <div class="field" style="margin-bottom: 5px;">
        <p><i class="question circle big icon" style="float:left;width:1.3em;height:1.0em;text-align: justify;padding-left: .2em;padding-top: .2em"></i>
          {{frmDialogMessage}}</p>
      </div>
      <div class="field" style="text-align: right">
        <button class="positive ui button" (click)="onDialogYes()" >Yes</button>
        <button class="negative ui button" (click)="onDialogNo()" >No</button>
      </div>
    </form>
  </div>


<footer-plugin [info]="info"></footer-plugin>